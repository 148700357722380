import { LoadingOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { Card, Col, Row, Space, Spin, Tabs } from "antd";
import dayjs from "dayjs";
import { useParams } from "react-router";
import GET_MATCH_BY_ID from "../../api/get_match_by_id";

import "./style.scss";
import EventsTab from "./tabs/events/events-tab";
import LineUpTab from "./tabs/lineup/lineup-tab";
import PreferenceTab from "./tabs/preferences/preference";
import withAuthProtection from "../../utils/auth";

const MatchDetails = ({}) => {
  const { id: matchId } = useParams();
  const { data: matchData, loading: matchLoading } = useQuery(GET_MATCH_BY_ID, {
    variables: { id: matchId },
  });
  console.log("matchData", matchData);
  const tabs = [
    {
      key: "1",
      label: `Lineups`,
      component: <LineUpTab matchData={matchData?.match} />,
    },
    {
      key: "2",
      label: `Events`,
      component: <EventsTab matchData={matchData?.match} />,
    },
    {
      key: "3",
      label: `Preferences`,
      component: <PreferenceTab matchData={matchData?.match} />,
    },
  ];

  return (
    <>
      {matchLoading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
      ) : matchData ? (
        <Card className="match-details">
          <div className="header">
            <Space size={132}>
              <div className="team">
                <img
                  className="logo"
                  src={matchData?.match?.team1?.display_picture}
                />
                <div>{matchData?.match?.team1?.name}</div>
              </div>
              V/S
              <div className="team">
                <div>
                  <img
                    className="logo"
                    src={matchData?.match?.team2?.display_picture}
                  />
                  <div>{matchData?.match?.team2?.name}</div>
                </div>
              </div>
            </Space>
          </div>
          <Row className="schedule">
            <Col>
              {dayjs(matchData?.match?.match_schedule?.start_date).format(
                "YYYY-MM-DD HH:mm"
              )}
            </Col>
          </Row>
          <div className="match-detail-tabs">
            <Col span={24}>
              <Tabs defaultActiveKey="1">
                {tabs.map((item) => (
                  <Tabs.TabPane key={item.key} tab={item.label}>
                    {item.component}
                  </Tabs.TabPane>
                ))}
              </Tabs>
            </Col>
          </div>
        </Card>
      ) : (
        "Not found Match"
      )}
    </>
  );
};

export default withAuthProtection(MatchDetails);
