import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery, useSubscription } from "@apollo/client";
import GET_MATCHES from "../../../../api/get_all_matches";
import dayjs from 'dayjs';

import './style.scss'

import {
  Col,
  Row,
  Select,
  Form,
  InputNumber,
  Popconfirm,
  Table,
  Typography,
  Input,
  Button,
  Switch,
  Tooltip,
  Modal,
  Space,
  DatePicker,
  Alert
} from "antd";
import moment from "moment/moment";
import { LoadingOutlined, EditOutlined, DeleteOutlined, SaveOutlined, CloseOutlined, FormOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import FIND_MATCH_EVENTS_BY_MATCH_ID from "../../../../api/get_match_stat_match_id";
import SUBSCRIPTION_EVENT_ADDED from "../../../../api/subscribe_match_stat";
import GET_EVENT_ENUM from "../../../../api/get_event_enum";
import GET_MATCH_LINEUP_BY_ID from "../../../../api/get_match_line_ups"
import UPDATE_MATCH_EVENT_BY_ID from "../../../../api/update_match_event_by_id";
import REMOVE_EVENT_BY_ID from "../../../../api/remove_event_by_id";
import PlayerSubstitute from "../../../lineups/blocks/player-subtitute";
import GET_MATCH_BY_ID from "../../../../api/get_match_by_id";

// import './style.scss';
import { useParams, useSearchParams } from "react-router-dom";
import NewEvent from "../../../match-events/blocks/new_event";
import MatchWalkover from "../../../lineups/blocks/match_walkover";
import RUN_MATCH_AGGREGATION_MUTATION from "../../../../api/run_match_aggregation_mutation";


const EventsTab = (match) => {
const {id: selectedMatch} = useParams();
  const utc = require('dayjs/plugin/utc');
  dayjs.extend(utc);
  const { data: eventEnuList } = useQuery(GET_EVENT_ENUM, { variables: { enumName: 'MatchEventType' } });
  const { data: matchStageEnumList } = useQuery(GET_EVENT_ENUM, { variables: { enumName: 'MatchStage' } });
  const [statusMessage, setStatusMessage] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [dataForFilter, setDataForFilter] = useState(null)
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [selectedMatchTeam, setSelectedMatchTeam] = useState(null)
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isSubstituteModalOpen, setIsSubstituteModalOpen] = useState(false);
  const [isWalkOverModalOpen , setIsWalkOverModalOpen] =useState(false);
  const [selectedEventRecord, setSelectedEventRecord] = useState(null);
  const { Option } = Select;
  const { TextArea } = Input;
  const [removeEvent, { data: removeEventData, loading: removeEventLoading, error: removeEventError }] = useMutation(REMOVE_EVENT_BY_ID);
  const [getMachEvents, { data: matchEvents, loading: matchEventsLoader, refetch }] = useLazyQuery(FIND_MATCH_EVENTS_BY_MATCH_ID, {
    variables: { match_id: selectedMatch },
  });
  const [getMatchLineUps, { data: matchLineUps }] = useLazyQuery(GET_MATCH_LINEUP_BY_ID, {
    variables: { match_id: selectedMatch },
  });

  const { data: subscribeEventsData } = useSubscription(SUBSCRIPTION_EVENT_ADDED, {
    variables: { match_id: selectedMatch },
    skip: !isSubscribed,
  });
  const [updateMatchEvent, { data: updateMatchEventData, loading: updateMatchEventLoading, error: updateMatchEventError }] = useMutation(UPDATE_MATCH_EVENT_BY_ID);
  const { data: matchData, loading: matchLoading } = useQuery(GET_MATCH_BY_ID, {
    variables: { id: selectedMatch },
});

const [runMatchAggregation, { data: runMatchAggregationData, loading: runMatchAggregationLoading, error: runMatchAggregationError }] = useMutation(RUN_MATCH_AGGREGATION_MUTATION);


  useEffect(() => {
    if (selectedMatch) {
      getMachEvents();
      getMatchLineUps()
    }
  }, [selectedMatch])
  useEffect(() => {
    if (subscribeEventsData) {
      const data = [formatMatchEventsFromSubscribedData(subscribeEventsData?.eventAdded), ...tableData];

      setTableData(data)
    }
  }, [subscribeEventsData])


  const eventDropDown = () => {
    return (
      <Select>
        {eventEnuList?.enumValues?.map((enumValue) =>
          <Option key={enumValue} value={enumValue}>{enumValue}</Option>
        )}
      </Select>
    )
  }

  const matchStageDropDown = () => {
    return (
      <Select>
        {matchStageEnumList?.enumValues?.map((enumValue) =>
          <Option key={enumValue} value={enumValue}>{enumValue}</Option>
        )}
      </Select>
    )
  }

  const teamDropDown = (selectedId) => {
    return (
      <Select defaultValue={selectedId} onChange={() => { form.setFieldValue('player', undefined) }}>
        <Option key={selectedMatchTeam?.team1?.id} value={selectedMatchTeam?.team1?.id}>{selectedMatchTeam?.team1?.name}</Option>
        <Option key={selectedMatchTeam?.team2?.id} value={selectedMatchTeam?.team2?.id}>{selectedMatchTeam?.team2?.name}</Option>
      </Select>
    )
  }

  const teamPlayerDropDown = (teamId) => {
    const teamLineUp = matchLineUps?.matchLineupByMatchId?.find((team) => team.id == teamId)

    return (
      <Select>
        {teamLineUp?.lineup?.map((player) =>
          <Option key={player?.userId} value={player?.userId}>{player?.name}</Option>
        )}
      </Select>
    )
  }

  useEffect(() => {
    const originData = [];
    matchEvents?.findMatchEventsByMatchId?.map((event) => {
      originData.push(formatMatchEventsFromSubscribedData(event));
    });

    setTableData(originData);
  }, [matchEvents]);

  const matchAggregation = async () => {
    try {
      const data = await runMatchAggregation({
        variables: {
          match_id: match?.matchData?.id,
          }})
          setStatusMessage({
            type: "success",
            content: "Success",
        });
        setTimeout(() => {
          setStatusMessage(null);
        }, 3000);

    } catch(err) {
      runMatchAggregationError = err;
      setStatusMessage({
        type: 'error',
        content: `Error in match Aggregation`,
    });
    }
    
   
  }

  const formatMatchEventsFromSubscribedData = (event) => {
    return {
      key: event?.id,
      eventName: event?.event,
      time_elapsed: event?.time_elapsed,
      extra_time_elapsed: event?.extra_time_elapsed,
      // time: `${new Date(event?.time).toDateString()} ${new Date(event?.time).toTimeString().split(' ')[0]}`,
      time: dayjs(event?.time).format("YYYY-MM-DD HH:mm:ss"),
      match_stage: event?.match_stage,
      teamName: event?.team,
      player: event?.player,
      commentary: event?.commentary,
      event_meta: event?.event_meta
    }
  }

  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      ...record,
      teamName: record?.teamName?.id,
      player: record?.player?.id,
      time: dayjs(record?.time)
    })

    setEditingKey(record.key);
  };

  const advancedEdit = (record) => {
    setSelectedEventRecord(record)
    setIsCreateModalOpen(true);
  }

  const cancel = () => {
    setEditingKey("");
  };

  const deleteEvent = (record) => {
    removeEvent({
      variables: {
        match_event_id: record?.key
      }
    }).then(resp => {
      refetch()
    }).catch(err => {
      console.log(err)
    })
  }

  const onToggleSubscribe = (value) => {
    setIsSubscribed(value)
  }
  const save = async (record) => {
    const formValue = form.getFieldsValue()
    const payload = {
      event: formValue?.eventName,
      player_id: formValue?.player,
      team_id: formValue?.teamName,
      time_elapsed: Number(formValue?.time_elapsed),
      extra_time_elapsed: Number(formValue?.extra_time_elapsed),
      match_stage: formValue?.match_stage,
      commentary: formValue?.commentary,
      time: formValue?.time?.utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
    }

    await updateMatchEvent({
      variables: {
        match_event_id: record?.key,
        updateMatchEventInput: payload
      },
    });

    record.eventName = payload?.event
    record.extra_time_elapsed = payload?.extra_time_elapsed
    record.time_elapsed = payload?.time_elapsed
    record.match_stage = payload?.match_stage
    record.commentary = payload?.commentary
    record.time = payload?.time;
    setEditingKey("");

    // try {
    //   const row = await form.validateFields();
    //   const newData = [...data];
    //   const index = newData.findIndex((item) => key === item.key);
    //   if (index > -1) {
    //     const item = newData[index];
    //     newData.splice(index, 1, {
    //       ...item,
    //       ...row,
    //     });
    //     setData(newData);
    //     setEditingKey("");
    //   } else {
    //     newData.push(row);
    //     setData(newData);
    //     setEditingKey("");
    //   }
    // } catch (errInfo) {
    //   console.log("Validate Failed:", errInfo);
    // }
  };
  const columns = [
    {
      title: "Event Name",
      dataIndex: "eventName",
      width: "15%",
      editable: true,
      filters: eventEnuList?.enumValues?.map((enumValue) => {
        return { text: enumValue, value: enumValue }
      }),
      onFilter: (value, record) => record.eventName == value,
      sorter: (a, b) => a?.eventName?.length - b?.eventName?.length,
    },
    {
      title: "Time Elapsed",
      dataIndex: "time_elapsed",
      width: "12%",
      editable: true,
      sorter: (a, b) => a?.time_elapsed - b?.time_elapsed,
    },
    {
      title: "Extra Time Elapsed",
      dataIndex: "extra_time_elapsed",
      width: "15%",
      editable: true,
      sorter: (a, b) => a?.extra_time_elapsed - b?.extra_time_elapsed,
    },
    {
      title: "Match Stage",
      dataIndex: "match_stage",
      width: "15%",
      editable: true,
      filters: matchStageEnumList?.enumValues?.map((enumValue) => {
        return { text: enumValue, value: enumValue }
      }),
      onFilter: (value, record) => record.match_stage.indexOf(value) === 0,
      sorter: (a, b) => a?.match_stage?.length - b?.match_stage?.length,
    },
    {
      title: "Team Name",
      dataIndex: "teamName",
      width: "15%",
      editable: true,
      filters: [
        { text: selectedMatchTeam?.team1?.name, value: selectedMatchTeam?.team1?.id },
        { text: selectedMatchTeam?.team2?.name, value: selectedMatchTeam?.team2?.id }],
      onFilter: (value, record) => record?.teamName?.id?.indexOf(value) === 0,
      sorter: (a, b) => a.teamName?.name?.length - b.teamName?.name?.length,
      render: (_, record) => {
        return record?.teamName?.name
      }
    },
    {
      title: "Player",
      dataIndex: "player",
      width: "15%",
      editable: true,
      // filters: [
      //   { text: selectedMatchTeam?.team1?.name, value: selectedMatchTeam?.team1?.id },
      //   { text: selectedMatchTeam?.team2?.name, value: selectedMatchTeam?.team2?.id }],
      // onFilter: (value, record) => record?.player?.id?.indexOf(value) === 0,
      sorter: (a, b) => a.player?.name?.length - b.player?.name?.length,
      render: (_, record) => {
        return `${record?.player?.name} (${record?.player?.preferred_jersey_no})`
      }
    },
    {
      title: "Time",
      dataIndex: "time",
      width: "15%",
      editable: true,
      sorter: (a, b) => dayjs(a?.time, 'YYYY-MM-DD HH:mm:ss[Z]').unix() - dayjs(b?.time, 'YYYY-MM-DD HH:mm:ss[Z]').unix(),
    },
    {
      title: "Commentary",
      dataIndex: "commentary",
      width: "25%",
      editable: true,
      sorter: (a, b) => a?.commentary - b?.commentary,
    },
    {
      title: "Operation",
      dataIndex: "operation",
      width: 120,
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record)}
              style={{
                marginRight: 8,
              }}
            >
              <SaveOutlined />
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <CloseOutlined />
            </Popconfirm>
          </span>
        ) : (
          <>
            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              <EditOutlined />
            </Typography.Link>

            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => advancedEdit(record)}
            >
              <FormOutlined style={{ marginLeft: '8px' }} />
            </Typography.Link>

            <Typography.Link>
              <Popconfirm title="Delete" onConfirm={() => deleteEvent(record)}>
                {/* <a style={{ marginLeft: '24px' }}>Delete</a> */}
                <DeleteOutlined style={{ marginLeft: '8px' }} />
              </Popconfirm>
            </Typography.Link>
          </>

        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    let inputNode;

    if (dataIndex == 'eventName') {
      inputNode = eventDropDown()
    }
    else if (dataIndex == 'match_stage') {
      inputNode = matchStageDropDown()
    } else if (dataIndex == 'teamName') {
      inputNode = teamDropDown(record?.teamName?.id)
    } else if (dataIndex == 'player') {
      inputNode = teamPlayerDropDown(form.getFieldValue('teamName'))
    } else if (dataIndex == 'commentary') {
      inputNode = <TextArea rows={4} placeholder="Commentary" />
    } else if (dataIndex == 'time') {
      inputNode = <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
    } else {
      inputNode = inputType === "number" ? <InputNumber /> : <Input />;
    }

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const handleCancel = () => {
    setIsCreateModalOpen(false);

    if (selectedEventRecord) {
      setSelectedEventRecord(null)
    }
  };

  const handleSubstituteCancel = () => {
    setIsSubstituteModalOpen(false);
  };



  const handleModalCancel =() => {
    setIsWalkOverModalOpen(false);
  }

  const handleMatchAggregation = () => {
    matchAggregation();
  }

  const onClose = () => {
    setStatusMessage(null);
    // refresh();
  };
  return (
    <>
    {statusMessage && (
        <Alert
          message={statusMessage?.content}
          type={statusMessage?.type}
          showIcon
          closable
          onClose={onClose}
        />
      )}
        <div className="events-tab_wrapper">
          <Row>
            <Col  xs={24} sm={12} span={12} style={{ paddingTop: "10px" }}>
                <Button className="walkover-button" danger type="default" onClick={() => setIsWalkOverModalOpen(true)} style={{ marginTop: '16px' }}>Declare match as walkover </Button>
                <Button  className="aggregation-button" type="default" onClick={handleMatchAggregation} loading={runMatchAggregationLoading}> Match Aggregation</Button>
            </Col>
            {/* <Col
            //  xs={24} sm={12} 
             span={12} style={{display:"flex", justifyContent:"flex-start"}}>
            <Button></Button>
            </Col> */}
            <Col xs={24} sm={12} span={12} style={{ display:"flex", justifyContent:"flex-end"}}>
              {selectedMatch && <Tooltip title="Subscribe events">
           
              <Space>
              <Button type="primary" onClick={() => setIsSubstituteModalOpen(true)} style={{ marginTop: '16px' }}>Substitution</Button>
              <Button type="primary" onClick={() => setIsCreateModalOpen(true)} style={{ marginTop: '16px' }}>New Event</Button>
              <Switch defaultChecked onChange={onToggleSubscribe} checked={isSubscribed} style={{ marginTop: '16px' }} />
              </Space>
              </Tooltip>}
            </Col>
          </Row>
          <Row style={{ overflowX:"scroll" }}>
            <Col span={24} style={{ paddingTop: "20px" }}>
              {matchEventsLoader ?
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                </div>
                :
                selectedMatch  && 
                <Form form={form} component={false}>
                  <Table
                    components={{
                      body: {
                        cell: EditableCell,
                      },
                    }}
                    bordered
                    dataSource={tableData}
                    columns={mergedColumns}
                    rowClassName="editable-row"
                    pagination={{
                      onChange: cancel,
                    }}
                  />
                </Form>
              }
            </Col>
        </Row>

      {isWalkOverModalOpen && <Modal width={500} footer={null}  open={isWalkOverModalOpen} onCancel={handleModalCancel}>
        <MatchWalkover matchData={matchData?.match} matchId={selectedMatch} refresh={refetch} close={handleModalCancel} />
      </Modal>}

      {isCreateModalOpen && <Modal title={selectedEventRecord ? "Update Event" : "New Event"} width={1000} open={isCreateModalOpen} footer={null} onCancel={handleCancel}>
        <NewEvent record={selectedEventRecord} matchId={selectedMatch} refresh={refetch} close={handleCancel} />
      </Modal>}

      {isSubstituteModalOpen && <Modal style={window?.innerWidth <= 768 ? {maxWidth: '100%', top: '0', paddingBottom: '0px'} : ''} className="substitute_modal" title={`Substitution`} open={isSubstituteModalOpen} footer={null} onCancel={handleSubstituteCancel}>
        <PlayerSubstitute  matchId={selectedMatch} matchData={matchData?.match} close={handleCancel} />
      </Modal>}
        </div>
    </>
  );
};

export default EventsTab;
