import { gql } from "@apollo/client";

const SUBSCRIPTION_EVENT_ADDED = gql`
  subscription EventAdded($match_id: String!) {
    eventAdded(match_id: $match_id) {
      id
      time
      event
      player {
        id
        name
        email
        phone_number
        display_picture
        position
        preferred_jersey_no
        status
      }
      team {
        id
        name
        description
        display_picture
      }
      match {
        id
        format
        duration
        sub_count
        match_stat_duration
        created_at
        updated_at
      }
      time_elapsed
      extra_time_elapsed
      commentary
      match_stage
      event_meta {
        id
        area_of_play_start
        area_of_play_end
        body_part
        action_type
        association_type
      }
    }
  }
`;

export default SUBSCRIPTION_EVENT_ADDED;
