import {
  FileOutlined,
  PieChartOutlined,
  GroupOutlined,
  UserOutlined,
  DesktopOutlined,
  TeamOutlined,
  MenuOutlined,
  UsergroupAddOutlined,
  BorderOuterOutlined,
  OrderedListOutlined,
  PoweroffOutlined,
  BookOutlined,
  DatabaseOutlined,
} from "@ant-design/icons";
import { Layout, Menu, theme } from "antd";
import {
  Route,
  Routes,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ApolloProvider } from "@apollo/client";

import logo from "./logo.png";
import { useState, useEffect } from "react";
import "antd/dist/reset.css";
import "./App.scss";
import MatchEvents from "./pages/match-events";
import Matches from "./pages/matches";
import client from "./utils/apollo";
import Lineups from "./pages/lineups";
// import Tournaments from "./pages/tournaments";
import Users from "./pages/users";
import TeamPlayers from "./pages/team-players";
import Teams from "./pages/teams";
import TournamentsNew from "./pages/tournaments-new/tournaments";
import TournamentDetails from "./pages/tournament-details/tournament-details";
import MatchDetails from "./pages/match-details/match-details";
import TeamDetails from "./pages/team-details/team-details";
import Test from "./pages/test";
import Venue from "./pages/venue";
import Login from "./pages/login";
import TournamentListing from "./pages/tournament-listing/tournamentListing";
import TournamentListDetails from "./pages/tournament-listing/tournamentDetails.jsx/tournament-details";
import WhatsNew from "./pages/whats-new/goalrush_school";
import GoalrushSchool from "./pages/whats-new/goalrush_school";
import Cookies from "js-cookie";
import { app } from "./firebase";
import { getAuth } from "firebase/auth";

import BulkMatches from "./pages/bulk-matches";
import UserRole from "./pages/user-roles";
import TournamentSquad from "./pages/tournament-squad";
import UserRoleForTeams from "./pages/user-roles_teams";
const { Header, Content, Footer, Sider } = Layout;
const menuItem = [
  {
    title: "Live Events",
    key: "1",
    icon: <PieChartOutlined />,
    link: "/",
  },
  {
    title: "Matches",
    key: "2",
    icon: <DesktopOutlined />,
    link: "/matches",
  },
  {
    title: "LineUps",
    key: "3",
    icon: <TeamOutlined />,
    link: "/line-ups",
  },
  // {
  //   title: 'Tournaments',
  //   key: '4',
  //   icon: <GroupOutlined />,
  //   link: '/tournaments'
  // },
  {
    title: "Tournaments ",
    key: "4",
    icon: <GroupOutlined />,
    link: "/tournaments-new",
    subPath: [
      "tournament-details",
      "match-details",
      "bulk-matches",
      "user-role",
      "tournament-squad"
    ],
  },
  {
    title: "Venue",
    key: "5",
    icon: <BorderOuterOutlined />,
    link: "/venue",
  },
  {
    title: "Users",
    key: "6",
    icon: <UserOutlined />,
    link: "/users",
    subPath: ["multi-user-creation"],
  },
  // {
  //   title: 'Team players',
  //   key: '7',
  //   icon: <UserAddOutlined />,
  //   link: '/team-players',
  // },
  {
    title: "Teams",
    key: "8",
    icon: <UsergroupAddOutlined />,
    link: "/teams",
    subPath: ["team-details"],
  },
  {
    title: "Tournament-Listing",
    key: "9",
    icon: <OrderedListOutlined />,
    link: "/tournament-listing",
    subPath: ["tournament-details-listing"],
  },
  {
    title: "Goalrush School",
    key: "10",
    icon: <BookOutlined />,
    link: "/goalrush-school",
    // subPath: ["tournament-details-listing"],
  },
  
  // {
  //   title: "Bulk match creation",
  //   key: "11",
  //   icon: <DatabaseOutlined />,
  //   link: "/bulk-matches",
  //   // subPath: ["tournament-details-listing"],
  // },
];

function App() {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const isAuthenticated = Cookies.get("jwt-token");

    console.log(">>>isAuthenticated ", isAuthenticated);
    if (!isAuthenticated) {
      navigate("/login");
    } else if (isAuthenticated && window.location.pathname.includes("login")) {
      navigate("/");
    } else {
      navigate(window.location.pathname);
    }
  }, []);

  const {
    token: { colorBgContainer, colorBgBase },
  } = theme.useToken();

  const getMenuByPathName = () =>
    menuItem?.filter(
      (menu) =>
        menu.link == location?.pathname ||
        menu?.subPath?.includes(location.pathname.split("/")[1])
    );
  const isAuthenticated = localStorage.getItem("authenticated") === "true";

  const onLogout = () => {
    const auth = getAuth(app);
    auth.signOut();
    Cookies.remove("jwt-token");
    navigate("/login");
  };

  return (
    <ApolloProvider client={client}>
      <Layout
        style={{
          minHeight: "100vh",
        }}
      >
        <Header
          style={{
            padding: 0,
            background: "#001529",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              height: 32,
              marginLeft: 16,
            }}
          >
            <img src={logo} width="32px" height="32px" alt="logo" />
            <span
              style={{
                fontSize: "20px",
                color: "white",
                marginLeft: "12px",
                fontWeight: "600",
                paddingTop: "16px",
              }}
            >
              GoalRush
            </span>
            {Cookies.get("jwt-token") && (
              <MenuOutlined
                style={{
                  marginLeft: "24px",
                  cursor: "pointer",
                  color: "black",
                  color: "white",
                }}
                onClick={() => setCollapsed(!collapsed)}
              />
            )}
          </div>
          {Cookies.get("jwt-token") && (
            <div style={{ marginRight: "16px" }}>
              <PoweroffOutlined
                style={{
                  marginLeft: "24px",
                  cursor: "pointer",
                  color: "black",
                  color: "white",
                  fontSize: "16px",
                }}
                onClick={onLogout}
              />
            </div>
          )}
        </Header>
        <Content style={{ minHeight: "calc(100vh - 66px)" }}>
          <Routes>
            <Route path="/login" element={<Login />} />

            <Route
              path="/*"
              element={
                <Layout className="site-layout">
                  <Sider
                    collapsible
                    collapsed={collapsed}
                    breakpoint="lg"
                    trigger={null}
                    collapsedWidth={0}
                    onCollapse={(value) => setCollapsed(value)}
                    style={{
                      height: "calc(100vh - 66px)",
                      position: "relative",
                      zIndex: 9,
                    }}
                  >
                    <Menu
                      theme="dark"
                      selectedKeys={getMenuByPathName()[0]?.key || ["1"]}
                      mode="inline"
                    >
                      {menuItem.map((item) => (
                        <Menu.Item key={item.key} icon={item.icon}>
                          <Link to={item.link}>{item.title}</Link>
                        </Menu.Item>
                      ))}
                    </Menu>
                  </Sider>
                  <div
                    style={{
                      height: "calc(100vh - 66px)",
                      overflowY: "scroll",
                      width: "100%",
                      padding: "10px",
                    }}
                  >
                    <Routes>
                      <Route path="/" element={<MatchEvents />} />
                      <Route path="/matches" element={<Matches />} />
                      <Route path="/line-ups" element={<Lineups />} />
                      <Route path="/venue" element={<Venue />} />
                      <Route path="/users" element={<Users />} />
                      <Route path="/team-players" element={<TeamPlayers />} />
                      <Route path="/teams" element={<Teams />} />
                      <Route
                        path="tournaments-new"
                        element={<TournamentsNew />}
                      />
                      <Route
                        path="tournament-details/:id"
                        element={<TournamentDetails />}
                      />
                      <Route
                        path="match-details/:id"
                        element={<MatchDetails />}
                      />
                      <Route
                        path="team-details/:id"
                        element={<TeamDetails />}
                      />
                      <Route path="/multi-user-creation" element={<Test />} />
                      <Route
                        path="/tournament-listing"
                        element={<TournamentListing />}
                      />
                      <Route
                        path="/tournament-details-listing/:id"
                        element={<TournamentListDetails />}
                      />
                      <Route
                        path="/goalrush-school"
                        element={<GoalrushSchool />}
                      />
                      <Route
                        path="/bulk-matches/:tournamentId/:seasonId"
                        element={<BulkMatches />}
                      />
                      <Route path="/user-role/:id" element={<UserRole />} />
                      <Route path="/user-role-teams/:id" element={<UserRoleForTeams />} />
                      <Route path="/tournament-squad/:teamId/:seasonId" element={<TournamentSquad/>} />
                    </Routes>
                  </div>
                </Layout>
              }
            />
          </Routes>
        </Content>
        {/* <Footer
          style={{
            textAlign: "center",
          }}
        >
          GoalRush ©2023
        </Footer> */}
      </Layout>
    </ApolloProvider>
  );
}

export default App;
