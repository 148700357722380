import { gql } from "@apollo/client";

const REMOVE_USER_BY_ID = gql`
  mutation removeUser($user_id: String!) {
    removeUser(user_id: $user_id) {
      id
      name
      email
      phone_number
      display_picture
      position
      preferred_jersey_no
      status
      teamPlayer {
        id
        join_date
        leave_date
        status
      }
    }
  }
`;

export default REMOVE_USER_BY_ID;
