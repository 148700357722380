import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { compareString } from "../../../utils/app.util";
import {
  Col,
  Row,
  Select,
  Form,
  InputNumber,
  Popconfirm,
  Table,
  Typography,
  Input,
  Avatar,
  Image,
  Alert,
} from "antd";
import GET_EVENT_ENUM from "../../../api/get_event_enum";
import UPDATE_MATCH_LINE_UP_BY_ID from "../../../api/update_match_line_up_by_id";
import REMOVE_MATCH_LINE_UP_BY_ID from "../../../api/remove_match_line_up_by_id";
import GET_TEAM_PLAYERS_BY_TEAM from "../../../api/get_team_players_by_team";
import { DeleteFilled, EditFilled } from "@ant-design/icons";

const LineUpTable = ({ lineUpData, teamId, refresh }) => {
  const [tableData, setTableData] = useState(lineUpData);
  const [duplicateJersey, setDuplicateJersey] = useState(false);
  const [errorField, setErrorField] = useState(null);

  const { Option } = Select;
  const [errorMessage, setErrorMessage] = useState(null);
  const { data: lineUpStatus } = useQuery(GET_EVENT_ENUM, {
    variables: { enumName: "LineupStatus" },
  });
  const { data: playerPositions } = useQuery(GET_EVENT_ENUM, {
    variables: { enumName: "PlayerPosition" },
  });
  const [
    updateMatchLineUp,
    {
      data: updateMatchLineUpData,
      loading: updateLineUploading,
      error: updateLienUpError,
    },
  ] = useMutation(UPDATE_MATCH_LINE_UP_BY_ID);
  const [
    removeMatchLineUp,
    {
      data: removeMatchLineUpData,
      loading: removeLineUploading,
      error: removeLienUpError,
    },
  ] = useMutation(REMOVE_MATCH_LINE_UP_BY_ID);
  const [form] = Form.useForm();
  const [fetchPlayerList, { data: teamPlayersList, refetch }] = useLazyQuery(
    GET_TEAM_PLAYERS_BY_TEAM,
    {
      variables: { team_id: teamId },
      fetchPolicy: "no-cache",
    }
  );
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;
  useEffect(() => {
    setTableData(lineUpData);
  }, [lineUpData]);
  const playerPositionDropDown = (teamId) => {
    return (
      <Select
        showSearch
        filterOption={(input, option) =>
          (option?.label?.toLowerCase() ?? "").includes(input.toLowerCase())
        }
        options={playerPositions?.enumValues?.map((enumValue) => {
          return { label: enumValue, value: enumValue };
        })}
      ></Select>
    );
  };

  const lineUpStatusDropDown = () => {
    return (
      <Select>
        {lineUpStatus?.enumValues?.map((enumValue) => (
          <Option key={enumValue} value={enumValue}>
            {enumValue}
          </Option>
        ))}
      </Select>
    );
  };

  const edit = (record) => {
    form.setFieldsValue({
      ...record,
      name: record?.teamPlayerId,
    });

    setEditingKey(record.key);
    refresh();
  };

  const deleteLineup = async (record) => {
    await removeMatchLineUp({
      variables: {
        lineup_id: record?.key,
      },
    });
    refresh(tableData);
    setTableData(tableData?.filter((item) => item?.key != record?.key));
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key, record) => {
    const { name, ...formValue } = form.getFieldsValue();

    const payload = {
      ...formValue,
      jersey_no: formValue?.jerseyNumber,
    };

    delete payload?.jerseyNumber;

    await updateMatchLineUp({
      variables: {
        lineup_id: key,
        updateMatchLineupInput: payload,
      },
    });

    record.jerseyNumber = payload?.jersey_no;
    record.status = payload?.status;
    record.position = payload?.position;

    setEditingKey("");
    refresh();
  };
  const columns = [
    {
      title: "#",
      width: "2%",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "",
      dataIndex: "displayPicture",
      width: "5%",
      editable: false,
      render: (displayPicture) => {
        return (
          displayPicture && (
            <div
              style={{
                display: "inline-block",
                width: "32px",
                height: "32px",
                borderRadius: "50%",
                overflow: "hidden",
              }}
            >
              <Image
                src={displayPicture}
                width="32px"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
          )
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      width: "15%",
      editable: false,
      // filters: [],
      // onFilter: (value, record) => record.name.indexOf(value) === 0,
      sorter: (a, b) => compareString(a, b, "name"),
      defaultSortOrder: "ascend",
    },
    {
      title: "Position",
      dataIndex: "position",
      width: "20%",
      editable: true,
      sorter: (a, b) => a?.position - b?.position,
    },
    {
      title: "Jersey No",
      dataIndex: "jerseyNumber",
      width: "15%",
      editable: true,
      inputType: "number",
      sorter: (a, b) => a?.jerseyNumber - b?.jerseyNumber,
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "15%",
      editable: true,
      sorter: (a, b) => a?.status - b?.status,
    },
    {
      title: "Operation",
      dataIndex: "operation",
      width: "15%",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key, record)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <>
            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              <EditFilled style={{ color: "#60BAF9" }} />
            </Typography.Link>

            <Typography.Link>
              <Popconfirm title="Delete" onConfirm={() => deleteLineup(record)}>
                <a style={{ marginLeft: "24px" }}>
                  <DeleteFilled style={{ color: "#FF6D59" }} />
                </a>
              </Popconfirm>
            </Typography.Link>
          </>
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  useEffect(() => {
    const isDuplicate = tableData?.some((player, index) =>
      tableData
        .slice(index + 1)
        .some(
          (otherPlayer) => player?.jerseyNumber === otherPlayer?.jerseyNumber
        )
    );

    if (isDuplicate) {
      setDuplicateJersey(true);
      setErrorField("jerseyNumber");
      setErrorMessage(
        "Duplicate Jersey Numbers:Some players have the same jersey number."
      );
    } else {
      setErrorMessage("");
      setErrorField(null);
      setDuplicateJersey(false);
    }
  }, [tableData]);

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    let inputNode;

    if (dataIndex == "position") {
      inputNode = playerPositionDropDown();
    } else if (dataIndex == "status") {
      inputNode = lineUpStatusDropDown();
    } else {
      inputNode =
        inputType === "number" ? (
          <InputNumber pattern="[0-9]*" inputmode="numeric" />
        ) : (
          <Input />
        );
    }

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
              // {
              //   validator: (_, value) => {
              //     if (dataIndex === "jerseyNumber") {
              //       const isDuplicate = tableData.some(
              //         (player) =>
              //           player.key !== record.key &&
              //           value !== null &&
              //           player.jerseyNumber === value
              //       );
              //       if (isDuplicate) {
              //         setDuplicateJersey(true);
              //         return Promise.reject("Jersey number already used.");
              //       }
              //       if (!isDuplicate) {
              //         setDuplicateJersey(false);
              //         setErrorField(null);
              //       }
              //     }
              //     return Promise.resolve();
              //   },
              // },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  return (
    <>
      <Row>
        {duplicateJersey && (
          <Alert
            style={{ width: "100%", marginBottom: "12px" }}
            message="Duplicate jersey numbers found"
            type="error"
            showIcon
          />
        )}
        <Col span={24}>
          <Form form={form} component={false}>
            <Table
              style={{ marginBottom: 20 }}
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              bordered
              dataSource={tableData}
              columns={mergedColumns}
              rowClassName="editable-row"
              pagination={false}
              scroll={{ y: 300, x: "auto" }}
            />
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default LineUpTable;
