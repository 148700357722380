import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Form,
  Input,
  Select,
  Space,
  DatePicker,
  Button,
  message,
  Alert,
  Row,
  Col,
  Upload,
  InputNumber,
  Divider,
} from "antd";
import { useEffect, useState } from "react";
import CREATE_TOURNAMENT_SEASON_TEAM from "../../../../api/create_tournament_season_team";
import GET_GROUPS_BY_TOURNAMENT_SEASON_ID from "../../../../api/get_groups_by_season_id";
import GET_TEAMS from "../../../../api/get_teams";
import UPDATE_SEASON_TEAM_BY_ID from "../../../../api/update_team_in_seaon";
import GET_TEAMS_BY_TOURNAMENT_SEASON_ID from "../../../../api/get_teams_by_season_id";

const AddTeamToSeason = ({
  record,
  refresh,
  close,
  selectedSeasonId,
  tournamentId,
}) => {
  const { data: allTeams, loading } = useQuery(GET_TEAMS);
  const [
    createTournamentSeasonTeam,
    {
      data: createdTournamentSeasonTeamData,
      loading: createTournamentSeasonTeamLoading,
      error: createdTeamSeasonError,
    },
  ] = useMutation(CREATE_TOURNAMENT_SEASON_TEAM);
  const [
    updateTournamentSeasonTeam,
    {
      data: updatedTournamentSeasonTeamData,
      loading: updateTournamentSeasonTeamLoading,
      error: updateTeamSeasonError,
    },
  ] = useMutation(UPDATE_SEASON_TEAM_BY_ID);
  const {
    data: groupsSeasonData,
    loading: groupLoading,
    error: groupsError,
  } = useQuery(GET_GROUPS_BY_TOURNAMENT_SEASON_ID, {
    variables: {
      tournament_season_id: selectedSeasonId,
    },
  });

  const [statusMessage, setStatusMessage] = useState(null);
  const { Option } = Select;
  const [form] = Form.useForm();

  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        team_id: record?.team?.id,
        group_id: record?.tournament_season_group?.group_name,
        tournament_enrollment_status: record?.tournament_enrollment_status,
      });
    } else {
      form.resetFields();
    }
  }, [record]);

  const onFinish = async (formValue) => {
    if (record) {
      updateTeamToTournamentSeason(formValue);
    } else {
      addTeamToTournamentSeason(formValue);
    }
  };

  const addTeamToTournamentSeason = async (formValue) => {
    createTournamentSeasonTeam({
      variables: {
        createTeamInput: {
          ...formValue,
          tournament_season_id: selectedSeasonId,
          tournament_id: tournamentId,
        },
      },
    })
      .then((resp) => {
        setStatusMessage({
          type: "success",
          content: "Tournament Team Added",
        });
        onReset();
        refresh();
        close();
      })
      .catch((err) => {
        setStatusMessage({
          type: "error",
          content: err?.message,
        });
      });
  };

  const updateTeamToTournamentSeason = async (formValue) => {
    Object.keys(formValue).forEach(
      (k) =>
        (formValue[k] =
          formValue[k] === "" || formValue[k] === undefined
            ? null
            : formValue[k])
    );

    updateTournamentSeasonTeam({
      variables: {
        tournament_season_team_id: record?.id,
        UpdateTournamentSeasonTeamInput: formValue,
      },
    })
      .then((resp) => {
        setStatusMessage({
          type: "success",
          content: "Tournament Team Updated",
        });
        onReset();
        refresh();
        close();
      })
      .catch((err) => {
        setStatusMessage({
          type: "error",
          content: err?.message,
        });
      });
  };

  const onReset = () => {
    form?.resetFields();
  };

  const onClose = () => {
    setStatusMessage(null);
  };
  return (
    <>
      {statusMessage && (
        <Alert
          message={statusMessage?.content}
          type={statusMessage?.type}
          showIcon
          closable
          onClose={onClose}
        />
      )}
      <Form
        form={form}
        name="complex-form"
        onFinish={onFinish}
        style={{ marginTop: "24px" }}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 15 }}
      >
        <Row gutter={4}>
          <Col span={24}>
            {!record && (
              <Form.Item
                label="Team"
                name="team_id"
                showSearch
                rules={[{ required: true, message: "Team is required" }]}
              >
                <Select
                  options={allTeams?.teams?.map((team) => {
                    return { label: team.name, value: team?.id };
                  })}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label?.toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  }
                ></Select>
              </Form.Item>
            )}
            <Form.Item
              label="Tournament Group"
              name="group_id"
              // rules={[{ required: true, message: "Group is required" }]}
            >
              <Select placeholder="Select group" allowClear={true}>
                {groupsSeasonData?.findAllGroupsBySeasonId?.map((group) => (
                  <Option key={group?.id} value={group?.id}>
                    {group?.group_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Status" name="tournament_enrollment_status">
              <Select placeholder="Select Status">
                <Option key="CONFIRMED" value="CONFIRMED">
                  CONFIRMED
                </Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Space style={{ justifyContent: "right", width: "100%" }}>
              <Button htmlType="button" onClick={onReset}>
                Reset
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={
                  createTournamentSeasonTeamLoading ||
                  updateTournamentSeasonTeamLoading
                }
              >
                {record ? "Update" : "Submit"}
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default AddTeamToSeason;
