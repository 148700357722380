import gql from "graphql-tag";

const FIND_ALL_ROUNDS_BY_SEASON_ID = gql`
  query findAllRoundsOfSeason($seasonId: String!) {
    findAllRoundsOfSeason(seasonId: $seasonId) {
      id
      type
      start_date
      break_time
      match_duration
      match_extra_time
      sequence_no
      tournament_season {
        id
        name
        start_date
        display_picture
        end_date
      }
    }
  }
`;

export default FIND_ALL_ROUNDS_BY_SEASON_ID;

