import { gql } from '@apollo/client';

const ADD_SPONSORS_TO_TOURNAMENT_SEASON_MUTATION = gql`
  mutation AddSponsorsToTournamentSeason($tournament_season_id: String!, $sponsorInput: SponsorInput!) {
    addSponsorsToTournamentSeason(tournament_season_id: $tournament_season_id, sponsorInput: $sponsorInput) {
      id
      name
      display_picture
      description
      link
    }
  }
`;
export default ADD_SPONSORS_TO_TOURNAMENT_SEASON_MUTATION;