import { gql } from "@apollo/client";

const UPDATE_TEAM_IN_MATCH = gql`
  mutation updateTeamInMatch($match_id: String!, $team1_id:String!, $team2_id: String!) {
    updateTeamInMatch(match_id: $match_id, team1_id: $team1_id, team2_id: $team2_id) {
      id
    tournamentSeason {
      id
      name
      start_date
      display_picture
      end_date
      about_tournament
    }
    venue {
      id
      name
      address
      map_location
      contact_info
    }
    team1 {
      id
      name
      description
      display_picture
    }
    team2 {
      id
      name
      description
      display_picture
    }
    format
    duration
    sub_count
    match_stat_duration
    streaming_url
    schedule_slot {
      id
      end_time
      start_time
      slot_no
    }
    match_schedule {
      id
      start_date
      end_date
      slot_no
    }
    aggregated_stats {
      id
      winner
      team1Goal
      team2Goal
      team1PenaltyGoal
      team2PenaltyGoal
    }
    created_at
    updated_at
    tournament_round {
      id
      type
      start_date
      break_time
      match_duration
      match_extra_time
      sequence_no
    }
    tournament_season_group {
      id
      group_name
    }
    timer {
      id
      duration
      extra_time
      isShootout
      time_elapsed
      time_elapsed_HT
      time_elapsed_FT
      extra_time_elapsed_HT
      extra_time_elapsed_FT
      match_status
      match_stage
      play_status
      ticker_duration
    }
    }
  }
`;

export default UPDATE_TEAM_IN_MATCH;
