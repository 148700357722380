import { useLazyQuery, useMutation } from "@apollo/client";
import { Button, Col, DatePicker, Form, Input, message, Row, Select, Space } from "antd"
import { useEffect, useState } from "react";
import GET_TEAMS_BY_TOURNAMENT_SEASON_ID from "../../../../../api/get_teams_by_season_id";
import UPDATE_TEAM_IN_MATCH from "../../../../../api/update_team_in_match";
import dayjs from "dayjs";
import UPDATE_SCHEDULE from "../../../../../api/update_schedule";

const MatchScheduleEdit = ({ record, refresh }) => {
    const [messageApi, contextHolder] = message.useMessage();
    const utc = require("dayjs/plugin/utc");
    dayjs.extend(utc);
    const [form] = Form.useForm();
    const [
        getTournamentSeasonTeams,
        {
            data: tournamentSeasonsTeams,
            loading: tournamentSeasonTeamsLoading,
            refetch,
        },
    ] = useLazyQuery(GET_TEAMS_BY_TOURNAMENT_SEASON_ID, {
        variables: {
            tournament_season_id: record?.tournamentSeason?.id,
        },
    });
    const [
        updateMatchSchedule,
        { updateMatchScheduleData, loading: updateMatchScheduleLoading, updateMatchScheduleError },
    ] = useMutation(UPDATE_SCHEDULE);

    const [statusMessage, setStatusMessage] = useState(null);

    useEffect(() => {
        getTournamentSeasonTeams({
            variables: {
                tournament_season_id: record?.tournamentSeason?.id,
            },
        });

    }, [record?.tournamentSeason?.id]);

    useEffect(() => {
        if (record) {
            form.setFieldsValue({
                start_date: dayjs(dayjs(record?.match_schedule?.start_date)?.format(
                    "YYYY-MM-DD HH:mm:ss"
                ), "YYYY-MM-DD HH:mm:ss"),
                end_date: dayjs(dayjs(record?.match_schedule?.end_date)?.format(
                    "YYYY-MM-DD HH:mm:ss"
                ), "YYYY-MM-DD HH:mm:ss"),
                slot_no: record?.match_schedule?.slot_no,
                // venue_round_availablity: record?.match_schedule?.venue_round_availablity
            });
        } else {
            form.resetFields();
        }
    }, [record]);

    const onFinish = async (formValue) => {
        if (record) {
            updateSchedule(formValue);
        }
    };


    const updateSchedule = (formValue) => {
        const formData = Object.create(formValue)

        updateMatchSchedule({
            variables: {
                match_schedule_id: record?.match_schedule?.id,
                UpdateMatchScheduleInput: {
                    ...formData,
                    start_date: formData?.start_date?.utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
                    end_date: formData?.end_date?.utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
                }
            },
        })
            .then((res) => {
                messageApi.open({
                    type: "success",
                    content: "Match Schedule Updated",
                });
            })
            .catch((err) => {
                messageApi.open({
                    type: "error",
                    content: err.message,
                });
            });
    };

    const onReset = () => {
        form?.resetFields();
    };

    return (
        <>
            {contextHolder}
            <Form
                form={form}
                name="team-form"
                onFinish={onFinish}
                style={{ marginTop: "24px" }}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
            // style={{ }}
            >
                <Row gutter={4}>
                    <Col span={12}>
                        <Form.Item
                            name="start_date"
                            label="Start Time"
                            rules={[{ required: true, message: "Please select time!" }]}
                        >
                            <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
                        </Form.Item>
                        <Form.Item
                            name="end_date"
                            label="End Time"
                            rules={[{ required: true, message: "Please select time!" }]}
                        >
                            <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
                        </Form.Item>

                        <Form.Item label="slot_no" name="Slot No">
                            <Input placeholder="Slot No" />
                        </Form.Item>

                        {/* <Form.Item label="venue_round_availablity" name="Venue Round Availability">
                        <Input placeholder="Venue round availability" />
                    </Form.Item> */}
                    </Col>

                    <Col span={24}>
                        <Space style={{ justifyContent: "right", width: "100%" }}>
                            <Button htmlType="button" onClick={onReset}>
                                Reset
                            </Button>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={updateMatchScheduleLoading}
                            >
                                {record ? "Update" : "Submit"}
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </>

    )
}

export default MatchScheduleEdit