import { gql } from '@apollo/client';

const CREATE_BULK_MATCH_MUTATION = gql`
  mutation CreateBulkMatch($createMatchInput: [CreateBulkMatchInput!]!) {
    createBulkMatch(createMatchInput: $createMatchInput) {
      id
      tournamentSeason {
        id
        name
        start_date
        display_picture
        end_date
        about_tournament
        tournament_rules
        no_of_groups
        no_of_teams_in_each_group
        no_of_teams_to_knockout
      }
      venue {
        id
        name
        address
        map_location
        contact_info
      }
      team1 {
        id
        name
        description
        display_picture
      }
      team2 {
        id
        name
        description
        display_picture
      }
      team1_color
      team2_color
      
      format
      duration
      sub_count
      match_stat_duration
      streaming_url
      schedule_slot {
        id
        end_time
        start_time
        slot_no
      }
      match_schedule {
        id
        start_date
        end_date
        slot_no
        venue_round_availablity
      }
      aggregated_stats {
        id
        winner
        team1Goal
        team2Goal
        team1PenaltyGoal
        team2PenaltyGoal
      }
      created_at
      updated_at
      tournament_round {
        id
        type
        start_date
        break_time
        match_duration
        match_extra_time
        sequence_no
      }
      tournament_season_group {
        id
        group_name
      }
      timer {
        id
        duration
        extra_time
        isShootout
        time_elapsed
        time_elapsed_HT
        time_elapsed_FT
        extra_time_elapsed_HT
        extra_time_elapsed_FT
        match_status
        match_stage
        play_status
        ticker_duration
      }
      best_player {
        id
        name
        email
        phone_number
        display_picture
        position
        preferred_jersey_no
        status
      }
    }
  }
`;

export default CREATE_BULK_MATCH_MUTATION;
