import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Typography,
  Popconfirm,
  Input,
  Select,
  Space,
  Table,
  Row,
  InputNumber,
  Image,
} from "antd";
import {  useMutation, useQuery } from "@apollo/client";
import {
  EditOutlined,
  DeleteOutlined,
  SaveOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import GET_USERS from "../../../api/get_all_users";
import FIND_USERS_WITH_ROLE_BY_ENTITY from "../../../api/find_users_with_role_by_entity";
import { Roles } from "../../user-roles/constants/const";
import ASSIGN_ROLES_TO_MULTIPLE_USERS_FOR_TEAM from "../../../api/assign_roles_to_multiple_users_for_team";
import REMOVE_USER_ROLE_FROM_TEAM from "../../../api/remove_user_role_from_team";
import GET_TEAM_BY_ID from "../../../api/get_team_by_id";

const TeamTable = ({
  teamId,
}) => {
  const { data: allUsers, loading } = useQuery(GET_USERS);
 
  const [
    // assignUserRoleSeasons,
    assignUsersForTeam,
    {
      data: assignUsersForTeamData,
      loading: assignUsersForTeamLoading,
      error: assignUsersForTeamError,
    },
  ] = useMutation(ASSIGN_ROLES_TO_MULTIPLE_USERS_FOR_TEAM);
  const {
    data: usersWithSeasonRoleData,
    loading: userWithTeamLoading,
    refetch: refetchTeamUserRole,
    error,
  } = useQuery(FIND_USERS_WITH_ROLE_BY_ENTITY, {
    variables: {
      args: {
        entityId: teamId,
        entityType: "TEAM",
      },
    },
  });
  const [
    deleteUserRoleTeam,
    { data: deletedData,loading: deleteUserRoleSeasonLoading, error: deleteUserRoleSeasonError },
  ] = useMutation(REMOVE_USER_ROLE_FROM_TEAM);
  const { data: teamData, loading: teamLoading } = useQuery(GET_TEAM_BY_ID, {
    variables: { id: teamId },
  });

  const [teamDataSource, setTeamDataSource] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;
  const { Option } = Select;

  useEffect(() => {
    if (usersWithSeasonRoleData?.findUsersWithRoleByEntity) {
      const newDataSource =
        usersWithSeasonRoleData?.findUsersWithRoleByEntity.map(
          (user, index) => ({
            key: index + 1,
            userId: user?.id,
            user: user?.name,
            role: user?.role,
          })
        );
      setTeamDataSource(newDataSource);
    }
  }, [usersWithSeasonRoleData]);

  
  const SeasonColumn = [
    {
      title: "#",
      width: "3%",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "User",
      dataIndex: "user",
      width: "40%",
      key: "user",
      editable: true,
      onCell: (record) => ({
        record,
        dataIndex: "user",
        title: "User",
        editing: isEditing(record),
      }),
    },
    {
      title: "Role",
      width: "40%",
      dataIndex: "role",
      key: "role",
      editable: true,
      onCell: (record) => ({
        record,
        dataIndex: "role",
        title: "Role",
        editing: isEditing(record),
      }),
    },
    {
      title: "Operation",
      dataIndex: "operation",
      width: 120,
      render: (_, record) => {
        if (record.role === "CREATOR") {
          return null;
        }
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => handleSubmitSeason(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              <SaveOutlined />
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <CloseOutlined />
            </Popconfirm>
          </span>
        ) : (
          <Space>
            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              <EditOutlined />
            </Typography.Link>
            <Typography.Link>
              <Popconfirm
                title="Delete"
                onConfirm={() =>
                  handleDelete(
                    record.key,
                  )
                }
              >
                <DeleteOutlined style={{ marginLeft: "8px" }} />
              </Popconfirm>
            </Typography.Link>
          </Space>
        );
      },
    },
  ];

  const [form] = Form.useForm();

  const UserDropdown = () => (
    <Form.Item
      name="user"
      rules={[{ required: true, message: "Please input user!" }]}
    >
      <Select
        showSearch
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onChange={(value) => form.setFieldsValue({ user: value })}
      >
        {allUsers?.users?.map((user) => (
          <Option key={user.id} value={user.id}>
            {user.name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );

  const RoleDropdown = () => (
    <Form.Item
      name="role"
      rules={[{ required: true, message: "Please input role!" }]}
    >
      <Select
        showSearch
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onChange={(value) => form.setFieldsValue({ role: value })}
      >
        {Object.entries(Roles).map(([key, value]) => (
          <Option key={key} value={value}>
            {value}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    let inputNode;

    if (dataIndex == "user") {
      inputNode = UserDropdown();
    } else if (dataIndex == "role") {
      inputNode = RoleDropdown();
    } else {
      inputNode =
        inputType === "number" ? (
          <InputNumber pattern="[0-9]*" inputmode="numeric" />
        ) : (
          <Input />
        );
    }

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const handleAdd = () => {
    const newKey = teamDataSource.length + 1;
    form.resetFields();
    const newRow = {
      key: newKey,
      user: "",
      role: "",
      isNew: true,
    };

    setTeamDataSource((prevDataSource) => [...prevDataSource, newRow]);
    setEditingKey(newKey);
  };

  const handleDeleteUserRole = async (userId, entityId) => {
    try {
      await deleteUserRoleTeam({
        variables: {
          input: { userId, entityId },
        },
      });
      
      refetchTeamUserRole();
    } catch (error) {
      console.error("Error deleting user role:", error);
    }
  };

  const handleDelete = (key) => {
    const record = teamDataSource.find((item) => item.key === key);
    if (record) {
      handleDeleteUserRole(record.userId, teamId);
    }
  };

  const cancel = () => {
    const newData = teamDataSource
      .map((item) => {
        if (item.isNew && editingKey === item.key) {
          return null;
        }
        return item;
      })
      .filter((item) => item != null);

    setTeamDataSource(newData);
    setEditingKey("");
    form.resetFields();
  };

  const edit = (record) => {
    form.setFieldsValue({
      user: "",
      role: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  const handleSubmitSeason = async (key) => {
    const formValue = form.getFieldsValue();
    try {
      const input = {
        userIds: [formValue.user],
        role: formValue.role,
        entityId: teamId,
      };
      const response = await assignUsersForTeam({
        variables: { input },
      });
     
      const row = await form.validateFields();
      const selectedUser = allUsers.users.find((u) => u.id === formValue.user);
      const userName = selectedUser ? selectedUser.name : null;

      const updatedData = { ...formValue, user: userName };
      const newData = [...teamDataSource];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        newData[index] = { ...newData[index], ...updatedData };
        setTeamDataSource(newData);
        setEditingKey("");
        refetchTeamUserRole();
      } else {
        newData.push(row);
        setTeamDataSource(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  

  return (
    <div>
      <Row style={{ marginTop:'30px'}}>
       <Col span={2} style={{ display:"flex", justifyContent:"center", alignContent:"center"}}>
       <Image style={{ borderRadius:"40px", width:"60px", height:"60px",}} src={teamData?.team?.display_picture}/></Col>
       <Col span={22}>
       <h2 style={{ display:"flex", justifyContent:"start", alignContent:"middle", marginTop:"15px" }}>
          {teamData?.team?.name}
        </h2>
        </Col>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Col span={16}>
         
        </Col>

        <Col span={8} style={{ textAlign: "right", marginBottom: "8px" }}>
          <Space>
            <Button onClick={handleAdd}>Add New</Button>
          </Space>
        </Col>
        <Col span={24}>
          
          <Form form={form} onFinish={handleSubmitSeason} component={false}>
            <Table
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              bordered
              dataSource={teamDataSource}
              columns={SeasonColumn}
              pagination={false}
              scroll={{ y: 300, x: "auto" }}
            />
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default TeamTable;
