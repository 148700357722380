import { gql } from "@apollo/client";

const UPDATE_TOURNAMENT_SEASON_SQUAD = gql`
  mutation UpdateTournamentSeasonSquad($updateTournamentSeasonSquadInput: UpdateTournamentSeasonSquadInput!) {
    updateTournamentSeasonSquad(updateTournamentSeasonSquadInput: $updateTournamentSeasonSquadInput) {
    season_id
    team_id
    playerId
    position
    jerseyNo
    status
    }
  }
`;

export default UPDATE_TOURNAMENT_SEASON_SQUAD;
