import { gql } from "@apollo/client";

const UPDATE_SCHEDULE = gql`
  mutation updateMatchSchedule($match_schedule_id: String!, $UpdateMatchScheduleInput:UpdateMatchScheduleInput!) {
    updateMatchSchedule(match_schedule_id: $match_schedule_id, UpdateMatchScheduleInput: $UpdateMatchScheduleInput) {
      id
      start_date
      end_date
      slot_no
      venue_round_availablity
    }
  }
`;

export default UPDATE_SCHEDULE;
