import { gql } from "@apollo/client";

const UPDATE_SEASON_TEAM_BY_ID = gql`
  mutation updateTeamFromTournamentSeasonTeam($tournament_season_team_id: String!, $UpdateTournamentSeasonTeamInput: UpdateTournamentSeasonTeamInput!) {
    updateTeamFromTournamentSeasonTeam(tournament_season_team_id: $tournament_season_team_id, UpdateTournamentSeasonTeamInput: $UpdateTournamentSeasonTeamInput) {
        id
        tournament_enrollment_status
        tournament_season {
          id
          name
          start_date
          display_picture
          end_date
          about_tournament
        }
        tournament {
          id
          name
          display_picture
          description
          about_tournament
        }
        team {
          id
          name
          description
          display_picture
        }
        tournament_season_group {
          id
          group_name
        }
    }
  }
`;

export default UPDATE_SEASON_TEAM_BY_ID;
