import { useLazyQuery, useMutation } from "@apollo/client";
import { Button, Col, Form, message, Row, Select, Space } from "antd"
import { useEffect, useState } from "react";
import GET_TEAMS_BY_TOURNAMENT_SEASON_ID from "../../../../../api/get_teams_by_season_id";
import UPDATE_TEAM_IN_MATCH from "../../../../../api/update_team_in_match";

const TeamEdit = ({ record }) => {
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [
        getTournamentSeasonTeams,
        {
            data: tournamentSeasonsTeams,
            loading: tournamentSeasonTeamsLoading,
            refetch,
        },
    ] = useLazyQuery(GET_TEAMS_BY_TOURNAMENT_SEASON_ID, {
        variables: {
            tournament_season_id: record?.tournamentSeason?.id,
        },
    });
    const [
        updateTeamInMatch,
        { updateTeamInMatchData, loading: updateMatchLoading, updateMatchError },
    ] = useMutation(UPDATE_TEAM_IN_MATCH);

    const [statusMessage, setStatusMessage] = useState(null);

    useEffect(() => {
        getTournamentSeasonTeams({
            variables: {
                tournament_season_id: record?.tournamentSeason?.id,
            },
        });

    }, [record?.tournamentSeason?.id]);

    useEffect(() => {
        if (record) {
            form.setFieldsValue({
                team1: record?.team1?.id,
                team2: record?.team2?.id,
            });
        } else {
            form.resetFields();
        }
    }, [record]);

    const onFinish = async (formValue) => {
        if (formValue?.team1 == formValue?.team2) {
            messageApi.open({
                type: "error",
                content: "Please choose different team",
            });

            return;
        }

        if (record) {
            updateMatch(formValue);
        }
    };


    const updateMatch = (formValue) => {
        updateTeamInMatch({
            variables: {
                match_id: record?.id,
                team1_id: formValue?.team1,
                team2_id: formValue?.team2,
            },
        })
            .then((res) => {
                messageApi.open({
                    type: "success",
                    content: "Match Team Updated",
                });
            })
            .catch((err) => {
                messageApi.open({
                    type: "error",
                    content: err.message,
                });
            });
    };

    const renderTeamOption = () => {
        if (tournamentSeasonsTeams)
            return tournamentSeasonsTeams?.findSeasonTeamsBySeasonId?.map((teams) => {
                return { label: teams?.team?.name, value: teams?.team?.id };
            });
    };

    const onReset = () => {
        form?.resetFields();
    };

    return (
        <>
            {contextHolder}
            <Form
                form={form}
                name="team-form"
                onFinish={onFinish}
                style={{ marginTop: "24px" }}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 12 }}
            // style={{ }}
            >
                <Row gutter={4}>
                    <Col span={12} style={{ height: 'calc(100% - 32px' }}>
                        <Form.Item
                            label="Team 1"
                            name="team1"
                            rules={[{ required: true, message: "Team 1 is required" }]}
                        >
                            <Select
                                placeholder="Select Team 1"
                                showSearch
                                filterOption={(input, option) =>
                                    (option?.label?.toLowerCase() ?? "").includes(
                                        input.toLowerCase()
                                    )
                                }
                                options={renderTeamOption()}
                            >
                                {/* {} */}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="team2"
                            label="Team 2"
                            rules={[{ required: true, message: "Team 2 is required" }]}
                        >
                            <Select
                                placeholder="Select Team 2"
                                showSearch
                                filterOption={(input, option) =>
                                    (option?.label?.toLowerCase() ?? "").includes(
                                        input.toLowerCase()
                                    )
                                }
                                options={renderTeamOption()}
                            ></Select>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Space style={{ justifyContent: "right", width: "100%" }}>
                            <Button htmlType="button" onClick={onReset}>
                                Reset
                            </Button>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={updateMatchLoading}
                            >
                                {record ? "Update" : "Submit"}
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </>

    )
}

export default TeamEdit