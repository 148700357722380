import { gql } from "@apollo/client";

const CREATE_MATCH_LINE_UP = gql`
  mutation createMatchLineUp($addLineupInput: AddLineupInput!) {
    createMatchLineUp(addLineupInput: $addLineupInput) {
      id
      match {
        id
        format
        duration
        sub_count
        match_stat_duration
      }
      team {
        id
        name
        description
        display_picture
       
      }
      teamPlayer {
        id
        join_date
        leave_date
        status
        user{
          id
        }
        team{
          id
        }
      }
      jersey_no
      position
      status
    }
  }
`;

export default CREATE_MATCH_LINE_UP;
