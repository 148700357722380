import React from "react";
import MatchTab from "./match-tab";
import GET_MATCHES_BY_TOURNAMENT_SEASON_ID from "../../../../api/get_matches_by_tournament_season_id";
import isToday from "dayjs/plugin/isToday";
import { useMutation, useQuery } from "@apollo/client";
import { Col, Popconfirm, Row, Image, Spin, Space, Table } from "antd";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router";
import Match from "../../../../components/match";
import CreateMatch from "../../../matches/blocks/new_match";

import "./style.scss";
import DELETE_MATCH_BY_ID from "../../../../api/delete_match_by_id";
import GET_MATCH_BY_ID from "../../../../api/get_match_by_id";
import {
  EditOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

const MatchTable = ({ seasonId, tournamentId, tournamentData }) => {
  const [matches, setMatches] = useState([]);
  const {
    data: matchesData,
    loading: matchesLoading,
    error: matchesError,
    refetch,
  } = useQuery(GET_MATCHES_BY_TOURNAMENT_SEASON_ID, {
    variables: {
      season_id: seasonId,
    },
  });
  const [activeKey, setActiveKey] = useState(
    localStorage.getItem("activeTab") || "1"
  );
 
  useEffect(() => {
    const filteredMatchData = [];
    matchesData?.getMatchesByTournamentSeasonIdWithGroupByTournamentRound.map(
      (roundMatch) => {
        roundMatch?.matches.map((match) =>
          filteredMatchData.push({
            key: match?.id,
            value: match?.id,
            team1: match?.team1,
            team2: match?.team2,
            team1_id: match?.team1?.id,
            team1_display_picture: match?.team1?.display_picture,
            team2_display_picture: match?.team2?.display_picture,
            team2_id: match?.team2?.id,
            startTime: dayjs(match?.match_schedule?.start_date)?.format(
              "YYYY-MM-DD HH:mm"
            ),
            endTime: dayjs(match?.match_schedule?.end_date)?.format(
              "YYYY-MM-DD HH:mm"
            ),
            format: match?.format,
            venue: match?.venue,
            duration: match?.duration,
            sub_count: match?.sub_count,
            tournament_season_id: match?.tournament_season_id,
            tournament_round_id: match?.tournament_round_id,
            tournament_season_group_id: match?.tournament_season_group_id,
            // tournament: tournament?.name,
          })
        );
      }
    );
    setMatches(filteredMatchData);
  }, [matchesData]);

  const columns = [
    {
      title: "Team 1",
      dataIndex: "team1",
      key: "team1",
      render: (team1) => team1?.name,
    },
    {
      //   title: "Picture",
      dataIndex: "team1_display_picture",
      key: "team1_display_picture",
      render: (team1_display_picture) => {
        return (
          team1_display_picture && (
            <div
              style={{
                display: "inline-block",
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                position: "center",
              }}
            >
              <Image
                src={team1_display_picture}
                width="40px"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
          )
        );
      },
    },
    {
      dataIndex: "",
      key: "",
      render: () => "V/S",
    },
    {
      //   title: "Picture",
      dataIndex: "team2",
      //   key: "picture",
      render: (team2) => {
        return (
          team2 && (
            <div
              style={{
                display: "inline-block",
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                position: "center",
              }}
            >
              <Image
                src={team2?.display_picture}
                width="40px"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
          )
        );
      },
    },
    {
      title: "Team 2",
      dataIndex: "team2",
      key: "team2",
      render: (team2) => team2?.name,
    },
    {
      title: "Start Time",
      dataIndex: "startTime",
      key: "startTime",
      render: (startTime) => startTime,
      sorter: (a, b) =>
        dayjs(a?.startTime, "YYYY-MM-DD HH:mm[Z]").unix() -
        dayjs(b?.startTime, "YYYY-MM-DD HH:mm[Z]").unix(),
    },
    {
      title: "End Time",
      dataIndex: "endTime",
      key: "endTime",
      render: (endTime) => endTime,
      sorter: (a, b) =>
        dayjs(a?.endTime, "YYYY-MM-DD HH:mm[Z]").unix() -
        dayjs(b?.endTime, "YYYY-MM-DD HH:mm[Z]").unix(),
    },
    
  ];

  return (
    <>
     
      <Row style={{ padding: "5px" }}>
        <Col span={24}>
          {matchesLoading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            </div>
          ) : (
            <Table columns={columns} dataSource={matches} />
          )}
        </Col>
        <Col span={16}></Col>
      </Row>

     
    </>
  );
};

export default MatchTable;
