import axios from "axios";
import * as XLSX from "xlsx";
import NewLineUp from "../lineups/blocks/new_lineup";
import {
  Button,
  Input,
  Card,
  Form,
  Row,
  Col,
  Select,
  DatePicker,
  Space,
  Table,
  Typography,
  Popconfirm,
  message,
  Alert,
  Result,
  Modal,
} from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import withAuthProtection from "../../utils/auth";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import GET_TOURNAMENTS from "../../api/get_tournaments";
import FIND_ALL_GROUPS_BY_SEASON_ID from "../../api/get_all_groups_by_season";
import FIND_ALL_SEASONS_BY_TOURNAMENT_ID from "../../api/get_tournament_seasons";
import GET_TEAMS_BY_TOURNAMENT_SEASON_ID from "../../api/get_teams_by_season_id";
import FIND_ALL_ROUNDS_BY_SEASON_ID from "../../api/get_all_rounds_by_season";
import GET_EVENT_ENUM from "../../api/get_event_enum";
import { Option } from "antd/es/mentions";
import GET_TEAMS from "../../api/get_teams";
import GET_VENUES from "../../api/get_venue_list";
import {
  DeleteFilled,
  PlusCircleOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import CREATE_BULK_MATCH_MUTATION from "../../api/create_bulk_match_mutation";
import moment from "moment";
import { useNavigate, useParams } from "react-router";

const BulkMatches = () => {
  const [form] = Form.useForm();
  const utc = require("dayjs/plugin/utc");
  dayjs.extend(utc);
  const tournament = Form.useWatch("tournament", form);
  const season = Form.useWatch("tournamentSeason", form);
  const [matchData, setMatchData] = useState([]);
  const [duration, setDuration] = useState(" ");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [endTime, setEndTime] = useState(" ");
  const [group, setGroup] = useState(" ");
  const [round, setRound] = useState(" ");
  const [statusMessage, setStatusMessage] = useState(null);
  const [duplicateTeams, setDuplicateTeams] = useState(false);
  const { tournamentId: tournamentId } = useParams();
  const { seasonId: seasonId } = useParams();
  const navigate = useNavigate();
  const { data: allTeams, loading } = useQuery(GET_TEAMS);
  const [messageApi, contextHolder] = message.useMessage();
  const { data: tournamentsData, loading: tournamentLoading } =
    useQuery(GET_TOURNAMENTS);
  const [
    getTournamentSeasonData,
    { data: seasonsData, loading: seasonsLoading },
  ] = useLazyQuery(FIND_ALL_SEASONS_BY_TOURNAMENT_ID);
  const [
    getTournamentGroupData,
    { data: groupData, loading: groupDataLoading },
  ] = useLazyQuery(FIND_ALL_GROUPS_BY_SEASON_ID);

  const [
    getTournamentSeasonTeams,
    {
      data: tournamentSeasonsTeams,
      loading: tournamentSeasonTeamsLoading,
      refetch,
    },
  ] = useLazyQuery(GET_TEAMS_BY_TOURNAMENT_SEASON_ID, {
    variables: {
      tournament_season_id: seasonId,
    },
  });

  const [
    getTournamentRoundData,
    { data: roundData, loading: roundDataLoading },
  ] = useLazyQuery(FIND_ALL_ROUNDS_BY_SEASON_ID);

  const { data: matchFormats } = useQuery(GET_EVENT_ENUM, {
    variables: { enumName: "MatchFormatEnums" },
  });

  const [
    createBulkMatches,
    {
      data: createBulkMatchesData,
      loading: cerateBulkMatchesLoading,
      error: createBulkMatchError,
    },
  ] = useMutation(CREATE_BULK_MATCH_MUTATION);

  const { data: venueData, venueLoading, venueError } = useQuery(GET_VENUES);

  useEffect(() => {
    getTournamentSeasonData({
      variables: {
        tournament_id: tournamentId,
      },
    });
  }, [tournamentId]);
  useEffect(() => {
    getTournamentSeasonTeams({
      variables: {
        tournament_season_id: seasonId,
      },
    });
  }, [seasonId]);

  useEffect(() => {
    getTournamentRoundData({
      variables: {
        seasonId: seasonId,
      },
    });

    getTournamentGroupData({
      variables: {
        tournament_season_id: seasonId,
      },
    });
  }, [season]);

  const renderTournament = () => {
    if (tournamentsData)
      return tournamentsData?.tournaments?.map((tournament) => {
        return {
          label: tournament?.name,
          value: tournament?.id,
        };
      });
  };
  const renderTournamentSeason = () => {
    if (seasonsData)
      return seasonsData?.findAllSeasonsByTournamentId?.map((season) => {
        return { label: season?.name, value: season?.id };
      });
  };

  const renderTournamentRound = () => {
    if (roundData)
      return roundData?.findAllRoundsOfSeason?.map((round) => {
        return { label: round?.type, value: round?.id };
      });
  };

  const renderTournamentGroup = () => {
    if (groupData)
      return groupData?.findAllGroupsBySeasonId?.map((group) => {
        return { label: group?.group_name, value: group?.id };
      });
  };
  const renderVenue = () => {
    if (allTeams)
      return venueData?.venues?.map((venue) => {
        return { label: venue?.name, value: venue?.id };
      });
  };
  const renderTeamOption = () => {
    if (tournamentSeasonsTeams) {
      return tournamentSeasonsTeams?.findSeasonTeamsBySeasonId?.map((teams) => {
        return { label: teams?.team?.name, value: teams?.team?.id };
      });
    } else {
      return allTeams?.teams?.map((team) => {
        return { label: team?.name, value: team?.id };
      });
    }
  };
  const handleStartTime = (value) => {
    // record.start_time = value?.$d;
    const startTime = value?.$d;
    calculateEndTime(startTime, duration);
  };

  const columns = [
    {
      title: "#",
      width: "3%",
      render: (text, record, index) => {
        return index + 1;
      },
    },

    {
      title: "Team 1",
      dataIndex: "team1Id",
      width: "15%",
      editable: true,

      render: (text, record, index) => {
        return team1DropDown(record);
      },
    },
    {
      title: "Team 2",
      dataIndex: "team2Id",
      width: "15%",
      editable: true,
      render: (text, record, index) => {
        return team2DropDown(record);
      },
    },
    {
      title: "Start time",
      dataIndex: "start_time",
      width: "18%",
      editable: true,
      render: (text, record, index) => {
        return (
          <DatePicker
            showTime
            placeholder="Select Start Time"
            format="YYYY-MM-DD HH:mm"
            defaultValue={
              record?.start_time ? dayjs(record.start_time) : dayjs()
            }
            onChange={(value) => {
              const updatedMatches = [...matchData];
              updatedMatches[index].start_time = value?.$d;

              updatedMatches[index].end_time = calculateEndTime(
                updatedMatches[index].start_time,
                duration
              );

              if (index < matchData.length - 1) {
                const nextMatch = updatedMatches[index + 1];
                nextMatch.start_time = updatedMatches[index]?.end_time;
              }

              setMatchData(updatedMatches);
            }}
          />
        );
      },
    },
    {
      title: "End time",
      dataIndex: "end_time",
      width: "18%",
      editable: true,
      render: (text, record, index) => {
        return (
          <DatePicker
            showTime
            format="YYYY-MM-DD HH:mm"
            placeholder="Select End Time"
            defaultValue={record?.end_time ? dayjs(record.end_time) : dayjs()}
            onChange={(value) => {
              record.end_time = value?.$d;
            }}
          />
        );
      },
    },
    {
      title: "Round",
      dataIndex: "round",
      width: "15%",
      editable: true,
      render: (text, record, index) => {
        return (
          <Select
            placeholder="Select Round"
            showSearch
            defaultValue={round}
            style={{ width: "100%" }}
            filterOption={(input, option) =>
              (option?.label?.toLowerCase() ?? "").includes(input.toLowerCase())
            }
            options={renderTournamentRound()}
          >
            {/* {} */}
          </Select>
        );
      },
    },
    {
      title: "Group",
      dataIndex: "group",
      width: "10%",
      editable: true,
      render: (text, record, index) => {
        return (
          <Select
            placeholder="Select Group"
            showSearch
            defaultValue={group}
            style={{ width: "100%" }}
            filterOption={(input, option) =>
              (option?.label?.toLowerCase() ?? "").includes(input.toLowerCase())
            }
            options={renderTournamentGroup()}
          >
            {/* {} */}
          </Select>
        );
      },
    },
    {
      title: " ",
      dataIndex: "action",
      width: "1%",
      editable: true,
      render: (_, record) => (
        <Space size="middle">
          <Typography.Link>
            <Popconfirm title="Delete" onConfirm={() => onDelete(record)}>
              <a>
                <DeleteFilled style={{ color: "#FF0000" }} />
              </a>
            </Popconfirm>
          </Typography.Link>
        </Space>
      ),
    },
  ];
  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    let inputNode;

    if (dataIndex == "team1") {
      inputNode = team1DropDown();
    } else if (dataIndex == "team2") {
      inputNode = team2DropDown();
    } else if (dataIndex == "group") {
      inputNode = renderTournamentGroup();
    } else if (dataIndex == "round") {
      inputNode = renderTournamentRound();
    }
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };
  const team1DropDown = (record) => {
    return (
      <Select
        placeholder="Select Team 1"
        showSearch
        style={{ width: "100%" }}
        defaultValue={record?.team1Id}
        options={renderTeamOption()}
        onChange={(value) => (record.team1Id = value)}
        filterOption={(input, option) =>
          (option?.label?.toLowerCase() ?? "").includes(input.toLowerCase())
        }
      ></Select>
    );
  };
  const currentTime = new Date().getTime();
  const team2DropDown = (record) => {
    return (
      <Select
        placeholder="Select Team 2"
        showSearch
        style={{ width: "100%" }}
        defaultValue={record?.team2Id}
        options={renderTeamOption()}
        onChange={(value) => (record.team2Id = value)}
        filterOption={(input, option) =>
          (option?.label?.toLowerCase() ?? "").includes(input.toLowerCase())
        }
      ></Select>
    );
  };
  const addNew = () => {
    // setEndTime("");
    const hasDuplicateTeams = matchData.some(
      (match) => match.team1Id === match?.team2Id
    );

    if (hasDuplicateTeams) {
      setDuplicateTeams(true);
      setStatusMessage({
        type: "error",
        content: "Please choose different teams",
      });
      return;
    }
    if (matchData?.length > 0) {
      const previousMatch = matchData[matchData.length - 1];
      const endDateTime = new Date(
        previousMatch?.end_time.getTime() + duration * 60000
      );
      setMatchData([
        ...matchData,
        {
          team1Id: "",
          team2Id: "",
          start_time: previousMatch?.end_time,
          end_time: endDateTime,
        },
      ]);
    } else {
      setMatchData([
        ...matchData,
        {
          team1Id: "",
          team2Id: "",
          start_time: "",
          end_time: "",
        },
      ]);
    }
  };
  const onDelete = (record) => {
    setMatchData(matchData.filter((item) => item !== record));
    setEndTime("");
  };

  const onFinish = async (formValue) => {
    const today = moment();

    try {
      const response = await createBulkMatches({
        variables: {
          createMatchInput: {
            format: formValue?.format,
            duration: formValue?.duration,
            subCount: formValue?.subCount,
            venueId: formValue?.venue,
            tournament_season_id: seasonId,
            tournament_round_id: formValue?.tournamentRound,
            tournament_season_group_id: formValue?.tournamentGroup,
            // managerId: process.env.REACT_APP_MANAGER_ID, commented becasue of the error in prod
            inputs: matchData,
          },
        },
      });
      if (response?.data?.createBulkMatch) {
        // message.success("Matches created");
        setMatchData([]);
        form.resetFields();
        setIsModalOpen(true);
        // onCancel();
      }
    } catch (error) {
      if (error.message === "Forbidden resource") {
        message.error("Token expired");
      } else {
        message.error("matches creation failed");
      }
    }
  };

  const reset = () => {
    // const matchData = [];
  };
  const onClose = () => {
    setStatusMessage(null);
  };
  const handleGroupChange = (value) => {
    setGroup(value);
  };
  const handleRoundChange = (value) => {
    setRound(value);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleMatch = (tournamentId) => {
    navigate(`/tournament-details/${tournamentId}`);
    handleCancel();
  };

  console.log("duration", duration);

  function calculateEndTime(startTime, duration) {
    const startDateTime = new Date(startTime);
    if (isNaN(startDateTime.getTime())) {
      console.error("Invalid start time");
      return null;
    }

    const endDateTime = new Date(startDateTime.getTime() + duration * 60000);
    setEndTime(endDateTime);
    setEndTime(endDateTime);
    return endDateTime;
  }
  return (
    <>
      <Card title="Bulk matches creation">
        <Row style={{ width: "100%" }}>
          <Form
            form={form}
            name="complex-form"
            onFinish={onFinish}
            style={{
              marginTop: "24px",
              width: "100%",
              overflowWrap: "break-word",
            }}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 24 }}
          >
            <Row gutter={4} style={{ width: "100%" }}>
              <Col span={8}>
                <Form.Item name="tournament" label="Tournament">
                  <Select
                    placeholder="Select Tournament"
                    showSearch
                    defaultValue={tournamentId}
                    filterOption={(input, option) =>
                      (option?.label?.toLowerCase() ?? "").includes(
                        input.toLowerCase()
                      )
                    }
                    options={renderTournament()}
                  ></Select>
                </Form.Item>
                <Form.Item name="tournamentGroup" label="Tournament Group">
                  <Select
                    placeholder="Select Group"
                    showSearch
                    onChange={handleGroupChange}
                    filterOption={(input, option) =>
                      (option?.label?.toLowerCase() ?? "").includes(
                        input.toLowerCase()
                      )
                    }
                    options={renderTournamentGroup()}
                  ></Select>
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please select format",
                    },
                  ]}
                  label="Format"
                  name="format"
                >
                  <Select placeholder="Select format">
                    {matchFormats?.enumValues?.map((enumValue) => (
                      <Option key={enumValue} value={enumValue}>
                        {enumValue}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="tournamentSeason" label="Tournament Season">
                  <Select
                    placeholder="Select Season"
                    defaultValue={seasonId}
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label?.toLowerCase() ?? "").includes(
                        input.toLowerCase()
                      )
                    }
                    options={renderTournamentSeason()}
                  ></Select>
                </Form.Item>
                <Form.Item
                  label="Duration"
                  name="duration"
                  rules={[
                    {
                      required: true,
                      message: "Please select match duration",
                    },
                  ]}
                >
                  <Input
                    onChange={(e) => setDuration(e.target.value)}
                    placeholder="Duration in minutes"
                    type="number"
                  />
                </Form.Item>
                <Form.Item name="venue" label="Venue">
                  <Select
                    placeholder="Select Venue"
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label?.toLowerCase() ?? "").includes(
                        input.toLowerCase()
                      )
                    }
                    options={renderVenue()}
                  ></Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please select round",
                    },
                  ]}
                  name="tournamentRound"
                  label="Tournament Round"
                >
                  <Select
                    placeholder="Select Round"
                    showSearch
                    onChange={handleRoundChange}
                    filterOption={(input, option) =>
                      (option?.label?.toLowerCase() ?? "").includes(
                        input.toLowerCase()
                      )
                    }
                    options={renderTournamentRound()}
                  ></Select>
                </Form.Item>

                <Form.Item label="Substitute Count" name="subCount">
                  <Input type="number" placeholder="Substitute count" />
                </Form.Item>
              </Col>
            </Row>
            {duplicateTeams && (
              <Alert
                style={{ width: "100%", marginBottom: "12px" }}
                message="Please select a different team "
                type="error"
                showIcon
                closable
                onClose={onClose}
              />
            )}
            <Row>
              <Col span={24}>
                <Form.Item>
                  <Table
                    style={{ width: "100%" }}
                    components={{
                      body: {
                        cell: EditableCell,
                      },
                    }}
                    // rowSelection={rowSelection}
                    bordered
                    dataSource={matchData}
                    columns={columns}
                    rowClassName="editable-row"
                    pagination={false}
                    scroll={{ y: 300, x: "auto" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12} style={{ textAlign: "left" }}>
                <Form.Item>
                  <Button style={{ color: "green" }} onClick={addNew}>
                    Add Match <PlusCircleOutlined />
                  </Button>
                </Form.Item>
              </Col>
              <Col span={12} style={{ textAlign: "right" }}>
                <Form.Item>
                  <Button style={{ marginRight: "8px" }} onClick={reset}>
                    Reset
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={cerateBulkMatchesLoading}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Row>
        {/* <Row style={{ width: "100%" }}>
          <Col
            span={24}
            style={{
              justifyContent: "center",
            }}
          > */}
        {isModalOpen && (
          <Modal
            width={700}
            open={isModalOpen}
            footer={null}
            onCancel={handleCancel}
          >
            <Result
              status="success"
              title="Successfully created Matches"
              extra={[
                <Button
                  // type="primary"
                  onClick={(e) => {
                    handleMatch(tournamentId);
                  }}
                >
                  <ArrowLeftOutlined />
                  View Matches
                </Button>,
                <Button onClick={handleCancel}>Continue Bulk creation</Button>,
              ]}
            />
          </Modal>
        )}
        {/* </Col> */}
        {/* </Row> */}
      </Card>
    </>
  );
};

export default BulkMatches;
