import gql from 'graphql-tag';

const DELETE_SPONSOR_FROM_TOURNAMENT_SEASON_MUTATION = gql`
mutation deleteSponsorFromTournamentSeason($sponsor_id: String!) {
  deleteSponsorFromTournamentSeason(sponsor_id: $sponsor_id) {
      id
      name
      display_picture
      description
      link
    }
  }
`;
export default DELETE_SPONSOR_FROM_TOURNAMENT_SEASON_MUTATION;
