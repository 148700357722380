import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Form,
  Input,
  Select,
  Space,
  DatePicker,
  Button,
  message,
  Alert,
  Row,
  Col,
  Upload,
  InputNumber,
  Divider,
} from "antd";
import { useState } from "react";
import { useEffect } from "react";
import GET_EVENT_ENUM from "../../api/get_event_enum";
import CREATE_USER from "../../api/create_user";
import GET_TEAMS from "../../api/get_teams";
import UPDATE_USER_BY_ID from "../../api/update_user";
import ADD_TEAM_PLAYERS from "../../api/add_team_player";
import { uploadS3Image } from "../../api/rest-api/s3-api";
import { PlusOutlined } from "@ant-design/icons";
import { useParams } from "react-router";
import UPDATE_TOURNAMENT_SEASON_SQUAD from "../../api/update_tournament_season_squad";
// import GET_TEAMS from "../../../api/get_teams";
// import { PlusOutlined } from "@ant-design/icons";
// import { uploadS3Image } from "../../../api/rest-api/s3-api";
// import CREATE_USER from "../../../api/create_user";
// import UPDATE_USER_BY_ID from "../../../api/update_user";
// import GET_EVENT_ENUM from "../../../api/get_event_enum";
// import ADD_TEAM_PLAYERS from "../../../api/add_team_player";

const UpdateSquad = ({ record, refresh, close, teamId }) => {
  const [
    createUser,
    {
      data: createdUserData,
      loading: createUserLoading,
      error: createdUserError,
    },
  ] = useMutation(CREATE_USER);
  const { data: playerPositions } = useQuery(GET_EVENT_ENUM, {
    variables: { enumName: "PlayerPosition" },
  });
  const {
    data: teams,
    loading: teamsLoading,
    error: teamsLoadingError,
  } = useQuery(GET_TEAMS);
  const [
    updateSquadById,
    {
      data: updateSquadData,
      loading: updateSquadLoading,
      error: updateSquadError,
    },
  ] = useMutation(UPDATE_TOURNAMENT_SEASON_SQUAD);
  const [
    addTeamPlayers,
    {
      data: addTeamPlayersData,
      loading: addTeamPlayersLoading,
      error: addTeamPlayersError,
    },
  ] = useMutation(ADD_TEAM_PLAYERS);
  const { Option } = Select;
  const [statusMessage, setStatusMessage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [form] = Form.useForm();
  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        name: record?.name,
        phone_number: record?.phone_number,
        preferred_jersey_no: record?.preferred_jersey_no,
        email: record?.email,
        position: record?.position,
        // display_picture: record?.display_picture
      });

      setSelectedImage({ src: record?.display_picture });
    } else {
      form.resetFields();
    }
  }, [record]);

  const onFinish = async (formValue) => {
    updateSquad(formValue);
  };

  const updateSquad = async (formValue) => {
    const payload = formValue;

    updateSquadById({
      variables: {
        updateTournamentSeasonSquadInput: {
          jerseyNo: formValue?.preferred_jersey_no,
          position: formValue?.position,
          id: record?.key,
          team_id: teamId,
        },
      },
    })
      .then((res) => {
        setStatusMessage({
          type: "success",
          content: "User Updated",
        });

        onReset();
        refresh();
        close();
      })
      .catch((err) => {
        setStatusMessage({
          type: "error",
          content: err.message,
        });
      });
  };

  const onReset = () => {
    form?.resetFields();
    setSelectedImage(null);
  };

  const onClose = () => {
    setStatusMessage(null);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Select</div>
    </div>
  );

  const readURL = (input) => {
    const file = input?.target?.files[0];

    if (file) {
      var reader = new FileReader();

      reader.onload = function (e) {
        setSelectedImage({ file, src: e.target.result });

        setTimeout(() => {
          document
            .getElementById("profile_image")
            .setAttribute("src", e.target.result);
        }, 0);
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      {statusMessage && (
        <Alert
          message={statusMessage?.content}
          type={statusMessage?.type}
          showIcon
          closable
          onClose={onClose}
        />
      )}
      <Form
        form={form}
        name="complex-form"
        onFinish={onFinish}
        style={{ marginTop: "24px" }}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 15 }}
      >
        <Row gutter={4}>
          <Col span={24}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Name is required" }]}
            >
              <Input />
            </Form.Item>

            {/* <Form.Item
              label="Team"
              name={"teamId"}
              rules={[{ required: true, message: "team" }]}
            >
              <Input />
            </Form.Item> */}

            <Form.Item label="Jersey No:" name="preferred_jersey_no">
              <Input />
            </Form.Item>

            <Form.Item label="Position" name="position">
              <Select
                showSearch
                filterOption={(input, option) =>
                  (option?.label?.toLowerCase() ?? "").includes(
                    input.toLowerCase()
                  )
                }
                options={playerPositions?.enumValues?.map((enumValue) => {
                  return { label: enumValue, value: enumValue };
                })}
              ></Select>
            </Form.Item>
            <Col span={24}>
              <Space style={{ justifyContent: "right", width: "100%" }}>
                <Button htmlType="button">Reset</Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={updateSquadLoading}
                >
                  Update
                </Button>
              </Space>
            </Col>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default UpdateSquad;
