import {
  DeleteOutlined,
  EditOutlined,
  ManOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
import { Avatar, Badge, Card, Col, Image, Popconfirm, Row, Space } from "antd";
import dayjs from "dayjs";

import "./style.scss";

const Match = ({ data, onClick, onEdit, onDelete, controls }) => {
  return (
    <Row className="match-card_wrapper">
      <Col className="match-card_column">
        <Card className="match-card " onClick={onClick}>
          {data?.tournament_season_group?.group_name && (
            <Badge.Ribbon
              text={`Group ${data.tournament_season_group.group_name}`}
            />
          )}

          <Row style={{ marginBottom: "12px" }}>
            <Col span={12}>
              {controls?.delete && (
                <Popconfirm
                  title="Delete the match"
                  icon={<DeleteOutlined />}
                  description="Are you sure to delete this match?"
                  onConfirm={(event) => {
                    event.stopPropagation();
                    onDelete(data);
                  }}
                  onCancel={(event) => event.stopPropagation()}
                  okText="Yes"
                  cancelText="No"
                >
                  <DeleteOutlined
                    className="match-delete-icon"
                    onClick={(event) => event.stopPropagation()}
                  />
                </Popconfirm>
              )}
            </Col>
          </Row>
          <Row>
            <Col span={11}>
              <div className="match-logo">
                {data?.team1?.display_picture && (
                  <Avatar
                    className="match-avatar"
                    src={data?.team1?.display_picture}
                  />
                )}
              </div>
              <div className="team-name">{data?.team1?.name}</div>
              {/* </Card> */}
            </Col>
            <Col span={2} className="match-X">
              X
            </Col>
            <Col span={11}>
              <div className="match-logo">
                {data?.team2?.display_picture && (
                  <Avatar
                    className="match-avatar"
                    src={data?.team2?.display_picture}
                  />
                )}
              </div>
              <div className="team-name">{data?.team2?.name}</div>
            </Col>
          </Row>
          <Row className="schedule-row">
            <Card className="schedule">
              {dayjs(data?.match_schedule?.start_date).format(
                "DD-MM-YYYY hh:mm"
              )}
            </Card>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default Match;
