import gql from "graphql-tag";

const GET_TEAM_PLAYERS_BY_TEAM = gql`
  query  teamPlayersByTeam($team_id: String!) {
    teamPlayersByTeam(team_id: $team_id) {
      id
    join_date
    leave_date
    status
    user {
      id
      name
      email
      phone_number
      display_picture
      position
      preferred_jersey_no
      status
      teamPlayer {
        id
      }
    }
    team {
      id
      name
      description
      display_picture
     
    }
   }
  }
`;

export default GET_TEAM_PLAYERS_BY_TEAM;

