import { PlusSquareOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/client";
import { Col, Empty, message, Modal, Radio, Row, Table } from "antd";
import { useEffect, useState } from "react";
import DELETE_TOURNAMENT_SEASON from "../../../../api/delete_tournament_season";
import GET_MATCHES_BY_TOURNAMENT_SEASON_ID from "../../../../api/get_matches_by_tournament_season_id";
import Match from "../../../../components/match";
import TournamentSeason from "../../../../components/tournament-season";
import CreateSeason from "./blocks/new_season";

import "./style.scss";

const SeasonTab = ({ seasonData, refetchSeason }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [seasons, setSeasons] = useState(seasonData);
  const [
    seasonDelete,
    {
      data: seasonDeleteData,
      loading: seasonDeleteLoading,
      error: seasonDeleteError,
    },
  ] = useMutation(DELETE_TOURNAMENT_SEASON);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [selectedSeason, setSelectedSeason] = useState(null);
  const [statusFilter, setStatusFilter] = useState("ALL");

  useEffect(() => {
    setSeasons(seasonData);
  }, [seasonData]);
  const onSeasonEdit = (season) => {
    setSelectedSeason(season);
    setIsCreateModalOpen(true);
  };
  const onSeasonDelete = (id) => {
    seasonDelete({
      variables: {
        tournament_season_id: id,
      },
    })
      .then((res) => {
        messageApi.open({
          type: "success",
          content: "Deleted Successfully",
        });
        refetchSeason();
      })
      .catch((error) => {
        messageApi.open({
          type: "error",
          content: error?.message || "Something went wrong!",
        });
      });
  };

  const handleCancel = () => {
    setIsCreateModalOpen(false);

    if (selectedSeason) {
      setSelectedSeason(null);
    }
  };

  const showModal = () => {
    setIsCreateModalOpen(true);
  };

  const onStatusFilterChange = (value) => {
    setStatusFilter(value);

    if (value == "ALL") {
      setSeasons(seasonData);
    } else {
      const filteredSeason = seasonData?.filter((season) => {
        return season?.status == value;
      });
      setSeasons(filteredSeason);
    }
  };

  return (
    <div className="seasons">
      {contextHolder}
      <Row>
        <Col span={5}>
          <h1>
            Seasons{" "}
            <PlusSquareOutlined
              onClick={showModal}
              style={{ color: "blue", fontSize: "24PX" }}
            />
          </h1>
        </Col>
        <Col span={19} style={{ textAlign: "right" }}>
          <Radio.Group
            value={statusFilter}
            onChange={(e) => onStatusFilterChange(e.target.value)}
          >
            <Radio.Button value="ALL">All</Radio.Button>
            <Radio.Button value="DRAFT">Draft</Radio.Button>
            <Radio.Button value="PUBLISHED">Published</Radio.Button>
          </Radio.Group>
        </Col>
      </Row>

      <div className="season-container">
        <Row gutter={24} style={{ width: "100%" }}>
          {!seasons?.length && (
            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Empty />
            </Row>
          )}
          {seasons?.map((season) => {
            return (
              <Col span={8}>
                <TournamentSeason
                  data={season}
                  controls={{ edit: true, delete: true }}
                  onEdit={() => onSeasonEdit(season)}
                  onDelete={() => onSeasonDelete(season?.id)}
                />
              </Col>
            );
          })}
        </Row>
      </div>

      {isCreateModalOpen && (
        <Modal
          title={selectedSeason ? "Update Season" : "Create Season"}
          width={500}
          open={isCreateModalOpen}
          footer={null}
          onCancel={handleCancel}
        >
          <CreateSeason
            record={selectedSeason}
            refresh={refetchSeason}
            close={handleCancel}
          />
        </Modal>
      )}
    </div>
  );
};

export default SeasonTab;
