import {
  DeleteFilled,
  DeleteOutlined,
  EditFilled,
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Card, Col, Popconfirm, Row, message } from "antd";
import Meta from "antd/es/card/Meta";
import "./style.scss";
import Modal from "antd/es/modal/Modal";
import { useMutation } from "@apollo/client";
import DELETE_TEAM from "../../../api/delete_team";
import { useState } from "react";
import CreateTeam from "./new_team";

const TeamCard = ({ data, onClick, refetch }) => {
  const [messageApi, contextHolder] = message.useMessage();
  // const navigate = useNavigate();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [selectedMatchRecord, setSelectedMatchRecord] = useState(null);
  const [deleteTeam, { data: deletedTeamData, loading: deleteTeamLoading }] =
    useMutation(DELETE_TEAM);
  const onDelete = (record) => {
    deleteTeam({
      variables: {
        team_id: record?.key,
      },
    })
      .then((res) => {
        messageApi.open({
          type: "success",
          content: "Team deleted",
        });
        refetch();
      })
      .catch((err) => {
        messageApi.open({
          type: "error",
          content: err?.message || "Something went wrong!",
        });
      });
  };

  const showModal = () => {
    setIsCreateModalOpen(true);
  };

  const onEdit = (record) => {
    setSelectedMatchRecord(record);
    showModal();
  };

  const handleCancel = () => {
    setIsCreateModalOpen(false);

    if (selectedMatchRecord) {
      setSelectedMatchRecord(null);
    }
  };

  return (
    <>
      <Row className="team-card_wrapper">
        <Col className="team-card_column">
          <Card
            onClick={onClick}
            className="team-card"
            actions={[
              // <div className="team-button-container">
              <Popconfirm
                key="delete"
                title="Delete the team"
                icon={<DeleteOutlined />}
                description="Are you sure to delete this team?"
                onConfirm={(event) => {
                  event.stopPropagation();
                  onDelete(data);
                }}
                onCancel={(event) => event.stopPropagation()}
                okText="Yes"
                cancelText="No"
              >
                <DeleteFilled
                  style={{
                    cursor: "pointer",
                    color: "#4f5453",
                    fontSize: "15px",
                  }}
                  onClick={(event) => event.stopPropagation()}
                />
              </Popconfirm>,
              <EditFilled
                style={{
                  cursor: "pointer",
                  color: "#4f5453",
                  fontSize: "15px",
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  onEdit(data);
                }}
              />,

              // </div>,
            ]}
          >
            <img
              className="card-image"
              alt=""
              src={
                data?.display_picture ||
                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdywxLCW7l-a-VcnPQQBc2IZbNXWBGVFGbPw&usqp=CAU"
              }
              preview={{
                src:
                  data?.display_picture ||
                  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdywxLCW7l-a-VcnPQQBc2IZbNXWBGVFGbPw&usqp=CAU",
              }}
            />

            <Row className="card-name">
              <Meta title={data?.name} description={data?.description} />
            </Row>
          </Card>
        </Col>
      </Row>
      {isCreateModalOpen && (
        <Modal
          title={selectedMatchRecord ? "Update Team" : "Create Team"}
          width={500}
          open={isCreateModalOpen}
          footer={null}
          onCancel={handleCancel}
        >
          <CreateTeam
            record={selectedMatchRecord}
            refresh={refetch}
            close={handleCancel}
          />
        </Modal>
      )}
    </>
  );
};

export default TeamCard;
