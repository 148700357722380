import { useMutation } from "@apollo/client";
import { Form, Input, Select, Space, DatePicker, Button, message, Alert, Row, Col, Upload, InputNumber, Divider } from "antd"
import { useState } from "react";
import { useEffect } from "react";
import { uploadS3Image } from "../../../../../api/rest-api/s3-api";
import CREATE_TOURNAMENT_SEASON from "../../../../../api/create_tournament_season";
import UPDATE_SEASON_TEAM_BY_ID from "../../../../../api/update_team_in_seaon";
import dayjs from "dayjs";
import UPDATE_TOURNAMENT_SEASON from "../../../../../api/update_tournament_season";
import { useParams } from "react-router";
import CREATE_TOURNAMENT_SEASON_ROUND from "../../../../../api/create_tournament_season_round";


const CreateRound = ({ selectedSeasonId, record, refresh, close }) => {
    const {id: seas} = useParams();

    const [createRound, { data: createdSeasonRoundData, loading: createSeasonRoundLoading, error: createdSeasonRoundError }] = useMutation(CREATE_TOURNAMENT_SEASON_ROUND);
    // const [updateSeasonById, { data: updateSeasonData, loading: updateSeasonLoading, error: updateSeasonError }] = useMutation(UPDATE_TOURNAMENT_SEASON);

    const [statusMessage, setStatusMessage] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedTeam, setSelectedTeam] = useState(null);

    const [form] = Form.useForm();

    useEffect(() => {
        if (record) {
            debugger
            form.setFieldsValue({
                type: record?.type,
                start_date: dayjs(record?.start_date, 'YYYY-MM-DD HH:mm:ss[Z]'),
                match_duration: record?.mtch_duration,
                match_extra_time: record?.match_extra_time,
                sequence_no: record?.sequence_no,
                break_time: record?.break_time,
            })

        } else {
            form.resetFields()
        }
    }, [record])

    const onFinish = async (formValue) => {
        if (record) {
            // updateSeason(formValue)
        } else {
            saveSeasonRound(formValue)
        }
    }

    const saveSeasonRound = async (formValue) => {
        let payload = formValue

          await createRound({
                variables: {
                    createTournamentRoundInput: {...payload, tournament_season_id: selectedSeasonId}
                },
            }).then(res => {
                setStatusMessage({
                    type: 'success',
                    content: 'Season Round created',
                });

                onReset();
                refresh();
                close();
            }).catch(err => {
                setStatusMessage({
                    type: 'error',
                    content: err.message,
                });
            })
    }

    const updateSeason = async (formValue) => {
        let payload = formValue

        // updateSeasonById({
        //     variables: {
        //         tournament_season_id: record?.id,
        //         updateTournamentSeasonInput: {
        //             ...payload
        //         }
        //     },
        // }).then((res) => {
        //     setStatusMessage({
        //         type: 'success',
        //         content: 'Season Updated',
        //     });

        //     onReset();
        //     refresh();
        //     close();
        // }).catch((err) => {
        //     setStatusMessage({
        //         type: 'error',
        //         content: err.message,
        //     });
        // })
    }

    const onReset = () => {
        form?.resetFields();
        setSelectedImage(null)
    };

    const onClose = () => {
        setStatusMessage(null)
    };

    const readURL = (input) => {
        const file = input?.target?.files[0];

        if (file) {
            var reader = new FileReader();

            reader.onload = function (e) {
                setSelectedImage({ file, src: e.target.result })

                setTimeout(() => {
                    document.getElementById('season_image').setAttribute('src', e.target.result);
                }, 0)
            }

            reader.readAsDataURL(file);
        }
    }

    return (
        <>
            {statusMessage && <Alert message={statusMessage?.content} type={statusMessage?.type} showIcon closable onClose={onClose} />}
            <Form
                form={form}
                name="complex-form"
                onFinish={onFinish}
                style={{ marginTop: '24px' }}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 15 }}
            >

                <Row gutter={4}>
                    <Col span={24}>
                        <Form.Item
                            label="Name"
                            name="type"
                            rules={[{ required: true, message: 'Name is required' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Sequence Number"
                            name="sequence_no"
                        >
                            <InputNumber />
                        </Form.Item>

                        <Form.Item
                            label="Start Date"
                            name="start_date"
                        >
                            <DatePicker />
                        </Form.Item>

                        <Form.Item
                            label="Match Duration"
                            name="match_duration"
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Match Extra Time"
                            name="match_extra_time"
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Break Time"
                            name="break_time"
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Space style={{ justifyContent: 'right', width: '100%' }}>
                            <Button htmlType="button" onClick={onReset}>
                                Reset
                            </Button>
                            <Button type="primary" htmlType="submit" loading={createSeasonRoundLoading}>
                                {record ? 'Update' : 'Submit'}
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default CreateRound