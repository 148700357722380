import gql from "graphql-tag";

const GET_LIST_GOALRUSH_SCHOOLS = gql`
query ListGoalrushSchools( $type: GoalrushSchoolType!) {
  listGoalrushSchools(listTournamentInput: { type: $type }) {
      id
      name
      description
      links
      images
      type
      createdAt
      createdBy
    }
  }
`;

export default GET_LIST_GOALRUSH_SCHOOLS;

