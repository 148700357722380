import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Card, Col, Row } from "antd";

import "./style.scss";

const Tournament = ({ data, onClick, onEditClick, onDeleteClick }) => {
  return (
    <Row className="tournament-card_wrapper">
      <Col className="tournament-card_column">
        <Card
          className="tournament-card"
          key={data?.id}
          onClick={onClick}
          actions={[
            <EditOutlined
              key="edit"
              onClick={(e) => {
                e.stopPropagation();
                onEditClick();
              }}
            />,
            <DeleteOutlined key="delete" />,
          ]}
        >
          <img
            className="logo"
            alt=""
            src={
              data?.display_picture ||
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdywxLCW7l-a-VcnPQQBc2IZbNXWBGVFGbPw&usqp=CAU"
            }
            preview={{
              src:
                data?.display_picture ||
                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdywxLCW7l-a-VcnPQQBc2IZbNXWBGVFGbPw&usqp=CAU",
            }}
          />

          <Row className="title">{data?.name} </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default Tournament;
