import { gql } from "@apollo/client";

const UPDATE_MATCH_LINE_UP_BY_ID = gql`
  mutation updateMatchLineupById($lineup_id: String!, $updateMatchLineupInput:UpdateMatchLineupInput!) {
    updateMatchLineupById(lineup_id: $lineup_id, UpdateMatchLineupInput: $updateMatchLineupInput) {
      id
    match {
      id
      format
      duration
      sub_count
      match_stat_duration
      streaming_url
      created_at
    }
    team {
      id
      name
      description
      display_picture
    }
    teamPlayer {
      id
      join_date
      leave_date
      status
    }
    jersey_no
    position
    status
    }
  }
`;

export default UPDATE_MATCH_LINE_UP_BY_ID;
