import { gql } from "@apollo/client";

const ASSIGN_TEAM_AWARDS= gql`
mutation assignTeamAwards($tournament_season_id: String!, $input: [TeamInput!]!) {
  assignTeamAwards(TeamAwardsInput: { tournament_season_id: $tournament_season_id, input: $input }) {
    id
    awardType
    team {
      id
      name
      description
      display_picture
    }
    player {
      id
      name
      email
      phone_number
      display_picture
      position
      preferred_jersey_no
      status
    }
    tournament_season {
      id
      name
      start_date
      display_picture
      end_date
      tournament_rules
    }
  }
}
`;

export default ASSIGN_TEAM_AWARDS;
