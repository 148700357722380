import gql from "graphql-tag";

export const GET_TOURNAMENT_SQUAD_BY_ID = gql`
  {
    findTournamentSquadById(id: "string") {
      season_id
      team_id
      position
      jerseyNo
      status
      id
      playerName
      playerId
      displayPicture
    }
  }
`;
export default GET_TOURNAMENT_SQUAD_BY_ID;
