import React, { useEffect, useState } from "react";
import { useLazyQuery, useQuery, useSubscription } from "@apollo/client";
import GET_MATCHES from "../../api/get_all_matches";
import {
  Col,
  Row,
  Select,
  Popconfirm,
  Button,
  Modal,
  Space,
} from "antd";
import moment from "moment/moment";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import GET_MATCH_LINEUP_BY_ID from "../../api/get_match_line_ups"
import LineUpTable from "./blocks/lineup-table";
import NewLineUp from "./blocks/new_lineup";
import GET_MATCH_BY_ID from "../../api/get_match_by_id";
import PlayerSubstitute from "./blocks/player-subtitute";
import withAuthProtection from "../../utils/auth";

const Lineups = () => {
  const { data: allMatches, loading: allMatchLoading } = useQuery(GET_MATCHES);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [selectedMatchTeam, setSelectedMatchTeam] = useState(null)
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isSubstituteModalOpen, setIsSubstituteModalOpen] = useState(false);
  const [selectedTeamIndex, setSelectedTeamIndex] = useState(null);
  const [setSelectedMatchId, selectedMatchId] = useState(null);
  // const [setMatchData, matchData] = useState(null);



  const { Option } = Select;

  const [getMatchLineups, { data: matchLineUps, loading: matchLineUpsLoading, refetch }] = useLazyQuery(GET_MATCH_LINEUP_BY_ID, {
    variables: { match_id: selectedMatch },
  });

  const { data: matchData, loading: matchLoading } = useQuery(GET_MATCH_BY_ID, {
    variables: { id: selectedMatch },
});
  useEffect(() => {
    if (selectedMatch) {
      getMatchLineups()
    }
  }, [selectedMatch])

  useEffect(() => {
    setSelectedMatchTeam({ team1: allMatches?.matches[0]?.team1, team2: allMatches?.matches[0]?.team2 })
    setSelectedMatch(allMatches?.matches[0]?.id)
  }, [allMatches])

  useEffect(() => {
    const originData = [];

    matchLineUps?.matchLineupByMatchId?.map((team) => {
      let obj = { id: team?.id, name: team?.name, lineups: [] }
      team?.lineup?.map((lineup) => {
        obj.lineups.push(formatLineupData(lineup));
      })

      originData.push(obj)
    });

    setTableData(originData);
  }, [matchLineUps]);
  const formatLineupData = (lineup) => {
    return {
      key: lineup?.id,
      name: lineup?.name,
      position: lineup?.position,
      teamPlayerId: lineup?.teamPlayerId,
      jerseyNumber: lineup?.jersey_no,
      status: lineup?.status,
      userId: lineup?.userId,
      displayPicture: lineup?.display_picture
    }
  }
  const onChange = (value) => {
    setSelectedMatch(value);

    const match = allMatches?.matches?.find((object) => object?.id == value)

    if (match) {
      setSelectedMatchTeam({ team1: match?.team1, team2: match?.team2 })
    }
  };

  const onSearch = (value) => {
    setSelectedMatch(value);
  };

  let selectMatches = [];
  allMatches?.matches?.map((matches) => {
    selectMatches.push({
      key: matches?.id,
      value: matches?.id,
      label: `${matches?.team1?.name} vs ${matches?.team2?.name} ${moment(
        matches?.match_schedule?.start_date
      ).format("DD-MMM-YYYY hh:mm A")}`,
    });
  });
  const handleCancel = () => {
    setIsCreateModalOpen(false);
  };

  const handleSubstituteCancel = () => {
    setIsSubstituteModalOpen(false);
  };

  return (
    <>
      <Row>
        <Col span={21} style={{ paddingTop: "10px" }}>
          {selectedMatch && <Select
            showSearch
            placeholder="Select a match"
            optionFilterProp="children"
            defaultValue={selectedMatch}
            onChange={onChange}
            onSearch={onSearch}
            style={{
              width: "500px",
            }}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={selectMatches}
          />}
        </Col>

        <Col span={24}>
          {matchLineUpsLoading || allMatchLoading ?
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
            </div>
            :
            <>
              {selectedMatch && <Col span={24}>
                <Col span={24} style={{ marginTop: "56px" }}>
                  <Row>
                    <Col span={16}>
                      <h2>{tableData?.[0]?.name}</h2>
                    </Col>
                    <Col span={8} style={{ textAlign: 'right', paddingBottom: '18px' }}>
                      <Space>
                        {/* <Button type="primary" onClick={() => {
                          setSelectedTeamIndex(0);
                          setSelectedTeam(tableData?.[0]?.id);
                          setIsSubstituteModalOpen(true)
                        }
                        }
                          style={{ marginTop: '16px' }}>Manage Substitution</Button> */}
                        <Button type="primary" onClick={() => {
                          setSelectedTeam(tableData?.[0]?.id)
                          setIsCreateModalOpen(true)
                        }
                        }
                        
                          style={{ marginTop: '16px' }}>Manage Lineup</Button>
                         
                      </Space>
                    </Col>
                  </Row>

                  <LineUpTable lineUpData={tableData?.[0]?.lineups}  teamId={tableData?.[0]?.id} refresh={refetch} />
                </Col>

                <Col span={24} style={{ marginTop: "56px" }}>
                  <Row>
                    <Col span={16}>
                      <h2>{tableData?.[1]?.name}</h2>
                    </Col>
                    <Col span={8} style={{ textAlign: 'right', paddingBottom: '18px' }}>
                      <Space>
                        {/* <Button type="primary" onClick={() => {
                          setSelectedTeam(tableData?.[1]?.id)
                          setSelectedTeamIndex(1);
                          setIsSubstituteModalOpen(true)
                        }
                        }
                          style={{ marginTop: '16px' }}>Manage Substitution</Button> */}
                        <Button type="primary" onClick={() => {
                          setSelectedTeam(tableData?.[1]?.id)
                          setIsCreateModalOpen(true)
                        }
                        }
                          style={{ marginTop: '16px' }}>Manage Lineup</Button>
                      </Space>
                    </Col>
                  </Row>
                  <LineUpTable lineUpData={tableData?.[1]?.lineups} teamId={tableData?.[1]?.id}  refresh={refetch}  />
                </Col>
              </Col>
              }
            </>

          }
        </Col>
      </Row>


      {isCreateModalOpen && <Modal title="Add Players" width={1000} open={isCreateModalOpen} footer={null} onCancel={handleCancel}>
                    <NewLineUp existingPlayers={tableData.filter((data) => data.id === selectedTeam)[0]} seasonId={matchData?.tournamentSeason?.id} teamId={selectedTeam} matchId={selectedMatch} refresh={refetch} close={handleCancel} />
                </Modal>}

      {/* {selectedTeamIndex} */}
      {/* {isSubstituteModalOpen && <Modal title={`Substitution(${tableData[selectedTeamIndex]?.name})`} open={isSubstituteModalOpen} footer={null} onCancel={handleSubstituteCancel}>
        <PlayerSubstitute playerList={tableData?.[selectedTeamIndex]?.lineups} matchId={selectedMatch} close={handleCancel} teamId={selectedTeam} />
      </Modal>} */}
    </>
  );
};

export default withAuthProtection(Lineups);
