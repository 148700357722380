import React, { useContext } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const withAuthProtection = (Component) => {
  return (props) => {
    const navigate = useNavigate();

    const userName = localStorage.getItem("userName");
    const password = localStorage.getItem("password");

    console.log(">>>password", password, userName);
    let isAuthenticated = false;
    if (Cookies.get('jwt-token')) {
      isAuthenticated = true;
    } else {
      isAuthenticated = false;
    }

    if (!isAuthenticated) {
      navigate("/login");
      return null;
    }

    console.log(">>>isAuthenticated", isAuthenticated);
    return isAuthenticated ? <Component {...props} /> : null;
  };
};

export default withAuthProtection;
