import { useMutation } from "@apollo/client";
import { Form, Input, Select, Space, DatePicker, Button, message, Alert, Row, Col, Upload, InputNumber, Divider } from "antd"
import { useState } from "react";
import { useEffect } from "react";
import { uploadS3Image } from "../../../../../api/rest-api/s3-api";
import CREATE_TOURNAMENT_SEASON from "../../../../../api/create_tournament_season";
import UPDATE_SEASON_TEAM_BY_ID from "../../../../../api/update_team_in_seaon";
import dayjs from "dayjs";
import UPDATE_TOURNAMENT_SEASON from "../../../../../api/update_tournament_season";
import { useParams } from "react-router";
import CREATE_TOURNAMENT_SEASON_ROUND from "../../../../../api/create_tournament_season_round";
import CREATE_TOURNAMENT_SEASON_GROUP from "../../../../../api/create_tournament_season_group";


const CreateGroup = ({ selectedSeasonId, record, refresh, close }) => {
    const {id: seas} = useParams();

    const [createGroup, { data: createdSeasonGroupData, loading: createSeasonGroupLoading, error: createdSeasonGroupError }] = useMutation(CREATE_TOURNAMENT_SEASON_GROUP);
    // const [updateSeasonById, { data: updateSeasonData, loading: updateSeasonLoading, error: updateSeasonError }] = useMutation(UPDATE_TOURNAMENT_SEASON);

    const [statusMessage, setStatusMessage] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedTeam, setSelectedTeam] = useState(null);

    const [form] = Form.useForm();

    useEffect(() => {
        if (record) {
            form.setFieldsValue({
                group_name: record?.group_name,
            })

        } else {
            form.resetFields()
        }
    }, [record])

    const onFinish = async (formValue) => {
        if (record) {
            // updateSeason(formValue)
        } else {
            saveSeasonGroup(formValue)
        }
    }

    const saveSeasonGroup= async (formValue) => {
        let payload = formValue

          await createGroup({
                variables: {
                    createTournamentSeasonGroupInput: {...payload, tournament_season_id: selectedSeasonId}
                },
            }).then(res => {
                setStatusMessage({
                    type: 'success',
                    content: 'Season Group created',
                });

                onReset();
                refresh();
                close();
            }).catch(err => {
                setStatusMessage({
                    type: 'error',
                    content: err.message,
                });
            })
    }

    const updateSeason = async (formValue) => {
        let payload = formValue

        // updateSeasonById({
        //     variables: {
        //         tournament_season_id: record?.id,
        //         updateTournamentSeasonInput: {
        //             ...payload
        //         }
        //     },
        // }).then((res) => {
        //     setStatusMessage({
        //         type: 'success',
        //         content: 'Season Updated',
        //     });

        //     onReset();
        //     refresh();
        //     close();
        // }).catch((err) => {
        //     setStatusMessage({
        //         type: 'error',
        //         content: err.message,
        //     });
        // })
    }

    const onReset = () => {
        form?.resetFields();
        setSelectedImage(null)
    };

    const onClose = () => {
        setStatusMessage(null)
    };

    return (
        <>
            {statusMessage && <Alert message={statusMessage?.content} type={statusMessage?.type} showIcon closable onClose={onClose} />}
            <Form
                form={form}
                name="complex-form"
                onFinish={onFinish}
                style={{ marginTop: '24px' }}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 15 }}
            >

                <Row gutter={4}>
                    <Col span={24}>
                        <Form.Item
                            label="Name"
                            name="group_name"
                            rules={[{ required: true, message: 'Name is required' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Space style={{ justifyContent: 'right', width: '100%' }}>
                            <Button htmlType="button" onClick={onReset}>
                                Reset
                            </Button>
                            <Button type="primary" htmlType="submit" loading={createSeasonGroupLoading}>
                                {record ? 'Update' : 'Submit'}
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default CreateGroup