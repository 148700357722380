import { gql } from "@apollo/client";

const UPDATE_TOURNAMENT_BY_ID= gql`
mutation updateTournamentById($tournament_id: String!, $updateTournamentInput: UpdateTournamentInput!) {
  updateTournamentById(tournament_id: $tournament_id, updateTournamentInput: $updateTournamentInput) {
    id
    name
    display_picture
    description
    about_tournament
  }
}

`;

export default UPDATE_TOURNAMENT_BY_ID;
