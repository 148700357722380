import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Form,
  Input,
  Select,
  Space,
  DatePicker,
  Button,
  message,
  Alert,
  Row,
  Col,
  Upload,
  InputNumber,
  Divider,
} from "antd";
import GET_TEAMS from "../../../api/get_teams";
import { PlusOutlined } from "@ant-design/icons";
import { uploadS3Image } from "../../../api/rest-api/s3-api";
import GET_EVENT_ENUM from "../../../api/get_event_enum";
import ADD_TEAM_PLAYERS from "../../../api/add_team_player";
import CREATE_TEAM from "../../../api/create_team";
import UPDATE_TEAM_BY_ID from "../../../api/update_team";
import { useEffect, useState } from "react";

const CreateTeam = ({ record, refresh, close }) => {
  const [
    createTeam,
    {
      data: createdTeamData,
      loading: createTeamLoading,
      error: createdTeamError,
    },
  ] = useMutation(CREATE_TEAM);
  const { data: playerPositions } = useQuery(GET_EVENT_ENUM, {
    variables: { enumName: "PlayerPosition" },
  });
  const {
    data: teams,
    loading: teamsLoading,
    error: teamsLoadingError,
  } = useQuery(GET_TEAMS);
  const [
    updateTeamById,
    {
      data: updateTeamData,
      loading: updateTeamLoading,
      error: updateTeamError,
    },
  ] = useMutation(UPDATE_TEAM_BY_ID);
  const [
    addTeamPlayers,
    {
      data: addTeamPlayersData,
      loading: addTeamPlayersLoading,
      error: addTeamPlayersError,
    },
  ] = useMutation(ADD_TEAM_PLAYERS);
  const { Option } = Select;
  const [statusMessage, setStatusMessage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);

  const [form] = Form.useForm();

  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        name: record?.name,
        description: record?.description,
      });

      setSelectedImage({ src: record?.display_picture });
    } else {
      form.resetFields();
    }
  }, [record]);

  const onFinish = async (formValue) => {
    if (record) {
      updateTeam(formValue);
    } else {
      saveTeam(formValue);
    }
  };

  const saveTeam = async (formValue) => {
    let payload = formValue;

    if (selectedImage?.file) {
      await uploadS3Image(selectedImage?.file)
        .then((resp) => {
          payload = {
            ...payload,
            display_picture: `${process.env.REACT_APP_API_URL}/media/download/${resp?.data}`,
          };
        })
        .catch((err) => {
          console.log(err);
        });
    }

    createTeam({
      variables: {
        createTeamInput: {
          ...payload,
        },
      },
    })
      .then((res) => {
        setStatusMessage({
          type: "success",
          content: "Team created",
        });
        onReset();
        refresh();
        close();
      })
      .catch((err) => {
        setStatusMessage({
          type: "error",
          content: err?.message,
        });
      });
  };

  const updateTeam = async (formValue) => {
    let payload = formValue;

    if (selectedImage?.file) {
      await uploadS3Image(selectedImage?.file)
        .then((resp) => {
          payload = {
            ...payload,
            display_picture: `${process.env.REACT_APP_API_URL}/media/download/${resp?.data}`,
          };
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (!selectedImage?.src) {
      payload = { ...payload, display_picture: "" };
    }

    updateTeamById({
      variables: {
        team_id: record?.id,
        updateTeamInput: {
          ...payload,
        },
      },
    })
      .then((res) => {
        setStatusMessage({
          type: "success",
          content: "Team Updated",
        });

        onReset();
        refresh();
        close();
      })
      .catch((err) => {
        setStatusMessage({
          type: "error",
          content: err.message,
        });
      });
  };

  const onReset = () => {
    form?.resetFields();
    setSelectedImage(null);
  };

  const onClose = () => {
    setStatusMessage(null);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Select</div>
    </div>
  );

  const readURL = (input) => {
    const file = input?.target?.files[0];

    if (file) {
      var reader = new FileReader();

      reader.onload = function (e) {
        setSelectedImage({ file, src: e.target.result });

        setTimeout(() => {
          document
            .getElementById("profile_image")
            .setAttribute("src", e.target.result);
        }, 0);
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      {statusMessage && (
        <Alert
          message={statusMessage?.content}
          type={statusMessage?.type}
          showIcon
          closable
          onClose={onClose}
        />
      )}
      <Form
        form={form}
        name="complex-form"
        onFinish={onFinish}
        style={{ marginTop: "24px" }}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 15 }}
      >
        <Row gutter={4}>
          <Col span={24}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Name is required" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Description"
              name="description"
              // rules={[{ required: true, message: 'Phone is required' }]}
            >
              <Input.TextArea />
            </Form.Item>

            {/* <Form.Item
                            label="Jersey Number"
                            name="preferred_jersey_no"
                        >
                            <InputNumber />
                        </Form.Item> */}

            <Form.Item label="Image" name="display_picture">
              <input
                type="file"
                id="select_file"
                onChange={(event) => readURL(event)}
                hidden
              />
              {selectedImage && selectedImage?.src != "" ? (
                <>
                  <img
                    id="profile_image"
                    src={selectedImage?.src}
                    style={{ cursor: "pointer", borderRadius: "50%" }}
                    alt="your image"
                    width={100}
                    height={100}
                    onClick={() => {
                      document.getElementById("select_file").click();
                    }}
                  />{" "}
                  <Button onClick={() => setSelectedImage(null)}>Remove</Button>{" "}
                </>
              ) : (
                <div
                  id="profile_image"
                  src={selectedImage?.src}
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    border: "1px solid gray",
                    height: "100px",
                    width: "100px",
                  }}
                  onClick={() => {
                    document.getElementById("select_file").click();
                  }}
                >
                  Select image
                </div>
              )}
            </Form.Item>
          </Col>

          <Col span={24}>
            <Space style={{ justifyContent: "right", width: "100%" }}>
              <Button htmlType="button" onClick={onReset}>
                Reset
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={createTeamLoading || updateTeamLoading}
              >
                {record ? "Update" : "Submit"}
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default CreateTeam;
