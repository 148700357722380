import { gql } from "@apollo/client";

const UPDATE_LISTED_TOURNAMENT = gql`
  mutation UpdateListedTournament($tournament_listed_id: String!, $updateTournamentListingInput: UpdateTournamentListingInput!) {
    updateListedTournament(tournament_listed_id: $tournament_listed_id, updateTournamentListingInput: $updateTournamentListingInput) {
      id
      tournament_name
      start_date
      end_date
      location
      timings
      format
      match_duration
      number_of_teams
      registration_fees
      prize
      age_limit
      season
      organizer_name
      organizer_contact
      tournament_profile_image
      tournament_banner_images
      createdBy
      createdAt
      status
      goalRush_verified_status
      registration_last_date
      tournament_reference_id
    }
  }
`;

export default UPDATE_LISTED_TOURNAMENT;
