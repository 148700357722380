import { PlusSquareOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { Col, Empty, Input, Modal, Radio, Row } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import GET_TOURNAMENTS from "../../api/get_tournaments";
import NewTournament from "./components/newTournament.jsx/new_tournament";
import Tournament from "./components/tournament/tournament";

import "./style.scss";

const TournamentsNew = () => {
  const navigate = useNavigate();
  const [tournaments, setTournaments] = useState([]);
  const [selectedTournament, setSelectedTournament] = useState(null);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [statusFilter, setStatusFilter] = useState("ALL");
  const {
    data: tournamentsData,
    loading: tournamentLoading,
    refetch,
  } = useQuery(GET_TOURNAMENTS);

  useEffect(() => {
    const tournamentList = [];

    tournamentsData?.tournaments?.map((tournament) => {
      tournamentList.push(tournament);
    });

    setTournaments(tournamentList);
  }, [tournamentsData]);

  const onTournamentClick = (tournamentId) => {
    navigate(`/tournament-details/${tournamentId}`);
  };

  const handleCancel = () => {
    setIsCreateModalOpen(false);

    if (selectedTournament) {
      setSelectedTournament(null);
    }
  };

  const showModal = () => {
    setIsCreateModalOpen(true);
  };

  const onEdit = (tournament) => {
    setSelectedTournament(tournament);
    setIsCreateModalOpen(true);
  };

  const onSearchTournament = (event) => {
    let data;

    if (event?.target?.value) {
      data = tournamentsData?.tournaments?.filter((record) =>
        record?.name
          ?.toLowerCase()
          .includes(event?.target?.value?.toLowerCase())
      );
    } else {
      data = tournamentsData?.tournaments;
    }

    const list = [];

    data.map((record) => {
      list.push({
        ...record,
        key: record?.id,
      });
    });

    setTournaments(list);
  };

  const onStatusFilterChange = (value) => {
    setStatusFilter(value);

    if (value == "ALL") {
      setTournaments(tournamentsData?.tournaments);
    } else {
      const filteredTournaments = tournamentsData?.tournaments?.filter(
        (tournament) => {
          return tournament?.status == value;
        }
      );

      setTournaments(filteredTournaments);
    }
  };

  return (
    <>
      <Row>
        <Col span={23}>
          <h2>
            Tournaments{" "}
            <PlusSquareOutlined
              onClick={showModal}
              style={{ color: "blue", fontSize: "24PX" }}
            />
          </h2>
        </Col>
        <Col span={1}></Col>
      </Row>

      <Row>
        <Col span={5}>
          <Input
            placeholder="Search Tournament"
            onChange={onSearchTournament}
          />
        </Col>
        <Col span={19} style={{ textAlign: "right" }}>
          <Radio.Group
            value={statusFilter}
            onChange={(e) => onStatusFilterChange(e.target.value)}
          >
            <Radio.Button value="ALL">All</Radio.Button>
            <Radio.Button value="DRAFT">Draft</Radio.Button>
            <Radio.Button value="PUBLISHED">Published</Radio.Button>
          </Radio.Group>
        </Col>
      </Row>

      <Row className="wrapper-grid">
        {!tournaments?.length && (
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Empty />
          </Row>
        )}
        {tournaments?.map((tournament) => (
          <Tournament
            data={tournament}
            onClick={(e) => {
              onTournamentClick(tournament?.id);
            }}
            onEditClick={() => onEdit(tournament)}
          />
        ))}
      </Row>

      {isCreateModalOpen && (
        <Modal
          title={selectedTournament ? "Update Tournament" : "Create Tournament"}
          width={500}
          open={isCreateModalOpen}
          footer={null}
          onCancel={handleCancel}
        >
          <NewTournament
            record={selectedTournament}
            refresh={refetch}
            close={handleCancel}
          />
        </Modal>
      )}
    </>
  );
};

export default TournamentsNew;
