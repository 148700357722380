import { gql } from "@apollo/client";

const GET_TOURNAMENT_AWARDS = gql`
query GetTournamentAwards($tournamentSeasonId: String!, $awardTypes: [AwardsEnum!]!) {
  getTournamentAwards(getAwardsArg: { tournament_season_id: $tournamentSeasonId, awardType: $awardTypes }) {
    id
    awardType
    team {
      id
      name
      description
      display_picture
    }
    player {
      id
      name
      email
      phone_number
      display_picture
      position
      preferred_jersey_no
    }
    tournament_season {
      id
      name
      start_date
      display_picture
      end_date
    }
  }
}
`;

export default GET_TOURNAMENT_AWARDS;
