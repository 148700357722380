import gql from "graphql-tag";

const GET_TEAMS = gql`
  query {
    teams {
      id
      name
      description
      display_picture
      
    }
  }
`;
export default GET_TEAMS;
