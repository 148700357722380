export function compareString (a, b, field) {
    // Use toUpperCase() to ignore character casing
    const stringA = a[field]?.toUpperCase();
    const stringB = b[field]?.toUpperCase();
  
    let comparison = 0;
    if (stringA > stringB) {
      comparison = 1;
    } else if (stringA < stringB) {
      comparison = -1;
    }

    return comparison;
  }