import gql from "graphql-tag";

const GET_VENUES = gql`
  query {
    venues {
      id
      name
      address
      map_location
      contact_info
    }
  }
`;

export default GET_VENUES;
