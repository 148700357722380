import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Alert,
  Avatar,
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Segmented,
  Select,
  Space,
  Transfer,
} from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import CREATE_MATCH_EVENT from "../../../api/create_match_event";
import GET_EVENT_ENUM from "../../../api/get_event_enum";
import GET_MATCH_LINEUP_BY_ID from "../../../api/get_match_line_ups";
import GET_MATCH_TIMER from "../../../api/get_match_timer";
import Title from "antd/es/skeleton/Title";
import {
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
  HeartTwoTone,
} from "@ant-design/icons";
import Icon from "@ant-design/icons/lib/components/Icon";
import WALKOVER from "../../../api/walk-over";

const MatchWalkover = ({ matchData, close }) => {
  const [selectedTeam, setSelectedTeam] = useState("");
  // const [team1Name, setTeam1Name] = useState(matchData?.match?.team1?.name);
  // const [team2Name, setTeam2Name] = useState(matchData?.match?.team2?.name);
  const team1 = {name:matchData?.team1?.name, id: matchData?.team1?.id};
  const team2 = {name:matchData?.team2?.name, id: matchData?.team2?.id};
  let [
    walkOver,
    { data: walkOverData, loading: wlakOverLoading, error: wlakOverError },
  ] = useMutation(WALKOVER);

  const handleSelectChange = (value) => {
    setSelectedTeam(value);
  };

  const nonSelectedTeam = selectedTeam === team1?.id ? team2 : team1;

  const doWalkOver = async () => {
    try {
      const data = await walkOver({
        variables: {
          matchId: matchData?.id,
          winnerTeamId: nonSelectedTeam?.id
          }})
  
      if(data) {
        close()
      }

      if (data) {
        close();
      }
    } catch (err) {
      wlakOverError = err;
    }
  };
  return (
    <>
      <Row style={{ margin: "10px" }}>
        <Col>
          <h3> Declare match as walkover</h3>
        </Col>
      </Row>
      <Row style={{ margin: "10px" }}>
        <h5>
          Select the team which is defaulting / not-participating in the Match :{" "}
        </h5>
      </Row>
      <Row style={{ margin: "10px", marginBottom: "10px" }}>
        <Select
          defaultValue="Select a team"
          onChange={handleSelectChange}
          style={{ width: "250px" }}
        >
          <option key={matchData?.team1?.id} value={matchData?.team1?.id}>{matchData?.team1?.name}</option>
          <option key={matchData?.team2?.id} value={matchData?.team2?.id}>{matchData?.team2?.name}</option>
        </Select>
      </Row>

      <Row style={{ margin: "10px" }}>
        {selectedTeam && (
          <Row>
            <Card
              title={
                <span>
                  <ExclamationCircleTwoTone />
                  Warning message!
                </span>
              }
              style={{ border: "1px solid red", marginTop: "10px" }}
            >
              <h4>
                Walkover victory will be awarded to {nonSelectedTeam?.name}. They will
                be credited with the winning points for this match.
              </h4>
            </Card>
            <Col xs={24} style={{ textAlign: "right" }}>
              <Button
                danger
                type="primary"
                style={{ margin: "10px" }}
                onClick={() => {
                  doWalkOver();
                }}
              >
                Yes
              </Button>
              <Button type="default" onClick={close}>
                No
              </Button>
            </Col>
          </Row>
        )}
      </Row>
      <Col style={{ color: "red" }}>{wlakOverError?.message}</Col>
      <Col
        style={{
          margin: "10px",
          displpay: "flex",
          left: "320px",
        }}
      ></Col>
      {/* {!selectedTeam && (
        <footer>
          <Row>
            <h5 style={{ fontSize: "px", color: "red" }}>
              "The selected team will be marked as walkover and the other team
              will gain points for that"
            </h5>
          </Row>
        </footer>
      )} */}
    </>
  );
};

export default MatchWalkover;
