import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Form,
  Input,
  Select,
  Space,
  DatePicker,
  Button,
  message,
  Alert,
  Row,
  Col,
  InputNumber,
  Table,
  Checkbox,
  Avatar,
} from "antd";
import { useCallback, useState } from "react";
import { useEffect } from "react";
import FIND_ALL_GROUPS_BY_SEASON_ID from "../../../api/get_all_groups_by_season";
import GET_MATCHES from "../../../api/get_all_matches";
import FIND_ALL_ROUNDS_BY_SEASON_ID from "../../../api/get_all_rounds_by_season";
import GET_EVENT_ENUM from "../../../api/get_event_enum";
import GET_TEAMS from "../../../api/get_teams";
import GET_TOURNAMENTS from "../../../api/get_tournaments";
import FIND_ALL_SEASONS_BY_TOURNAMENT_ID from "../../../api/get_tournament_seasons";
import GET_VENUES from "../../../api/get_venue_list";
import GET_MATCH_LINEUP_BY_ID from "../../../api/get_match_line_ups";
import CREATE_MATCH_EVENT from "../../../api/create_match_event";
import GET_TEAM_PLAYERS_BY_TEAM from "../../../api/get_team_players_by_team";
import CREATE_MATCH_LINE_UP from "../../../api/create_match_line_up";
import CREATE_USER from "../../../api/create_user";
import CREATE_USERS from "../../../api/create_users";
import ADD_TEAM_PLAYERS from "../../../api/add_team_player";

const UserList = ({ teamId, matchId, refresh, close, data }) => {
  const [statusMessage, setStatusMessage] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const { Option } = Select;
  const [selectedTeam, setSelectedTeam] = useState(null);
  const { data: allTeams, loading: allTeamsLoading } = useQuery(GET_TEAMS);
  const [
    createUsers,
    {
      data: createdUsersData,
      loading: createUsersLoading,
      error: createdUsersError,
    },
  ] = useMutation(CREATE_USERS);
  const { data: playerPositions } = useQuery(GET_EVENT_ENUM, {
    variables: { enumName: "PlayerPosition" },
  });
  const { data: lineUpStatus } = useQuery(GET_EVENT_ENUM, {
    variables: { enumName: "LineupStatus" },
  });
  const [
    createMatchLineUp,
    { data: createMatchLineUpData, loading: lineUploading, error: lienUpError },
  ] = useMutation(CREATE_MATCH_LINE_UP);
  const [
    addTeamPlayers,
    {
      data: addTeamPlayersData,
      loading: addTeamPlayersLoading,
      error: addTeamPlayersError,
    },
  ] = useMutation(ADD_TEAM_PLAYERS);
  const [isToAddUsersToTeam, setIsToAddUsersToTeam] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const [errorList, setErrorList] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedUserList, setSelectedUserList] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  useEffect(() => {
    reset();
    setErrorList([]);
    setStatusMessage(null);
  }, [data]);

  const reset = () => {
    const userTable = [];

    data?.map((player, index) => {
      userTable.push({
        isSelected: false,
        key: index,
        ...player,
      });
    });

    setUsersData(userTable);
    setSelectedRowKeys([]);
    setIsToAddUsersToTeam(false);
  };

  const clearUserList = () => {
    setUsersData([]);
  };
  const columns = [
    {
      title: "#",
      width: "3%",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    // {
    //     title: "Select",
    //     dataIndex: "isSelect",
    //     width: "5%",
    //     render: (text, record, index) => {
    //         return <Checkbox onChange={(value) =>  {
    //             record.isSelected = !record.isSelected
    //         }} />
    //     },
    //     // editable: true,
    //     // filters: [],
    //     // onFilter: (value, record) => record.name.indexOf(value) === 0,
    //     // sorter: (a, b) => a?.name?.length - b?.name?.length,
    // },
    // {
    //     title: 'Picture',
    //     dataIndex: 'display_picture',
    //     key: 'picture',
    //     width: '5%',
    //     render: (display_picture) => {
    //         return display_picture?.length && <Avatar src={display_picture}/>
    //     }
    // },
    {
      title: "Name",
      dataIndex: "name",
      width: "40%",
      editable: true,
      render: (text, record, index) => {
        return (
          <Input
            defaultValue={record?.name}
            onChange={(event) => {
              record.name = event?.target?.value;
            }}
          />
        );
      },
      // filters: [],
      // onFilter: (value, record) => record.name.indexOf(value) === 0,
      // sorter: (a, b) => a?.name?.length - b?.name?.length,
    },
    {
      title: "Phone",
      dataIndex: "phone_number",
      width: "30%",
      editable: true,
      render: (text, record, index) => {
        return (
          <Input
            defaultValue={record?.phone_number}
            onChange={(event) => {
              record.phone_number = event?.target?.value;
            }}
          />
        );
      },
      // filters: [],
      // onFilter: (value, record) => record.name.indexOf(value) === 0,
      // sorter: (a, b) => a?.name?.length - b?.name?.length,
    },
    {
      title: "Position",
      dataIndex: "position",
      width: "12%",
      editable: true,
      render: (text, record, index) => {
        return playerPositionDropDown(record);
      },
      // sorter: (a, b) => a?.position - b?.position,
    },
    {
      title: "Jersey Number",
      dataIndex: "preferred_jersey_no",
      width: "15%",
      editable: true,
      render: (text, record, index) => {
        return (
          <InputNumber
            step={1}
            type="number"
            pattern="[0-9]*"
            inputmode="numeric"
            defaultValue={record?.preferred_jersey_no}
            onChange={(value) => {
              record.preferred_jersey_no = value;
            }}
          />
        );
      },

      // sorter: (a, b) => a?.jerseyNumber - b?.jerseyNumber,
    },
  ];

  const [form] = Form.useForm();

  // const teamPlayerDropDown = (teamId) => {
  //     return (
  //         <Select>
  //             {teamPlayersList?.teamPlayersByTeam?.map((player) =>
  //                 <Option key={player?.id} value={player?.id}>{player?.user?.name}</Option>
  //             )}
  //         </Select>
  //     )
  // }

  const playerPositionDropDown = (record) => {
    return (
      <Select
        defaultValue={record.position}
        onChange={(value) => (record.position = value)}
        placeholder="Select Position"
      >
        {playerPositions?.enumValues?.map((enumValue) => (
          <Option key={enumValue} value={enumValue}>
            {enumValue}
          </Option>
        ))}
      </Select>
    );
  };

  const lineUpStatusDropDown = (record) => {
    return (
      <Select defaultValue={record.status} placeholder="Select Status">
        {lineUpStatus?.enumValues?.map((enumValue) => (
          <Option key={enumValue} value={enumValue}>
            {enumValue}
          </Option>
        ))}
      </Select>
    );
  };

  const onClose = () => {
    setStatusMessage(null);
  };

  const onSave = async () => {
    const filteredData = structuredClone(
      usersData?.filter((record) => selectedRowKeys.includes(record?.key))
    );

    //validation
    if (isToAddUsersToTeam && !selectedTeam) {
      messageApi.error(
        "Please select the team to add players. Because you have selected the Add player option"
      );
      return;
    }

    if (filteredData?.length) {
      if (filteredData?.find((item) => !item?.name || !item?.phone_number)) {
        messageApi.error("Name and phone number are required field!");

        return;
      }
      const formattedData = filteredData?.map((player) => {
        const { isSelected, key, ...formattedPayload } = player;

        return formattedPayload;
      });

      createUsersFromTable(formattedData)
        .then((result) => {
          const users = result.data.createUsers;

          if (selectedTeam) {
            addTeamPlayers({
              variables: {
                addTeamPlayersInput: {
                  user_ids: users?.map((user) => user?.id),
                  team_id: selectedTeam,
                },
              },
            })
              .then((res) => {
                messageApi.success("Created User and added to selected team");
              })
              .catch((err) => {
                messageApi.error(err?.message);
              });
          } else {
            messageApi.success("Created User");
          }
        })
        .catch((err) => {
          messageApi.error(err?.message);
        });
    }

    // const requestArray = [];
    // const errorArray = [];

    // if (filteredData?.length) {
    //     filteredData?.forEach((user) => {
    //         requestArray.push(createUsers(user))
    //     })

    //     setIsLoading(true);
    //     Promise.allSettled(requestArray).then((resp) => {
    //         console.log(resp)
    //         resp.map((respStatus, index) => {
    //             if (respStatus.status == 'rejected') {
    //                 errorArray.push({ message: respStatus?.reason?.message, user: filteredData[index]?.name })
    //             }
    //         })

    //         setErrorList(errorArray);

    //         if (errorArray.length) {
    //             setStatusMessage({
    //                 type: 'error',
    //                 content: 'Some user creation is failed, Please check the error logs',
    //             });
    //         } else {
    //             setStatusMessage({
    //                 type: 'success',
    //                 content: 'Created Users',
    //             });
    //         }
    //         reset();
    //     }).catch(err => {
    //         console.log(err)
    //         setStatusMessage({
    //             type: 'error',
    //             content: err.message,
    //         });
    //     }).finally(() => {
    //         setIsLoading(false)
    //     })
    //     // createMatchLineUp({
    //     //     variables: {
    //     //         addLineupInput: {
    //     //             matchId,
    //     //             teamId,
    //     //             lineup_players: filteredData?.map((player) => {
    //     //                 return {
    //     //                     team_player_id: player.teamPlayerId,
    //     //                     status: player?.status,
    //     //                     jersey_no: player?.jerseyNumber?.toString(),
    //     //                     position: player?.position,
    //     //                 };
    //     //             })
    //     //         },
    //     //     },
    //     // }).then((res) => {
    //     //     setStatusMessage({
    //     //         type: 'success',
    //     //         content: 'Saved',
    //     //     });

    //     //     refresh();
    //     //     close();
    //     // }).catch((err) => {
    //     //     setStatusMessage({
    //     //         type: 'error',
    //     //         content: err.message,
    //     //     });
    //     // })
    // }
  };

  const createUsersFromTable = (payload) => {
    return new Promise((resolve, reject) => {
      createUsers({
        variables: {
          createUserInputs: payload,
        },
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    let inputNode;

    if (dataIndex == "position") {
      inputNode = playerPositionDropDown();
    } else if (dataIndex == "status") {
      inputNode = lineUpStatusDropDown();
    } else {
      inputNode =
        inputType === "number" ? (
          <InputNumber pattern="[0-9]*" inputmode="numeric" />
        ) : (
          <Input />
        );
    }

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const addNew = () => {
    setUsersData([
      ...usersData,
      {
        name: "",
        phone_number: "",
        position: "STOPPER",
        preferred_jersey_no: 1,
      },
    ]);
    console.log("usersData", usersData);
  };
  const onChange = (value) => {
    setSelectedTeam(value);
  };

  const onSearch = (value) => {
    setSelectedTeam(value);
  };

  const rowSelection = {
    selectedRowKeys,
    width: "5%",
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  return (
    <>
      {contextHolder}
      <Col span={24} style={{ textAlign: "right", marginBottom: "8px   " }}>
        <Space>
          <Button onClick={clearUserList}>Clear User List</Button>
          <Button onClick={addNew}>Add New</Button>
        </Space>
      </Col>

      <Col span={24}>
        <Form form={form} component={false}>
          <Table
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            rowSelection={rowSelection}
            bordered
            dataSource={usersData}
            columns={columns}
            rowClassName="editable-row"
            pagination={false}
            scroll={{ y: 300, x: "auto" }}
          />
        </Form>
      </Col>

      <Row style={{ marginTop: "24px" }}>
        <Col span={24}>
          <Checkbox
            defaultValue={isToAddUsersToTeam}
            onChange={() => setIsToAddUsersToTeam(!isToAddUsersToTeam)}
          >
            Do you want to add selected players to the team?
          </Checkbox>
        </Col>

        <Col span={12}>
          {/* {isToAddUsersToTeam && */}
          <Select
            showSearch
            placeholder="Select a Team"
            optionFilterProp="children"
            defaultValue={selectedTeam}
            onChange={onChange}
            onSearch={onSearch}
            style={{ marginTop: "24px" }}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={allTeams?.teams?.map((team) => {
              return {
                key: team?.id,
                value: team?.id,
                label: team?.name,
              };
            })}
          />
          {/* } */}
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <Space>
            <Button onClick={reset}>Reset</Button>
            <Button
              type="primary"
              onClick={onSave}
              loading={createUsersLoading || addTeamPlayersLoading}
            >
              Create Selected Users
            </Button>
          </Space>
        </Col>
      </Row>

      {/* {errorList?.length > 0 && <div style={{ color: 'red' }}>
                <span style={{ fontWeight: 'bold', fontSize: '16px' }}>Error logs</span>
                <div style={{ marginTop: '24px' }}>

                    <pre>
                        {JSON.stringify(errorList, null, 4)}
                    </pre>

                </div>
            </div>} */}

      {statusMessage && (
        <Alert
          style={{ marginTop: "24px" }}
          message={statusMessage?.content}
          type={statusMessage?.type}
          showIcon
          closable
          onClose={onClose}
        />
      )}
    </>
  );
};

export default UserList;
