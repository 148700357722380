import { gql } from "@apollo/client";

const ASSIGN_ROLES_TO_MULTIPLE_USERS_FOR_TEAM = gql`

mutation assignRolesToMultipleUsersForTeam(
  $input: AssignRolesToUsersInput!
) {
  assignRolesToMultipleUsersForTeam(input: $input)
}
`;

export default ASSIGN_ROLES_TO_MULTIPLE_USERS_FOR_TEAM;
