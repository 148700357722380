import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
  Upload,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import "./style.scss";
import { UploadOutlined } from "@ant-design/icons";
import CREATE_TOURNAMENT_LISTING from "../../../api/create_tournament_listing";
import { useMutation, useQuery } from "@apollo/client";
import GET_EVENT_ENUM from "../../../api/get_event_enum";
import { Option } from "antd/es/mentions";
import moment from "moment";
import UPDATE_LISTED_TOURNAMENT from "../../../api/update_tournament_listing";
import { uploadS3Image } from "../../../api/rest-api/s3-api";

const CreateTournamentListing = ({ open, onCancel, refresh, record }) => {
  const [form] = Form.useForm();
  const dateFormat = "YYYY/MM/DD";
  const { RangePicker } = DatePicker;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [statusMessage, setStatusMessage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const { data: matchFormats } = useQuery(GET_EVENT_ENUM, {
    variables: { enumName: "MatchFormatEnums" },
  });

  const [
    createTournamentListing,
    { data: TournamentListing, loading: loadingGoalrushSchool },
  ] = useMutation(CREATE_TOURNAMENT_LISTING);
  const [
    updateTournamentListing,
    {
      data: updateTournamentListingData,
      loading: updateTournamentListingLoading,
      error: updateTournamentListingError,
    },
  ] = useMutation(UPDATE_LISTED_TOURNAMENT);

  const onFinish = async (formValue) => {
    if (record) {
      updateContent(formValue);
    } else {
      saveContent(formValue);
    }
  };
  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        tournament_name: record?.tournament_name,
        // start_date: record?.start_date,
        // end_date: record?.end_date,
        location: record?.location,
        timings: record?.timings,
        format: record?.format,
        match_duration: record?.match_duration,
        number_of_teams: record?.number_of_teams,
        registration_fees: record?.registration_fees,
        prize: record?.prize,
        age_limit: record?.age_limit,
        season: record?.season,
        organizer_name: record?.organizer_name,
        organizer_contact: record?.organizer_contact,
        tournament_profile_image: record.tournament_profile_image,
        // tournament_banner_images: formValue?.links,
        // createdBy: formValue?.createdBy,
        // createdAt: formValue?.createdAt,
        status: record?.status,
        goalRush_verified_status: record?.goalRush_verified_status,
        // registration_last_date: record?.registration_last_date,
        tournament_reference_id: record?.tournament_reference_id,
      });

      setSelectedImage({ src: record?.tournament_profile_image });
    } else {
      form.resetFields();
    }
  }, [record]);

  const saveContent = async (formValue) => {
    var imagedata = new FormData();
    imagedata.append("file", formValue?.tournament_profile_image?.file);
    const response = await fetch(
      process.env.REACT_APP_API_URL + "/media/upload",
      {
        method: "POST",
        body: imagedata,
      }
    );

    const fileName = await response.text();

    var imageArrayData = new FormData();
    const files = [];
    if (formValue?.tournament_banner_images?.fileList) {
      formValue?.tournament_banner_images?.fileList.forEach((file) => {
        imageArrayData.append("files", file.originFileObj);
      });
    }

    const responseArray = await fetch(
      process.env.REACT_APP_API_URL + "/media/upload_multiple_files",
      {
        method: "POST",
        body: imageArrayData,
      }
    );
    const fileNames = await responseArray.json();

    createTournamentListing({
      variables: {
        createTournamentListingInput: {
          tournament_name: formValue?.tournament_name,
          start_date: formValue?.start_date,
          end_date: formValue?.end_date,
          location: formValue?.location,
          timings: formValue?.timings,
          format: formValue?.format,
          match_duration: formValue?.match_duration,
          number_of_teams: formValue?.number_of_teams,
          registration_fees: formValue?.linregistration_feesks,
          prize: formValue?.prize,
          age_limit: formValue?.age_limit,
          season: formValue?.season,
          organizer_name: formValue?.organizer_name,
          organizer_contact: formValue?.organizer_contact,
          tournament_profile_image: fileName,
          tournament_banner_images: fileName,
          status: formValue?.status,
          goalRush_verified_status: formValue?.goalRush_verified_status,
          goalRush_verified_status: formValue?.goalRush_verified_status,
          registration_last_date: formValue?.registration_last_date,
          tournament_reference_id: formValue?.tournament_reference_id,
        },
      },
    })
      .then((resp) => {
        message.open({
          type: "success",
          content: "Content Added",
        });
        // close();
        refresh();
      })
      .catch((err) => {
        message.open({
          type: "error",
          content: err?.message || "Something went wrong!",
        });
      });
    onCancel();
  };

  const updateContent = async (formValue) => {
    let payload = formValue;
    if (selectedImage?.src.length != null) {
      await uploadS3Image(formValue?.tournament_profile_image?.file)
        .then((resp) => {
          payload = {
            ...payload,
            tournament_profile_image: `${process.env.REACT_APP_API_URL}/media/download/${resp?.data}`,
          };
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (!selectedImage?.src) {
      payload = { ...payload, tournament_profile_image: "" };
    }

    updateTournamentListing({
      variables: {
        tournament_listed_id: record?.id,
        updateTournamentListingInput: {
          ...payload,
        },
      },
    })
      .then((res) => {
        setStatusMessage({
          type: "success",
          content: "Team Updated",
        });

        refresh();
        refresh();
        onCancel();
      })
      .catch((err) => {
        setStatusMessage({
          type: "error",
          content: err.message,
        });
      });
  };

  const handleSubmit = async () => {
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);
  };
  const props = {
    multiple: true,
  };

  const handleImageChange = () => {
    setSelectedImage(null);
  };

  return (
    <Form
      form={form}
      name="complex-form"
      labelCol={{ span: 8 }}
      onFinish={onFinish}
      wrapperCol={{ span: 13 }}
      layout="horizontal"
      // style={{ maxWidth: 800 }}
    >
      {/* <Row style={{ margin: "12px" }}>
        <h2>Create Tournament Listing</h2>
      </Row> */}
      <Row gutter={4} className="tournament-listing-wrapper">
        <Col span={12}>
          <Form.Item
            label="Tournament Name"
            name="tournament_name"
            rules={[{ required: true, message: "Please Enter a name!" }]}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item label="Start Date" name="start_date">
            <DatePicker format={dateFormat} style={{ display: "flex" }} />
          </Form.Item>
          <Form.Item label="End Date" name="end_date">
            <DatePicker format={dateFormat} style={{ display: "flex" }} />
          </Form.Item>
          <Form.Item label="Location" name="location">
            <Input></Input>
          </Form.Item>
          <Form.Item label="Timings" name="timings">
            <Input style={{ width: "100%" }}></Input>
          </Form.Item>
          <Form.Item
            label="Format"
            name="format"
            rules={[{ required: true, message: "Please select match format!" }]}
          >
            <Select>
              {matchFormats?.enumValues?.map((enumValue) => (
                <Option key={enumValue} value={enumValue}>
                  {enumValue}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Match Duartion" name="match_duration">
            <Input style={{ width: "100%" }}></Input>
          </Form.Item>
          <Form.Item label="Number of Teams" name="number_of_teams">
            <InputNumber style={{ width: "100%" }}></InputNumber>
          </Form.Item>
          <Form.Item label="Registration fee" name="registration_fees">
            <InputNumber prefix="₹" style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item label="Prize" name="prize">
            <Input></Input>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Age Limit" name="age_limit">
            <Input style={{ width: "100%" }}></Input>
          </Form.Item>
          <Form.Item label="Season" name="season">
            <Input></Input>
          </Form.Item>
          <Form.Item
            label="Organizers"
            name="organizer_name"
            rules={[
              { required: true, message: "Please enter the Organizer's name!" },
            ]}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            label="Organizer's contact"
            name="organizer_contact"
            rules={[
              {
                required: true,
                message: "Please enter the Organizer's contact!",
              },
            ]}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item label="Profile Image" name="tournament_profile_image">
            <Upload
              beforeUpload={(file) => {
                return false;
              }}
              onChange={handleImageChange}
            >
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item label="Banner Images" name="tournament_banner_images">
            <Upload
              beforeUpload={(file) => {
                return false;
              }}
              // listType="picture-card"
              {...props}
            >
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
          {/* <Form.Item label="Created By" name="createdBy">
            <Input></Input>
          </Form.Item>
          <Form.Item label="Created At" name="createdAt">
            <Input></Input>
          </Form.Item> */}
          <Form.Item
            label="Status"
            name="status"
            rules={[{ required: true, message: "Please Select the status!" }]}
          >
            <Select>
              <Option value="UNCONFIRMED">UNCONFIRMED</Option>
              <Option value="UPCOMING">UPCOMING </Option>
              <Option value="ONGOING">ONGOING </Option>
              <Option value="ACHIEVED">ACHIEVED </Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Verified"
            name="goalRush_verified_status"
            rules={[
              { required: true, message: "Please add the verification status" },
            ]}
          >
            <Select>
              {" "}
              <Option value={true}>True</Option>
              <Option value={false}>False</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Registration last Date"
            name="registration_last_date"
          >
            <DatePicker format={dateFormat} style={{ display: "flex" }} />
          </Form.Item>
          <Form.Item label="Reference Id " name="tournament_reference_id">
            <Input></Input>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "right",
          }}
          span={19}
        >
          <Button style={{ marginRight: "4px" }} htmlType="button">
            Reset
          </Button>
        </Col>{" "}
        <Col span={5}>
          <Button type="primary" htmlType="submit" onClick={handleSubmit}>
            Submit
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default CreateTournamentListing;
