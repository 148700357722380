import { LoadingOutlined } from "@ant-design/icons";
import { useLazyQuery, useQuery } from "@apollo/client";
import { Button, Col, Modal, Row, Spin, Input } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import PlayersTable from "../team-players/blocks/players-table";
import GET_TEAM_PLAYERS_BY_TEAM from "../../api/get_team_players_by_team";
import AddPlayer from "../team-players/blocks/add_player";
import SquadTable from "./squad-table";
import GET_TOURNAMENT_SQUAD_BY_ID from "../../api/get_tournament_squad_by_id";
import CreateSquad from "./create-tournament-squad";
import FIND_ALL_TOURNAMENT_SQUAD from "../../api/get_all_tournament_squad";
import { getAll } from "firebase/remote-config";

const SquadTab = ({ seasonId, teamId }) => {
  const [tableData, setTableData] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const [getAllSquad, { data, loading, refetch: squadRefetch }] = useLazyQuery(
    FIND_ALL_TOURNAMENT_SQUAD,
    { fetchPolicy: "no-cache" }
  );

  useEffect(() => {
    if (teamId && seasonId) {
      getAllSquad({
        variables: {
          fetchTournamentSeasonSquadInput: {
            team_id: teamId,
            season_id: seasonId,
          },
        },
      });
    }
  }, [teamId, seasonId]);

  useEffect(() => {
    if (data) {
      setTableData(data?.findAllTournamentSquad?.fetchTournamentSeasonSquad);
    }
  }, [data]);

  const onSearchTeamPlayer = (event) => {
    let filteredData = [];
    if (event?.target?.value) {
      filteredData =
        data?.findAllTournamentSquad?.fetchTournamentSeasonSquad?.filter(
          (squad) =>
            squad?.playerName
              ?.toLowerCase()
              .includes(event?.target?.value?.toLowerCase())
        );
    } else {
      filteredData = data?.findAllTournamentSquad?.fetchTournamentSeasonSquad;
    }
    setTableData(filteredData);
  };

  const handleCancel = () => {
    setIsCreateModalOpen(false);
  };
  return (
    <>
      <Row>
        <Col span={24}>
          {loading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            </div>
          ) : (
            <>
              {getAllSquad && (
                <Col span={24}>
                  <Col span={24} style={{ paddingTop: "20px" }}>
                    <Row style={{ marginTop: "32px" }}>
                      <Col span={5}>
                        <Input
                          placeholder="Search in squad"
                          onChange={onSearchTeamPlayer}
                        />
                      </Col>
                      <Col span={15}></Col>
                      <Col
                        span={4}
                        style={{ textAlign: "right", paddingBottom: "18px" }}
                      >
                        <Button
                          type="primary"
                          onClick={() => {
                            setIsCreateModalOpen(true);
                          }}
                        >
                          Add Players
                        </Button>
                      </Col>
                    </Row>

                    <SquadTable
                      playersData={tableData}
                      refetch={squadRefetch}
                    />
                  </Col>
                </Col>
              )}
            </>
          )}
        </Col>
      </Row>

      {isCreateModalOpen && (
        <Modal
          title="Add players to squad"
          width={1000}
          open={isCreateModalOpen}
          footer={null}
          onCancel={handleCancel}
        >
          <CreateSquad
            playersData={tableData}
            seasonId={seasonId}
            teamId={teamId}
            refresh={squadRefetch}
            close={handleCancel}
          />
        </Modal>
      )}
    </>
  );
};

export default SquadTab;
