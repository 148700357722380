import { gql } from '@apollo/client';

const GET_MATCHES_BY_TOURNAMENT_SEASON_ID = gql`
  query getMatchesByTournamentSeasonIdWithGroupByTournamentRound($season_id: String!) {
    getMatchesByTournamentSeasonIdWithGroupByTournamentRound(season_id: $season_id) {
      id
      type
      start_date
      break_time
      match_duration
      match_extra_time
      sequence_no
      tournament_season {
        id
        name
        start_date
        display_picture
        end_date
      }
      matches {
        id
        format
        duration
        sub_count
        team1{
          id
          name
          display_picture
        }
        team2{
          id
          name
          display_picture
        }
        match_schedule {
          id
          start_date
        }
        match_stat_duration
        streaming_url
        aggregated_stats{
          team1Goal
          team2Goal
        }
        tournament_season_group{
          id
          group_name
        }
        venue{
          name
        }
        created_at
        updated_at
        timer{
          match_stage
          match_status
        }
      }

    }
  }
`;

export default GET_MATCHES_BY_TOURNAMENT_SEASON_ID;
