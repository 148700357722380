import { gql } from "@apollo/client";

const CREATE_TEAM= gql`
mutation createTeam($createTeamInput: CreateTeamInput!) {
  createTeam(createTeamInput: $createTeamInput) {
    id
    name
    description
    display_picture
  }
}

`;

export default CREATE_TEAM;
