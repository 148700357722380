import { gql } from "@apollo/client";

export const GET_ALL_LISTED_TOURNAMENTS = gql`
  query GetAllListedTournaments {
    findAllListedTournaments(listTournamentInput: {}) {
      id
      tournament_name
      start_date
      end_date
      location
      timings
      format
      match_duration
      number_of_teams
      registration_fees
      prize
      age_limit
      season
      organizer_name
      organizer_contact
      tournament_profile_image
      tournament_banner_images
      createdBy
      createdAt
      status
      goalRush_verified_status
      registration_last_date
      tournament_reference_id
    }
  }
`;

export default GET_ALL_LISTED_TOURNAMENTS;
