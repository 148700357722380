import { useMutation } from "@apollo/client";
import { Form, Input, Space, Button, Row, Col } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import CREATE_VENUE from "../../../api/create_venue";

const CreateVenue = ({ record, refresh, close }) => {
  const [form] = Form.useForm();
  const [statusMessage, setStatusMessage] = useState(null);

  const [
    CreateVenue,
    {
      data: createdVenues,
      loading: createdVenueLoading,
      error: createdVenueError,
    },
  ] = useMutation(CREATE_VENUE);

  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        name: record?.name,
        address: record?.address,
        map_location: record?.map_location,
        contact_info: record?.contact_info,
      });
    } else {
      form.resetFields();
    }
  }, [record]);
  const onFinish = async (formValue) => {
    saveVenue(formValue);
  };

  const saveVenue = async (formValue) => {

    const venueInput = {
      name: formValue.name,
      contact_info: formValue.contact_info,
      map_location: formValue.map_location,
      address: formValue.address,
    };
    const response = await CreateVenue({
      variables: {
        createVenueInput: venueInput,
      },
    });

    if (response?.data) {
      setStatusMessage({
        type: "success",
        content: "Venue created",
      });

      onReset();
      refresh();
      close();
    }
  };

  const onReset = () => {
    form?.resetFields();
  };

  return (
    <>
      <Form
        form={form}
        name="complex-form"
        onFinish={onFinish}
        style={{ marginTop: "24px" }}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 15 }}
      >
        <Row gutter={4}>
          <Col span={24}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Name is required" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="Address" name="address">
              <Input />
            </Form.Item>

            <Form.Item label="Location" name="map_location">
              <Input />
            </Form.Item>
            <Form.Item label="Contact" name="contact_info">
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Space style={{ justifyContent: "right", width: "100%" }}>
              <Button htmlType="button" onClick={onReset}>
                Reset
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={createdVenueLoading}
              >
                {record ? "Update" : "Submit"}
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default CreateVenue;
