import gql from "graphql-tag";

const GET_USERS = gql`
  {
    users {
      id
      id
    name
    email
    phone_number
    display_picture
    position
    preferred_jersey_no
    status
    teamPlayer {
      id
      join_date
      leave_date
      status
    }
    }
  }
`;


export default GET_USERS;
