import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Alert, Avatar, Button, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Segmented, Select, Space, Transfer } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import CREATE_MATCH_EVENT from "../../../api/create_match_event";
import GET_EVENT_ENUM from "../../../api/get_event_enum";
import GET_MATCH_LINEUP_BY_ID from "../../../api/get_match_line_ups";
import GET_MATCH_TIMER from "../../../api/get_match_timer";

const PlayerSubstitute = ({ matchId, close, matchData }) => {
    const utc = require('dayjs/plugin/utc');
    dayjs.extend(utc);
    const [modal, contextHolder] = Modal.useModal();
    const [statusMessage, setStatusMessage] = useState(null);
    const [form] = Form.useForm();
    const { Option } = Select;
    const [selectedTeam, setSelectedTeam] = useState();
    const [playerList, setPlayerList] = useState();
    const mockData = Array.from({
        length: 20,
    }).map((_, i) => ({
        key: i.toString(),
        title: `content${i + 1}`,
        description: `description of content${i + 1}`,
    }));
    const [getMatchLineUps, { data: matchLineUps, loading: matchLineUpsLoading }] = useLazyQuery(GET_MATCH_LINEUP_BY_ID, {
        variables: { match_id: matchId },
    });
    const [createMatchEvent, { data, loading: createMatchEventLoading, error }] = useMutation(CREATE_MATCH_EVENT);
    const { data: matchStageEnumList } = useQuery(GET_EVENT_ENUM, { variables: { enumName: 'MatchStage' } });
    const [getMatchTimer, { data: matchTimerData, loading: getMatchTimerLoading, error: matchTimerError, refetch }] = useLazyQuery(GET_MATCH_TIMER, {
        variables: {
            match_id: matchId
        },
        fetchPolicy: "no-cache"
    });

    useEffect(() => {
        getMatchLineUps()
    }, [])

    useEffect(() => {
        if (statusMessage && statusMessage?.type == 'success') {
            setTimeout(() => {
                setStatusMessage(null)
            }, 15000)
        }
    }, [statusMessage])

    useEffect(() => {
        const originData = [];

        matchLineUps?.matchLineupByMatchId?.map((team) => {
            let obj = { id: team?.id, name: team?.name, lineups: [] }
            team?.lineup?.map((lineup) => {
                obj.lineups.push(formatLineupData(lineup));
            })

            obj.lineups?.sort((a, b) => a.jerseyNumber - b.jerseyNumber)
            originData.push(obj)
        });

        setSelectedTeam(originData[0])
        setPlayerList(originData);
    }, [matchLineUps]);

    const formatLineupData = (lineup) => {
        return {
            key: lineup?.id,
            name: lineup?.name,
            position: lineup?.position,
            teamPlayerId: lineup?.teamPlayerId,
            jerseyNumber: lineup?.jersey_no,
            status: lineup?.status,
            userId: lineup?.userId,
            displayPicture: lineup?.display_picture
        }
    }
    // const inPlayers = playerList?.filter((item) => item.status == 'CONFIRMED')?.map((item) => item?.key);
    // const outPlayers = playerList?.filter((item) => item.status == 'BENCH')?.map((item) => item);

    const [targetKeys, setTargetKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([])

    const [selectedInPlayer, setSelectedInPlayer] = useState(null);
    const [selectedOutPlayer, setSelectedOutPlayer] = useState(null)


    useEffect(() => {
        getMatchTimer()
        form.setFieldValue('time', dayjs())

        const interval = setInterval(() => {
            getMatchTimer()
            form.setFieldValue('time', dayjs(new Date))
        }, 15000);

        return () => clearInterval(interval);
    }, [])
    useEffect(() => {

        if (matchTimerData) {
            form.setFieldsValue({
                match_stage: matchTimerData?.getMatchTimer?.match_stage,
                time_elapsed: matchTimerData?.getMatchTimer?.time_elapsed || 0,
                extra_time_elapsed: getExtraTimeElapsed() || 0
            })
        }
    }, [matchTimerData])

    const getExtraTimeElapsed = () => {
        if (matchTimerData?.getMatchTimer?.match_stage == 'FIRST_HALF') {
            return matchTimerData?.getMatchTimer?.time_elapsed_HT;
        } else if (matchTimerData?.getMatchTimer?.match_stage == 'SECOND_HALF') {
            return matchTimerData?.getMatchTimer?.time_elapsed_FT;
        } else if (matchTimerData?.getMatchTimer?.match_stage == 'EXTRA_TIME_FH') {
            return matchTimerData?.getMatchTimer?.extra_time_elapsed_HT;
        } else if (matchTimerData?.getMatchTimer?.match_stage == 'EXTRA_TIME_SH') {
            return matchTimerData?.getMatchTimer?.extra_time_elapsed_FT
        }
    }

    const onChange = (nextTargetKeys, direction, moveKeys) => {
        modal.confirm(config);
        setTargetKeys(nextTargetKeys);
    };
    const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        console.log('sourceSelectedKeys:', sourceSelectedKeys);
        console.log('targetSelectedKeys:', targetSelectedKeys);

        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };
    const onScroll = (direction, e) => {
        console.log('direction:', direction);
        console.log('target:', e.target);
    };

    const config = {
        title: 'Use Hook!',
        content: (
            <>
                Are you sure to move this player
                {/* <ReachableContext.Consumer>{(name) => `Reachable: ${name}!`}</ReachableContext.Consumer>
            <br />
            <UnreachableContext.Consumer>{(name) => `Unreachable: ${name}!`}</UnreachableContext.Consumer> */}
            </>
        ),
    };

    const onFinish = async (formValue) => {
        if (!selectedInPlayer || !selectedOutPlayer) {
            setStatusMessage({
                type: 'error',
                content: `Please select players`,
            });

            return
        }

        if (selectedInPlayer == selectedOutPlayer) {
            setStatusMessage({
                type: 'error',
                content: `Can't Substitute same player. Please select different players`,
            });

            return
        }

        const eventPayload = {
            event: 'SUBSTITUTION',
            player_id: selectedInPlayer,
            team_id: selectedTeam?.id,
            match_stage: formValue?.match_stage,
            time_elapsed: Number(formValue?.time_elapsed),
            extra_time_elapsed: Number(formValue?.extra_time_elapsed),
            time: formValue?.time?.utc()?.format("YYYY-MM-DDTHH:mm:ss[Z]"),
            associated_player_id: selectedOutPlayer,
            associated_team_id: selectedTeam?.id,
        }

        createMatchEvent({
            variables: {
                createMatchEventInput: {
                    ...eventPayload,
                    match_id: matchId
                },
            },
        }).then((res) => {
            setStatusMessage({
                type: 'success',
                content: 'Substitution added',
            });

            onReset();
        }).catch((err) => {
            setStatusMessage({
                type: 'error',
                content: err.message,
            });
        })
    }

    const onClose = () => {
        setStatusMessage(null)
    };

    const onReset = () => {
        form.resetFields();
        getMatchTimer();
        form.setFieldValue('time', dayjs())
        setSelectedInPlayer(null);
        setSelectedOutPlayer(null);

        if (matchTimerData) {
            form.setFieldsValue({
                match_stage: matchTimerData?.getMatchTimer?.match_stage,
                time_elapsed: matchTimerData?.getMatchTimer?.time_elapsed || 0,
                extra_time_elapsed: getExtraTimeElapsed() || 0
            })
        }
    }

    return (
        <>
            {contextHolder}
            {/* <Transfer
            dataSource={playerList}
            titles={['Out', 'In']}
            targetKeys={targetKeys}
            selectedKeys={selectedKeys}
            onChange={onChange}
              onSelectChange={onSelectChange}
            //   onScroll={onScroll}
            render={(item) => `${item.name} (${item?.jerseyNumber})`}
        /> */}

            {playerList?.length &&
                <>
                    <Segmented
                        block
                        options={[
                            {
                                label: (
                                    <div style={{ padding: 4 }}>
                                        <Avatar src={matchData?.team1?.display_picture} />

                                        <div>
                                            <Space>
                                                <div style={{ backgroundColor: matchData?.team1_color, width: '25px', height: '25px', borderRadius: '50%' }}></div>
                                                {matchData?.team1?.name}
                                            </Space>
                                        </div>

                                    </div>
                                ), value: playerList[0]
                            },
                            {
                                label: (
                                    <div style={{ padding: 4 }}>
                                        <Avatar src={matchData?.team2?.display_picture} />

                                        <div>
                                            <Space>
                                                <div style={{ backgroundColor: matchData?.team2_color, width: '25px', height: '25px', borderRadius: '50%' }}></div>
                                                {matchData?.team2?.name}
                                            </Space>
                                        </div>

                                    </div>
                                ), value: playerList[1]
                            }
                        ]}
                        // options={playerList?.map((team) => {
                        //     return {
                        //         label: (
                        //             <div style={{ padding: 4 }}>
                        //                 {/* <Avatar src={team?.display_picture} /> */}
                        //                 <div>{team.name}</div>
                        //             </div>
                        //         ), value: team
                        //     }
                        // })}
                        onChange={(value) => setSelectedTeam(value)}>
                    </Segmented>

                    <Form
                        form={form}
                        name="complex-form"
                        onFinish={onFinish}
                        style={{ marginTop: '24px' }}
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 15 }}
                    >

                        <Row gutter={4}>
                            <Col span={24}>
                                <Form.Item name="time" label="Time" rules={[{ required: true, message: 'Please select time!' }]}>
                                    <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
                                </Form.Item>

                                <Row>
                                    <Col span={12}>
                                        <Form.Item labelCol={{ span: 12 }} wrapperCol={{ span: 15 }} name="time_elapsed" label="Time elapsed" rules={[{ required: true, message: 'Please select Time elapsed!' }]}>
                                            <InputNumber />
                                        </Form.Item>

                                    </Col>
                                    <Col span={12}>
                                        <Form.Item labelCol={{ span: 10 }} wrapperCol={{ span: 15 }} name="extra_time_elapsed" label="Extra Time" rules={[{ required: true, message: 'Please select Extra Time elapsed!' }]}>
                                            <InputNumber />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Form.Item name="match_stage" label="Match Stage" rules={[{ required: true, message: 'Please select Match Stage!' }]}>
                                    <Select>
                                        {matchStageEnumList?.enumValues?.map((enumValue) =>
                                            <Option key={enumValue} value={enumValue}>{enumValue}</Option>
                                        )}
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label="Player In"
                                    name="playerIn"
                                >
                                    <div style={{ display: 'flex' }}>
                                        <Space style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            {selectedTeam?.lineups?.map((player) => {
                                                return (
                                                    <div style={{ width: 65, height: 50, cursor: 'pointer', backgroundColor: selectedInPlayer == player?.userId ? 'green' : 'lightgray', display: 'flex', flexFlow: 'column', justifyContent: 'center', alignItems: 'center', color: selectedInPlayer == player?.userId ? 'white' : 'black' }} onClick={() => setSelectedInPlayer(player?.userId)}>
                                                        <b>{player?.jerseyNumber}</b>
                                                        <div style={{ fontSize: '10px', textAlign: 'center' }}>{player?.name}</div>
                                                    </div>

                                                )
                                            })}
                                        </Space>

                                    </div>
                                </Form.Item>

                                <Form.Item
                                    label="Player Out"
                                    name="playerIn"
                                >
                                    <div style={{ display: 'flex' }}>
                                        <Space style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            {selectedTeam?.lineups?.map((player) => {
                                                return (
                                                    <div style={{ width: 65, height: 50, cursor: 'pointer', backgroundColor: selectedOutPlayer == player?.userId ? 'red' : 'lightgray', display: 'flex', flexFlow: 'column', justifyContent: 'center', alignItems: 'center', color: selectedOutPlayer == player?.userId ? 'white' : 'black' }} onClick={() => setSelectedOutPlayer(player?.userId)}>
                                                        <b>{player?.jerseyNumber}</b>
                                                        <div style={{ fontSize: '10px', textAlign: 'center' }}>{player?.name}</div>
                                                    </div>

                                                )
                                            })}
                                        </Space>

                                    </div>
                                </Form.Item>

                                {/* <Form.Item
                            label="Player In"
                            name="playerIn"
                            rules={[{ required: true, message: 'Player In is required' }]}
                        >
                            <Select allowClear={true} showSearch
                                filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())}
                                options={playerList?.map((player) => {
                                    return { label: `${player?.name} (${player?.jerseyNumber})`, value: player?.key }
                                })}
                            >
                            </Select>
                        </Form.Item> */}

                                {/* <Form.Item
                            label="Player Out"
                            name="playerOut"
                            rules={[{ required: true, message: 'Player out is required' }]}
                        >
                            <Select allowClear={true} showSearch
                                filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())}
                                options={playerList?.map((player) => {
                                    return { label: `${player?.name} (${player?.jerseyNumber})`, value: player?.key }
                                })}
                            >
                            </Select>
                        </Form.Item> */}
                                {statusMessage && <Alert style={{ margin: '24px 0px' }} message={statusMessage?.content} type={statusMessage?.type} showIcon closable onClose={onClose} />}
                            </Col>

                            <Col span={24}>
                                <Space style={{ justifyContent: 'right', width: '100%' }}>
                                    <Button htmlType="button" onClick={onReset}>
                                        Reset
                                    </Button>
                                    <Button type="primary" htmlType="submit" loading={createMatchEventLoading}>
                                        Submit
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </>
            }
        </>
    )
}

export default PlayerSubstitute