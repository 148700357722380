import { EditFilled } from "@ant-design/icons";
import { Button, Card, Col, Modal, Row, Select, Space } from "antd";
import Title from "antd/es/skeleton/Title";
import React, { useEffect, useState } from "react";
import TournamentWinner from "./tournament_winner";
import RunnersUpTab from "./runnersUpTab";
import GET_TOURNAMENT_AWARDS from "../../../../api/get_tournament_awards";
import { useLazyQuery, useMutation } from "@apollo/client";
import ASSIGN_TEAM_AWARDS from "../../../../api/assign_team_awards";
import GET_TEAMS_BY_TOURNAMENT_SEASON_ID from "../../../../api/get_teams_by_season_id";
import BestPlayer from "./best_player";
import BestGoalKeeper from "./best_goal_keeper";
import TopScorer from "./top_scorer";

const WinnersTab = ({ selectedSeasonId }) => {
  const [winnersModalOpen, setWinnersModalOpen] = useState(false);
  const [runnersUpModalOpen, setRunnersUpModalOpen] = useState(false);
  const [playerModalOpen, setPlayerModalOpen] = useState(false);
  const [gkModalOpen, setGkModalOpen] = useState(false);
  const [topScorerModalOpen, setTopScorerModalOpen] = useState(false);

  const [awardType, setAwardType] = useState(false);
  const [selectedValue, setSelectedValue] = useState("null");
  const [winner, setWinner] = useState("CHAMPIONS");
  const [runnerup, setRunnerup] = useState("RUNNERS_UP");

  const [
    getTournamentAwards,
    { data: tournamentAwards, loading: tournamentAwardsLoading, refetch },
  ] = useLazyQuery(GET_TOURNAMENT_AWARDS, {
    variables: {
      tournamentSeasonId: selectedSeasonId,
    },
  });

  const [
    assignTeamAwards,
    { data: assignedTeamAwardData, loading: lineUploading, error: lineUpError },
  ] = useMutation(ASSIGN_TEAM_AWARDS);

  useEffect(() => {
    getTournamentAwards({
      variables: {
        tournament_season_id: selectedSeasonId,
        awardTypes: [
          "RUNNERS_UP",
          "CHAMPIONS",
          "GOLDEN_BALL",
          "GOLDEN_GLOVE",
          "GOLDEN_BOOT",
        ],
      },
    });
  }, [selectedSeasonId]);

  const handleWinnersModal = () => {
    setWinnersModalOpen(true);
  };

  const handleWinnersOk = () => {
    setWinnersModalOpen(false);
    refetch();
  };

  const handleWinnersCancel = () => {
    setWinnersModalOpen(false);
  };
  const handleRunnersModal = () => {
    setRunnersUpModalOpen(true);
  };

  const handleRunnersOk = () => {
    setRunnersUpModalOpen(false);
    refetch();
  };

  const handleRunnersCancel = () => {
    setRunnersUpModalOpen(false);
  };
  const handleSelect = (label) => {
    setSelectedValue(label);
  };

  const handlePlayerModal = () => {
    setPlayerModalOpen(true);
  };

  const handlePlayerOk = () => {
    setPlayerModalOpen(false);
    refetch();
  };

  const handlePlayerCancel = () => {
    setPlayerModalOpen(false);
  };

  const handleGkModal = () => {
    setGkModalOpen(true);
  };

  const handleGkOk = () => {
    setGkModalOpen(false);
    refetch();
  };

  const handleGkCancel = () => {
    setGkModalOpen(false);
    refetch();
  };

  const handleTopScorerModal = () => {
    setTopScorerModalOpen(true);
  };

  const handleTopScorerOk = () => {
    setTopScorerModalOpen(false);
    refetch();
  };

  const handleTopScorerCancel = () => {
    setTopScorerModalOpen(false);
  };
  const tournamentWinners = tournamentAwards?.getTournamentAwards
    .filter((item) => item.awardType == "champions")
    ?.map((item) => item?.team?.name);

  const tournamentRunnersUp = tournamentAwards?.getTournamentAwards
    .filter((item) => item.awardType == "runners_up")
    ?.map((item) => item?.team?.name);

  const tournamentBestPlayer = tournamentAwards?.getTournamentAwards
    .filter((item) => item.awardType == "golden_ball")
    ?.map((item) => item?.player?.name);

  const tournamentGoalKeeper = tournamentAwards?.getTournamentAwards
    .filter((item) => item.awardType == "golden_glove")
    ?.map((item) => item?.player?.name);

  const tournamentTopScorer = tournamentAwards?.getTournamentAwards
    .filter((item) => item.awardType == "golden_boot")
    ?.map((item) => item?.player?.name);

  return (
    <>
      <div>
        <Row gutter={16} justify="center">
          <Col xs={24} sm={24} md={12} lg={12}>
            <Card
              style={{
                height: "150px",
                background: "#AED6F1",
                margin: "5px",
              }}
            >
              <h3>Tournament winners</h3>

              <Card>
                <Row span={1}>
                  <Col span={22}>
                    <h4>{tournamentWinners}</h4>
                  </Col>
                  <Col span={2}>
                    <Button
                      shape="circle"
                      type="text"
                      style={{
                        padding: "10px",
                        display: "flex",
                        alignContent: "right",
                      }}
                      onClick={handleWinnersModal}
                    >
                      <EditFilled />
                    </Button>
                  </Col>
                </Row>
              </Card>
              <Space align="left"></Space>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Card
              style={{
                height: "150px",
                background: "#AED6F1",
                margin: "5px",
              }}
            >
              <h3>Runners Up</h3>

              <Card>
                <Row span={1}>
                  <Col span={22}>
                    {" "}
                    <h4>{tournamentRunnersUp}</h4>
                  </Col>
                  <Col span={2}>
                    <Button
                      shape="circle"
                      type="text"
                      style={{
                        padding: "10px",
                        display: "flex",
                        alignContent: "right",
                      }}
                      onClick={handleRunnersModal}
                    >
                      <EditFilled />
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Card>
          </Col>
        </Row>
        <Row gutter={16} justify="center">
          <Col span={8} xs={24} md={8} lg={8} justify="center">
            <Card
              style={{ height: "150px", background: "#D1F2EB", margin: "5px" }}
            >
              <Row>
                <h3> Best Player</h3>
              </Row>
              <Card>
                <Row span={1}>
                  <Col span={22}>
                    <h4>{tournamentBestPlayer}</h4>
                  </Col>
                  <Col span={2}>
                    <Button
                      shape="circle"
                      type="text"
                      onClick={handlePlayerModal}
                      style={{
                        padding: "10px",
                        display: "flex",
                        alignContent: "right",
                      }}
                    >
                      <EditFilled />
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Card>
          </Col>
          <Col span={8} xs={24} md={8} lg={8}>
            <Card
              style={{ height: "150px", background: "#D1F2EB", margin: "5px" }}
            >
              <Row>
                <h3>Best GoalKeeper</h3>
              </Row>

              <Card>
                <Row span={1}>
                  <Col span={22}>
                    <h4>{tournamentGoalKeeper}</h4>
                  </Col>
                  <Col span={2}>
                    <Button
                      shape="circle"
                      type="text"
                      onClick={handleGkModal}
                      style={{
                        padding: "10px",
                        display: "flex",
                        alignContent: "right",
                      }}
                    >
                      <EditFilled />
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Card>
          </Col>
          <Col span={8} xs={24} md={8} lg={8}>
            <Card
              style={{ height: "150px", background: "#D1F2EB", margin: "5px" }}
            >
              <Row>
                <h3>Top scorer</h3>
              </Row>
              <Card>
                <Row span={1}>
                  <Col span={22}>
                    <h4>{tournamentTopScorer}</h4>
                  </Col>

                  <Col span={2}>
                    <Button
                      shape="circle"
                      type="text"
                      onClick={handleTopScorerModal}
                      style={{
                        padding: "10px",
                        display: "flex",
                        alignContent: "right",
                      }}
                    >
                      <EditFilled />
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Card>
          </Col>
        </Row>
        {winnersModalOpen && (
          <Modal
            centered
            open={winnersModalOpen}
            footer={null}
            onCancel={handleWinnersCancel}
          >
            {" "}
            <TournamentWinner
              selectedSeasonId={selectedSeasonId}
              handleClose={handleWinnersOk}
              // refresh={refetch}
            />
          </Modal>
        )}
        {runnersUpModalOpen && (
          <Modal
            centered
            open={runnersUpModalOpen}
            footer={null}
            onCancel={handleRunnersCancel}
          >
            <RunnersUpTab
              selectedSeasonId={selectedSeasonId}
              handleClose={handleRunnersOk}
              // refresh={refetch}
            />
          </Modal>
        )}
        {playerModalOpen && (
          <Modal
            centered
            open={handlePlayerModal}
            footer={null}
            onCancel={handlePlayerCancel}
          >
            <BestPlayer
              selectedSeasonId={selectedSeasonId}
              handleClose={handlePlayerOk}
              refresh={refetch}
            />
          </Modal>
        )}
        {gkModalOpen && (
          <Modal
            centered
            open={handleGkModal}
            footer={null}
            onCancel={handleGkCancel}
          >
            <BestGoalKeeper
              awards={tournamentAwards}
              selectedSeasonId={selectedSeasonId}
              handleClose={handleGkOk}
              refetch={refetch}
            />
          </Modal>
        )}
        {topScorerModalOpen && (
          <Modal
            centered
            open={handleTopScorerModal}
            footer={null}
            onCancel={handleTopScorerCancel}
          >
            <TopScorer
              selectedSeasonId={selectedSeasonId}
              handleClose={handleTopScorerOk}
              // refresh={refetch}
            />
          </Modal>
        )}
      </div>
    </>
  );
};

export default WinnersTab;
