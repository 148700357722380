import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Button, Alert, Col, Table, Checkbox, Avatar, Input } from "antd";
import { useState } from "react";
import { useEffect } from "react";
// import GET_USERS from "../../../api/get_all_users";
// import ADD_TEAM_PLAYERS from "../../../api/add_team_player";
import GET_TEAM_PLAYERS_BY_TEAM from "../../api/get_team_players_by_team";
import ADD_TEAM_PLAYERS from "../../api/add_team_player";
import CREATE_TOURNAMENT_SEASON_SQUAD from "../../api/create_tournament_squad";

const CreateSquad = ({ playersData, teamId, refresh, close, seasonId }) => {
  const [statusMessage, setStatusMessage] = useState(null);
  const [users, setUsers] = useState([]);
  const [playerTable, setPlayerTable] = useState([]);
  // const { data: allUsers, loading } = useQuery(GET_USERS);
  const [
    addTeamPlayers,
    {
      data: addTeamPlayersData,
      loading: addTeamPlayersLoading,
      error: addTeamPlayersError,
    },
  ] = useMutation(ADD_TEAM_PLAYERS);

  const [
    createTournamentSquad,
    {
      data: tournamentSquadData,
      loading: tournamentSquadDataLoading,
      error: tournamentSquadDataError,
    },
  ] = useMutation(CREATE_TOURNAMENT_SEASON_SQUAD);

  const [
    getTeamPlayerByTeam,
    {
      data: teamPlayers,
      loading: teamPlayersLoading,
      refetch: teamPlayersRefetch,
    },
  ] = useLazyQuery(GET_TEAM_PLAYERS_BY_TEAM, {
    variables: {
      team_id: teamId,
    },
  });
  const [selectedPlayers, setSelectedPlayers] = useState([]);

  useEffect(() => {
    let duplicatePlayers;
    let filteredPlayers;

    if (teamPlayers) {
      const filteredPlayers = teamPlayers?.teamPlayersByTeam?.filter(
        (player) =>
          !playersData?.some(
            (playerData) => playerData?.playerId === player?.id
          )
      );

      const userList = [];

      filteredPlayers?.forEach((record) => {
        userList.push({
          ...record?.user,
          key: record?.id,
        });
      });
      setUsers(userList);
      setPlayerTable(userList);
    }
  }, [teamPlayers]);

  useEffect(() => {
    if (teamId) {
      getTeamPlayerByTeam();
    }
    setUsers(teamPlayers?.teamPlayersByTeam);
  }, [teamId]);

  const onSearchUser = (event) => {
    let data = [];
    if (event?.target?.value && event?.target?.value != "") {
      data = users?.filter((record) =>
        record?.name
          ?.toLowerCase()
          .includes(event?.target?.value?.toLowerCase())
      );
    } else if (event?.target?.value == "") {
      data = users;
    }
    const list = [];
    data?.map((record) => {
      list.push({
        ...record,
        key: record?.id,
      });
    });
    // setUsers(list);
    setPlayerTable(list);
  };

  const onUserSelect = (value, record) => {
    const selectedPlayer = {
      player_id: record?.key,
      status: record?.status,
      position: record?.position,
      jerseyNo: record?.preferred_jersey_no?.toString(),
    };
    let updatedSelectedList;

    if (value.target?.checked) {
      updatedSelectedList = [...selectedPlayers, selectedPlayer];
    } else {
      updatedSelectedList = selectedPlayers.filter(
        (player) => player.id !== selectedPlayer?.id
      );
    }
    setSelectedPlayers(updatedSelectedList);
  };

  const columns = [
    {
      title: "Select",
      dataIndex: "isSelect",
      width: "10%",
      render: (text, record, index) => {
        return <Checkbox onChange={(value) => onUserSelect(value, record)} />;
      },
    },
    {
      title: "Picture",
      dataIndex: "display_picture",
      key: "picture",
      render: (display_picture) => {
        return display_picture && <Avatar src={display_picture} />;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name) => name,
      editable: true,
      // filters: [],
      // onFilter: (value, record) => record.name.indexOf(value) === 0,
      // sorter: (a, b) => a > b ? -1 : 1,
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
      render: (position) => position,
    },
    {
      title: "Jersey No",
      dataIndex: "preferred_jersey_no",
      key: "preferred_jersey_no",
      width: "15%",
      render: (preferred_jersey_no) => preferred_jersey_no,
    },
  ];

  const onClose = () => {
    setStatusMessage(null);
  };

  const onSave = async () => {
    if (selectedPlayers?.length) {
      createTournamentSquad({
        variables: {
          createTournamentSeasonSquadInput: {
            playerDetails: selectedPlayers,
            team_id: teamId,
            season_id: seasonId,
          },
        },
      })
        .then((res) => {
          setStatusMessage({
            type: "success",
            content: "Saved",
          });

          refresh();
          setSelectedPlayers([]);
          close();
        })
        .catch((err) => {
          setStatusMessage({
            type: "error",
            content: err.message,
          });
        });
    }
  };

  return (
    <>
      {statusMessage && (
        <Alert
          message={statusMessage?.content}
          style={{ marginTop: "8px" }}
          type={statusMessage?.type}
          showIcon
          closable
          onClose={onClose}
        />
      )}
      <Col span={5} style={{ marginBottom: "8px" }}>
        <Input placeholder="Search Team player" onChange={onSearchUser} />
      </Col>
      <Col span={24}>
        <Table
          bordered
          dataSource={playerTable}
          columns={columns}
          rowClassName="editable-row"
          pagination={false}
          scroll={{ y: 300, x: "auto" }}
        />
      </Col>

      <Col
        span={24}
        style={{
          display: "flex",
          justifyContent: "end",
          marginTop: 24,
          gap: 16,
        }}
      >
        {/* <Button onClick={reset}>Reset</Button> */}
        <Button
          type="primary"
          onClick={onSave}
          loading={tournamentSquadDataLoading}
        >
          Add to squad
        </Button>
      </Col>
    </>
  );
};

export default CreateSquad;
