import { gql } from "@apollo/client";

const ADD_TEAM_PLAYERS= gql`
mutation addTeamPlayers($addTeamPlayersInput: AddTeamPlayersInput!) {
  addTeamPlayers(addTeamPlayersInput: $addTeamPlayersInput) {
    id
    join_date
    leave_date
    status
    user {
      id
      name
      email
      phone_number
      display_picture
      position
      preferred_jersey_no
    }
    team {
      id
      name
      description
      display_picture
    }
  }
}

`;

export default ADD_TEAM_PLAYERS;
