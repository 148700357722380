import { gql } from "@apollo/client";

const FIND_ALL_SEASONS_BY_TOURNAMENT_ID = gql`
  query findAllSeasonsByTournamentId(
    $tournament_id: String!
    $status: TournamentSeasonStatus
  ) {
    findAllSeasonsByTournamentId(
      tournament_id: $tournament_id
      status: $status
    ) {
      id
      name
      start_date
      end_date
      advanced_stats
      display_picture
      status
      tournament {
        id
        name
        description
      }
    }
  }
`;

export default FIND_ALL_SEASONS_BY_TOURNAMENT_ID;
