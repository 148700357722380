import { gql } from "@apollo/client";

const CREATE_TOURNAMENT_SEASON= gql`
mutation createTournamentSeason($createTournamentSeasonInput: CreateTournamentSeasonInput!) {
  createTournamentSeason(createTournamentSeasonInput: $createTournamentSeasonInput) {
    id
    name
    display_picture
    advanced_stats
    tournament {
      id
      name
      display_picture
      description
      about_tournament
   
    }
    about_tournament
    tournament_sponsors {
      id
      name
      display_picture
      description
      link
    }
    tournament_gallery {
      id
      display_picture
      is_published
      match_id
      created_at
      updated_at
    }
    
  }
}

`;

export default CREATE_TOURNAMENT_SEASON;
