import { gql } from "@apollo/client";

const CREATE_TOURNAMENT_SEASON_TEAM= gql`
mutation createTournamentSeasonTeam($createTeamInput: CreateTournamentSeasonTeamInput!) {
  createTournamentSeasonTeam(createTeamInput: $createTeamInput) {
    id
    tournament_enrollment_status
    tournament_season {
      id
      name
      start_date
      display_picture
      end_date
      about_tournament
    }
    tournament {
      id
      name
      display_picture
      description
      about_tournament
    }
    team {
      id
      name
      description
      display_picture
    }
    tournament_season_group {
      id
      group_name
    }
  }
}

`;

export default CREATE_TOURNAMENT_SEASON_TEAM;
