import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Typography,
  Popconfirm,
  Input,
  Select,
  Space,
  Table,
  Row,
  InputNumber,
} from "antd";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  LoadingOutlined,
  EditOutlined,
  DeleteOutlined,
  SaveOutlined,
  CloseOutlined,
  FormOutlined,
  EditFilled,
  DeleteFilled,
} from "@ant-design/icons";
import GET_USERS from "../../api/get_all_users";
import GET_TOURNAMENT_BY_ID from "../../api/get_tournament_by_id";
import { useLocation, useParams } from "react-router";
import FIND_ALL_SEASONS_BY_TOURNAMENT_ID from "../../api/get_tournament_seasons";
import GET_MATCHES from "../../api/get_all_matches";
import moment from "moment";
import ASSIGN_ROLES_FOR_TOURNAMENT_MUTATION from "../../api/assign_user_roles_for_tournaments";
import ASSIGN_ROLES_FOR_SEASON_MUTATION from "../../api/assign_user_roles_for_season";
import GET_MATCHES_BY_TOURNAMENT_SEASON_ID from "../../api/get_matches_by_tournament_season_id";
import ASSIGN_ROLES_FOR_MATCHES_MUTATION from "../../api/assign_user_roles_for_matches";
import SeasonTable from "./components/season_user_role";
import MatchesTable from "./components/matches_user_role";
import FIND_USERS_WITH_ROLE_BY_ENTITY from "../../api/find_users_with_role_by_entity";
import REMOVE_USER_ROLE_FROM_TOURNAMENT from "../../api/remove_user_role_from_tournament";
import TournamentTable from "./components/tournament_user_role";

const UserRole = () => {
  const location = useLocation();
  const { id: tournamentId } = useParams();
  const [form] = Form.useForm();
  const [selectedSeasonId, setSelectedSeasonId] = useState();
  const [
    assignUserRoleTournaments,
    {
      data: assignUserRoleTournamentData,
      loading: assignUserRoleTournamentLoading,
      error: assignUserRoleTournamentError,
    },
  ] = useMutation(ASSIGN_ROLES_FOR_TOURNAMENT_MUTATION);
  const { data: tournamentData, loading: tournamentLoading } = useQuery(
    GET_TOURNAMENT_BY_ID,
    {
      variables: { id: tournamentId },
    }
  );
  const {
    data: usersWithTournamentRoleData,
    loading: usersWithTournamentRoleLoading,
    refetch,
    error,
  } = useQuery(FIND_USERS_WITH_ROLE_BY_ENTITY, {
    variables: {
      args: {
        entityId: tournamentId,
        entityType: "TOURNAMENT",
      },
    },
  });
  const [
    deleteUserRole,
    { loading: deleteUserRoleLoading, error: deleteUserRoleError },
  ] = useMutation(REMOVE_USER_ROLE_FROM_TOURNAMENT);
  const [selectedMatch, setSelectedMatch] = useState();
  const [selectedMatchTeam, setSelectedMatchTeam] = useState(null);
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;
  const { data: allUsers, loading } = useQuery(GET_USERS);
  const [tournamentDataSource, setTournamentDataSource] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [seasonDataSource, setSeasonDataSource] = useState([]);
  const [matchDataSource, setMatchDataSource] = useState([]);
  const { Option } = Select;

  return (
    <>
      <TournamentTable />
      <SeasonTable
        tournamentId={tournamentId}
        selectedSeasonId={selectedSeasonId}
        setSelectedSeasonId={setSelectedSeasonId}
      />
      <MatchesTable
        selectedSeasonId={selectedSeasonId}
        tournamentId={tournamentId}
      />
    </>
  );
};

export default UserRole;
