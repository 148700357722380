import gql from "graphql-tag";

const FIND_ALL_TOURNAMENT_SQUAD = gql`
  query FindAllTournamentSquad($fetchTournamentSeasonSquadInput: FetchTournamentSeasonSquadInput!) {
    findAllTournamentSquad(fetchTournamentSeasonSquadInput: $fetchTournamentSeasonSquadInput) {
      season_id
      team_id
      fetchTournamentSeasonSquad {
        position
        jerseyNo
        status
        id
        playerName
        playerId
        displayPicture
      }
    }
  }
`;


export default FIND_ALL_TOURNAMENT_SQUAD;
