import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Form,
  Input,
  Select,
  Space,
  DatePicker,
  Button,
  message,
  Alert,
  Row,
  Col,
  InputNumber,
} from "antd";
import { useCallback, useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import dayjs from "dayjs";
import moment from "moment/moment";
import FIND_ALL_GROUPS_BY_SEASON_ID from "../../../api/get_all_groups_by_season";
import GET_MATCHES from "../../../api/get_all_matches";
import FIND_ALL_ROUNDS_BY_SEASON_ID from "../../../api/get_all_rounds_by_season";
import GET_EVENT_ENUM from "../../../api/get_event_enum";
import GET_TEAMS from "../../../api/get_teams";
import GET_TOURNAMENTS from "../../../api/get_tournaments";
import FIND_ALL_SEASONS_BY_TOURNAMENT_ID from "../../../api/get_tournament_seasons";
import GET_VENUES from "../../../api/get_venue_list";
import GET_MATCH_LINEUP_BY_ID from "../../../api/get_match_line_ups";
import CREATE_MATCH_EVENT from "../../../api/create_match_event";
import TextArea from "antd/es/input/TextArea";
import UPDATE_MATCH_EVENT_BY_ID from "../../../api/update_match_event_by_id";
import UPDATE_MATCH_EVENT_META_BY_ID from "../../../api/update_match_event_meta_by_id";
import "./style.scss";

const NewEvent = ({ record, matchId, refresh, close }) => {
  const utc = require("dayjs/plugin/utc");
  dayjs.extend(utc);
  const { data: allMatches } = useQuery(GET_MATCHES);
  const { data: allTeams, loading } = useQuery(GET_TEAMS);
  const { data: eventEnumList } = useQuery(GET_EVENT_ENUM, {
    variables: { enumName: "MatchEventType" },
  });
  const { data: associateEventEnumList } = useQuery(GET_EVENT_ENUM, {
    variables: { enumName: "AssociatedEventType" },
  });
  const { data: matchStageEnumList } = useQuery(GET_EVENT_ENUM, {
    variables: { enumName: "MatchStage" },
  });
  const { data: tournamentsData, loading: tournamentLoading } =
    useQuery(GET_TOURNAMENTS);
  const [createMatchEvent, { data, loading: createMatchEventLoading, error }] =
    useMutation(CREATE_MATCH_EVENT);
  const { data: venueData, venueLoading, venueError } = useQuery(GET_VENUES);
  const [
    getTournamentSeasonData,
    { data: seasonsData, loading: seasonsLoading },
  ] = useLazyQuery(FIND_ALL_SEASONS_BY_TOURNAMENT_ID);
  const [
    getTournamentRoundData,
    { data: roundData, loading: roundDataLoading },
  ] = useLazyQuery(FIND_ALL_ROUNDS_BY_SEASON_ID);
  const [
    getTournamentGroupData,
    { data: groupData, loading: groupDataLoading },
  ] = useLazyQuery(FIND_ALL_GROUPS_BY_SEASON_ID);
  const [
    updateMatchEvent,
    {
      data: updateMatchEventData,
      loading: updateMatchEventLoading,
      error: updateMatchEventError,
    },
  ] = useMutation(UPDATE_MATCH_EVENT_BY_ID);
  const [
    updateMatchEventMeta,
    {
      data: updateMatchEventMetaData,
      loading: updateMatchEventMetaLoading,
      error: updateMatchEventMetaError,
    },
  ] = useMutation(UPDATE_MATCH_EVENT_META_BY_ID);
  const { Option } = Select;
  const [statusMessage, setStatusMessage] = useState(null);
  const [selectedMatch, setSelectedMatch] = useState(matchId);
  const [selectedMatchTeam, setSelectedMatchTeam] = useState(null);
  const [playerList, setPlayerList] = useState([]);
  const [associatedPlayerList, setAssociatedPlayerList] = useState([]);

  const [form] = Form.useForm();
  const tournament = Form.useWatch("tournament", form);
  const season = Form.useWatch("tournamentSeason", form);

  const { data: matchLineUps } = useQuery(GET_MATCH_LINEUP_BY_ID, {
    variables: { match_id: selectedMatch },
  });
  useEffect(() => {
    if (record) {
      const teamLineUp = matchLineUps?.matchLineupByMatchId?.find(
        (team) => team.id == record?.teamName?.id
      );
      const associatedTeamLineUp = matchLineUps?.matchLineupByMatchId?.find(
        (team) => team.id == record?.event_meta?.associated_team?.id
      );

      setPlayerList(teamLineUp?.lineup);
      setAssociatedPlayerList(associatedTeamLineUp?.lineup);
      form.setFieldsValue({
        event: record?.eventName,
        time: dayjs(record?.time, "YYYY-MM-DD HH:mm[Z]"),
        time_elapsed: record?.time_elapsed,
        extra_time_elapsed: record?.extra_time_elapsed,
        match_stage: record?.match_stage,
        team_id: record?.teamName?.id,
        player_id: record?.player?.id,
        association_type: record?.event_meta?.association_type,
        associated_team_id: record?.event_meta?.associated_team?.id,
        associated_player_id: record?.event_meta?.associated_player?.id,
        area_of_play_start: record?.event_meta?.area_of_play_start,
        area_of_play_end: record?.event_meta?.area_of_play_end,
        action_type: record?.event_meta?.action_type,
        body_part: record?.event_meta?.body_part,
        commentary: record?.commentary,
      });
    }
  }, [record]);

  let selectMatches = [];
  allMatches?.matches?.map((matches) => {
    selectMatches.push({
      key: matches?.id,
      value: matches?.id,
      label: `${matches?.team1?.name} vs ${matches?.team2?.name} ${moment(
        matches?.match_schedule?.start_date
      ).format("DD-MMM-YYYY hh:mm A")}`,
    });
  });

  useEffect(() => {
    const match = allMatches?.matches?.find((object) => object?.id == matchId);
    if (match) {
      setSelectedMatchTeam({ team1: match?.team1, team2: match?.team2 });
    }
  }, [matchId]);

  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        team1: record?.team1?.name,
        team2: record?.team2?.name,
      });
    } else {
      form.resetFields();
    }
  }, [record]);

  useEffect(() => {
    if (tournament) {
      getTournamentSeasonData({
        variables: {
          tournament_id: tournament,
        },
      });
    }
  }, [tournament]);

  useEffect(() => {
    if (season) {
      getTournamentRoundData({
        variables: {
          seasonId: season,
        },
      });

      getTournamentGroupData({
        variables: {
          tournament_season_id: season,
        },
      });
    }
  }, [season]);

  const teamDropDown = (selectedId) => {
    return (
      <Select
        onChange={(value) => {
          form.setFieldValue("player_id", undefined);
          const teamLineUp = matchLineUps?.matchLineupByMatchId?.find(
            (team) => team.id == value
          );

          setPlayerList(teamLineUp?.lineup);
        }}
      >
        <Option
          key={selectedMatchTeam?.team1?.id}
          value={selectedMatchTeam?.team1?.id}
        >
          {selectedMatchTeam?.team1?.name}
        </Option>
        <Option
          key={selectedMatchTeam?.team2?.id}
          value={selectedMatchTeam?.team2?.id}
        >
          {selectedMatchTeam?.team2?.name}
        </Option>
      </Select>
    );
  };

  const associatedTeamDropDown = (selectedId) => {
    return (
      <Select
        allowClear={true}
        onChange={(value) => {
          form.setFieldValue("associated_player_id", undefined);
          const teamLineUp = matchLineUps?.matchLineupByMatchId?.find(
            (team) => team.id == value
          );

          setAssociatedPlayerList(teamLineUp?.lineup);
        }}
      >
        <Option
          key={selectedMatchTeam?.team1?.id}
          value={selectedMatchTeam?.team1?.id}
        >
          {selectedMatchTeam?.team1?.name}
        </Option>
        <Option
          key={selectedMatchTeam?.team2?.id}
          value={selectedMatchTeam?.team2?.id}
        >
          {selectedMatchTeam?.team2?.name}
        </Option>
      </Select>
    );
  };

  const onFinish = async (formaValue) => {
    if (record) {
      updateEvent(formaValue);
    } else {
      saveEvent(formaValue);
    }
  };

  const saveEvent = (formValue) => {
    createMatchEvent({
      variables: {
        createMatchEventInput: {
          ...formValue,
          time: formValue?.time?.utc()?.format("YYYY-MM-DDTHH:mm[Z]"),
          match_id: selectedMatch,
        },
      },
    })
      .then((res) => {
        setStatusMessage({
          type: "success",
          content: "Match Event created",
        });

        refresh();
        onReset();
        close();
      })
      .catch((err) => {
        setStatusMessage({
          type: "error",
          content: err.message,
        });
      });
  };

  const updateEvent = (formValue) => {
    Object.keys(formValue).forEach(
      (k) =>
        (formValue[k] =
          formValue[k] === "" || formValue[k] === undefined
            ? null
            : formValue[k])
    );

    const eventPayload = {
      event: formValue?.event,
      player_id: formValue?.player_id,
      team_id: formValue?.team_id,
      time_elapsed: Number(formValue?.time_elapsed),
      extra_time_elapsed: Number(formValue?.extra_time_elapsed),
      match_stage: formValue?.match_stage,
      commentary: formValue?.commentary,
      time: formValue?.time?.utc()?.format("YYYY-MM-DDTHH:mm[Z]"),
    };

    const eventMetaPayload = {
      association_type: formValue?.association_type,
      associated_team_id: formValue?.associated_team_id,
      associated_player_id: formValue?.associated_player_id,
      area_of_play_start: formValue?.area_of_play_start,
      area_of_play_end: formValue?.area_of_play_end,
      body_part: formValue?.body_part,
      action_type: formValue?.action_type,
    };

    const requests = [
      updateMatchEvent({
        variables: {
          match_event_id: record?.key,
          updateMatchEventInput: eventPayload,
        },
      }),
      updateMatchEventMeta({
        variables: {
          event_meta_id: record?.event_meta?.id,
          updateMatchEventInput: eventMetaPayload,
        },
      }),
    ];

    Promise.all(requests)
      .then((res) => {
        setStatusMessage({
          type: "success",
          content: "Match Event created",
        });

        onReset();
        refresh();
        close();
      })
      .catch((err) => {
        setStatusMessage({
          type: "error",
          content: err.message,
        });
      });
  };

  const onReset = () => {
    form?.resetFields();
  };

  const onClose = () => {
    setStatusMessage(null);
  };

  const onChange = (value) => {
    setSelectedMatch(value);

    const match = allMatches?.matches?.find((object) => object?.id == value);

    if (match) {
      setSelectedMatchTeam({ team1: match?.team1, team2: match?.team2 });
    }
  };

  const onSearch = (value) => {
    setSelectedMatch(value);
  };

  const teamPlayerDropDown = (teamId) => {
    return (
      <Select
        showSearch
        filterOption={(input, option) =>
          (option?.label?.toLowerCase() ?? "").includes(input.toLowerCase())
        }
        options={playerList?.map((player) => {
          return {
            label: `${player?.name} (${player?.jersey_no})`,
            value: player?.userId,
          };
        })}
      ></Select>
    );
  };

  const associatedTeamPlayerDropDown = (teamId) => {
    return (
      <Select
        allowClear={true}
        showSearch
        filterOption={(input, option) =>
          (option?.label?.toLowerCase() ?? "").includes(input.toLowerCase())
        }
        options={associatedPlayerList?.map((player) => {
          return {
            label: `${player?.name} (${player?.jersey_no})`,
            value: player?.userId,
          };
        })}
      ></Select>
    );
  };

  return (
    <>
      {statusMessage && (
        <Alert
          message={statusMessage?.content}
          type={statusMessage?.type}
          showIcon
          closable
          onClose={onClose}
        />
      )}
      <Row className="new-event">
        {!record && (
          <Select
            className="event-select"
            showSearch
            placeholder="Select a match"
            optionFilterProp="children"
            defaultValue={matchId}
            onChange={onChange}
            onSearch={onSearch}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={selectMatches}
          />
        )}
      </Row>
      <Form
        form={form}
        name="complex-form"
        onFinish={onFinish}
        style={{ marginTop: "24px" }}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 12 }}
        // style={{ }}
      >
        <Row gutter={4}>
          <Col span={24} md={12}>
            <Form.Item
              label="Event"
              name="event"
              rules={[{ required: true, message: "Event is required" }]}
            >
              <Select
                placeholder="Select Event "
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  (option?.label?.toLowerCase() ?? "").includes(
                    input.toLowerCase()
                  )
                }
                options={eventEnumList?.enumValues?.map((enumValue) => {
                  return { label: enumValue, value: enumValue };
                })}
              ></Select>
            </Form.Item>

            <Form.Item
              name="time"
              label="Time"
              rules={[{ required: true, message: "Please select time!" }]}
            >
              <DatePicker
                style={{ width: "100%" }}
                showTime
                format="YYYY-MM-DD HH:mm "
              />
            </Form.Item>

            <Form.Item
              name="time_elapsed"
              label="Time elapsed"
              rules={[
                { required: true, message: "Please select Time elapsed!" },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                pattern="[0-9]*"
                inputmode="numeric"
              />
            </Form.Item>

            <Form.Item
              name="extra_time_elapsed"
              label="Extra Time elapsed"
              rules={[
                {
                  required: true,
                  message: "Please select Extra Time elapsed!",
                },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                pattern="[0-9]*"
                inputmode="numeric"
              />
            </Form.Item>

            <Form.Item
              name="team_id"
              label="Team"
              rules={[{ required: true, message: "Please select Team" }]}
            >
              {teamDropDown()}
            </Form.Item>

            <Form.Item
              name="player_id"
              label="Player"
              rules={[{ required: true, message: "Please select Player" }]}
            >
              {teamPlayerDropDown(form.getFieldValue("team_id"))}
            </Form.Item>

            <Form.Item label="Match Stage" name="match_stage">
              <Select>
                {matchStageEnumList?.enumValues?.map((enumValue) => (
                  <Option key={enumValue} value={enumValue}>
                    {enumValue}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={24} md={12}>
            <Form.Item label="Association Type" name="association_type">
              <Select
                allowClear={true}
                onClear={null}
                showSearch
                filterOption={(input, option) =>
                  (option?.label?.toLowerCase() ?? "").includes(
                    input.toLowerCase()
                  )
                }
                options={associateEventEnumList?.enumValues?.map(
                  (enumValue) => {
                    return { label: enumValue, value: enumValue };
                  }
                )}
              ></Select>
            </Form.Item>

            <Form.Item name="associated_team_id" label="Associated Team Id">
              {associatedTeamDropDown()}
            </Form.Item>

            <Form.Item name="associated_player_id" label="Associated Player Id">
              {associatedTeamPlayerDropDown(
                form.getFieldValue("associated_team_id")
              )}
            </Form.Item>

            <Form.Item label="Area of play start" name="area_of_play_start">
              {/* <Select>
                                <Option key="Type1" value="Type1">Type1</Option>
                            </Select> */}
              <Input />
            </Form.Item>

            <Form.Item label="Area of play end" name="area_of_play_end">
              {/* <Select>
                                <Option key="Type1" value="Type1">Type1</Option>
                            </Select> */}
              <Input />
            </Form.Item>

            <Form.Item label="Body part" name="body_part">
              {/* <Select>
                                <Option key="Part1" value="Part1">Part1</Option>
                            </Select> */}
              <Input />
            </Form.Item>

            <Form.Item label="Action type" name="action_type">
              {/* <Select>
                                <Option key="Type1" value="Type1">Type1</Option>
                            </Select> */}
              <Input />
            </Form.Item>

            {record && (
              <Form.Item label="Commentary" name="commentary">
                <TextArea />
              </Form.Item>
            )}
          </Col>

          <Col span={24}>
            <Space style={{ justifyContent: "right", width: "100%" }}>
              <Button htmlType="button" onClick={onReset}>
                Reset
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={createMatchEventLoading || updateMatchEventLoading}
              >
                Submit
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default NewEvent;
