import { useMutation, useQuery } from "@apollo/client";
import { Button, Alert, Col, Table, Checkbox, Avatar, Input } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import GET_USERS from "../../../api/get_all_users";
import ADD_TEAM_PLAYERS from "../../../api/add_team_player";

const AddPlayer = ({ teamId, refresh, close }) => {
  const [statusMessage, setStatusMessage] = useState(null);
  const [users, setUsers] = useState([]);
  const { data: allUsers, loading } = useQuery(GET_USERS);
  const [
    addTeamPlayers,
    {
      data: addTeamPlayersData,
      loading: addTeamPlayersLoading,
      error: addTeamPlayersError,
    },
  ] = useMutation(ADD_TEAM_PLAYERS);
  const [selectedPlayers, setSelectedPlayers] = useState([]);

  useEffect(() => {
    const userList = [];

    allUsers?.users?.map((record) => {
      userList.push({
        ...record,
        key: record?.id,
      });
    });

    setUsers(userList);
  }, [allUsers]);

  const onSearchUser = (event) => {
    let data;

    if (event?.target?.value) {
      data = allUsers?.users?.filter((record) =>
        record?.name
          ?.toLowerCase()
          .includes(event?.target?.value?.toLowerCase())
      );
    } else {
      data = allUsers?.users;
    }

    const list = [];

    data.map((record) => {
      list.push({
        ...record,
        key: record?.id,
      });
    });

    setUsers(list);
  };

  const onUserSelect = (value, record) => {
    let selectedList = selectedPlayers;

    if (value.target?.checked) {
      selectedList.push(record?.key);
    } else {
      selectedList = selectedList?.filter((player) => player != record?.key);
    }

    setSelectedPlayers(selectedList);
  };

  const columns = [
    {
      title: "Select",
      dataIndex: "isSelect",
      width: "10%",
      render: (text, record, index) => {
        return <Checkbox onChange={(value) => onUserSelect(value, record)} />;
      },
    },
    {
      title: "Picture",
      dataIndex: "display_picture",
      key: "picture",
      render: (display_picture) => {
        return display_picture && <Avatar src={display_picture} />;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name) => name,
      editable: true,
      // filters: [],
      // onFilter: (value, record) => record.name.indexOf(value) === 0,
      // sorter: (a, b) => a > b ? -1 : 1,
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
      render: (position) => position,
    },
    {
      title: "Jersey No",
      dataIndex: "preferred_jersey_no",
      key: "preferred_jersey_no",
      width: "15%",
      render: (preferred_jersey_no) => preferred_jersey_no,
    },
  ];

  const onClose = () => {
    setStatusMessage(null);
  };

  const onSave = async () => {
    if (selectedPlayers?.length) {
      addTeamPlayers({
        variables: {
          addTeamPlayersInput: {
            user_ids: selectedPlayers,
            team_id: teamId,
          },
        },
      })
        .then((res) => {
          setStatusMessage({
            type: "success",
            content: "Saved",
          });

          refresh();
          setSelectedPlayers([]);
          close();
        })
        .catch((err) => {
          setStatusMessage({
            type: "error",
            content: err.message,
          });
        });
    }
  };

  return (
    <>
      {statusMessage && (
        <Alert
          message={statusMessage?.content}
          style={{ marginTop: "8px" }}
          type={statusMessage?.type}
          showIcon
          closable
          onClose={onClose}
        />
      )}
      <Col span={5} style={{ marginBottom: "8px" }}>
        <Input placeholder="Search Team player" onChange={onSearchUser} />
      </Col>
      <Col span={24}>
        <Table
          bordered
          dataSource={users}
          columns={columns}
          rowClassName="editable-row"
          pagination={false}
          scroll={{ y: 300, x: "auto" }}
        />
      </Col>

      <Col
        span={24}
        style={{
          display: "flex",
          justifyContent: "end",
          marginTop: 24,
          gap: 16,
        }}
      >
        {/* <Button onClick={reset}>Reset</Button> */}
        <Button type="primary" onClick={onSave} loading={addTeamPlayersLoading}>
          Add Players
        </Button>
      </Col>
    </>
  );
};

export default AddPlayer;
