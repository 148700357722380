// src/components/Login.js

import React, { useState } from "react";
import { Form, Input, Button, notification } from "antd";
import { useNavigate } from "react-router";

const PhoneField = ({onSubmit}) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="">
      <h3 style={{ textAlign: "center", marginBottom: "24px" }}>Enter your phone number</h3>
      <Form onFinish={onSubmit}>
        <Form.Item
          // label="Phone"
          name="phone"
          rules={[{ required: true, message: "Please enter your Phone number." }]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button style={{marginTop: '10px'}} type="primary" htmlType="submit" loading={loading}>
            Next
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default PhoneField;
