import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Form,
  Input,
  Select,
  Space,
  DatePicker,
  Button,
  message,
  Alert,
  Row,
  Col,
} from "antd";
import moment from "moment";
import dayjs from "dayjs";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import CREATE_MATCH from "../../../api/create_match";
import FIND_ALL_GROUPS_BY_SEASON_ID from "../../../api/get_all_groups_by_season";
import FIND_ALL_ROUNDS_BY_SEASON_ID from "../../../api/get_all_rounds_by_season";
import GET_TEAMS from "../../../api/get_teams";
import GET_TOURNAMENTS from "../../../api/get_tournaments";
import FIND_ALL_SEASONS_BY_TOURNAMENT_ID from "../../../api/get_tournament_seasons";
import GET_VENUES from "../../../api/get_venue_list";
import UPDATE_TEAM_IN_MATCH from "../../../api/update_team_in_match";
import GET_TEAMS_BY_TOURNAMENT_SEASON_ID from "../../../api/get_teams_by_season_id";
import GET_EVENT_ENUM from "../../../api/get_event_enum";

const CreateMatch = ({
  record,
  refresh,
  cancel,
  seasonId,
  tournamentId,
  currentTournament,
}) => {
  const { data: allTeams, loading } = useQuery(GET_TEAMS);
  const { data: matchFormats } = useQuery(GET_EVENT_ENUM, {
    variables: { enumName: "MatchFormatEnums" },
  });
  const { data: tournamentsData, loading: tournamentLoading } =
    useQuery(GET_TOURNAMENTS);
  const [createMatch, { data, createMatchLoading, error }] =
    useMutation(CREATE_MATCH);
  const [
    updateTeamInMatch,
    { updateTeamInMatchData, loading: updateMatchLoading, updateMatchError },
  ] = useMutation(UPDATE_TEAM_IN_MATCH);
  const { data: venueData, venueLoading, venueError } = useQuery(GET_VENUES);
  const [
    getTournamentSeasonData,
    { data: seasonsData, loading: seasonsLoading },
  ] = useLazyQuery(FIND_ALL_SEASONS_BY_TOURNAMENT_ID);
  const [
    getTournamentRoundData,
    { data: roundData, loading: roundDataLoading },
  ] = useLazyQuery(FIND_ALL_ROUNDS_BY_SEASON_ID);
  const [
    getTournamentGroupData,
    { data: groupData, loading: groupDataLoading },
  ] = useLazyQuery(FIND_ALL_GROUPS_BY_SEASON_ID);
  const [
    getTournamentSeasonTeams,
    {
      data: tournamentSeasonsTeams,
      loading: tournamentSeasonTeamsLoading,
      refetch,
    },
  ] = useLazyQuery(GET_TEAMS_BY_TOURNAMENT_SEASON_ID, {
    variables: {
      tournament_season_id: seasonId,
    },
  });
  const { Option } = Select;
  const formRef = useRef(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [statusMessage, setStatusMessage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = Form.useForm();
  const tournament = Form.useWatch("tournament", form);
  const season = Form.useWatch("tournamentSeason", form);
  const utc = require("dayjs/plugin/utc");
  dayjs.extend(utc);
  // const [startTime, setStartTime] = useState(null);
  // const [duration, setDuration] = useState("");

  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        team1: record?.team1?.id,
        team2: record?.team2?.id,
        startTime: dayjs(record?.startTime, "YYYY-MM-DD HH:mm[Z]"),
        endTime: dayjs(record?.endTime, "YYYY-MM-DD HH:mm[Z]"),
      });
    } else {
      form.resetFields();
    }
  }, [record]);

  useEffect(() => {
    if (tournament == null) {
      getTournamentSeasonData({
        variables: {
          tournament_id: currentTournament?.tournament?.id,
        },
      });
    } else {
      getTournamentSeasonData({
        variables: {
          tournament_id: tournament,
        },
      });
    }
  }, [tournament, currentTournament]);
  useEffect(() => {
    getTournamentSeasonTeams({
      variables: {
        tournament_season_id: seasonId,
      },
    });
  }, [seasonId]);

  useEffect(() => {
    if (season == null) {
      getTournamentRoundData({
        variables: {
          seasonId: seasonId,
        },
      });
      getTournamentGroupData({
        variables: {
          tournament_season_id: seasonId,
        },
      });
    } else {
      getTournamentRoundData({
        variables: {
          seasonId: season,
        },
      });

      getTournamentGroupData({
        variables: {
          tournament_season_id: season,
        },
      });
    }
  }, [season, seasonId]);

  const renderTeamOption = () => {
    if (tournamentSeasonsTeams) {
      return tournamentSeasonsTeams?.findSeasonTeamsBySeasonId?.map((teams) => {
        return { label: teams?.team?.name, value: teams?.team?.id };
      });
    } else {
      return allTeams?.teams?.map((team) => {
        return { label: team?.name, value: team?.id };
      });
    }
  };
  const renderTournament = () => {
    if (tournamentsData)
      return tournamentsData?.tournaments?.map((tournament) => {
        return {
          label: tournament?.name,
          value: tournament?.id,
        };
      });
  };
  const renderTournamentSeason = () => {
    if (seasonsData)
      return seasonsData?.findAllSeasonsByTournamentId?.map((season) => {
        return { label: season?.name, value: season?.id };
      });
  };

  const renderTournamentRound = () => {
    if (roundData)
      return roundData?.findAllRoundsOfSeason?.map((round) => {
        return { label: round?.type, value: round?.id };
      });
  };

  const renderTournamentGroup = () => {
    if (groupData)
      return groupData?.findAllGroupsBySeasonId?.map((group) => {
        return { label: group?.group_name, value: group?.id };
      });
  };

  const renderVenue = () => {
    if (allTeams)
      return venueData?.venues?.map((venue) => {
        return { label: venue?.name, value: venue?.id };
      });
  };

  const onFinish = async (formValue) => {
    if (formValue?.team1 == formValue?.team2) {
      setStatusMessage({
        type: "error",
        content: "Please choose different team",
      });

      return;
    }

    if (record) {
      updateMatch(formValue);
    } else {
      saveMatch(formValue);
    }
  };

  const saveMatch = (formValue) => {
    const formattedStartTime = formValue?.startTime
      ?.utc()
      ?.format("YYYY-MM-DDTHH:mm:ss[Z]");
    const formattedEndTime = formValue?.endTime
      .utc()
      ?.format("YYYY-MM-DDTHH:mm:ss[Z]");

    createMatch({
      variables: {
        createMatchInput: {
          name: formValue?.name,
          format: formValue?.format,
          duration: formValue?.duration,
          subCount: formValue?.subCount,
          venueId: formValue?.venue,
          tournament_season_id: formValue?.tournamentSeason || seasonId,
          tournament_round_id: formValue?.tournamentRound,
          tournament_season_group_id: formValue?.tournamentGroup,
          team1Id: formValue?.team1,
          team2Id: formValue?.team2,
          start_time: formattedStartTime,
          end_time: formattedEndTime,
        },
      },
    })
      .then((res) => {
        setStatusMessage({
          type: "success",
          content: "Match created",
        });

        refresh();
        onReset();
        cancel();
      })
      .catch((err) => {
        setStatusMessage({
          type: "error",
          content: err.message,
        });
      });
  };

  const updateMatch = (formValue) => {
    updateTeamInMatch({
      variables: {
        match_id: record?.id,
        team1_id: formValue?.team1,
        team2_id: formValue?.team2,
      },
    })
      .then((res) => {
        setStatusMessage({
          type: "success",
          content: "Match Updated",
        });

        refresh();
        onReset();
        cancel();
      })
      .catch((err) => {
        setStatusMessage({
          type: "error",
          content: err.message,
        });
      });
  };
  const onReset = () => {
    form?.resetFields();
  };

  const onClose = () => {
    setStatusMessage(null);
  };

  const handleSubmit = async () => {
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);
  };

  return (
    <>
      {contextHolder}
      {statusMessage && (
        <Alert
          message={statusMessage?.content}
          type={statusMessage?.type}
          showIcon
          closable
          onClose={onClose}
        />
      )}
      <Form
        form={form}
        name="complex-form"
        onFinish={onFinish}
        style={{ marginTop: "24px" }}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 12 }}
        // style={{ }}
      >
        <Row gutter={4}>
          <Col span={12}>
            <Form.Item
              label="Team 1"
              name="team1"
              rules={[{ required: true, message: "Team 1 is required" }]}
            >
              <Select
                placeholder="Select Team 1"
                showSearch
                filterOption={(input, option) =>
                  (option?.label?.toLowerCase() ?? "").includes(
                    input.toLowerCase()
                  )
                }
                options={renderTeamOption()}
              >
                {/* {} */}
              </Select>
            </Form.Item>

            <Form.Item
              name="team2"
              label="Team 2"
              rules={[{ required: true, message: "Team 2 is required" }]}
            >
              <Select
                placeholder="Select Team 2"
                showSearch
                filterOption={(input, option) =>
                  (option?.label?.toLowerCase() ?? "").includes(
                    input.toLowerCase()
                  )
                }
                options={renderTeamOption()}
              ></Select>
            </Form.Item>

            {/* <Form.Item
                    rules={[{ required: true, message: 'Manager required' }]}
                >
                    <Select placeholder="Select Manager">
                        <Option value="Zhejiang">Zhejiang</Option>
                        <Option value="Jiangsu">Jiangsu</Option>
                    </Select>
                </Form.Item> */}
            <Form.Item
              name="startTime"
              label="Start Time"
              rules={[{ required: true, message: "Please select time!" }]}
            >
              <DatePicker showTime format="YYYY-MM-DD HH:mm" />
            </Form.Item>
            <Form.Item
              name="endTime"
              label="End Time"
              rules={[{ required: true, message: "Please select time!" }]}
            >
              <DatePicker showTime format="YYYY-MM-DD HH:mm" />
            </Form.Item>

            <Form.Item label="Name" name="name">
              <Input placeholder="Match Name" />
            </Form.Item>

            <Form.Item label="Format" name="format">
              <Select placeholder="Select format">
                {matchFormats?.enumValues?.map((enumValue) => (
                  <Option key={enumValue} value={enumValue}>
                    {enumValue}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name="venue" label="Venue">
              <Select
                placeholder="Select Venue"
                showSearch
                filterOption={(input, option) =>
                  (option?.label?.toLowerCase() ?? "").includes(
                    input.toLowerCase()
                  )
                }
                options={renderVenue()}
              ></Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Duration" name="duration">
              <Input placeholder="Duration in minutes" />
            </Form.Item>

            <Form.Item label="Substitute Count" name="subCount">
              <Input type="number" placeholder="Substitute count" />
            </Form.Item>
            <Form.Item name="tournament" label="Tournament">
              <Select
                placeholder="Select Tournament"
                defaultValue={currentTournament?.tournament?.name}
                showSearch
                filterOption={(input, option) =>
                  (option?.label?.toLowerCase() ?? "").includes(
                    input.toLowerCase()
                  )
                }
                options={renderTournament()}
              ></Select>
            </Form.Item>

            <Form.Item name="tournamentSeason" label="Tournament Season">
              <Select
                placeholder="Select Season"
                defaultValue={seasonId}
                showSearch
                filterOption={(input, option) =>
                  (option?.label?.toLowerCase() ?? "").includes(
                    input.toLowerCase()
                  )
                }
                options={renderTournamentSeason()}
              ></Select>
            </Form.Item>

            <Form.Item name="tournamentRound" label="Tournament Round">
              <Select
                placeholder="Select Round"
                showSearch
                filterOption={(input, option) =>
                  (option?.label?.toLowerCase() ?? "").includes(
                    input.toLowerCase()
                  )
                }
                options={renderTournamentRound()}
              ></Select>
            </Form.Item>

            <Form.Item name="tournamentGroup" label="Tournament Group">
              <Select
                placeholder="Select Group"
                showSearch
                filterOption={(input, option) =>
                  (option?.label?.toLowerCase() ?? "").includes(
                    input.toLowerCase()
                  )
                }
                options={renderTournamentGroup()}
              ></Select>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Space style={{ justifyContent: "right", width: "100%" }}>
              <Button htmlType="button" onClick={onReset}>
                Reset
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                onClick={handleSubmit}
                loading={isSubmitting}
              >
                {record ? "Update" : "Submit"}
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default CreateMatch;
