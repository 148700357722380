import { Col, Row } from "antd"
import MatchEdit from "./blocks/match-edit"
import MatchScheduleEdit from "./blocks/match-schedule-edit"
import TeamEdit from "./blocks/team-edit"

const PreferenceTab = ({ matchData, refresh }) => {

    return (
        <div>
            <Row gutter={[36, 16]}>
                <Col md={12} sm={24}>
                    <h3>Teams</h3>
                    <div style={{ border: '1px solid #c8c8c8', padding: '10px', minHeight: '250px' }} >

                        <TeamEdit record={matchData} />
                    </div>

                </Col>
                <Col md={12} sm={24}>
                    <h3>Schedule</h3>
                    <div style={{ border: '1px solid #c8c8c8', minHeight: '250px', padding: '10px' }}>

                        <MatchScheduleEdit record={matchData} refresh={refresh} />
                    </div>

                </Col>
                <Col span={24}>
                    <h3>Other properties</h3>
                    <div style={{ border: '1px solid #c8c8c8', minHeight: '250px', padding: '10px' }}>
                        <MatchEdit record={matchData} />
                    </div>
                </Col>
            </Row>

        </div>
    )
}

export default PreferenceTab