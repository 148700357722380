import gql from "graphql-tag";

const FIND_ALL_GROUPS_BY_SEASON_ID = gql`
  query findAllGroupsBySeasonId($tournament_season_id: String!) {
    findAllGroupsBySeasonId(tournament_season_id: $tournament_season_id) {
      id
    group_name
    tournament_season {
      id
      name
      start_date
      display_picture
      end_date
    }
    }
  }
`;

export default FIND_ALL_GROUPS_BY_SEASON_ID;

