import { LoadingOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { Button, Card, Col, Space, Spin, Tabs } from "antd";
import { useNavigate, useParams } from "react-router";
import GET_MATCH_BY_ID from "../../api/get_match_by_id";
import GET_TEAM_BY_ID from "../../api/get_team_by_id";

import "./style.scss";
import PlayersTab from "./tabs/players/players-tab";
import LineUpTab from "./tabs/players/players-tab";
import withAuthProtection from "../../utils/auth";
const TeamDetails = ({}) => {
  const { id: teamId } = useParams();
  const { data: teamData, loading: teamLoading } = useQuery(GET_TEAM_BY_ID, {
    variables: { id: teamId },
  });
  const navigate = useNavigate();
  const tabs = [
    {
      key: "1",
      label: `Players`,
      component: <PlayersTab />,
    },
  ];

  return (
    <>
      {teamLoading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
      ) : teamData ? (
        <Card className="team-details">
          <div className="header">
            <Col span={13} style={{ display: "flex", justifyContent: "right" }}>
              <div className="team">
                <img className="logo" src={teamData?.team?.display_picture} />
                <div>{teamData?.team?.name}</div>
              </div>
            </Col>
            <Col
              span={11}
              style={{
                justifyContent: "right",

                display: "flex",
                // alignContent:"right"
              }}
            >
              <Button
                style={{ marginLeft: "30px" }}
                onClick={() => navigate(`/user-role-teams/${teamId}`)}
              >
                Assign roles
              </Button>
            </Col>
          </div>

          <div className="match-detail-tabs">
            <Col span={24}>
              <Tabs defaultActiveKey="1">
                {tabs.map((item) => (
                  <Tabs.TabPane key={item.key} tab={item.label}>
                    {item.component}
                  </Tabs.TabPane>
                ))}
              </Tabs>
            </Col>
          </div>
        </Card>
      ) : (
        "Not found Team"
      )}
    </>
  );
};

export default withAuthProtection(TeamDetails);
