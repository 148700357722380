import axios from "axios";
import * as XLSX from 'xlsx';
import NewLineUp from "../lineups/blocks/new_lineup";
import { Button, Select, Space } from "antd";
import { useState } from "react";
import UserList from "./blocks/user-list";
import withAuthProtection from "../../utils/auth";

const Test = () => {
    const [importedTeamUsers, setImportedTeamUsers] = useState(null);
    const [selectedTeamUsers, setSelectedTeamUsers] = useState([]);
    const [selectedTeamFromExcel, setSelectedTeamFromExcel] = useState(null);
    const { Option } = Select;

    const onFileSelect = (e) => {
        console.log(e.target.files)


        const reader = new FileReader();

        // reader.readAsText(e.target.files[0]);
        // debugger
        if (e.target.files[0] && e.target.files[0]?.name.includes('.xlsx')) {
            reader.readAsBinaryString(e.target.files[0]);
        } else {
            alert('Please select xlsx file')
        }


        reader.onload = evt => {
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws);
            /* Update state */
            console.log("Data>>>", data[0]?.["Player 1"]);

            formatData(data)
        };


    }

    const formatData = (data) => {
        let formattedObject = {};

        data?.forEach((row) => {
            formattedObject[row?.["Team Name"]] = []

            for (let i = 1; i <= 30; i++) {
                if (row?.["Team Name"] && row?.[`Player_${i}_Name`]) {
                    formattedObject[row?.["Team Name"]]?.push({ name: row?.[`Player_${i}_Name`], phone_number: row?.[`Player_${i}_Phone_Number`]?.toString(), position: 'FORWARD', preferred_jersey_no: row?.[`Player_${i}_Jersey_No`] })
                }

            }
        })

        console.log(formattedObject)
        setImportedTeamUsers(formattedObject)
    }

    return (
        <>
            <h2>Bulk user upload</h2>
            <Space>
                <input type="file" id='excel_file_upload' onChange={onFileSelect} style={{ display: 'none' }} />
                <Button onClick={() => document.getElementById('excel_file_upload').click()}>Select Excel file</Button>
                {importedTeamUsers && <Select placeholder="Select team from XL File" allowClear={true} onChange={(value) => {
                    setSelectedTeamFromExcel(value)
                    setSelectedTeamUsers(importedTeamUsers[value])
                }}>
                    {Object?.keys(importedTeamUsers)?.map((item) => {
                        return <Option key={item} value={item}>{item}</Option>
                    })}

                </Select>}
            </Space>

            <div style={{ marginTop: '24px' }}>
                <UserList data={selectedTeamUsers} />
            </div>

        </>)
}

export default withAuthProtection(Test)