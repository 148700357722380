import { gql } from "@apollo/client";

const CREATE_TOURNAMENT_SEASON_GROUP= gql`
mutation createTournamentSeasonGroup($createTournamentSeasonGroupInput: CreateTournamentSeasonGroupInput!) {
  createTournamentSeasonGroup(createTournamentSeasonGroupInput: $createTournamentSeasonGroupInput) {
    id
    group_name
    tournament_season {
      id
      name
      start_date
      display_picture
      end_date
      about_tournament
    }
  }
}

`;

export default CREATE_TOURNAMENT_SEASON_GROUP;
