import React from "react";
import MatchTab from "./match-tab";
import { Button, Card, Col, Row, Select, Spin, Tabs } from "antd";
import { useEffect, useState } from "react";
import MatchTable from "./match-table";

const MatchesTab = ({ seasonId, tournamentId, tournamentData }) => {
  const [activeKey, setActiveKey] = useState(
    localStorage.getItem("activeTab") || "1"
  );

  const tabs = [
    {
      key: "2",
      label: "Matches-Card",
      component: (
        <MatchTab
          seasonId={seasonId}
          tournamentId={tournamentId}
          tournamentsData={tournamentData}
        />
      ),
      //   component: <TableTab />,
    },
    {
      key: "1",
      label: "Matches-Table",
      component: (
        <MatchTable
          seasonId={seasonId}
          tournamentId={tournamentId}
          tournamentsData={tournamentData}
        />
      ),
    },
    // Add more tabs as needed
  ];
  

  const handleTabChange = (key) => {
    setActiveKey(key);
    localStorage.setItem("activeTab", key);
  };
  useEffect(() => {
    const storedKey = localStorage.getItem("activeTab");
    if (storedKey && tabs.some((tab) => tab.key === storedKey)) {
      setActiveKey(storedKey);
    }
  }, [tabs]);

  return (
    <div>
      <Col span={24}>
        <Tabs activeKey={activeKey} onChange={handleTabChange}>
          {tabs.map((item) => (
            <Tabs.TabPane key={item.key} tab={item.label}>
              {item.component}
            </Tabs.TabPane>
          ))}
        </Tabs>
      </Col>
    </div>
  );
};

export default MatchesTab;
