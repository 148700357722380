import gql from "graphql-tag";

const GET_GROUPS_BY_TOURNAMENT_SEASON_ID = gql`
query findAllGroupsBySeasonId($tournament_season_id: String!) {
  findAllGroupsBySeasonId(tournament_season_id: $tournament_season_id) {
    id
    group_name
    tournament_season {
      id
      name
      start_date
      display_picture
      end_date
      about_tournament
    }
    }
  }
`;
export default GET_GROUPS_BY_TOURNAMENT_SEASON_ID;
