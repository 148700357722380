import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import TeamTable from "./components/teams_user_role";


const UserRoleForTeams = () => {
 
  const { id: teamId } = useParams();

  return (
    <>
      <TeamTable teamId={teamId} />
      
    </>
  );
};

export default UserRoleForTeams;
