import { gql } from "@apollo/client";

const CREATE_MATCH= gql`
mutation createMatch($createMatchInput: CreateMatchInput!) {
  createMatch(createMatchInput: $createMatchInput) {
    id
    tournamentSeason {
      id
      name
      start_date
      end_date
      tournament{
        id
      }
    
    }
    venue {
      id
      name
      address
      map_location
      contact_info
    }
    team1 {
      id
      name
      description
      display_picture
    }
    team2 {
      id
      name
      description
      display_picture
    }
   
    format
    duration
    sub_count
    match_stat_duration
  }
}

`;

export default CREATE_MATCH;
