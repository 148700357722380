import {
  BorderBottomOutlined,
  DeleteOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Modal,
  Popconfirm,
  Row,
  Select,
  Upload,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import ADD_IMAGES_TO_TOURNAMENT_SEASON_GALLERY from "../../../../api/add_images_to_tournament_season_gallery";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import DELETE_IMAGES_FROM_TOURNAMENT_SEASON_GALLERY from "../../../../api/delete_images_from_tournament_season_gallery";
import GET_MATCHES_BY_TOURNAMENT_SEASON_ID from "../../../../api/get_matches_by_tournament_season_id";

const AddGalleryImage = ({ selectedSeasonId, onCancel, onSubmit }) => {
  const [form] = Form.useForm();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedMatchId, setSelectedMatchId] = useState(false);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [addGalleryImages, { data, loading, error }] = useMutation(
    ADD_IMAGES_TO_TOURNAMENT_SEASON_GALLERY
  );

  // const handleBeforeUpload = (file) => {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.onload = (event) => {
  //       const img = new Image();
  //       img.src = event.target.result;
  //       img.onload = function() {
  //         const canvas = document.createElement('canvas');
  //         const ctx = canvas.getContext('2d');

  //         let width = img.width;
  //         let height = img.height;

  //         // Set desired aspect ratio (e.g., 1:1)
  //         const aspectRatio = 1;
  //         if (width > height) {
  //           height = width / aspectRatio;
  //         } else {
  //           width = height * aspectRatio;
  //         }

  //         canvas.width = width;
  //         canvas.height = height;

  //         // Rotate image if needed
  //         // You can also handle orientation here if necessary

  //         ctx.drawImage(img, 0, 0, width, height);

  //         canvas.toBlob((blob) => {
  //           resolve(new File([blob], file.name));
  //         }, file.type);
  //       };

  //       reader.readAsDataURL(file);
  //     };

  //     reader.onerror = (error) => {
  //       message.error('Error reading the file. Please try again.');
  //       reject(error);
  //     };
  //   });
  // };

  const onFinish = async (formaValue) => {
    setButtonDisabled(true);
    if (buttonDisabled == true) {
      message.info("This is a normal message");
    }
    setIsImageUploading(true);
    var imagedata = new FormData();
    const files = [];
    // imagedata.append("file", formaValue.display_picture.file);
    if (formaValue?.display_picture?.fileList) {
      formaValue.display_picture.fileList.forEach((file) => {
        imagedata.append("files", file.originFileObj);
      });
    }

    console.log("imagedata", imagedata);
    const response = await fetch(
      process.env.REACT_APP_API_URL + "/media/upload_multiple_files",
      {
        method: "POST",
        body: imagedata,
      }
    );

    // console.log("response", response);
    const fileNames = await response.json();
    console.log("fileNames", fileNames);
    let displayPictures = fileNames.map(
      (fileName) =>
        process.env.REACT_APP_API_URL + "/media/download/" + fileName
    );
    try {
      formaValue = {
        ...formaValue,
        display_picture: displayPictures,
        // fileNames,
      };
      await addGalleryImages({
        variables: {
          tournament_season_id: selectedSeasonId,
          galleryInput: formaValue,
        },
      });
      form.resetFields();
      setIsImageUploading(false);
      onCancel();
      onSubmit();
      setButtonDisabled(false);
    } catch (error) {
      console.error("Error adding picture:", error);
      setIsImageUploading(false);
    }
  };
  const [
    getTournamentMatchesData,
    { data: matchesData, loading: matchesLoading },
  ] = useLazyQuery(GET_MATCHES_BY_TOURNAMENT_SEASON_ID, {
    variables: { season_id: selectedSeasonId },
  });
  useEffect(() => {
    getTournamentMatchesData();
  }, [selectedSeasonId]);
  const selectMatches =
    matchesData?.getMatchesByTournamentSeasonIdWithGroupByTournamentRound[0]?.matches?.map(
      (match) => ({
        key: match?.id,
        value: match?.id,
        description: match?.description,
        label: `${match?.team1?.name} vs ${match?.team2?.name}`,
      })
    );
  const handleMatchSelect = (value) => {
    setSelectedMatchId(value);
  };
  const props = {
    multiple: true,
  };
  return (
    <div class="container">
      <Form form={form} name="basic" onFinish={onFinish}>
        <Form.Item
          label="Upload image"
          name="display_picture"
          rules={[
            {
              required: true,
              message: "Please input your image!",
            },
          ]}
        >
          <Upload
            beforeUpload={(file) => {
              return false;
            }}
            // beforeUpload={handle}
            style={{ width: 100, height: 100, display: "inline-block" }}
            listType="picture-card"
            {...props}
          >
            <div>
              <PlusOutlined />
              <div
                style={{
                  marginTop: 8,
                }}
              >
                Upload
              </div>
            </div>
          </Upload>
        </Form.Item>
        {/* <Form.Item>
          <Select
            name="match_id"
            defaultValue={"Select a match"}
            style={{ width: 300, margin: 5 }}
            options={selectMatches}
            onChange={()=> selectedMatchId}
          />
        </Form.Item> */}
        <Form.Item label="Select a match" name="match_id">
          <Select
            defaultValue=" Select a match"
            name="matchSelect"
            style={{ width: 300, margin: 5 }}
            label="Select a match"
            options={selectMatches}
            onChange={handleMatchSelect}
          ></Select>
        </Form.Item>
        <Form.Item
          wrapperCol={{
            offset: 18,
            span: 20,
          }}
        >
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={buttonDisabled}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddGalleryImage;
