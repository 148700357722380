import React from "react";
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Typography,
  Popconfirm,
  Input,
  Select,
  Space,
  Table,
  Row,
  InputNumber,
} from "antd";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  LoadingOutlined,
  EditOutlined,
  DeleteOutlined,
  SaveOutlined,
  CloseOutlined,
  FormOutlined,
  EditFilled,
  DeleteFilled,
} from "@ant-design/icons";
import GET_MATCHES_BY_TOURNAMENT_SEASON_ID from "../../../api/get_matches_by_tournament_season_id";
import GET_USERS from "../../../api/get_all_users";
import ASSIGN_ROLES_FOR_MATCHES_MUTATION from "../../../api/assign_user_roles_for_matches";
import GET_MATCHES from "../../../api/get_all_matches";
import FIND_USERS_WITH_ROLE_BY_ENTITY from "../../../api/find_users_with_role_by_entity";
import REMOVE_USER_ROLE_FROM_MATCH from "../../../api/remove_user_role_from_match";
import { Roles } from "../constants/const";

const MatchesTable = ({ selectedSeasonId, tournamentId }) => {
  const [form] = Form.useForm();
  const { data: allUsers, loading } = useQuery(GET_USERS);
  const { data: allMatches, loading: allMatchLoading } = useQuery(GET_MATCHES);
  const { data: matchesData, loading: matchesLoading } = useQuery(
    GET_MATCHES_BY_TOURNAMENT_SEASON_ID,
    {
      variables: { season_id: selectedSeasonId },
    }
  );
  const [
    assignUserRoleMatches,
    {
      data: assignUserRoleMatchData,
      loading: assignUserRoleMatchDataLoading,
      error: assignUserRoleMatchDataError,
    },
  ] = useMutation(ASSIGN_ROLES_FOR_MATCHES_MUTATION);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const {
    data: usersWithMatchRoleData,
    loading: usersWithMatchRoleLoading,
    refetch,
    error,
  } = useQuery(FIND_USERS_WITH_ROLE_BY_ENTITY, {
    variables: {
      args: {
        entityId: selectedMatch,
        entityType: "MATCH",
      },
    },
  });

  const [
    deleteUserRoleMatch,
    { loading: deleteUserRoleMatchLoading, error: deleteUserRoleMatchError },
  ] = useMutation(REMOVE_USER_ROLE_FROM_MATCH);

  const [selectedMatchTeam, setSelectedMatchTeam] = useState(null);
  const [editingKey, setEditingKey] = useState("");
  const [matchDataSource, setMatchDataSource] = useState([]);
  const isEditing = (record) => record.key === editingKey;
  const { Option } = Select;

  useEffect(() => {
    if (usersWithMatchRoleData?.findUsersWithRoleByEntity) {
      const newDataSource =
        usersWithMatchRoleData.findUsersWithRoleByEntity.map((user, index) => ({
          key: index + 1,
          userId: user.id,
          user: user.name,
          role: user.role,
        }));
      setMatchDataSource(newDataSource);
    }
  }, [usersWithMatchRoleData]);

  useEffect(() => {
    if (matchesData && matchesData.matches && matchesData.matches.length > 0) {
      setSelectedMatchTeam({
        team1: matchesData.matches[0]?.team1,
        team2: matchesData.matches[0]?.team2,
      });
      setSelectedMatch(matchesData.matches[0]?.id);
    } else {
      setSelectedMatchTeam(null);
      setSelectedMatch(null);
    }
  }, [matchesData]);

  const MatchesColumn = [
    {
      title: "#",
      width: "3%",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "User",
      width: "40%",
      dataIndex: "user",
      key: "user",
      editable: true,
      onCell: (record) => ({
        record,
        dataIndex: "user",
        title: "User",
        editing: isEditing(record),
      }),
    },
    {
      title: "Role",
      width: "40%",
      dataIndex: "role",
      key: "role",
      editable: true,
      onCell: (record) => ({
        record,
        dataIndex: "role",
        title: "Role",
        editing: isEditing(record),
      }),
    },
    {
      title: "Operation",
      dataIndex: "operation",
      width: 120,
      render: (_, record) => {
        if (record.role === "CREATOR") {
          return null;
        }
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => handleSubmitMatch(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              <SaveOutlined />
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <CloseOutlined />
            </Popconfirm>
          </span>
        ) : (
          <Space>
            <Typography.Link onClick={() => edit(record)}>
              <EditOutlined />
            </Typography.Link>
            <Typography.Link>
              <Popconfirm
                title="Delete"
                onConfirm={() =>
                  handleDelete(record.key, matchDataSource, setMatchDataSource)
                }
              >
                <DeleteOutlined style={{ marginLeft: "8px" }} />
              </Popconfirm>
            </Typography.Link>
          </Space>
        );
      },
    },
  ];

  const UserDropdown = () => (
    <Form.Item
      name="user"
      rules={[{ required: true, message: "Please input user!" }]}
    >
      <Select
        showSearch
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onChange={(value) => form.setFieldsValue({ user: value })}
      >
        {allUsers?.users?.map((user) => (
          <Option key={user.id} value={user.id}>
            {user.name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );

  const RoleDropdown = () => (
    <Form.Item
      name="role"
      rules={[{ required: true, message: "Please input role!" }]}
    >
      <Select
        showSearch
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onChange={(value) => form.setFieldsValue({ role: value })}
      >
        {Object.entries(Roles).map(([key, value]) => (
          <Option key={key} value={value}>
            {value}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    let inputNode;

    if (dataIndex == "user") {
      inputNode = UserDropdown();
    } else if (dataIndex == "role") {
      inputNode = RoleDropdown();
    } else {
      inputNode =
        inputType === "number" ? (
          <InputNumber pattern="[0-9]*" inputmode="numeric" />
        ) : (
          <Input />
        );
    }

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const handleAdd = () => {
    const newKey = matchDataSource.length + 1;
    form.resetFields();
    const newRow = {
      key: newKey,
      user: "",
      role: "",
      isNew: true,
    };
    setMatchDataSource((prevDataSource) => [...prevDataSource, newRow]);
    setEditingKey(newKey);
  };

  const onChange = (value) => {
    setSelectedMatch(value);
    const match = allMatches?.matches?.find((object) => object?.id == value);

    if (match) {
      setSelectedMatchTeam({ team1: match?.team1, team2: match?.team2 });
    }
  };

  const onSearch = (value) => {
    setSelectedMatch(value);
  };

  const matches =
    matchesData?.getMatchesByTournamentSeasonIdWithGroupByTournamentRound[0]
      ?.matches;
  const selectMatches = Array.isArray(matches)
    ? matches.map((match) => ({
        key: match?.id,
        value: match?.id,
        description: match?.description,
        label: `${match?.team1?.name} vs ${match?.team2?.name}`,
      }))
    : [];

  const handleSubmitMatch = async (key) => {
    const formValue = form.getFieldsValue();
    try {
      const input = {
        userIds: [formValue.user],
        role: formValue.role,
        entityId: selectedMatch,
      };
      const response = await assignUserRoleMatches({
        variables: { input },
      });
      const row = await form.validateFields();
      const selectedUser = allUsers.users.find((u) => u.id === formValue.user);
      const userName = selectedUser ? selectedUser.name : null;

      const updatedData = { ...formValue, user: userName };
      const newData = [...matchDataSource];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        newData[index] = { ...newData[index], ...updatedData };
        setMatchDataSource(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setMatchDataSource(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const handleDeleteUserRole = async (userId, entityId) => {
    try {
      await deleteUserRoleMatch({
        variables: {
          input: { userId, entityId },
        },
      });
      refetch();
    } catch (error) {
      console.error("Error deleting user role:", error);
    }
  };

  const handleDelete = (key) => {
    const record = matchDataSource.find((item) => item.key === key);
    if (record) {
      handleDeleteUserRole(record.userId, selectedMatch);
    }
  };

  const cancel = () => {
    const newData = matchDataSource
      .map((item) => {
        if (item.isNew && editingKey === item.key) {
          return null;
        }
        return item;
      })
      .filter((item) => item != null);

    setMatchDataSource(newData);
    setEditingKey("");
    form.resetFields();
  };

  const edit = (record) => {
    form.setFieldsValue({
      user: "",
      role: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  return (
    <div>
      <Row style={{ marginTop: "50px" }}>
        <Col span={16}>
          <Select
            showSearch
            placeholder="Select a match"
            optionFilterProp="children"
            defaultValue={selectedMatch}
            onChange={onChange}
            onSearch={onSearch}
            style={{
              minWidth: "180px",
              maxWidth: "400px",
            }}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={selectMatches}
          />
        </Col>

        <Col span={8} style={{ textAlign: "right", marginBottom: "8px" }}>
          <Space>
            <Button onClick={handleAdd}>Add New</Button>
          </Space>
        </Col>
        <Col span={24}>
          <Form form={form} onFinish={handleSubmitMatch} component={false}>
            <Table
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              bordered
              dataSource={matchDataSource}
              columns={MatchesColumn}
              pagination={false}
              scroll={{ y: 300, x: "auto" }}
            />
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default MatchesTable;
