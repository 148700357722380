import { gql } from "@apollo/client";

const GET_MATCH_TIMER = gql`
query getMatchTimer($match_id: String!) {
  getMatchTimer(match_id: $match_id) {
    id
    duration
    extra_time
    isShootout
    time_elapsed
    time_elapsed_HT
    time_elapsed_FT
    extra_time_elapsed_HT
    extra_time_elapsed_FT
    match_status
    match_stage
    play_status
    match {
      id
      team1_color
      team2_color
      format
      duration
      sub_count
      match_stat_duration
      streaming_url
      created_at
      updated_at
    }
    ticker_duration
  }
}
`;

export default GET_MATCH_TIMER;
