import gql from "graphql-tag";

const GET_TEAM_BY_ID = gql`
query team($id: String!) {
  team(id: $id) {
    id
    name
    description
    display_picture
    
  }
}
`;
export default GET_TEAM_BY_ID;
