import { Button, Checkbox, Form, Input, Upload, message } from "antd";
import { useMutation } from "@apollo/client";
import SponsorsTab from "./sponsorsTab";
import {
  DeleteOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import axios, { Axios } from "axios";
import ADD_SPONSORS_TO_TOURNAMENT_SEASON_MUTATION from "../../../../api/add_sponsors_to_tournament_seasons";


const AddNewSponsor = ({ selectedSeasonId, onCancel, onSubmit }) => {
  const [form] = Form.useForm();
  const [addSponsordata, { data, loading, error }] = useMutation(
    ADD_SPONSORS_TO_TOURNAMENT_SEASON_MUTATION
  );

  const onFinish = async (formaValue) => {
    var data = new FormData();
    data.append("file", formaValue.display_picture.file);
    const response = await fetch(
      process.env.REACT_APP_API_URL + "/media/upload",
      {
        method: "POST",
        body: data,
      }
    );

    const fileName = await response.text();
    try {
      formaValue = {
        ...formaValue,
        display_picture:
          process.env.REACT_APP_API_URL + "/media/download/" + fileName,
      };
      await addSponsordata({
        variables: {
          tournament_season_id: selectedSeasonId,
          sponsorInput: formaValue,
        },
      });
      form.resetFields();
      onCancel();
      onSubmit();
    } catch (error) {
      console.error("Error adding sponsor:", error);
    }
  };

  return (
    <Form
      form={form}
      name="basic"
      onFinish={onFinish}
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      style={{
        maxWidth: 600,
      }}
      initialValues={{
        remember: true,
      }}
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[
          {
            required: true,
            message: "Please input your name!",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Description"
        name="description"
        rules={[
          {
            required: true,
            message: "Please input your description!",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Upload image"
        name="display_picture"
        rules={[
          {
            required: true,
            message: "Please input your image!",
          },
        ]}
      >
        <Upload
          beforeUpload={(file) => {
            return false;
          }}
          listType="picture-card"
          maxCount={1}
        >
          <div>
            <PlusOutlined />
            <div
              style={{
                marginTop: 8,
              }}
            >
              Upload
            </div>
          </div>
        </Upload>
      </Form.Item>
      <Form.Item
        label="Link"
        name="link"
        rules={[
          {
            required: true,
            message: "Please input your link!",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};
export default AddNewSponsor;
