import {
  DeleteFilled,
  DeleteOutlined,
  EditFilled,
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Avatar, Card, Col, Image, Popconfirm, Row, Space } from "antd";
import Meta from "antd/es/card/Meta";
import "./style.scss";
import { useNavigate } from "react-router";

const Team = ({ data, onClick, onEdit, onDelete, controls, seasonId }) => {
  const navigate = useNavigate();
  const onTeamClick = (teamId) => {
    navigate(`/tournament-squad/${teamId}/${seasonId}`);
  };
  return (
    <Row className="team-tab-wrapper">
      <Col className="team-card-column">
        <Card
          className="team-card"
          onClick={(e) => {
            onTeamClick(data?.id);
          }}
          actions={[
            controls?.delete && (
              <Popconfirm
                key="delete"
                title="Delete the team"
                icon={<DeleteOutlined />}
                description="Are you sure to delete this team?"
                onConfirm={(event) => {
                  event.stopPropagation();
                  onDelete(data);
                }}
                onCancel={(event) => event.stopPropagation()}
                okText="Yes"
                cancelText="No"
              >
                <DeleteFilled
                  style={{
                    cursor: "pointer",
                    color: "#4f5453",
                    fontSize: "15px",
                  }}
                  onClick={(event) => event.stopPropagation()}
                />
              </Popconfirm>
            ),
            controls?.edit && (
              <EditFilled
                style={{
                  cursor: "pointer",
                  color: "#4f5453",
                  fontSize: "15px",
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  onEdit(data);
                }}
              />
            ),
          ]}
        >
          {/* <Avatar className="team-avatar"> */}
          <img
            className="card-image"
            src={data?.display_picture}
            preview={{
              src: data?.display_picture,
            }}
          />
          {/* </Avatar> */}
          <Row className="card-name">
            <Meta title={data?.name} />
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default Team;
