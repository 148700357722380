import { gql } from "@apollo/client";

const REMOVE_MATCH_LINE_UP_BY_ID = gql`
  mutation removeMatchLineupById($lineup_id: String!) {
    removeMatchLineupById(lineup_id: $lineup_id) {
      id
    match {
      id
      format
      duration
      sub_count
      match_stat_duration
      streaming_url
      created_at
      updated_at
    }
    team {
      id
      name
      description
      display_picture
    }
    teamPlayer {
      id
      join_date
      leave_date
      status
    }
    jersey_no
    position
    status
    }
  }
`;

export default REMOVE_MATCH_LINE_UP_BY_ID;
