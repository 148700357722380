import { gql } from "@apollo/client";

const UPDATE_LINEUP_JERSEY_NUMBER = gql`
 mutation updateLineupJerseyNumber($matchId: String!, $input: [JerseyNumberInput!]!) {
    updateLineUpJerseyNumber(
      LineupJerseyNumberUpdate: {
        matchId: $matchId,
        input: $input,
      }
    ) {
      id
      match {
        id
        team1_color
        team2_color
        format
        duration
        sub_count
        match_stat_duration
        streaming_url
        created_at
        updated_at
      }
      team {
        id
        name
        description
        display_picture
      }
      teamPlayer {
        id
        join_date
        leave_date
        status
      }
      jersey_no
      x_position
      y_position
      position
      status
    }
  }
`;


export default UPDATE_LINEUP_JERSEY_NUMBER;
