import { gql } from "@apollo/client";

const ASSIGN_ROLES_FOR_TOURNAMENT_MUTATION = gql`
  mutation AssignRolesToMultipleUsersForTournaments(
    $input: AssignRolesToUsersInput!
  ) {
    assignRolesToMultipleUsersForTournaments(input: $input)
  }
`;

export default ASSIGN_ROLES_FOR_TOURNAMENT_MUTATION;
