import { gql } from "@apollo/client";

const DELETE_TOURNAMENT_SEASON_SQUAD = gql`
  mutation DeleteTournamentSeasonSquad($deleteTournamentSeasonSquadInput: DeleteTournamentSeasonSquadInput!) {
    deleteTournamentSeasonSquad(deleteTournamentSeasonSquadInput: $deleteTournamentSeasonSquadInput) {
      season_id
      team_id
      position
      jerseyNo
      status
    }
  }
`;

export default DELETE_TOURNAMENT_SEASON_SQUAD;
