import { gql } from "@apollo/client";

const RUN_MATCH_AGGREGATION_MUTATION = gql`
mutation runMatchAggregation($match_id: String!) {
  runMatchAggregation(match_id: $match_id) {
    id
    winner
    team1Goal
    team2Goal
    team1PenaltyGoal
    team2PenaltyGoal
    match_events {
      id
      time
      event
      extra_time_elapsed
      commentary
      match_stage
    }
    match {
      id
    }
  }
}

`;

export default RUN_MATCH_AGGREGATION_MUTATION ;
