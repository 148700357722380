import { gql } from 'graphql-tag';

const GET_TOURNAMENT_SEASON_SPONSORS_QUERY = gql`
  query GetTournamentSeasonSponsors($tournament_season_id: String!) {
    getTournamentSeasonSponsors(tournament_season_id: $tournament_season_id) {
      id
      name
      display_picture
      description
      link
    }
  }
`;

export default GET_TOURNAMENT_SEASON_SPONSORS_QUERY;
