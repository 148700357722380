// src/components/Login.js

import React, { useState } from "react";
import { Form, Input, Button, notification } from "antd";
import { useNavigate } from "react-router";

const NameField = ({onSubmit}) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="">
      <h3 style={{ textAlign: "center", marginBottom: "24px" }}>Enter your full name</h3>
      <Form onFinish={onSubmit}>
        <Form.Item
          label="Full Name"
          name="fullName"
          rules={[{ required: true, message: "Please enter your Full Name." }]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button style={{marginTop: '10px'}} type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default NameField;
