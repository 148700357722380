import axios from 'axios';

export async function uploadS3Image(file) {
  const data = new FormData();
  data.append('file', file, file.name);
  return axios.post(`${process.env.REACT_APP_API_URL}/media/upload`, data, {
    headers: {
      'Content-Type': file.type
    },
  });
}   