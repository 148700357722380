import React from "react";
import GET_TEAM_BY_ID from "../../api/get_team_by_id";
import { useQuery } from "@apollo/client";
import { Card, Col, Space, Spin, Tabs } from "antd";
import { useParams } from "react-router";
import { LoadingOutlined } from "@ant-design/icons";
import SquadTab from "./squad-tab";
import withAuthProtection from "../../utils/auth";

const TournamentSquad = ({}) => {
  const { teamId: teamId } = useParams();
  const { seasonId: seasonId } = useParams();
  const { data: teamData, loading: teamLoading } = useQuery(GET_TEAM_BY_ID, {
    variables: { id: teamId },
  });
  const tabs = [
    {
      key: "1",
      label: `Squad`,
      component: <SquadTab seasonId={seasonId} teamId={teamId} />,
    },
  ];

  return (
    <div>
      {teamLoading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
      ) : teamData ? (
        <Card className="team-details">
          <div className="header">
            <Space size={132}>
              <div className="team">
                <img className="logo" src={teamData?.team?.display_picture} />
                <div>{teamData?.team?.name}</div>
              </div>
            </Space>
          </div>

          <div className="match-detail-tabs">
            <Col span={24}>
              <Tabs defaultActiveKey="1">
                {tabs.map((item) => (
                  <Tabs.TabPane key={item.key} tab={item.label}>
                    {item.component}
                  </Tabs.TabPane>
                ))}
              </Tabs>
            </Col>
          </div>
        </Card>
      ) : (
        "Not found Team"
      )}
    </div>
  );
};

export default withAuthProtection(TournamentSquad);
