import { gql } from "@apollo/client";

const CREATE_MATCH_EVENT= gql`
mutation createMatchEvent($createMatchEventInput: CreateMatchEventInput!) {
  createMatchEvent(createMatchEventInput: $createMatchEventInput) {
    id
    time
    event
    player {
      id
      name
      email
      phone_number
      display_picture
      position
      preferred_jersey_no
      status
      teamPlayer {
        id
      }
    }
    team {
      id
      name
      description
      display_picture
     
    }
    match {
      id
      tournamentSeason {
        id
      }
      venue {
        id
      }
      team1 {
        id
      }
      team2 {
        id
      }
      format
      duration
      sub_count
      match_stat_duration
      streaming_url
      schedule_slot {
        id
      }
      match_schedule {
        id
      }
      aggregated_stats {
        id
      }
      created_at
      updated_at
      tournament_round {
        id
      }
      tournament_season_group {
        id
      }
      timer {
        id
      }
    }
    time_elapsed
    extra_time_elapsed
    commentary
    match_stage
    event_meta {
      id
      area_of_play_start
      area_of_play_end
      body_part
      action_type
      association_type
      associated_player {
        id
      }
      associated_team {
        id
      }
      match_event {
        id
      }
    }
  }
}
`;

export default CREATE_MATCH_EVENT;
