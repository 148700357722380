import React from "react";

const MatchStats = () => {

    
  return (
    <div>
      <h2>About</h2>
      <p>This is a page about our goalrush and what we do.</p>
    </div>
  );
};

export default MatchStats;
