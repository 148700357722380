import { gql } from "@apollo/client";

const UPDATE_MATCH_EVENT_META_BY_ID = gql`
  mutation updateMatchEventMetaData($event_meta_id: String!, $updateMatchEventInput: UpdateMatchEventMetaData!) {
    updateMatchEventMetaData(event_meta_id: $event_meta_id, updateMatchEventInput:$updateMatchEventInput) {
      id
    area_of_play_start
    area_of_play_end
    body_part
    action_type
    association_type
    associated_player {
      id
      name
      email
      phone_number
      display_picture
      position
      preferred_jersey_no
      status
    }
    associated_team {
      id
      name
      description
      display_picture
    }
    match_event {
      id
      time
      event
      time_elapsed
      extra_time_elapsed
      commentary
      match_stage
    }
    }
  }
`;

export default UPDATE_MATCH_EVENT_META_BY_ID;
