import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Avatar,
  Card,
  Col,
  Image,
  Popconfirm,
  Radio,
  Row,
  Segmented,
  Space,
  message,
} from "antd";
import dayjs from "dayjs";

import "./style.scss";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import UPDATE_TOURNAMENT_SEASON from "../../api/update_tournament_season";

const TournamentSeason = ({ data, onEdit, onDelete, controls }) => {
  const [status, setStatus] = useState(data?.status);

  const [
    updateSeasonById,
    {
      data: updateSeasonData,
      loading: updateSeasonLoading,
      error: updateSeasonError,
    },
  ] = useMutation(UPDATE_TOURNAMENT_SEASON);

  const onTournamentStatusChange = (value) => {
    const payload = { status: value };
    updateSeasonById({
      variables: {
        tournament_season_id: data?.id,
        updateTournamentSeasonInput: {
          ...payload,
        },
      },
    })
      .then((res) => {
        setStatus(value);
        message.success({
          type: "success",
          content: "Season Updated",
        });
      })
      .catch((err) => {
        message.error({
          type: "error",
          content: err.message,
        });
      });
  };

  const Title = ({ data }) => {
    return (
      <Row>
        <Col span={12}>
          {data?.display_picture && (
            // <Avatar src={data?.display_picture} style={{ marginRight: '8px' }} />
            <Image
              width={32}
              height={32}
              style={{ borderRadius: "50%" }}
              src={data?.display_picture}
              preview={{
                src: data?.display_picture,
              }}
            />
          )}
          <span style={{ marginLeft: "8px" }}>{data?.name}</span>
        </Col>
        <Col span={12} style={{ textAlign: "right", margin: "auto" }}>
          <Space>
            {controls?.delete && (
              <Popconfirm
                title="Delete the team"
                icon={<DeleteOutlined />}
                description="Are you sure to delete this team?"
                onConfirm={() => onDelete(data)}
                // onCancel={cancel}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined
                  style={{ cursor: "pointer", color: "red", fontSize: "18px" }}
                />
              </Popconfirm>
            )}

            {controls?.edit && (
              <EditOutlined
                style={{ cursor: "pointer", color: "blue", fontSize: "18px" }}
                onClick={() => {
                  onEdit(data);
                }}
              />
            )}
          </Space>
        </Col>
      </Row>
    );
  };

  return (
    <Card
      style={{ minHeight: "150px", cursor: "pointer" }}
      title={<Title data={data} />}
    >
      <Segmented
        value={status}
        onChange={onTournamentStatusChange}
        options={[
          {
            label: "Draft",
            value: "DRAFT",
          },
          {
            label: "Published",
            value: "PUBLISHED",
          },
        ]}
      />
    </Card>
  );
  // return (

  //     <Card className="tournament-season">
  //         <Row>
  //             <Col span={24}>
  //                 {data?.team1?.display_picture &&
  //                     <Avatar src={data?.team1?.display_picture} />
  //                     // <Image
  //                     //     width={48}
  //                     //     height={48}
  //                     //     style={{ borderRadius: '50%' }}
  //                     //     src={data?.team1?.display_picture}
  //                     //     preview={{
  //                     //         src: data?.team1?.display_picture,
  //                     //     }}
  //                     // />
  //                 }
  //                 <div className="season-name">{data?.name}</div>
  //                 <Row>
  //                     <Col span={12} style={{textAlign: 'left'}}>{data?.start_date && dayjs(data?.start_date).format("YYYY-MM-DD HH:mm:ss")}</Col>
  //                     <Col span={12} style={{textAlign: 'right'}}>{data?.end_date && dayjs(data?.end_date).format("YYYY-MM-DD HH:mm:ss")}</Col>
  //                 </Row>
  //             </Col>
  //         </Row>
  //     </Card>
  // )
};

export default TournamentSeason;
