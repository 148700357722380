import { useMutation, useQuery } from "@apollo/client";
import { Button, Alert, Col, Table, Checkbox, Avatar, Select, DatePicker, Form, Row, Space } from "antd"
import { useState } from "react";
import { useEffect } from "react";
import UPDATE_TEAM_PLAYER_BY_ID from "../../../api/update_team_player_by_id";
import dayjs from "dayjs";

const EditTeamPlayer = ({ record, refresh, close }) => {
    const [updateTeamPlayerById, { data: updateTeamPlayerData, loading: updateTeamPlayerLoading, error: updateTeamPlayerError }] = useMutation(UPDATE_TEAM_PLAYER_BY_ID);
    const { Option } = Select;
    const [statusMessage, setStatusMessage] = useState(null);
    const [form] = Form.useForm();

    useEffect(() => {
        if (record) {
            form.setFieldsValue({
                join_date: record?.join_date ? dayjs(record?.join_date, 'YYYY-MM-DD HH:mm:ss[Z]') : null,
                leave_date: record?.leave_date ? dayjs(record?.leave_date, 'YYYY-MM-DD HH:mm:ss[Z]') : null,
                status: record?.status
            })
        } else {
            form.resetFields()
        }
    }, [record])

    const onFinish = async (formValue) => {
        if (record) {
            updateTeamPlayer(formValue)
        }
    }

    const updateTeamPlayer = (formValue) => {
        const join_date = formValue?.join_date ? formValue?.join_date?.utc()?.format("YYYY-MM-DDTHH:mm:ss[Z]") : null;
        const leave_date = formValue?.leave_date ? formValue?.leave_date?.utc()?.format("YYYY-MM-DDTHH:mm:ss[Z]"): null;
        updateTeamPlayerById({
            variables: {
                team_player_id: record?.key,
                updateTeamPlayerInput: {
                    ...formValue,
                    join_date,
                    leave_date
                }
            },
        }).then((res) => {
            setStatusMessage({
                type: 'success',
                content: 'Team player Updated',
            });

            refresh();
            onReset();
            close();
        }).catch((err) => {
            setStatusMessage({
                type: 'error',
                content: err.message,
            });
        })
    }

    const onReset = () => {
        form?.resetFields();
    };

    const onClose = () => {
        setStatusMessage(null)
    };

    return (
        <>
            {statusMessage && <Alert message={statusMessage?.content} type={statusMessage?.type} showIcon closable onClose={onClose} />}
            
            <h3 style={{textAlign:'center', marginTop: '24px'}}>{record?.name}</h3>
            <Form
                form={form}
                name="complex-form"
                onFinish={onFinish}
                style={{ marginTop: '24px' }}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 15 }}
            >
                <Row gutter={4}>
                    <Col span={24}>
                        <Form.Item
                            label="Join Date"
                            name="join_date"
                        >
                            <DatePicker />
                        </Form.Item>

                        <Form.Item
                            label="Leave Date"
                            name="leave_date"
                        >
                            <DatePicker />
                        </Form.Item>

                        <Form.Item
                            label="Status"
                            name="status"
                        >
                            <Select>
                                <Option key="active">Active</Option>
                                <Option key="inactive">InActive</Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Space style={{ justifyContent: 'right', width: '100%' }}>
                            <Button htmlType="button" onClick={onReset}>
                                Reset
                            </Button>
                            <Button type="primary" htmlType="submit" loading={updateTeamPlayerLoading}>
                                {record ? 'Update' : 'Submit'}
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default EditTeamPlayer