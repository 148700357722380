import { Button, Form, Modal, Select, Upload } from "antd";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import GET_MATCHES_BY_TOURNAMENT_SEASON_ID from "../../../../api/get_matches_by_tournament_season_id";
import UPDATE_IMAGES_FROM_TOURNAMENT_SEASON_GALLERY_MUTATION from "../../../../api/update_images_from_tournament_season_gallery_mutation";

const UpdateGalleryImage = ({
  gallery,
  onCancel,
  onSubmit,
  onOk,
  selectedSeasonId,
}) => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedMatchId, setSelectedMatchId] = useState();
  const [updateGalleryImage, { data, loading, error }] = useMutation(
    UPDATE_IMAGES_FROM_TOURNAMENT_SEASON_GALLERY_MUTATION
  );
  const [
    getTournamentMatchesData,
    { data: matchesData, loading: matchesLoading },
  ] = useLazyQuery(GET_MATCHES_BY_TOURNAMENT_SEASON_ID);

  useEffect(() => {
    getTournamentMatchesData({
      variables: {
        season_id: selectedSeasonId,
      },
    });
  }, [selectedSeasonId]);

  const allMatches = [];
  matchesData?.getMatchesByTournamentSeasonIdWithGroupByTournamentRound?.forEach(
    (r) =>
      r?.matches?.forEach((match) => {
        allMatches.push({
          key: match?.id,
          value: match?.id,
          label: `${match?.team1?.name} vs ${match?.team2?.name}`,
        });
      })
  );
  const handleMatchSelect = (value) => {
    setSelectedMatchId(value);
  };

  const onFinish = async (formaValue) => {
    var imagedata = new FormData();
    imagedata.append("file", formaValue.display_picture.file);
    const response = await fetch(
      process.env.REACT_APP_API_URL + "/media/upload",
      {
        method: "POST",
        body: imagedata,
      }
    );
    const fileName = await response.text();
    try {
      formaValue = {
        ...formaValue,
        display_picture:
          process.env.REACT_APP_API_URL + "/media/download/" + fileName,
      };
      await updateGalleryImage({
        variables: {
          gallery_id: gallery.id,
          updateGalleryInput: formaValue,
        },
      });
      form.resetFields();
      onCancel();
      onSubmit();
      onOk();
    } catch (error) {
      console.error("Error adding picture:", error);
    }
  };

  return (
    <Form form={form} name="basic" onFinish={onFinish}>
      <Form.Item
        label="Edit image"
        name="display_picture"
        rules={[
          {
            required: true,
            message: "Add new image!",
          },
        ]}
      >
        <Upload
          beforeUpload={(file) => {
            return false;
          }}
          listType="picture-card"
          maxCount={1}
        >
          <div>
            <PlusOutlined />
            <div
              style={{
                marginTop: 8,
              }}
            >
              Upload
            </div>
          </div>
        </Upload>
      </Form.Item>
      <Form.Item label="Select a match">
        <Select
          defaultValue={gallery?.match_id}
          name="matchSelect"
          style={{ width: 300, margin: 5 }}
          label="Selectamatch"
          options={allMatches}
          onChange={handleMatchSelect}
        ></Select>
      </Form.Item>
      <Button type="primary" htmlType="submit" loading={loading}>
        Submit
      </Button>
    </Form>
  );
};

export default UpdateGalleryImage;
