import { gql } from "@apollo/client";

const ASSIGN_ROLES_FOR_MATCHES_MUTATION = gql`
  mutation AssignRolesToMultipleUsersForMatches(
    $input: AssignRolesToUsersInput!
  ) {
    assignRolesToMultipleUsersForMatches(input: $input)
  }
`;

export default ASSIGN_ROLES_FOR_MATCHES_MUTATION;
