import gql from "graphql-tag";

const FIND_USERS_WITH_ROLE_BY_ENTITY = gql`
  query findUsersWithRoleByEntity($args: FindUsersWithRoleByEntityDTO!) {
    findUsersWithRoleByEntity(args: $args) {
      id
      name
      display_picture
      role
    }
  }
`;

export default FIND_USERS_WITH_ROLE_BY_ENTITY;
