import { LoadingOutlined } from "@ant-design/icons";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Col, List, Modal, Row, Spin, Button, message, Input } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import DELETE_TEAM_FROM_TOURNAMENT_SEASON from "../../../../api/delete_team_from_season";
import GET_TEAMS_BY_TOURNAMENT_SEASON_ID from "../../../../api/get_teams_by_season_id";
import Team from "../../../../components/team";
import AddTeamToSeason from "../blocks/add_team_to_season";
import "./style.scss";

const TeamTab = ({ selectedSeasonId, tournamentId }) => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [teamsByGroup, setTeamsByGroup] = useState(null);
  const [nonGroupTeams, setNonGroupTeams] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const navigate = useNavigate();

  const [
    getTournamentSeasonTeams,
    {
      data: tournamentSeasonsTeams,
      loading: tournamentSeasonTeamsLoading,
      refetch,
    },
  ] = useLazyQuery(GET_TEAMS_BY_TOURNAMENT_SEASON_ID, {
    variables: {
      tournament_season_id: selectedSeasonId,
    },
  });
  const [
    deleteTeamFromSeason,
    { data: deleteSeasonTeamData, loading: deleteSeasonTeamDataLoading },
  ] = useMutation(DELETE_TEAM_FROM_TOURNAMENT_SEASON);

  useEffect(() => {
    if (selectedSeasonId) {
      getTournamentSeasonTeams();
    }
  }, [tournamentId, selectedSeasonId]);

  useEffect(() => {
    formatTeamsByGroup();
  }, [tournamentSeasonsTeams]);

  const formatTeamsByGroup = () => {
    let formattedData = {};
    let nonGroupTeamList = [];

    tournamentSeasonsTeams?.findSeasonTeamsBySeasonId?.map((team) => {
      if (team?.tournament_season_group) {
        if (
          !formattedData?.hasOwnProperty(
            team?.tournament_season_group?.group_name
          )
        ) {
          formattedData[team?.tournament_season_group?.group_name] = [team];
        } else {
          formattedData[team?.tournament_season_group?.group_name].push(team);
        }
      } else {
        nonGroupTeamList.push(team);
      }
    });

    setTeamsByGroup(formattedData);
    setNonGroupTeams(nonGroupTeamList);
  };

  const onDelete = (seasonTeamId) => {
    deleteTeamFromSeason({
      variables: {
        tournament_season_team_id: seasonTeamId,
      },
    })
      .then((resp) => {
        messageApi.open({
          type: "success",
          content: "Team removed from Season",
        });

        refetch();
      })
      .catch((err) => {
        messageApi.open({
          type: "error",
          content: err?.message || "Something went wrong!",
        });
      });
  };

  const onEdit = (record) => {
    setIsCreateModalOpen(true);
    setSelectedRecord(record);
  };

  const showModal = () => {
    setIsCreateModalOpen(true);
  };

  const handleCancel = () => {
    setIsCreateModalOpen(false);
    setSelectedRecord(null);
  };

  const onSearchTeams = (event) => {
    let data;
    let formattedData = {};
    let nonGroupTeamList = [];

    if (event?.target?.value) {
      data = tournamentSeasonsTeams?.findSeasonTeamsBySeasonId?.filter(
        (record) =>
          record?.team?.name
            ?.toLowerCase()
            .includes(event?.target?.value?.toLowerCase())
      );
    } else {
      data = tournamentSeasonsTeams?.findSeasonTeamsBySeasonId;
    }

    const list = [];

    data.map((record) => {
      list.push({
        ...record,
        key: record?.id,
      });
    });

    data?.map((team) => {
      if (team?.tournament_season_group) {
        if (
          !formattedData?.hasOwnProperty(
            team?.tournament_season_group?.group_name
          )
        ) {
          formattedData[team?.tournament_season_group?.group_name] = [team];
        } else {
          formattedData[team?.tournament_season_group?.group_name].push(team);
        }
      } else {
        nonGroupTeamList.push(team);
      }
    });

    setTeamsByGroup(formattedData);
    setNonGroupTeams(nonGroupTeamList);
  };

  return (
    <>
      {selectedSeasonId ? (
        <div>
          {contextHolder}
          <Row style={{ marginBottom: "24px" }}>
            <Col span={5}>
              <Input placeholder="Search Team" onChange={onSearchTeams} />
            </Col>
            <Col span={19} style={{ textAlign: "right" }}>
              <Button type="primary" onClick={showModal}>
                Add New
              </Button>
            </Col>
          </Row>
          {tournamentSeasonTeamsLoading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            </div>
          ) : (
            teamsByGroup && (
              <div className="teams">
                {Object.keys(teamsByGroup)?.map((key) => {
                  // {teamsByGroup.map((key) => {
                  return (
                    <>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <h2>Group {key}</h2>
                        <Row className="team-rows-wrapper">
                          {teamsByGroup[key]?.map((teams) => {
                            return (
                              <Team
                                seasonId={selectedSeasonId}
                                data={teams.team}
                                onClick={() => {
                                  navigate(`/team-details/${teams?.team?.id}`);
                                }}
                                controls={{ delete: true, edit: true }}
                                onEdit={(teamData) => onEdit(teams)}
                                onDelete={(teamData) => onDelete(teams?.id)}
                              />
                            );
                          })}
                        </Row>
                      </div>
                    </>
                  );
                })}
                {nonGroupTeams?.length > 0 && (
                  <div className="teams">
                    <h2>Non Group teams </h2>
                    <Row className="team-rows-wrapper">
                      {nonGroupTeams?.map((teams, key) => {
                        return (
                          <Team
                            seasonId={selectedSeasonId}
                            data={teams.team}
                            onClick={() => {
                              navigate(`/team-details/${teams?.team?.id}`);
                            }}
                            controls={{ delete: true, edit: true }}
                            onEdit={(teamData) => onEdit(teams)}
                            onDelete={(teamData) => onDelete(teams?.id)}
                          />
                        );
                      })}
                    </Row>
                    {/* <Row style={{ padding: "5px" }}>
                      <Col span={24}>
                        <List
                          grid={{ gutter: 24, column: 12 }}
                          dataSource={nonGroupTeams}
                          // pagination={true}
                          renderItem={(item) => (
                            <List.Item>
                              <Team
                                seasonId={selectedSeasonId}
                                data={item?.team}
                                controls={{ delete: true, edit: true }}
                                onDelete={(teamData) => onDelete(item?.id)}
                                onEdit={(teamData) => onEdit(item)}
                              />
                            </List.Item>
                          )}
                        />
                      </Col>
                      <Col span={16}></Col>
                    </Row> */}
                  </div>
                )}
              </div>
            )
          )}
          {isCreateModalOpen && (
            <Modal
              title={
                selectedRecord
                  ? "Update Tournament Team"
                  : "Add Tournament Team"
              }
              width={750}
              open={isCreateModalOpen}
              footer={null}
              onCancel={handleCancel}
            >
              <AddTeamToSeason
                refresh={refetch}
                record={selectedRecord}
                close={handleCancel}
                selectedSeasonId={selectedSeasonId}
                tournamentId={tournamentId}
              />
            </Modal>
          )}
        </div>
      ) : (
        <div className="empty-container">Please select season</div>
      )}
    </>
  );
};

export default TeamTab;
