import { gql } from "@apollo/client";

const CREATE_GOALRUSH_SCHOOL = gql`
  mutation CreateGoalrushSchool($createGoalrushSchoolInput: [CreateGoalrushSchoolInput!]!) {
    createGoalrushSchool(createGoalrushSchoolInput: $createGoalrushSchoolInput) {
      id
      name
      description
      links
      images
      type
      createdAt
      createdBy
    }
  }
`;

export default CREATE_GOALRUSH_SCHOOL;
