import { gql } from '@apollo/client';

const DELETE_IMAGES_FROM_TOURNAMENT_SEASON_GALLERY = gql`
  mutation deleteImagesFromTournamentSeasonGallery($gallery_id: String!) {
    deleteImagesFromTournamentSeasonGallery(gallery_id: $gallery_id) {
      id
      display_picture
      is_published
      created_at
      updated_at
    }
  }
`;
export default DELETE_IMAGES_FROM_TOURNAMENT_SEASON_GALLERY;
