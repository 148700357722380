import React, { useEffect, useState } from "react";
import { useLazyQuery, useQuery, useSubscription } from "@apollo/client";
import GET_MATCHES from "../../api/get_all_matches";
import {
  Col,
  Row,
  Select,
  Popconfirm,
  Button,
  Modal,
  Input,
  Table,
} from "antd";
import moment from "moment/moment";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import GET_MATCH_LINEUP_BY_ID from "../../api/get_match_line_ups";
import LineUpTable from "./blocks/players-table";
import NewLineUp from "./blocks/add_player";
import GET_TEAMS from "../../api/get_teams";
import GET_TEAM_PLAYERS_BY_TEAM from "../../api/get_team_players_by_team";
import PlayersTable from "./blocks/players-table";
import AddPlayer from "./blocks/add_player";
import EditTeamPlayer from "./blocks/edit_team_player";
import withAuthProtection from "../../utils/auth";

const TeamPlayers = () => {
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const { Option } = Select;
  const { data: allTeams, loading: allTeamsLoading } = useQuery(GET_TEAMS);
  const [
    getTeamPlayersByTeam,
    { data: teamPlayers, loading: teamPlayersLoading, refetch },
  ] = useLazyQuery(GET_TEAM_PLAYERS_BY_TEAM, {
    variables: { team_id: selectedTeam },
  });

  useEffect(() => {
    if (selectedTeam) {
      getTeamPlayersByTeam();
    }
  }, [selectedTeam]);

  useEffect(() => {
    setTableData(teamPlayers?.teamPlayersByTeam);
  }, [teamPlayers]);

  const onChange = (value) => {
    setSelectedTeam(value);
  };

  const onSearchTeamPlayer = (event) => {
    let data;

    if (event?.target?.value) {
      data = teamPlayers?.teamPlayersByTeam?.filter((record) =>
        record?.user?.name
          ?.toLowerCase()
          .includes(event?.target?.value?.toLowerCase())
      );
    } else {
      data = teamPlayers?.teamPlayersByTeam;
    }

    const list = [];

    data.map((record) => {
      list.push({
        ...record,
        key: record?.id,
      });
    });

    setTableData(list);
  };

  const onSearch = (value) => {
    setSelectedTeam(value);
  };

  let selectTeams = [];
  allTeams?.teams?.map((team) => {
    selectTeams.push({
      key: team?.id,
      value: team?.id,
      label: team?.name,
    });
  });

  const handleCancel = () => {
    setIsCreateModalOpen(false);
  };

  return (
    <>
      <Row>
        <Col span={21} style={{ paddingTop: "10px" }}>
          <Select
            showSearch
            placeholder="Select a Team"
            optionFilterProp="children"
            defaultValue={selectedTeam}
            onChange={onChange}
            onSearch={onSearch}
            style={{
              width: "500px",
            }}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={selectTeams}
          />
        </Col>

        <Col span={24}>
          {teamPlayersLoading || allTeamsLoading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            </div>
          ) : (
            <>
              {selectedTeam && (
                <Col span={24}>
                  <Col span={24} style={{ paddingTop: "20px" }}>
                    <Row style={{ marginTop: "32px" }}>
                      <Col span={5}>
                        <Input
                          placeholder="Search Team player"
                          onChange={onSearchTeamPlayer}
                        />
                      </Col>
                      <Col span={15}></Col>
                      <Col
                        span={4}
                        style={{ textAlign: "right", paddingBottom: "18px" }}
                      >
                        <Button
                          type="primary"
                          onClick={() => {
                            setIsCreateModalOpen(true);
                          }}
                        >
                          Add Players
                        </Button>
                      </Col>
                    </Row>

                    <PlayersTable playersData={tableData} refresh={refetch} />
                  </Col>
                </Col>
              )}
            </>
          )}
        </Col>
      </Row>

      {isCreateModalOpen && (
        <Modal
          title="Add Players"
          width={1000}
          open={isCreateModalOpen}
          footer={null}
          onCancel={handleCancel}
        >
          <AddPlayer
            teamId={selectedTeam}
            refresh={refetch}
            close={handleCancel}
          />
        </Modal>
      )}
    </>
  );
};

export default withAuthProtection(TeamPlayers);
