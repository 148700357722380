// src/components/Login.js

import React, { useState } from "react";
import { Form, Input, Button, notification } from "antd";
import { useNavigate } from "react-router";
import { InputOTP } from "antd-input-otp";

const OTPField = ({onSubmit, resendOtp}) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="">
      <h3 style={{ textAlign: "center", marginBottom: "24px" }}>Enter Code</h3>
      <Form onFinish={onSubmit}>
        <Form.Item
          label="Code"
          name="code"
          rules={[{ required: true, message: "Please enter your Code." }]}
        >
           <InputOTP autoFocus inputType="numeric" length={6} />
        
        </Form.Item>

        <Form.Item>
          <Button style={{marginTop: '10px', marginLeft: 0}} onClick={resendOtp} type="secondary" htmlType="button" loading={loading}>
            Resend OTP
          </Button>
          <Button style={{marginTop: '10px'}} type="primary" htmlType="submit" loading={loading}>
            Submit Code
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default OTPField;
