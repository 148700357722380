import { gql } from "@apollo/client";


const UPDATE_IMAGES_FROM_TOURNAMENT_SEASON_GALLERY_MUTATION = gql`
  mutation UpdateImagesFromTournamentSeasonGallery($gallery_id: String!, $updateGalleryInput: UpdateGalleryInput!) {
    updateImagesFromTournamentSeasonGallery(gallery_id: $gallery_id, updateGalleryInput: $updateGalleryInput) {
      id
      display_picture
      is_published
      match_id
      created_at
      updated_at
    }
  }
`;
export default UPDATE_IMAGES_FROM_TOURNAMENT_SEASON_GALLERY_MUTATION;