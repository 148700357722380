import { gql } from "@apollo/client";

const CREATE_USER= gql`
mutation createUser($createUserInput: CreateUserInput!) {
  createUser(createUserInput: $createUserInput) {
    id
    name
    email
    phone_number
    display_picture
    position
    preferred_jersey_no
    status
    teamPlayer {
      id
      join_date
      leave_date
      status
    }
  }
}

`;

export default CREATE_USER;
