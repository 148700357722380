import { useMutation } from "@apollo/client";
import {
  Form,
  Input,
  Select,
  Space,
  DatePicker,
  Button,
  message,
  Alert,
  Row,
  Col,
  Upload,
  InputNumber,
  Divider,
  Switch,
} from "antd";
import { useState } from "react";
import { useEffect } from "react";
import { uploadS3Image } from "../../../../../api/rest-api/s3-api";
import CREATE_TOURNAMENT_SEASON from "../../../../../api/create_tournament_season";
import UPDATE_SEASON_TEAM_BY_ID from "../../../../../api/update_team_in_seaon";
import dayjs from "dayjs";
import UPDATE_TOURNAMENT_SEASON from "../../../../../api/update_tournament_season";
import { useParams } from "react-router";

const CreateSeason = ({ record, refresh, close }) => {
  const { id: tournamentId } = useParams();

  const [
    createSeason,
    {
      data: createdSeasonData,
      loading: createSeasonLoading,
      error: createdSeasonError,
    },
  ] = useMutation(CREATE_TOURNAMENT_SEASON);
  const [
    updateSeasonById,
    {
      data: updateSeasonData,
      loading: updateSeasonLoading,
      error: updateSeasonError,
    },
  ] = useMutation(UPDATE_TOURNAMENT_SEASON);

  const [statusMessage, setStatusMessage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [switchState, setSwitchState] = useState(
    record?.advanced_stats ? record?.advanced_stats : false
  );

  const [form] = Form.useForm();

  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        name: record?.name,
        start_date: dayjs(record?.start_date, "YYYY-MM-DD HH:mm:ss[Z]"),
        end_date: dayjs(record?.end_date, "YYYY-MM-DD HH:mm:ss[Z]"),
        advanced_stats: record?.advanced_stats,
      });

      setSelectedImage({ src: record?.display_picture });
      setSwitchState(record?.advanced_stats);
    } else {
      form.resetFields();
    }
  }, [record]);

  const onFinish = async (formValue) => {
    if (record) {
      updateSeason(formValue);
    } else {
      saveSeason(formValue);
    }
  };

  const saveSeason = async (formValue) => {
    let payload = formValue;

    if (selectedImage?.file) {
      await uploadS3Image(selectedImage?.file)
        .then((resp) => {
          payload = {
            ...payload,
            display_picture: `${process.env.REACT_APP_API_URL}/media/download/${resp?.data}`,
          };
        })
        .catch((err) => {
          console.log(err);
        });
    }

    payload = { ...payload, tournamentId };
    await createSeason({
      variables: {
        createTournamentSeasonInput: payload,
      },
    })
      .then((res) => {
        setStatusMessage({
          type: "success",
          content: "User created",
        });

        onReset();
        refresh();
        close();
      })
      .catch((err) => {
        setStatusMessage({
          type: "error",
          content: err.message,
        });
      });
  };

  const updateSeason = async (formValue) => {
    let payload = formValue;

    if (selectedImage?.file) {
      await uploadS3Image(selectedImage?.file)
        .then((resp) => {
          payload = {
            ...payload,
            display_picture: `${process.env.REACT_APP_API_URL}/media/download/${resp?.data}`,
          };
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (!selectedImage?.src) {
      payload = { ...payload, display_picture: "" };
    }

    updateSeasonById({
      variables: {
        tournament_season_id: record?.id,
        updateTournamentSeasonInput: {
          ...payload,
        },
      },
    })
      .then((res) => {
        setStatusMessage({
          type: "success",
          content: "Season Updated",
        });

        onReset();
        refresh();
        close();
      })
      .catch((err) => {
        setStatusMessage({
          type: "error",
          content: err.message,
        });
      });
  };

  const onReset = () => {
    form?.resetFields();
    setSelectedImage(null);
  };

  const onClose = () => {
    setStatusMessage(null);
  };

  const readURL = (input) => {
    const file = input?.target?.files[0];

    if (file) {
      var reader = new FileReader();

      reader.onload = function (e) {
        setSelectedImage({ file, src: e.target.result });

        setTimeout(() => {
          document
            .getElementById("season_image")
            .setAttribute("src", e.target.result);
        }, 0);
      };

      reader.readAsDataURL(file);
    }
  };

  const switched = (checked) => {
    setSwitchState(!switchState);
  };

  return (
    <>
      {statusMessage && (
        <Alert
          message={statusMessage?.content}
          type={statusMessage?.type}
          showIcon
          closable
          onClose={onClose}
        />
      )}
      <Form
        form={form}
        name="complex-form"
        onFinish={onFinish}
        style={{ marginTop: "24px" }}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 15 }}
      >
        <Row gutter={4}>
          <Col span={24}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Name is required" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="Start date" name="start_date">
              <DatePicker />
            </Form.Item>

            <Form.Item label="End date" name="end_date">
              <DatePicker />
            </Form.Item>
            <Form.Item label="Advanced stats" name="advanced_stats">
              <Switch defaultChecked={switchState} onChange={switched} />
            </Form.Item>

            <Form.Item label="Image" name="display_picture">
              <input
                type="file"
                id="select_file"
                onChange={(event) => readURL(event)}
                hidden
              />
              {selectedImage && selectedImage?.src != "" ? (
                <>
                  <img
                    id="season_image"
                    src={selectedImage?.src}
                    style={{ cursor: "pointer", borderRadius: "50%" }}
                    alt="your image"
                    width={100}
                    height={100}
                    onClick={() => {
                      document.getElementById("select_file").click();
                    }}
                  />{" "}
                  <Button onClick={() => setSelectedImage(null)}>Remove</Button>{" "}
                </>
              ) : (
                <div
                  id="season_image"
                  src={selectedImage?.src}
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    border: "1px solid gray",
                    height: "100px",
                    width: "100px",
                  }}
                  onClick={() => {
                    document.getElementById("select_file").click();
                  }}
                >
                  Select image
                </div>
              )}
            </Form.Item>
          </Col>

          <Col span={24}>
            <Space style={{ justifyContent: "right", width: "100%" }}>
              <Button htmlType="button" onClick={onReset}>
                Reset
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={createSeasonLoading || updateSeasonLoading}
              >
                {record ? "Update" : "Submit"}
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default CreateSeason;
