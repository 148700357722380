import { gql } from "@apollo/client";

const CREATE_TOURNAMENT_SEASON_ROUND= gql`
mutation createTournamentRound($createTournamentRoundInput: CreateTournamentRoundInput!) {
  createTournamentRound(createTournamentRoundInput: $createTournamentRoundInput) {
    id
    type
    break_time
    match_duration
    match_extra_time
    sequence_no
    tournament_season {
      id
      name
      display_picture
      end_date
      about_tournament
    }
  }
}
`;

export default CREATE_TOURNAMENT_SEASON_ROUND;
