import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Button, Col, Form, Input, message, Row, Select, Space, ColorPicker } from "antd"
import { useEffect, useState } from "react";
import FIND_ALL_GROUPS_BY_SEASON_ID from "../../../../../api/get_all_groups_by_season";
import FIND_ALL_ROUNDS_BY_SEASON_ID from "../../../../../api/get_all_rounds_by_season";
import GET_TEAMS_BY_TOURNAMENT_SEASON_ID from "../../../../../api/get_teams_by_season_id";
import GET_TOURNAMENTS from "../../../../../api/get_tournaments";
import FIND_ALL_SEASONS_BY_TOURNAMENT_ID from "../../../../../api/get_tournament_seasons";
import GET_VENUES from "../../../../../api/get_venue_list";
import dayjs from "dayjs";
import UPDATE_MATCH from "../../../../../api/update_match";
import GET_EVENT_ENUM from "../../../../../api/get_event_enum";

const MatchEdit = ({ record }) => {
    const [messageApi, contextHolder] = message.useMessage();
    const utc = require("dayjs/plugin/utc");
    dayjs.extend(utc);
    const [form] = Form.useForm();
    const { Option } = Select;
    const { data: tournamentsData, loading: tournamentLoading } =
        useQuery(GET_TOURNAMENTS);
    const { data: venueData, venueLoading, venueError } = useQuery(GET_VENUES);
    const { data: matchFormats } = useQuery(GET_EVENT_ENUM, { variables: { enumName: 'MatchFormatEnums' } });
    const [
        getTournamentSeasonTeams,
        {
            data: tournamentSeasonsTeams,
            loading: tournamentSeasonTeamsLoading,
            refetch,
        },
    ] = useLazyQuery(GET_TEAMS_BY_TOURNAMENT_SEASON_ID, {
        variables: {
            tournament_season_id: record?.tournamentSeason?.id,
        },
    });
    const [
        updateMatch,
        { updateMatchData, loading: updateMatchLoading, updateMatchError },
    ] = useMutation(UPDATE_MATCH);

    const [
        getTournamentSeasonData,
        { data: seasonsData, loading: seasonsLoading },
    ] = useLazyQuery(FIND_ALL_SEASONS_BY_TOURNAMENT_ID);
    const [
        getTournamentRoundData,
        { data: roundData, loading: roundDataLoading },
    ] = useLazyQuery(FIND_ALL_ROUNDS_BY_SEASON_ID);
    const [
        getTournamentGroupData,
        { data: groupData, loading: groupDataLoading },
    ] = useLazyQuery(FIND_ALL_GROUPS_BY_SEASON_ID);
    const [statusMessage, setStatusMessage] = useState(null);

    const tournament = Form.useWatch("tournament", form);
    const season = Form.useWatch("tournamentSeason", form);

    useEffect(() => {
        getTournamentSeasonTeams({
            variables: {
                tournament_season_id: record?.tournamentSeason?.id,
            },
        });
    }, [record?.tournamentSeason?.id]);


    useEffect(() => {
        if (record?.tournamentSeason?.id) {
            getTournamentRoundData({
                variables: {
                    seasonId: record?.tournamentSeason?.id,
                },
            });

            getTournamentGroupData({
                variables: {
                    tournament_season_id: record?.tournamentSeason?.id,
                },
            });
        }
    }, [record?.tournamentSeason?.id]);

    useEffect(() => {
        if (tournament) {
            getTournamentSeasonData({
                variables: {
                    tournament_id: tournament,
                },
            });
        }
    }, [tournament]);

    useEffect(() => {
        if (record) {
            form.setFieldsValue({
                team1_color: record?.team1_color || '#ffffff',
                team2_color: record?.team2_color || '#ffffff',
                format: record?.format,
                sub_count: record?.sub_count,
                duration: record?.duration,
                match_stat_duration: record?.match_stat_duration,
                streaming_url: record?.streaming_url,
                venueId: record?.venue?.id,
                tournament_season_group_id: record?.tournament_season_group?.id
            });
        } else {
            form.resetFields();
        }
    }, [record]);

    const onFinish = async (formValue) => {
        if (record) {
            formValue = {
                ...formValue,
                venueId: formValue?.venueId ? formValue?.venueId : '',
                tournament_season_group_id: formValue?.tournament_season_group_id ? formValue?.tournament_season_group_id : ''
            }

            updateMatchInputs(formValue);
        }
    };


    const updateMatchInputs = (formValue) => {
        updateMatch({
            variables: {
                match_id: record?.id,
                updateMatchInput: formValue
            },
        })
            .then((res) => {
                messageApi.open({
                    type: "success",
                    content: "Match Updated",
                });
            })
            .catch((err) => {
                messageApi.open({
                    type: "error",
                    content: err.message,
                });
            });
    };

    const renderTournamentGroup = () => {
        if (groupData)
            return groupData?.findAllGroupsBySeasonId?.map((group) => {
                return { label: group?.group_name, value: group?.id };
            });
    };

    const renderVenue = () => {
        if (venueData)
            return venueData?.venues?.map((venue) => {
                return { label: venue?.name, value: venue?.id };
            });
    };

    const onReset = () => {
        form?.resetFields();
    };

    return (
        <>
            {contextHolder}
            <Form
                form={form}
                name="team-form"
                onFinish={onFinish}
                style={{ marginTop: "24px" }}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 12 }}
            // style={{ }}
            >
                <Row gutter={4}>
                    <Col span={12}>
                        <Form.Item label="Team1 Color" name="team1_color">
                            <Input type="color" placeholder="Team1 Color" />
                        </Form.Item>

                        <Form.Item label="Team2 Color" name="team2_color">
                            <Input type="color" placeholder="Team2 Color" />
                        </Form.Item>

                        <Form.Item label="Format" name="format">
                            <Select placeholder="Select format">
                                {matchFormats?.enumValues?.map((enumValue) =>
                                    <Option key={enumValue} value={enumValue}>{enumValue}</Option>
                                )}
                            </Select>
                        </Form.Item>

                        <Form.Item label="Duration" name="duration">
                            <Input placeholder="Duration in minutes" />
                        </Form.Item>

                        <Form.Item label="Sub count" name="sub_count">
                            <Input placeholder="Sub Count" />
                        </Form.Item>

                        <Form.Item label="Match Stat Duration" name="match_stat_duration">
                            <Input placeholder="Match Stat Duration" />
                        </Form.Item>

                        <Form.Item label="Streaming URL" name="streaming_url">
                            <Input placeholder="Streaming URL" />
                        </Form.Item>

                        <Form.Item name="venueId" label="Venue">
                            <Select
                                allowClear={true}
                                placeholder="Select Venue"
                                showSearch
                                filterOption={(input, option) =>
                                    (option?.label?.toLowerCase() ?? "").includes(
                                        input.toLowerCase()
                                    )
                                }
                                options={renderVenue()}
                            ></Select>
                        </Form.Item>

                        <Form.Item name="tournament_season_group_id" label="Tournament Group">
                            <Select
                                placeholder="Select Group"
                                allowClear={true}
                                showSearch
                                filterOption={(input, option) =>
                                    (option?.label?.toLowerCase() ?? "").includes(
                                        input.toLowerCase()
                                    )
                                }
                                options={renderTournamentGroup()}
                            ></Select>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Space style={{ justifyContent: "right", width: "100%" }}>
                            <Button htmlType="button" onClick={onReset}>
                                Reset
                            </Button>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={updateMatchLoading}
                            >
                                {record ? "Update" : "Submit"}
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>

        </>

    )
}

export default MatchEdit