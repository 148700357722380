import { useMutation, useQuery } from '@apollo/client';
import { Avatar, Button, Col, Input, List, Image, message, Modal, Popconfirm, Row, Space, Table, Typography } from 'antd';
import React, { useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import GET_USERS from '../../api/get_all_users';
import CreateUser from './blocks/new_user';
import REMOVE_USER_BY_ID from '../../api/remove_user';
import { useNavigate } from 'react-router';
import { compareString } from '../../utils/app.util';
import withAuthProtection from '../../utils/auth';

const Users = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const { data: allUsers, loading, refetch } = useQuery(GET_USERS);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [selectedUserRecord, setSelectedUserRecord] = useState(null);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const utc = require('dayjs/plugin/utc');
  dayjs.extend(utc);

  const [
    deleteUser,
    {
      data: deleteUserData,
      loading: deleteUserLoading,
      error: deleteUserError,
    },
  ] = useMutation(REMOVE_USER_BY_ID);

  useEffect(() => {
    const userList = [];

    allUsers?.users?.map((record) => {
      userList.push({
        ...record,
        key: record?.id,
      });
    });

    setUsers(userList);
  }, [allUsers]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name) => name,
      defaultSortOrder: 'ascend',
      sorter: (a, b) => compareString(a, b, 'name'),
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
      render: (position) => position,
    },
    {
      title: "Jersey Number",
      dataIndex: "preferred_jersey_no",
      key: "preferred_jersey_no",
      render: (preferred_jersey_no) => preferred_jersey_no,
    },
    {
      title: "Picture",
      dataIndex: "display_picture",
      key: "picture",
      render: (display_picture) => {
        return (
          display_picture && (
            <div
              style={{
                display: "inline-block",
                width: "32px",
                height: "32px",
                borderRadius: "50%",
                overflow: "hidden",
              }}
            >
              <Image
                src={display_picture}
                width="32px"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
          )
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <a onClick={() => onEdit(record)}>Edit</a>
          <Typography.Link>
            <Popconfirm title="Delete" onConfirm={() => onDelete(record)}>
              <a style={{ marginLeft: "24px" }}>Delete</a>
            </Popconfirm>
          </Typography.Link>
        </Space>
      ),
    },
  ];

  const showModal = () => {
    setIsCreateModalOpen(true);
  };

  const handleOk = () => {
    setIsCreateModalOpen(false);
  };

  const handleCancel = () => {
    setIsCreateModalOpen(false);

    if (selectedUserRecord) {
      setSelectedUserRecord(null);
    }
  };

  const onSearchUser = (event) => {
    let data;

    if (event?.target?.value) {
      data = allUsers?.users?.filter((record) =>
        record?.name
          ?.toLowerCase()
          .includes(event?.target?.value?.toLowerCase())
      );
    } else {
      data = allUsers?.users;
    }

    const list = [];

    data.map((record) => {
      list.push({
        ...record,
        key: record?.id,
      });
    });

    setUsers(list);
  };

  const onEdit = (record) => {
    setSelectedUserRecord(record);
    showModal();
  };

  const onDelete = (record) => {
    deleteUser({
      variables: {
        user_id: record?.key,
      },
    })
      .then((resp) => {
        refetch();
      })
      .catch((err) => {
        messageApi.open({
          type: "error",
          content: err?.message || "Something went wrong!",
        });
      });
  };

  return (
    <>
      {contextHolder}
      <h1>Users</h1>
      <Row>
        <Col span={5}>
          {/* <Col span={5}> */}
            <Input placeholder="Search Team player" onChange={onSearchUser} />
          {/* </Col> */}
        </Col>
        <Col span={19} style={{textAlign: 'right'}}>
          <Space>
            <Button type='primary' onClick={() => navigate('/multi-user-creation')}>Import Users from excel</Button>
            <Button type='primary' onClick={showModal}>Add New</Button>
          </Space>

        </Col>
      </Row>
      <Row style={{ padding: "5px" }}>
        <Col span={24}>
          {loading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            </div>
          ) : (
            <Table columns={columns} dataSource={users} />
          )}
        </Col>
        <Col span={16}></Col>
      </Row>

      {isCreateModalOpen && (
        <Modal
          title={
            selectedUserRecord
              ? `Update User [${selectedUserRecord?.name}]`
              : "New User"
          }
          open={isCreateModalOpen}
          footer={null}
          onCancel={handleCancel}
        >
          <CreateUser
            record={selectedUserRecord}
            refresh={refetch}
            close={handleCancel}
          />
        </Modal>
      )}
    </>
  );
};

export default withAuthProtection(Users);
