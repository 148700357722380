import {
  Button,
  Card,
  Col,
  Image,
  Input,
  Modal,
  Popconfirm,
  Row,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import "./style.scss";
import {
  DeleteFilled,
  DeleteOutlined,
  EditFilled,
  EditOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import GET_TOURNAMENTS from "../../api/get_tournaments";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate } from "react-router";
import TournamentCard from "./tournamentCard/tournamentCard";
import { NoCompactStyle } from "antd/es/space/Compact";
import CreateTournamentListing from "./createTournamentListing/createTournamentListing";
import GET_ALL_LISTED_TOURNAMENTS from "../../api/get_all_listed_tournaments";
import REMOVE_GOALRUSH_SCHOOL from "../../api/remove_tournament_listing";
import TournamentListDetails from "./tournamentDetails.jsx/tournament-details";
import REMOVE_LISTED_TOURNAMENT from "../../api/remove_tournament_listing";

const TournamentListing = () => {
  const [tournaments, setTournaments] = useState([]);
  const [listingModalOpen, setListingModalOpen] = useState(false);
  const [cardListing, setCardListing] = useState(false);
  const navigate = useNavigate();
  const [selectedContent, setSelectedContent] = useState(null);

  const onTournamentClick = (tournamentId) => {
    navigate(`/tournament-details-listing/ ${tournamentId}`);
  };

  const {
    data: listedTournamentsData,
    loading: listedTournamentLoading,
    refetch: listedTournamentRefetch,
  } = useQuery(GET_ALL_LISTED_TOURNAMENTS);

  const [
    removeListedTournament,
    {
      data: deletedemoveListedTournament,
      loading: removeListedTournamentLoading,
    },
  ] = useMutation(REMOVE_LISTED_TOURNAMENT);

  useEffect(() => {
    const tournamentList = [];

    listedTournamentsData?.findAllListedTournaments?.map((tournament) => {
      tournamentList.push(tournament);
    });

    setTournaments(tournamentList);
  }, [listedTournamentsData]);

  const onSearchTournament = (event) => {
    let data;

    if (event?.target?.value) {
      data = listedTournamentsData?.tournaments?.filter((record) =>
        record?.name
          ?.toLowerCase()
          .includes(event?.target?.value?.toLowerCase())
      );
    } else {
      data = listedTournamentsData?.tournaments;
    }

    const list = [];

    data.map((record) => {
      list.push({
        ...record,
        key: record?.id,
      });
    });

    setTournaments(list);
  };

  const onEdit = (record) => {
    setSelectedContent(record);

    setListingModalOpen(true);
  };

  const onDelete = (record) => {
    removeListedTournament({
      variables: {
        tournament_listed_id: record?.id,
      },
    })
      .then((res) => {
        message.open({
          type: "success",
          content: "Content Removed",
        });
        listedTournamentRefetch();
      })
      .catch((err) => {
        message.open({
          type: "error",
          content: err?.message || "Something went wrong!",
        });
      });
  };
  const handleCreate = () => {
    setListingModalOpen(true);
  };
  const handleCancel = () => {
    setListingModalOpen(false);
    if (selectedContent) {
      setSelectedContent(null);
    }
  };
  const handleCardClick = () => {
    setCardListing(true);
  };
  const handleDeleteButton = () => {
    setCardListing(true);
  };
  return (
    <>
      <Row>
        <Col span={23}>
          <h2>Tournament Listing </h2>
        </Col>
        <Col span={1}></Col>
      </Row>

      <Row className="title-rows">
        <Col className="title-column-search" span={12}>
          <Input
            className="search-box"
            placeholder="Search Tournament"
            onChange={onSearchTournament}
          />
        </Col>
        <Col className="title-column-button " span={12}>
          <Button type="primary" onClick={handleCreate}>
            Create New+
          </Button>
        </Col>
      </Row>

      <Row>
        {tournaments?.map((tournament) => (
          // <TournamentCard
          //   data={tournament}
          //   //   onClick={(e) => {
          //   //     onTournamentClick(tournament?.id);
          //   //   }}
          //   //   onEditClick={() => onEdit(tournament)}
          // />
          <Row className="tournament-listing_wrapper">
            <Col className="tournament-listing_column">
              <Card
                className="listing-card"
                key={tournament?.id}
                // onClick={(e) => {
                //   onTournamentClick(tournament?.id);
                // }}
                onClick={(e) => {
                  onTournamentClick(tournament?.id);
                }}
                actions={[
                  <EditFilled
                    onClick={(event) => {
                      event.stopPropagation();
                      onEdit(tournament);
                    }}
                  />,
                  <Popconfirm
                    key="delete"
                    title="Delete the content"
                    icon={<DeleteFilled />}
                    description="Are you sure to delete this content?"
                    onConfirm={(event) => {
                      event.stopPropagation();
                      onDelete(tournament);
                    }}
                    onCancel={(event) => event.stopPropagation()}
                    okText="Yes"
                    cancelText="No"
                  >
                    <DeleteFilled
                      style={{
                        cursor: "pointer",
                        color: "#4f5453",
                        fontSize: "15px",
                      }}
                      onClick={(event) => event.stopPropagation()}
                    />
                  </Popconfirm>,
                ]}
              >
                <img
                  className="logo"
                  alt=""
                  src={
                    process.env.REACT_APP_API_URL +
                      "/media/download/" +
                      tournament?.tournament_profile_image ||
                    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdywxLCW7l-a-VcnPQQBc2IZbNXWBGVFGbPw&usqp=CAU"
                  }
                  preview={{
                    src:
                      tournament?.image ||
                      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdywxLCW7l-a-VcnPQQBc2IZbNXWBGVFGbPw&usqp=CAU",
                  }}
                />

                <Row className="title">{tournament?.tournament_name} </Row>
              </Card>
            </Col>
          </Row>
        ))}
      </Row>
      {listingModalOpen && (
        <Modal
          title={
            selectedContent
              ? "Update Tournament Listing"
              : "Create Tournament Listing"
          }
          open={listingModalOpen}
          width={900}
          footer={null}
          onCancel={handleCancel}
        >
          <CreateTournamentListing
            record={selectedContent}
            open={listingModalOpen}
            onCancel={handleCancel}
            refresh={listedTournamentRefetch}
          />
        </Modal>
      )}
      {cardListing &&
        tournaments?.map((tournament) => (
          <TournamentListDetails
            data={tournament}
            onClick={(e) => {
              onTournamentClick(tournament?.id);
            }}
            // // onEditClick={() => onEdit(data)}
          />
        ))}
    </>
  );
};

export default TournamentListing;
