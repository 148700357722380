import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Form,
  Select,
  Button,
  Alert,
  Row,
  Col,
  InputNumber,
  Table,
} from "antd";
import { useState } from "react";
import { useEffect } from "react";
import GET_EVENT_ENUM from "../../../api/get_event_enum";
import GET_MATCH_LINEUP_BY_ID from "../../../api/get_match_line_ups";
import GET_TEAM_PLAYERS_BY_TEAM from "../../../api/get_team_players_by_team";
import CREATE_MATCH_LINE_UP from "../../../api/create_match_line_up";
import { compareString } from "../../../utils/app.util";
import { useParams } from "react-router";
import UPDATE_LINEUP_JERSEY_NUMBER from "../../../api/update_lineUp_jersey_number";
import { valueFromAST } from "graphql";

const EditJerseyTable = ({
  lineUpData,
  teamId,
  refresh,
  close,
  matchId,
  seasonId,
  matchLineUps,
}) => {
  const { id: selectedMatchId } = useParams();
  const { Option } = Select;
  const [errorMessage, setErrorMessage] = useState(null);
  const [statusMessage, setStatusMessage] = useState(null);
  const [teamPreviousMatches, setTeamPreviousMatches] = useState([]);
  const [lineupDataSource, setLineupDataSource] = useState(
    JSON.parse(JSON.stringify(lineUpData?.lineups))
  );
  const [previousMatches, setPreviousMatches] = useState([]);
  const [selectedPreviousMatch, setSelectedPreviousMatch] = useState(null);
  const [selectedPreviousMatchLineUps, setSelectedPreviousMatchLineUps] =
    useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [editedJerseyNumbers, setEditedJerseyNumbers] = useState([
    lineUpData?.lineups,
  ]);

  const { data: playerPositions } = useQuery(GET_EVENT_ENUM, {
    variables: { enumName: "PlayerPosition" },
  });

  const { data: lineUpStatus } = useQuery(GET_EVENT_ENUM, {
    variables: { enumName: "LineupStatus" },
  });

  // const [
  //   getMatchLineups,
  //   { data: matchLineUps, loading: matchLineUpsLoading, refetch },
  // ] = useLazyQuery(GET_MATCH_LINEUP_BY_ID, {
  //   variables: { match_id: selectedPreviousMatch },
  //   fetchPolicy: "no-cache",
  // });

  const [fetchPlayerList, { data: teamPlayersList }] = useLazyQuery(
    GET_TEAM_PLAYERS_BY_TEAM,
    {
      variables: { team_id: teamId },
      fetchPolicy: "no-cache",
    }
  );

  const [
    createMatchLineUp,
    { data: createMatchLineUpData, loading: lineUploading, error: lienUpError },
  ] = useMutation(CREATE_MATCH_LINE_UP);

  const [
    updateLineUpJerseyNumber,
    {
      data: updateLineUpJerseyNumberData,
      loading: updateLineupLoading,
      error: updateLineupError,
    },
  ] = useMutation(UPDATE_LINEUP_JERSEY_NUMBER);

  // const getFilteredPlayers = (teamPlayers, playerLists) => {
  //   return teamPlayers
  //     ? teamPlayers.filter(
  //         (player) =>
  //           player?.user?.id && !playerLists.includes(player?.user?.id)
  //       )
  //     : [];
  // };

  useEffect(() => {
    if (selectedPreviousMatch) {
      // getMatchLineups();
    }
  }, [selectedPreviousMatch]);

  useEffect(() => {
    if (matchLineUps) {
      let selectedRows = [];
      const teamData = matchLineUps?.matchLineupByMatchId?.find(
        (team) => team?.id == teamId
      );

      setSelectedPreviousMatchLineUps(teamData?.lineup);

      lineUpData?.lineups?.map((player) => {
        let obj = teamData?.lineup?.find(
          (lineUpDataObj) => lineUpDataObj?.teamPlayerId == player?.teamPlayerId
        );

        if (obj) {
          player.jerseyNumber = obj?.jersey_no;
          player.position = obj?.position;
          player.isSelected = true;
          selectedRows?.push(player?.key);
        }
      });

      setSelectedRowKeys(selectedRows);
    }
  }, [matchLineUps]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const columns = [
    {
      title: "#",
      width: "15%",
      render: (text, record, index) => {
        return index + 1;
      },
    },

    {
      title: "Name",
      dataIndex: "name",
      width: "75%",
      editable: true,
      defaultSortOrder: "ascend",
      sorter: (a, b) => compareString(a, b, "name"),
    },

    {
      title: "Jersey No",
      dataIndex: "jerseyNumber",
      width: "15%",
      editable: true,
      render: (text, record, index) => {
        return (
          <>
            <InputNumber
              step={1}
              type="number"
              pattern="[0-9]*"
              inputmode="numeric"
              defaultValue={record?.jerseyNumber}
              onChange={(value) => {
                const isDuplicate =
                  lineupDataSource.filter(
                    (player) =>
                      player.key !== record.key &&
                      player.jerseyNumber === String(value)
                  ).length > 0;

                if (isDuplicate) {
                  setErrorMessage(
                    "Duplicate Jersey Number: This jersey number is already in use by another player."
                  );
                  record.jerseyNumber = value;
                } else {
                  record.jerseyNumber = value;
                }
              }}
            />
          </>
        );
      },
      onCell: (record) => ({
        record,
        editable: true,
        inputType: "number",
        dataIndex: "jerseyNumber",
      }),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const [form] = Form.useForm();

  const onClose = () => {
    setStatusMessage(null);
    close();
    // refresh();
  };

  const onSave = async () => {
    const filteredData = structuredClone(
      lineupDataSource?.filter((record) =>
        selectedRowKeys?.includes(record?.key)
      )
    );
    if (filteredData?.length) {
      const inputArray = filteredData?.map((player) => ({
        lineup_id: player?.key?.toString(),
        jersey_no: player?.jerseyNumber?.toString(),
      }));
      const variables = {
        matchId: matchId?.toString(),
        input: inputArray,
      };

      updateLineUpJerseyNumber({ variables })
        .then((res) => {
          setStatusMessage({
            type: "success",
            content: "Saved",
          });
          close();
          refresh();
          // reset();
        })
        .catch((err) => {
          setStatusMessage({
            type: "error",
            content: err.message,
          });
        });
      close();
      refresh();
      setEditedJerseyNumbers(filteredData);
    }
  };

  // useEffect(() => {
  //   if (seasonId) {
  //     refresh();
  //   }
  // }, [matchLineUps]);

  // const reset = () => {
  //   // const lineup = getFilteredPlayers(
  //   //   teamPlayersList?.teamPlayersByTeam,
  //   //   existingPlayers?.lineups?.map((lineup) => lineup.userId)
  //   // );
  //   resetLineUpData(lineupDataSource, setSelectedRowKeys);
  // };

  // const resetLineUpData = (lineupDataSource, setSelectedRowKeys) => {
  //   const playerTable = [];
  //   lineupDataSource?.lineups?.map((player) => {
  //     playerTable.push({
  //       key: player?.id,
  //       isSelected: false,
  //       name: player?.name,
  //       jerseyNumber: player?.jerseyNumber?.toString(),
  //       status: "CONFIRMED",
  //     });
  //   });
  //   console.log("playerTable", playerTable);
  //   setLineupDataSource(playerTable);
  //   setSelectedRowKeys([]);
  // };

  const editedJerseyNumber = (value) => {
    setEditedJerseyNumbers(value);
  };

  return (
    <>
      {statusMessage && (
        <Alert
          message={statusMessage?.content}
          type={statusMessage?.type}
          showIcon
          closable
          onClose={onClose}
        />
      )}
      <Row style={{ margin: "10px" }}>
        {errorMessage && (
          <Alert
            message={errorMessage}
            type="error"
            closable
            onClose={() => setErrorMessage(null)}
            style={{ background: "#FC9F9F" }}
          />
        )}
      </Row>
      <Col span={24}>
        <Form form={form} component={false}>
          <Table
            rowSelection={rowSelection}
            bordered
            dataSource={lineupDataSource}
            columns={columns}
            rowClassName="editable-row"
            pagination={false}
            scroll={{ y: "500px", x: "auto" }}
          />
        </Form>
      </Col>

      <Col
        span={24}
        style={{
          display: "flex",
          justifyContent: "end",
          marginTop: 24,
          gap: 16,
        }}
      >
        {/* <Button type="dashed" onClick={reset}>
          Reset
        </Button> */}
        <Button
          type="primary"
          onClick={onSave}
          loading={lineUploading}
          disabled={selectedRowKeys.length === 0}
        >
          Save
        </Button>
      </Col>
    </>
  );
};

export default EditJerseyTable;
