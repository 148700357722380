import { useLazyQuery } from "@apollo/client";
import { Button, Col, Modal, Row, Space } from "antd"
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import GET_MATCHES_BY_TOURNAMENT_SEASON_ID from "../../../../api/get_matches_by_tournament_season_id";
import GET_MATCH_LINEUP_BY_ID from "../../../../api/get_match_line_ups";
import LineUpTable from "../../../lineups/blocks/lineup-table";
import NewLineUp from "../../../lineups/blocks/new_lineup";
import PlayerSubstitute from "../../../lineups/blocks/player-subtitute";
import EditJerseyTable from "../../../lineups/blocks/editJerseyTable";
import { SkinOutlined } from "@ant-design/icons";

const LineUpTab = ({ matchData }) => {
    const { id: selectedMatchId } = useParams();
    const [selectedMatchTeam, setSelectedMatchTeam] = useState(null)
    const [tableData, setTableData] = useState([]);
    const [existingPlayers, setExistingPlayers] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [selectedSeasonTeam1,setSelectedSeasonTeam1] = useState(null);
    const [selectedSeasonTeam2,setSelectedSeasonTeam2] = useState(null);


    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isSubstituteModalOpen, setIsSubstituteModalOpen] = useState(false);
    const [selectedTeamIndex, setSelectedTeamIndex] = useState(null);
    const [getMatchLineups, { data: matchLineUps, loading: matchLineUpsLoading, refetch }] = useLazyQuery(GET_MATCH_LINEUP_BY_ID, {
        variables: { match_id: selectedMatchId },
        fetchPolicy: "no-cache"
    });

    useEffect(() => {
        if (selectedMatchId) {
            getMatchLineups()

        }
        let team1;
        let team2;
        if (matchData) {
          team1 = matchData?.team1?.id;
          team2 = matchData?.team2?.id;
        }
        setSelectedSeasonTeam1(team1);
        setSelectedSeasonTeam2(team2);
    }, [selectedMatchId])


        //   useEffect(() => {
        //     setSelectedMatchTeam({ team1: allMatches?.matches[0]?.team1, team2: allMatches?.matches[0]?.team2 })
        //     setSelectedMatch(allMatches?.matches[0]?.id)
        //   }, [allMatches])

        useEffect(() => {
            const originData = [];

            matchLineUps?.matchLineupByMatchId?.map((team) => {
                let obj = { id: team?.id, name: team?.name, lineups: [] }
                team?.lineup?.map((lineup) => {
                    obj.lineups.push(formatLineupData(lineup));
                })

                originData.push(obj)
            });
            setTableData(originData);
            setExistingPlayers(tableData);
        }, [matchLineUps]);
        const formatLineupData = (lineup) => {
            return {
                key: lineup?.id,
                name: lineup?.name,
                position: lineup?.position,
                teamPlayerId: lineup?.teamPlayerId,
                jerseyNumber: lineup?.jersey_no,
                status: lineup?.status,
                displayPicture: lineup?.display_picture,
                userId: lineup?.userId
            }
        }
        const handleCancel = () => {
            setIsCreateModalOpen(false);
        };

        const handleSubstituteCancel = () => {
            setIsSubstituteModalOpen(false);
        };
        const handleEditCancel = () => {
            refetch();
            setIsEditModalOpen(false);
            
        };

        
        const handleEditClose = () => {
            
            refetch();
            setIsEditModalOpen(false);
         
            
        };

        return (
            <>
                <Col span={24} style={{ paddingTop: "20px" }}>
                    <Row>
                        <Col span={16}>
                            <h2>{tableData?.[0]?.name}</h2>
                        </Col>
                        
                    </Row>
                    <Row  span={24} style={{display:"flex", justifyContent:"flex-end ", marginBottom:"20px"}}>
                    {/* <Col  style={{ textAlign: 'right', paddingBottom: '18px',display:"grid", justifyContent:"end" }}> */}
                            <Space>
                                {/* <Button type="primary" onClick={() => {
                                setSelectedTeamIndex(0);
                                setSelectedTeam(tableData?.[0]?.id);
                                setIsSubstituteModalOpen(true)
                            }
                            }
                                style={{ marginTop: '16px' }}>Manage Substitution</Button> */}
                                
                                 <Button type="primary"
                                    onClick={() => {
                                    setSelectedTeam(tableData?.[0]?.id)
                                    setIsEditModalOpen(true)
                                    }
                                   }
                                 style={{ marginTop: '16px' }}><SkinOutlined /></Button>
                               <Button type="primary" 
                                onClick={() => {
                                    setSelectedTeam(tableData?.[0]?.id)
                                    setIsCreateModalOpen(true)
                                    }
                                 }
                                 style={{ marginTop: '16px' }}>Manage Lineup</Button>
                              
                                    
                            </Space>

                    </Row>

                    <LineUpTable lineUpData={tableData?.[0]?.lineups} teamId={tableData?.[0]?.id} refresh={refetch} />
                </Col>

                <Col span={24} style={{ marginTop: "56px" }}>
                    <Row>
                        <Col span={16}>
                            <h2>{tableData?.[1]?.name}</h2>
                        </Col>
                    </Row>
                    <Row  span={24} style={{display:"flex", justifyContent:"flex-end ", marginBottom:"20px"}}>
                    <Space>

                    <Button type="primary"
                                    onClick={() => {
                                    setSelectedTeam(tableData?.[1]?.id)
                                    setIsEditModalOpen(true)
                                    }
                                   }
                                 style={{ marginTop: '16px' }}><SkinOutlined /></Button>
                        
                    <Button type="primary" onClick={() => {
                                    setSelectedTeam(tableData?.[1]?.id)
                                    setIsCreateModalOpen(true)
                                }
                                }
                                    style={{ marginTop: '16px' }}>Manage Lineup</Button>
                                   {/* <Button type="primary" onClick={() => {
                                    setSelectedTeam(tableData?.[0]?.id)
                                    setIsCreateModalOpen(true)
                                }
                                }
                                    style={{ marginTop: '16px' }}>Edit Jersey Number</Button> */}
                            </Space>
                        </Row>
                    <LineUpTable lineUpData={tableData?.[1]?.lineups} teamId={tableData?.[1]?.id} refresh={refetch} />
                </Col>

                {isCreateModalOpen && <Modal title="Add Players" width={1000} open={isCreateModalOpen} footer={null} onCancel={handleCancel}>
                    <NewLineUp  existingPlayers={tableData.filter((data) => data.id === selectedTeam)[0]} seasonId={matchData?.tournamentSeason?.id} teamId={selectedTeam} matchId={selectedMatchId} refresh={refetch} close={handleCancel} />
                </Modal>}

                {isEditModalOpen && <Modal style={{ height: "500px" }}  maskClosable={false}  title={<span style={{ fontSize:"20px"}}>Edit Jersey Number</span>}  open={isEditModalOpen} footer={null} onCancel={handleEditClose}>
                <EditJerseyTable lineUpData={tableData.filter((data) => data.id === selectedTeam)[0]} matchId={selectedMatchId} close={handleEditClose}  seasonId={matchData?.tournamentSeason?.id}  teamId={selectedTeam} refresh={refetch} matchLineups={matchLineUps} />
                 </Modal>}

              {/* {isSubstituteModalOpen && <Modal title={`Substitution(${tableData[selectedTeamIndex]?.name})`} open={isSubstituteModalOpen} footer={null} onCancel={handleSubstituteCancel}>
                <PlayerSubstitute playerList={tableData?.[selectedTeamIndex]?.lineups} matchId={selectedMatchId} close={handleCancel} teamId={selectedTeam} />
            </Modal>} */}
            </>

        )
    }

    export default LineUpTab