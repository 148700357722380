// src/components/Login.js

import React, { useState } from "react";
import { Form, Input, Button, notification, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router";
import OTPField from "./otp-field";
import NameField from "./name-field";
import PhoneField from "./phone-field";
import Cookies from "js-cookie";
import {
    getAuth,
    RecaptchaVerifier,
    signInWithPhoneNumber,
} from "firebase/auth";
import { app } from "../../firebase";
import jwtDecode from "jwt-decode";
import ONBOARD_USER from "../../api/onboard_user";
import { useMutation } from "@apollo/client";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const Login = () => {
    const [loading, setLoading] = useState(false);
    const [mode, setMode] = useState('enter-phone');
    const [confirmationResult, setConformationResult] = useState();
    const [onboardUser] = useMutation(ONBOARD_USER);
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState(null)
    const [phoneNumber, setPhoneNumber] = useState();

    const auth = getAuth(app);
    const handlePhoneSubmit = async (values) => {
        setErrorMessage(null);
        try {
            setLoading(true);
            const recaptchaVerifier = new RecaptchaVerifier(
                "recaptcha-container",
                { size: "invisible" },
                auth
            );
            // Simulate authentication. Replace this with your actual authentication logic.
            await recaptchaVerifier.verify();
            setPhoneNumber(values?.phone)
            signInWithPhoneNumber(
                auth,
                `+91${values?.phone}`,
                recaptchaVerifier
            ).then((result) => {
                setConformationResult(result)
                setMode('otp-validation')
            }).catch((error) => {
                setErrorMessage(error?.message)
                clearRecaptcha()
            }).finally(() => {
                setLoading(false)
            });
        } catch (err) {
            setLoading(false)
            setErrorMessage('Something went wrong..')
        }

    };

    const handleOtpSubmit = async (values) => {
        setLoading(true);
        confirmationResult.confirm(values?.code?.join(''))
            .then((result) => {
                const user = result.user;
                if (user?.accessToken) {
                    const decodedToken = jwtDecode(user.accessToken);
                    Cookies.set("jwt-token", user.accessToken, {
                        // domain: process.env.REACT_APP_COOKIE_DOMAIN,
                        path: "/",
                    });
                    if (!decodedToken?.verified) {
                        setMode('full-name')
                    } else {
                        Cookies.set("userId", decodedToken?.userId, {
                            //   domain: process.env.REACT_APP_COOKIE_DOMAIN,
                            path: "/",
                        });
                        navigate("/");
                    }
                };
            }).catch((error) => {
                setErrorMessage(error?.message)
            }).finally(() => {
                setLoading(false);
            })
    }

    const handleNameSubmit = async (values) => {
        setLoading(true);
        onboardUser({
            variables: { fullName: values?.fullName },
        }).then(async (result) => {
            if (result?.data?.onboardUser === true) {
                try {
                    const idToken = await auth.currentUser?.getIdToken(true);
                    const decodedToken = jwtDecode(idToken);
                    Cookies.set("jwt-token", idToken, {
                        // domain: process.env.REACT_APP_COOKIE_DOMAIN,
                        path: "/",
                    });
                    Cookies.set("userId", decodedToken?.userId, {
                        // domain: process.env.REACT_APP_COOKIE_DOMAIN,
                        path: "/",
                    });
                    navigate('/')
                } catch (error) {
                    console.error(error);
                }
            }
        }).catch((error) => {
            setErrorMessage(error?.message);
        }).finally(() => {
            setLoading(false);
        });
    }

    const resendOtp = async () => {
        clearRecaptcha();
        handlePhoneSubmit({ phone: phoneNumber })
    };

    const clearRecaptcha = () => {
        const container = document.getElementById("recaptcha-container");
        if (container) {
            container.remove();
            const newContainer = document.createElement("div");
            newContainer.id = "recaptcha-container";
            document.body.appendChild(newContainer);
        }
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

            <div className="login-form">
                <h2 style={{ margin: '12px 0px 36px 0px', textAlign: 'center' }}>GoalRush Admin Sign in</h2>
                {mode === 'enter-phone' && <PhoneField onSubmit={handlePhoneSubmit} />}
                {mode === 'otp-validation' && <OTPField onSubmit={handleOtpSubmit} resendOtp={resendOtp} />}
                {mode === 'full-name' && <NameField onSubmit={handleNameSubmit} />}

                <div
                    id="recaptcha-container"
                    style={{ visibility: "hidden", display: "none" }}
                ></div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {loading && <Spin indicator={antIcon} />}
                    {errorMessage && <span style={{ color: 'red' }}>{errorMessage}</span>}
                </div>

            </div>
        </div>

    );
};

export default Login;
