import { gql } from "@apollo/client";

const UPDATE_USER_BY_ID = gql`
  mutation updateUser($user_id: String!, $updateUserInput: UpdateUserInput!) {
    updateUser(user_id: $user_id, updateUserInput: $updateUserInput) {
    id
    name
    email
    phone_number
    display_picture
    position
    preferred_jersey_no
    status
    teamPlayer {
      id
      join_date
      leave_date
      status
    }
    }
  }
`;

export default UPDATE_USER_BY_ID;
