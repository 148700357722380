import { gql } from "@apollo/client";

const CREATE_TOURNAMENT= gql`
mutation createTournament($createTournamentInput: CreateTournamentInput!) {
  createTournament(createTournamentInput: $createTournamentInput) {
    id
    name
    display_picture
    description
   
    about_tournament
  }
}

`;

export default CREATE_TOURNAMENT;
