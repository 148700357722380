import { PlusSquareOutlined } from "@ant-design/icons";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { Col, message, Modal, Popconfirm, Row, Space, Table, Typography } from "antd";
import { useEffect, useState } from "react"
import { useParams } from "react-router";
import DELETE_TOURNAMENT_SEASON from "../../../../api/delete_tournament_season";
import FIND_ALL_GROUPS_BY_SEASON_ID from "../../../../api/get_all_groups_by_season";
import FIND_ALL_ROUNDS_BY_SEASON_ID from "../../../../api/get_all_rounds_by_season";
import GET_MATCHES_BY_TOURNAMENT_SEASON_ID from "../../../../api/get_matches_by_tournament_season_id"
import Match from "../../../../components/match";
import TournamentSeason from "../../../../components/tournament-season";
import CreateRound from "./blocks/new_group";
import CreateGroup from "./blocks/new_group";
import CreateSeason from "./blocks/new_group";

import './style.scss';

const GroupsTab = ({ selectedSeasonId, refetchSeason }) => {
    const [messageApi, contextHolder] = message.useMessage();
    const [groups, setGroups] = useState();
    const [getAllGroups, { data: allSeasonGroups, loading: allGroupsLoading, error: allGroupsError, refetch }] = useLazyQuery(FIND_ALL_GROUPS_BY_SEASON_ID, {
        variables: {
            tournament_season_id: selectedSeasonId
        }
    });
    const [seasonDelete, { data: seasonDeleteData, loading: seasonDeleteLoading, error: seasonDeleteError }] = useMutation(DELETE_TOURNAMENT_SEASON);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState(null);

    useEffect(() => {
        const formattedGroups = allSeasonGroups?.findAllGroupsBySeasonId?.map((group) => {
            return { key: group?.id, ...group }
        })

        setGroups(formattedGroups)
    }, [allSeasonGroups])

    useEffect(() => {
        if (selectedSeasonId) {
            getAllGroups()
        }
    }, [selectedSeasonId])

    const columns = [
        {
            title: 'Group Name',
            dataIndex: 'group_name',
            key: 'group_name',
            width: '50%',
            render: (group_name) => group_name
        },


        {
            title: 'Action',
            key: 'action',
            width: '5%',
            render: (_, record) => (
                <Space size="middle">
                    <a onClick={() => onGroupEdit(record)}>Edit</a>
                    <Typography.Link>
                        <Popconfirm title="Delete" onConfirm={() => onGroupDelete(record)}>
                            <a style={{ marginLeft: '24px' }}>Delete</a>
                        </Popconfirm>
                    </Typography.Link>
                </Space>
            ),
        },
    ];

    const onGroupEdit = (season) => {
        setSelectedGroup(season)
        setIsCreateModalOpen(true);
    }

    const onGroupDelete = (id) => {
        // seasonDelete({
        //     variables: {
        //         tournament_season_id: id
        //     }
        // }).then(res => {
        //     messageApi.open({
        //         type: 'success',
        //         content: 'Deleted Successfully',
        //     });
        //     refetchSeason();
        // }).catch(error => {
        //     messageApi.open({
        //         type: 'error',
        //         content: error?.message || 'Something went wrong!',
        //     });
        // })
    }

    const handleCancel = () => {
        setIsCreateModalOpen(false);

        if (selectedGroup) {
            setSelectedGroup(null)
        }
    };

    const showModal = () => {
        setIsCreateModalOpen(true);
    };


    return (
        <>
            {selectedSeasonId ? <div className="seasons">
                {contextHolder}
                <h1 >Groups <PlusSquareOutlined onClick={showModal} style={{ color: 'blue', fontSize: '24PX' }} /></h1>

                <div className="season-container">
                    {/* <Row gutter={24} style={{ width: '100%' }}> */}
                    <Table bordered={true} columns={columns} dataSource={groups} pagination={false} />
                    {/* </Row> */}

                </div>


                {isCreateModalOpen && <Modal title={selectedGroup ? "Update Season Group" : "Create Season Group"} width={600} open={isCreateModalOpen} footer={null} onCancel={handleCancel}>
                    <CreateGroup selectedSeasonId={selectedSeasonId} record={selectedGroup} refresh={refetch} close={handleCancel} />
                </Modal>}
            </div> : <div className='empty-container'>Please select season</div>}</>


    )
}


export default GroupsTab