import { gql } from '@apollo/client';

const GET_IMAGES_FROM_TOURNAMENT_SEASON_GALLERY = gql`
 query getImagesFromTournamentSeasonGallery($tournament_season_id: String!, $is_published: Boolean!,$match_id: String ) {
getImagesFromTournamentSeasonGallery(tournament_season_id: $tournament_season_id, is_published: $is_published, match_id: $match_id) {
 id
 display_picture
is_published
created_at
updated_at
match_id }
 }
`;
export default GET_IMAGES_FROM_TOURNAMENT_SEASON_GALLERY;