import { ConfigProvider } from "antd";

const AppThemeProvider = ({ children }) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          //   Segmented: {
          //     itemSelectedBg: "green",
          //   },
        },
        token: {
          // Seed Token
          //   colorPrimary: "#00b96b",
          // borderRadius: 2,
          // // Alias Token
          // colorBgContainer: '#f6ffed',
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};

export default AppThemeProvider;
