import { LoadingOutlined } from "@ant-design/icons";
import { useLazyQuery } from "@apollo/client";
import { Button, Col, Modal, Row, Space, Spin, Input } from "antd"
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import GET_MATCH_LINEUP_BY_ID from "../../../../api/get_match_line_ups";
import GET_TEAM_PLAYERS_BY_TEAM from "../../../../api/get_team_players_by_team";
import LineUpTable from "../../../lineups/blocks/lineup-table";
import NewLineUp from "../../../lineups/blocks/new_lineup";
import AddPlayer from "../../../team-players/blocks/add_player";
import PlayersTable from "../../../team-players/blocks/players-table";

const PlayersTab = ({ }) => {
    const { id: teamId } = useParams();
    const [selectedMatchTeam, setSelectedMatchTeam] = useState(null)
    const [tableData, setTableData] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [getTeamPlayersByTeam, { data: teamPlayers, loading: teamPlayersLoading, refetch }] = useLazyQuery(GET_TEAM_PLAYERS_BY_TEAM, {
        variables: { team_id: teamId },
      });
    // const [getMatchLineups, { data: matchLineUps, loading: matchLineUpsLoading, refetch }] = useLazyQuery(GET_MATCH_LINEUP_BY_ID, {
    //     variables: { match_id: selectedMatchId },
    // });

    useEffect(() => {
        if (teamId) {
            getTeamPlayersByTeam()
        }
    }, [teamId])

    //   useEffect(() => {
    //     setSelectedMatchTeam({ team1: allMatches?.matches[0]?.team1, team2: allMatches?.matches[0]?.team2 })
    //     setSelectedMatch(allMatches?.matches[0]?.id)
    //   }, [allMatches])

    // useEffect(() => {
    //     const originData = [];

    //     matchLineUps?.matchLineupByMatchId?.map((team) => {
    //         let obj = { id: team?.id, name: team?.name, lineups: [] }
    //         team?.lineup?.map((lineup) => {
    //             obj.lineups.push(formatLineupData(lineup));
    //         })

    //         originData.push(obj)
    //     });

    //     setTableData(originData);
    // }, [matchLineUps]);

    useEffect(() => {
        setTableData(teamPlayers?.teamPlayersByTeam)
      }, [teamPlayers])

    

    const onSearchTeamPlayer = (event) => {
        let data;
    
        if (event?.target?.value) {
          data = teamPlayers?.teamPlayersByTeam?.filter(record => record?.user?.name?.toLowerCase().includes(event?.target?.value?.toLowerCase()))
        } else {
          data = teamPlayers?.teamPlayersByTeam;
        }
    
        const list = [];
    
        data.map((record) => {
          list.push({
            ...record,
            key: record?.id,
          })
        })
    
        setTableData(list)
      }

    const handleCancel = () => {
        setIsCreateModalOpen(false);
    };
    return (
        <>
            <Row>
                <Col span={24}>
                    {teamPlayersLoading ?
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                        </div>
                        :
                        <>
                            {teamId && <Col span={24}>
                                <Col span={24} style={{ paddingTop: "20px" }}>
                                    <Row style={{ marginTop: '32px' }}>
                                        <Col span={5}>
                                            <Input placeholder="Search Team player" onChange={onSearchTeamPlayer} />
                                        </Col>
                                        <Col span={15}></Col>
                                        <Col span={4} style={{ textAlign: 'right', paddingBottom: '18px' }}>
                                            <Button type="primary" onClick={() => {
                                                setIsCreateModalOpen(true)
                                            }
                                            }
                                            >Add Players</Button>
                                        </Col>
                                    </Row>

                                    <PlayersTable playersData={tableData} refresh={refetch} />
                                </Col>
                            </Col>
                            }
                        </>
                    }
                </Col>
            </Row>

            {isCreateModalOpen && <Modal title="Add Players" width={1000} open={isCreateModalOpen} footer={null} onCancel={handleCancel}>
                <AddPlayer teamId={teamId} refresh={refetch} close={handleCancel} />
            </Modal>}
        </>

    )
}

export default PlayersTab