import {
  BorderBottomOutlined,
  DeleteFilled,
  DeleteOutlined,
  DeleteTwoTone,
  EditFilled,
  EditOutlined,
  EditTwoTone,
  EllipsisOutlined,
  ExclamationCircleFilled,
  PlusOutlined,
  SettingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Image,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Select,
  Skeleton,
  Switch,
  Upload,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
// import ADD_IMAGES_TO_TOURNAMENT_SEASON_GALLERY from "../../../../api/add_images_to_tournament_season_gallery";
import { useLazyQuery, useMutation } from "@apollo/client";
import GET_IMAGES_FROM_TOURNAMENT_SEASON_GALLERY from "../../../../api/get_Images_From_Tournament_Season_Gallery";
import DELETE_IMAGES_FROM_TOURNAMENT_SEASON_GALLERY from "../../../../api/delete_images_from_tournament_season_gallery";
import Meta from "antd/es/card/Meta";
import UpdateGalleryImage from "./updategalleryimage";
import AddGalleryImage from "./addGalleryImage";
import GET_MATCHES_BY_TOURNAMENT_SEASON_ID from "../../../../api/get_matches_by_tournament_season_id";
import { all } from "axios";

const { confirm } = Modal;

const GalleryTab = ({ selectedSeasonId }) => {
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [updateGallery, setUpdateGallery] = useState("");
  const [selectedMatch, setselectedMatch] = useState("");
  const [showAll, setShowAll] = useState(true);
  const [selectedImages, setSelectedImages] = useState([]);
  const [filterGallery, setFiltergallery] = useState([]);

  const [
    getGalleryImages,
    { data: galleryImages, loading: galleryImagesLoading, refetch },
  ] = useLazyQuery(GET_IMAGES_FROM_TOURNAMENT_SEASON_GALLERY);

  const [
    deleteImages,
    { data: deleteImageGalleryData, error: deleteImageGalleryError },
  ] = useMutation(DELETE_IMAGES_FROM_TOURNAMENT_SEASON_GALLERY);

  const showDeleteConfirm = (galleryImage) => {
    confirm({
      title: "Do you Want to delete this image?",
      icon: <ExclamationCircleFilled />,
      onOk() {
        {
          DeleteImageButtonClick(galleryImage?.id);
        }
      },
      onCancel() {
        message.error("Cancelled");
      },
    });
    refetch();
  };

  const DeleteImageButtonClick = (key) => {
    deleteImages({
      variables: { gallery_id: key },
    })
      .then(() => {
        refetch();
      })
      .catch((error) => {
        console.log("error");
      });
  };

  const cancel = (e) => {
    message.error("Cancelled");
  };

  useEffect(() => {
    if (selectedSeasonId)
      getGalleryImages({
        variables: {
          tournament_season_id: selectedSeasonId,

          is_published: true,
        },
      });
  }, [selectedSeasonId]);

  useEffect(() => {
    if (galleryImages) {
      setSelectedImages("all");
      handleMatchSelect();
      setselectedMatch("");
      filterMatches();
    }
  }, [galleryImages]);

  const [
    getTournamentMatchesData,
    { data: matchesData, loading: matchesLoading },
  ] = useLazyQuery(GET_MATCHES_BY_TOURNAMENT_SEASON_ID, {
    variables: { season_id: selectedSeasonId },
  });

  const matches =
    matchesData?.getMatchesByTournamentSeasonIdWithGroupByTournamentRound[0]
      ?.matches;
  const selectMatches = Array.isArray(matches)
    ? matches.map((match) => ({
        key: match?.id,
        value: match?.id,
        description: match?.description,
        label: `${match?.team1?.name} vs ${match?.team2?.name}`,
      }))
    : [];

  const showAllOption = {
    key: "all",
    value: "all",
    label: "All",
  };
  const selectMatchesWithShowAll = [showAllOption, ...selectMatches];

  useEffect(() => {
    getTournamentMatchesData();
  }, [selectedSeasonId]);

  const filterMatches = (value) => {
    setselectedMatch(value);
    let filteredGalleryImages = [];
    if (value == "" || value == undefined) {
      filteredGalleryImages =
        galleryImages?.getImagesFromTournamentSeasonGallery;
      setFiltergallery(filteredGalleryImages);
    } else {
      filteredGalleryImages =
        galleryImages?.getImagesFromTournamentSeasonGallery.filter(
          (galleryImage) => galleryImage?.match_id?.includes(value)
        );
      setFiltergallery(filteredGalleryImages);
    }
  };

  let AllGalleryImages = [];
  galleryImages?.getImagesFromTournamentSeasonGallery?.map((galleryImage) =>
    AllGalleryImages.push(galleryImage)
  );

  const handleClick = () => {
    setIsAddModalVisible(true);
  };

  const handleMatchSelect = (value) => {
    if (value == "all" || value == undefined) {
      setselectedMatch(null);
      setFiltergallery(galleryImages?.getImagesFromTournamentSeasonGallery);
      refetch();
    } else {
      setselectedMatch(value);
      filterMatches(value);
      refetch();
    }
  };
  // const onChange = (checked) => {
  //   setShowAll(checked);
  //   setselectedMatch("");
  // };

  // useEffect(() => {
  //   if (showAll == false) {
  //     setgalleryImageList(galleryImagesFilter);
  //   } else if (showAll == true) {
  //     setgalleryImageList(filteredGalleryImages);
  //   }
  //   refetch();
  // }, [showAll, selectedMatch]);

  const handleImageSelect = (imageId) => {
    setSelectedImages((prevSelected) =>
      prevSelected.includes(imageId)
        ? prevSelected.filter((id) => id !== imageId)
        : [...prevSelected, imageId]
    );
  };

  const handleDeleteImages = () => {
    selectedImages.forEach((imageId) => DeleteImageButtonClick(imageId));

    setSelectedImages([]);
  };

  return (
    <>
      <div
        className="flex flex-wrap justify-center text-white"
        flex="container"
      >
        <Row>
          {
            <Row>
              <Col xs={24} sm={12} md={8} lg={6}>
                {/* <Select
                  defaultValue="Select match"
                  style={{ width: 300, margin: 5 }}
                  options={selectMatches}
                  onChange={handleMatchSelect}
                /> */}
                <Select
                  allowClear
                  // onClear={handleClear}
                  style={{ width: 300, margin: 5 }}
                  defaultValue={"all"}
                  onChange={handleMatchSelect}
                  options={selectMatchesWithShowAll}
                />
              </Col>
            </Row>
          }
          <Col
            style={{ width: "100%", display: "flex", justifyContent: "right" }}
          >
            {selectedImages.length > 0 && (
              <Button
                type="primary"
                onClick={handleDeleteImages}
                style={{ padding: "5px", marginRight: "10px" }}
              >
                Delete Selected Images
              </Button>
            )}
            <Button
              style={{ display: "flex", justifyContent: "right" }}
              type="primary"
              onClick={handleClick}
            >
              Add Image
            </Button>
          </Col>
        </Row>

        {isAddModalVisible && (
          <Modal
            title="Add New Image"
            visible={isAddModalVisible}
            onCancel={() => setIsAddModalVisible(false)}
            onOk={() => {
              setIsAddModalVisible(false);
            }}
            okButtonProps={{}}
            footer={null}
            width={500}
          >
            <AddGalleryImage
              selectedSeasonId={selectedSeasonId}
              onCancel={() => setIsAddModalVisible(false)}
              onSubmit={() => refetch()}
              onOk={() => {
                setIsAddModalVisible(false);
              }}
            />
          </Modal>
        )}

        {isUpdateModalVisible && (
          <Modal
            title="Update Image"
            visible={isUpdateModalVisible}
            onCancel={() => setIsUpdateModalVisible(false)}
            onOk={() => {
              setIsUpdateModalVisible(false);
            }}
            okButtonProps={{}}
            footer={null}
          >
            <UpdateGalleryImage
              gallery={updateGallery}
              selectedSeasonId={selectedSeasonId}
              onCancel={() => setIsUpdateModalVisible(false)}
              onSubmit={() => refetch()}
              onOk={() => {
                setIsUpdateModalVisible(false);
              }}
            />
          </Modal>
        )}
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            flex: "container",
          }}
        >
          {filterGallery?.map((galleryImage) => (
            <div key={galleryImage?.id} style={{ margin: "10px" }}>
              <Checkbox
                checked={selectedImages.includes(galleryImage?.id)}
                onChange={() => handleImageSelect(galleryImage?.id)}
              />
              <Card
                cover={
                  galleryImage?.display_picture ? (
                    <Image
                      src={
                        galleryImage?.display_picture.startsWith("https")
                          ? galleryImage?.display_picture
                          : process.env.REACT_APP_API_URL +
                            "/media/download/" +
                            galleryImage?.display_picture
                      }
                      alt={galleryImage?.name}
                      style={{ objectFit: "cover", height: "200px" }}
                      preview={false}
                    />
                  ) : (
                    <Skeleton.Image
                      style={{ width: "100%", height: "200px" }}
                      active
                    />
                  )
                }
                actions={[
                  <DeleteTwoTone
                    twoToneColor="#eb2f96"
                    onClick={() => showDeleteConfirm(galleryImage)}
                    key="delete"
                  />,
                  <EditTwoTone
                    key="edit"
                    onClick={() => {
                      setUpdateGallery(galleryImage);
                      setIsUpdateModalVisible(true);
                    }}
                  />,
                ]}
              >
                <Meta />
              </Card>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export default GalleryTab;
