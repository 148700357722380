import React, { useEffect, useState } from "react";
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
} from "@apollo/client";
// import GET_MATCHES from "../../api/get_all_matches";
import {
  Col,
  Row,
  Select,
  Form,
  InputNumber,
  Popconfirm,
  Table,
  Typography,
  Avatar,
  Modal,
  message,
  Image,
} from "antd";
import { Spin } from "antd";
import dayjs from "dayjs";
import { compareString } from "../../utils/app.util";
import EditTeamPlayer from "../team-players/blocks/edit_team_player";
import CreateUser from "../users/blocks/new_user";
import DELETE_TOURNAMENT_SEASON_SQUAD from "../../api/delete_tournament_season_squad";
import { useParams } from "react-router";
import UpdateSquad from "./update_squad_member";

const SquadTable = ({ playersData, refetch }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const utc = require("dayjs/plugin/utc");
  dayjs.extend(utc);
  const [tableData, setTableData] = useState(playersData);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false);
  const [selectedTeamPlayer, setSelectedTeamPlayer] = useState(null);
  const { teamId } = useParams();

  const [
    deleteTournamentSeasonSquad,
    {
      data: removeTournamentSquad,
      loading: removeTournamentSquadLoading,
      error: tournamentSquadError,
    },
  ] = useMutation(DELETE_TOURNAMENT_SEASON_SQUAD);

  useEffect(() => {
    setTableData(formatPlayersData());
  }, [playersData]);

  const handleEditModalCancel = () => {
    setIsEditModalOpen(false);
  };

  const handleEditUserModalCancel = () => {
    setIsEditUserModalOpen(false);
  };
  const formatPlayersData = () => {
    if (Array.isArray(playersData)) {
      return playersData?.map((squad) => {
        return {
          key: squad?.id,
          name: squad?.playerName,
          display_picture: squad?.displayPicture,
          position: squad?.position,
          preferred_jersey_no: squad?.jerseyNo,
          status: squad?.status,
        };
      });
    } else {
      console.error("Squad data is not an array");
      return [];
    }
  };

  const onDelete = (record) => {
    deleteTournamentSeasonSquad({
      variables: {
        deleteTournamentSeasonSquadInput: {
          id: record?.key,
          team_id: teamId,
        },
      },
    })
      .then((res) => {
        messageApi.open({
          type: "success",
          content: "Player removed",
        });
        refetch();
      })
      .catch((err) => {
        messageApi.open({
          type: "error",
          content: err?.message || "Something went wrong!",
        });
      });
  };
  const onEdit = (record) => {
    setSelectedTeamPlayer(record);
    setIsEditModalOpen(true);
  };

  const onEditUserProfile = (record) => {
    setIsEditModalOpen(true);
    setSelectedTeamPlayer(record);
    // setIsEditUserModalOpen(true);
  };

  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;
  const columns = [
    {
      title: "#",
      width: "2%",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "",
      dataIndex: "display_picture",
      width: "5%",
      editable: false,
      render: (display_picture) => {
        return (
          display_picture && (
            <div
              style={{
                display: "inline-block",
                width: "32px",
                height: "32px",
                borderRadius: "50%",
                overflow: "hidden",
              }}
            >
              <Image
                src={display_picture}
                width="32px"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
          )
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      width: "15%",
      editable: false,
      // filters: [],
      // onFilter: (value, record) => record.name.indexOf(value) === 0,
      defaultSortOrder: "ascend",
      sorter: (a, b) => compareString(a, b, "name"),
    },
    {
      title: "Position",
      dataIndex: "position",
      width: "12%",
      editable: false,
      sorter: (a, b) => a?.position - b?.position,
    },
    {
      title: "Jersey",
      dataIndex: "preferred_jersey_no",
      width: "8%",
      editable: false,
      sorter: (a, b) => a?.preferred_jersey_no - b?.preferred_jersey_no,
    },
    // {
    //   title: "Join Date",
    //   dataIndex: "join_date",
    //   width: "15%",
    //   editable: false,
    //   render: (join_date) => {
    //     return dayjs(join_date)?.format("YYYY-MM-DD HH:mm:ss");
    //   },
    // },
    // {
    //   title: "Leave Date",
    //   dataIndex: "leave_date",
    //   width: "15%",
    //   editable: false,
    //   render: (leave_date) => {
    //     if (leave_date) {
    //       return dayjs(leave_date)?.format("YYYY-MM-DD HH:mm:ss");
    //     }
    //   },
    // },
    {
      title: "Status",
      dataIndex: "status",
      width: "10%",
      editable: false,
    },
    {
      title: "Operation",
      dataIndex: "operation",
      width: "20%",
      render: (_, record) => {
        const editable = isEditing(record);
        return (
          <>
            {/* <Typography.Link>
              <a onClick={() => onEdit(record)}>Edit</a>
            </Typography.Link> */}

            <Typography.Link>
              <a
                style={{ marginLeft: "16px" }}
                onClick={() => onEditUserProfile(record)}
              >
                Edit Profile
              </a>
            </Typography.Link>

            <Typography.Link>
              <Popconfirm
                title="Delete "
                onConfirm={() => onDelete(record)}
                refetch={refetch}
              >
                <a style={{ marginLeft: "16px" }}>Delete</a>
              </Popconfirm>
            </Typography.Link>
          </>
        );
      },
    },
  ];

  // useEffect(() => {
  //   const isDuplicate = tableData?.some((player, index) =>
  //     tableData
  //       .slice(index + 1)
  //       .some(
  //         (otherPlayer) => player?.jerseyNumber === otherPlayer?.jerseyNumber
  //       )
  //   );

  //   if (isDuplicate) {
  //     setDuplicateJersey(true);
  //     setErrorField("jerseyNumber");
  //     setErrorMessage(
  //       "Duplicate Jersey Numbers:Some players have the same jersey number."
  //     );
  //   } else {
  //     setErrorMessage("");
  //     setErrorField(null);
  //     setDuplicateJersey(false);
  //   }
  // }, [tableData]);

  return (
    <>
      {contextHolder}
      <Row>
        <Col span={24}>
          <Form form={form} component={false}>
            <Table
              style={{ marginBottom: 20 }}
              bordered
              dataSource={tableData}
              columns={columns}
              rowClassName="editable-row"
              pagination={false}
              scroll={{ y: 300, x: "auto" }}
            />
          </Form>
        </Col>
      </Row>

      {isEditModalOpen && (
        <Modal
          title="Edit Team Player"
          width={450}
          open={isEditModalOpen}
          footer={null}
          onCancel={handleEditModalCancel}
        >
          <UpdateSquad
            teamId={teamId}
            record={selectedTeamPlayer}
            refresh={refetch}
            close={handleEditModalCancel}
          />
        </Modal>
      )}

      {isEditUserModalOpen && (
        <Modal
          title="Edit Team Player"
          width={450}
          open={isEditUserModalOpen}
          footer={null}
          onCancel={handleEditUserModalCancel}
        >
          <CreateUser
            record={selectedTeamPlayer?.user}
            refresh={refetch}
            close={handleEditUserModalCancel}
          />
        </Modal>
      )}
    </>
  );
};

export default SquadTable;
