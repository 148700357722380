import { gql } from "@apollo/client";


const UPDATE_GOALRUSH_SCHOOL = gql`
  mutation UpdateGoalrushSchool($goalrush_school_id: String!, $updateGoalrushSchoolInput: UpdateGoalrushSchoolInput!) {
    updateGoalrushSchool(goalrush_school_id: $goalrush_school_id, updateGoalrushSchoolInput: $updateGoalrushSchoolInput) {
      id
      name
      description
      links
      images
      type
      createdAt
      createdBy
    }
  }
`;

export default UPDATE_GOALRUSH_SCHOOL;
