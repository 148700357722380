import { gql } from "@apollo/client";

const UPDATE_TEAM_PLAYER_BY_ID = gql`
  mutation updateTeamPlayer($team_player_id: String!, $updateTeamPlayerInput: UpdateTeamPlayerInput!) {
    updateTeamPlayer(team_player_id: $team_player_id, updateTeamPlayerInput: $updateTeamPlayerInput) {
      id
      join_date
      leave_date
      status
      user {
        id
        name
        email
        phone_number
        display_picture
        position
        preferred_jersey_no
        status
      }
      team {
        id
        name
        description
        display_picture
      }
    }
  }
`;

export default UPDATE_TEAM_PLAYER_BY_ID;
