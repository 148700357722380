import isToday from "dayjs/plugin/isToday";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Avatar,
  Badge,
  Button,
  Card,
  Col,
  Input,
  message,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Segmented,
  Tag,
} from "antd";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router";
import GET_MATCHES_BY_TOURNAMENT_SEASON_ID from "../../../../api/get_matches_by_tournament_season_id";
import Match from "../../../../components/match";
import CreateMatch from "../../../matches/blocks/new_match";

import "./style.scss";
import DELETE_MATCH_BY_ID from "../../../../api/delete_match_by_id";
import GET_MATCH_BY_ID from "../../../../api/get_match_by_id";
import { CheckCircleOutlined, DeleteOutlined } from "@ant-design/icons";
dayjs.extend(isToday);

const MatchTab = ({ seasonId, tournamentId, tournamentsData }) => {
  const [matches, setMatches] = useState(null);
  const [live, setLive] = useState([]);
  const [data, setData] = useState([]);
  const [initialFilteredData, setInitialFilteredData] = useState([]);
  const [filter, setFilter] = useState("all");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedMatchId, setSelectedMatchId] = useState(null);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [selectedMatchRecord, setSelectedMatchRecord] = useState(null);
  const navigate = useNavigate();
  const RadioGroup = Radio.Group;
  const utc = require("dayjs/plugin/utc");
  dayjs.extend(utc);

  const [
    getTournamentMatchesData,
    { data: matchesData, loading: matchesLoading, refetch },
  ] = useLazyQuery(GET_MATCHES_BY_TOURNAMENT_SEASON_ID, {
    variables: { season_id: seasonId },
  });

  useEffect(() => {
    if (seasonId) {
      getTournamentMatchesData();
    }
    refetch();
  }, [seasonId]);

  // const {
  //   data: matchEvents,
  //   loading: matchEventsLoading,
  //   error: matchEventsEror,
  //   refetch: matchEventsRefetch,
  // } = useQuery(GET_MATCH_BY_ID, {
  //   variables: {
  //     id: seasonId,
  //   },
  // });
  const [
    deleteMatch,
    {
      data: deleteMatchData,
      loading: deleteMatchLoading,
      error: deleteMatchError,
    },
  ] = useMutation(DELETE_MATCH_BY_ID);

  useEffect(() => {
    if (matchesData) {
      formatMatches();
    }
  }, [matchesData]);

  const formatMatches = () => {
    let matchesByType = {};

    matchesData?.getMatchesByTournamentSeasonIdWithGroupByTournamentRound?.map(
      (round) => {
        matchesByType[round?.type] = round?.matches;
      }
    );

    const matchArray = Object.keys(matchesByType).reduce((acc, type) => {
      const newAcc = [...acc];
      newAcc.push(...matchesByType[type]);

      return newAcc;
    }, []);

    const liveMatches = matchArray.filter((match) => {
      return match?.timer?.match_status == "LIVE";
    });

    setLive(liveMatches);
    setMatches(matchesByType);
  };
  const showModal = () => {
    setIsCreateModalOpen(true);
  };

  const onMatchClick = (matchId) => {
    navigate(`/match-details/${matchId}`);
  };
  const handleCancel = () => {
    setIsCreateModalOpen(false);
    setSelectedMatchRecord(null);
  };
  useEffect(() => {
    handleFilterClick(filter);
  }, [filter]);

  const filteredMatchData = [];
  filteredData?.map((roundMatch) => {
    roundMatch?.matches.map((match) =>
      filteredMatchData.push({
        key: match.id,
        value: match.id,
        label: `${match?.team1?.name} vs ${match?.team2?.name}`,
      })
    );
  });
  const handleMatchSelect = (value) => {
    setSelectedMatchId(value);
  };

  const handleFilterClick = (value) => {
    setFilter(value);
  };

  const filterData = () => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    const isMatchDayToday = (someDate) => {
      const tdy = dayjs();
      const smDte = dayjs(someDate);
      return dayjs(someDate).isToday();
    };

    const isUpcoming = (someDate) => {
      return dayjs(someDate).isAfter(dayjs());
    };

    let filteredRounds;

    switch (filter) {
      case "all": {
        filteredRounds =
          matchesData?.getMatchesByTournamentSeasonIdWithGroupByTournamentRound.map(
            (round) => ({
              roundId: round.id,
              roundType: round.type,
              matches: round.matches,
            })
          );
        break;
      }

      case "today": {
        filteredRounds =
          matchesData?.getMatchesByTournamentSeasonIdWithGroupByTournamentRound?.map(
            (round) => ({
              roundId: round.id,
              roundType: round.type,
              matches: round.matches.filter((match) =>
                isMatchDayToday(match?.match_schedule?.start_date)
              ),
            })
          );
        break;
      }

      case "upcoming": {
        filteredRounds =
          matchesData?.getMatchesByTournamentSeasonIdWithGroupByTournamentRound?.map(
            (round) => ({
              roundId: round.id,
              roundType: round.type,
              matches: round.matches.filter((match) =>
                isUpcoming(match?.match_schedule?.start_date)
              ),
            })
          );
        break;
      }
    }

    setData(filteredRounds);
    setInitialFilteredData(filteredRounds);
    setFilteredData(filteredRounds);
  };

  useEffect(() => {
    filterData();
  }, [filter, matchesData]);

  const onSearch = (value) => {};
  useEffect(() => {
    if (selectedMatchId) {
      formatMatches();
    }
  }, [filteredData]);

  const onSearchMatches = (event) => {
    let data;
    if (event?.target?.value && event?.target?.value.trim() !== "") {
      data = filteredData?.map((round) => ({
        roundId: round.id,
        roundType: round.type,
        matches: round.matches.filter(
          (match) =>
            match?.team1?.name
              ?.toLowerCase()
              .includes(event?.target?.value?.toLowerCase()) ||
            match?.team2?.name
              ?.toLowerCase()
              .includes(event?.target?.value?.toLowerCase())
        ),
      }));
    } else {
      data = initialFilteredData;
    }

    const list = [];
    if (Array.isArray(data)) {
      data.map((match) => {
        list.push({
          ...match,
          key: match?.id,
        });
      });
    }
    setFilteredData(list);
  };
  const onDelete = (matchId) => {
    deleteMatch({
      variables: {
        match_id: matchId,
      },
    })
      .then((resp) => {
        messageApi.open({
          type: "success",
          content: "Deleted Successfully",
        });
        refetch();
      })
      .catch((err) => {
        messageApi.open({
          type: "error",
          content: err?.message || "Something went wrong!",
        });
      });
  };

  const handleBulkMatch = () => {
    navigate(`/bulk-matches/${tournamentId}/${seasonId}`);
  };
  return (
    <div>
      {contextHolder}
      <Row>
        <Segmented
          value={filter}
          onChange={setFilter}
          options={[
            {
              label: "All",
              value: "all",
            },
            {
              label: "Today",
              value: "today",
            },
            {
              label: "Upcoming",
              value: "upcoming",
            },
          ]}
        />
        {/* <Button style={{ marginLeft: "12px" }} type="primary">
          Show as Table
        </Button> */}
      </Row>
      <Row style={{ marginTop: "10px" }}>
        <Col span={5}>
          <Input placeholder="Search Match" onChange={onSearchMatches}></Input>
        </Col>
      </Row>
      <Row style={{ marginBottom: "24px" }}>
        <Col span={5}></Col>
        <Col span={22} style={{ textAlign: "right" }}>
          <Button type="primary" onClick={handleBulkMatch}>
            Bulk Match Upload
          </Button>
        </Col>
        <Col span={2} style={{ textAlign: "center" }}>
          <Button type="primary" onClick={showModal}>
            Add New
          </Button>
        </Col>
      </Row>
      <Row>{live.length > 0 && <h2>Live Matches</h2>}</Row>
      <Row>
        {live.map((match, index) => (
          <Row className="match-card-wrapper ">
            <Col className="match-card-column">
              <Card
                onClick={() => onMatchClick(match?.id)}
                className="match_card"
              >
                <Row className="live-tag-row">
                  <Col className="live-tag-column1" span={16}>
                    <Tag className="live-tag " color="magenta">
                      <div>
                        <Row>
                          <Col className="blink-dot-column" span={8}>
                            <div className="blinking-dot"> </div>
                          </Col>
                          <Col
                            className="blink-dot-column"
                            style={{ fontSize: "12px", fontWeight: "bold" }}
                            span={16}
                          >
                            LIVE
                          </Col>{" "}
                        </Row>
                      </div>
                    </Tag>
                  </Col>
                  <Col className="live-tag-column2" span={8}>
                    <Tag className="stage-tag">{match?.timer?.match_stage}</Tag>
                  </Col>
                </Row>
                {/* {data?.tournament_season_group?.group_name && (
            <Badge.Ribbon
              text={`Group ${data.tournament_season_group.group_name}`}
            />
          )} */}
                <Row style={{ marginTop: "10px" }}>
                  <Col style={{ marginTop: "20px" }} span={11}>
                    <div className="match-logo">
                      {match?.team1?.display_picture && (
                        <Avatar
                          className="match-avatar"
                          src={match?.team1?.display_picture}
                        />
                      )}
                    </div>
                    <div className="team-name">{match?.team1?.name}</div>
                    {/* </Card> */}
                  </Col>
                  <Col span={2} className="match-X">
                    X
                  </Col>
                  <Col style={{ marginTop: "20px" }} span={11}>
                    <div className="match-logo">
                      {match?.team2?.display_picture && (
                        <Avatar
                          className="match-avatar"
                          src={match?.team2?.display_picture}
                        />
                      )}
                    </div>
                    <div className="team-name">{match?.team2?.name}</div>
                  </Col>
                </Row>
                <Row className="schedule-row">
                  <Card className="schedule">
                    {dayjs(match?.match_schedule?.start_date).format(
                      "DD-MM-YYYY hh:mm"
                    )}
                  </Card>
                </Row>
              </Card>
            </Col>
          </Row>
        ))}
      </Row>
      {seasonId ? (
        <div style={{ margin: "0px", padding: "2px" }}>
          {filteredData &&
            filteredData?.map((round) => {
              return (
                <div key={round.roundId}>
                  {round?.matches?.length > 0 && <h2>{round.roundType}</h2>}
                  <div className="matches">
                    <Row className="match-rows">
                      {round?.matches?.map((match) => {
                        return (
                          <Match
                            data={match}
                            controls={{ delete: true }}
                            onClick={() => onMatchClick(match?.id)}
                            onDelete={(match) => onDelete(match?.id)}
                          />
                        );
                      })}
                    </Row>
                  </div>
                </div>
              );
            })}
        </div>
      ) : (
        <div className="empty-container">Please select season</div>
      )}
      {isCreateModalOpen && (
        <Modal
          title={selectedMatchRecord ? "Update Match" : "Create Match"}
          width={1000}
          open={isCreateModalOpen}
          footer={null}
          onCancel={handleCancel}
        >
          <CreateMatch
            tournamentId={tournamentId}
            currentTournament={tournamentsData}
            seasonId={seasonId}
            record={selectedMatchRecord}
            refresh={refetch}
            cancel={handleCancel}
          />
        </Modal>
      )}
    </div>
  );
};

export default MatchTab;
