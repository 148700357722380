import gql from "graphql-tag";

const GET_TOURNAMENTS = gql`
  {
    tournaments {
      id
      name
      description
      display_picture
      status
    }
  }
`;
export default GET_TOURNAMENTS;
