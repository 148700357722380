import gql from "graphql-tag";

const GET_MATCH_LINEUP_BY_ID = gql`
query matchLineupByMatchId($match_id: String!) {
  matchLineupByMatchId(match_id: $match_id) {
    id
    name
    lineup {
      id
      name
      jersey_no
      position
      teamPlayerId
      userId
      status
      display_picture
    }
  }
}
`;

export default GET_MATCH_LINEUP_BY_ID;
