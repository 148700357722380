import { gql } from "@apollo/client";

const DELETE_TEAM_FROM_TOURNAMENT_SEASON = gql`
  mutation deleteTeamFromTournamentSeasonTeam($tournament_season_team_id: String!) {
    deleteTeamFromTournamentSeasonTeam(tournament_season_team_id: $tournament_season_team_id) {
      id
      tournament_enrollment_status
      tournament_season {
        id
        name
        start_date
        display_picture
        end_date
        about_tournament
      }
      tournament {
        id
        name
        display_picture
        description
        about_tournament
      }
      team {
        id
        name
        description
        display_picture
      }
      tournament_season_group {
        id
        group_name
      }
    }
  }
`;

export default DELETE_TEAM_FROM_TOURNAMENT_SEASON;
