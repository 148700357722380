import { LoadingOutlined } from "@ant-design/icons";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Card,
  Col,
  Radio,
  Row,
  Segmented,
  Select,
  Spin,
  Tabs,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import GET_TOURNAMENT_BY_ID from "../../api/get_tournament_by_id";
import FIND_ALL_SEASONS_BY_TOURNAMENT_ID from "../../api/get_tournament_seasons";
import HomeTab from "../tournaments/tabs/hometab";

import "./style.scss";
import GroupsTab from "./tabs/groups/groups-tab";
import MatchTab from "./tabs/match/match-tab";
import RoundsTab from "./tabs/rounds/rounds-tab";
import SeasonTab from "./tabs/seasons/season-tab";
import TeamTab from "./tabs/teams/team_tab";
import GalleryTab from "./tabs/gallery/gallerytab";
import SponsorsTab from "./tabs/sponsors/sponsorsTab";
import WinnersTab from "./tabs/winners/winnersTab";
import MatchStats from "./tabs/match-stats/matchStats";
import { Option } from "antd/es/mentions";
import MatchesTab from "./tabs/match/matchesTab";
import UPDATE_TOURNAMENT_BY_ID from "../../api/update_tournament_by_id";

const TournamentDetails = () => {
  const { id: tournamentId } = useParams();
  const { data: tournamentData, loading: tournamentLoading } = useQuery(
    GET_TOURNAMENT_BY_ID,
    {
      variables: { id: tournamentId },
    }
  );
  const [
    getTournamentSeasonData,
    { data: seasonsData, loading: seasonsLoading, refetch: refetchSeasonData },
  ] = useLazyQuery(FIND_ALL_SEASONS_BY_TOURNAMENT_ID, {
    variables: { tournament_id: tournamentId },
  });

  const [
    updateTournamentRecord,
    {
      data: updateTournamentData,
      loading: updateTournamentLoading,
      error: updateTournamentError,
    },
  ] = useMutation(UPDATE_TOURNAMENT_BY_ID);

  const [selectedSeasonId, setSelectedSeasonId] = useState();
  const [activeKey, setActiveKey] = useState(
    localStorage.getItem("activeTab") || "1"
  );
  const navigate = useNavigate();
  const [status, setStatus] = useState();

  const tabs = [
    {
      key: "1",
      label: `Seasons`,
      component: (
        <SeasonTab
          seasonData={seasonsData?.findAllSeasonsByTournamentId}
          refetchSeason={refetchSeasonData}
        />
      ),
    },
    {
      key: "2",
      label: `Matches`,
      component: (
        <MatchesTab
          seasonId={selectedSeasonId}
          tournamentId={tournamentId}
          tournamentsData={tournamentData}
        />
      ),
    },
    {
      key: "3",
      label: `Gallery`,
      component: <GalleryTab selectedSeasonId={selectedSeasonId} />,
    },
    {
      key: "4",
      label: `Sponsors`,
      component: <SponsorsTab selectedSeasonId={selectedSeasonId} />,
    },
    {
      key: "5",
      label: `About`,
      //   component: <AboutTab selectedSeasonId={selectedSeasonId}/>,
    },
    {
      key: "6",
      label: `Teams`,
      component: (
        <TeamTab
          selectedSeasonId={selectedSeasonId}
          tournamentId={tournamentId}
        />
      ),
    },
    {
      key: "7",
      label: `Rounds`,
      component: <RoundsTab selectedSeasonId={selectedSeasonId} />,
    },
    {
      key: "8",
      label: `Groups`,
      component: <GroupsTab selectedSeasonId={selectedSeasonId} />,
    },
    {
      key: "9",
      label: `Winners`,
      component: <WinnersTab selectedSeasonId={selectedSeasonId} />,
    },
    {
      key: "10",
      label: `Match-stats`,
      component: <MatchStats selectedSeasonId={selectedSeasonId} />,
    },
  ];
  useEffect(() => {
    getTournamentSeasonData();
  }, [tournamentId]);

  useEffect(() => {
    if (seasonsData) {
      setSelectedSeasonId(
        seasonsData?.findAllSeasonsByTournamentId?.[
          seasonsData?.findAllSeasonsByTournamentId?.length - 1 || 0
        ]?.id
      );
    }
  }, [seasonsData]);

  useEffect(() => {
    if (tournamentData?.tournament) {
      setStatus(tournamentData?.tournament?.status);
    }
  }, [tournamentData]);

  const handleSeasonSelect = (value) => {
    setSelectedSeasonId(value);
  };

  const handleTabChange = (key) => {
    setActiveKey(key);
    localStorage.setItem("activeTab", key);
  };

  useEffect(() => {
    const storedKey = localStorage.getItem("activeTab");
    if (storedKey && tabs.some((tab) => tab.key === storedKey)) {
      setActiveKey(storedKey);
    }
  }, [tabs]);

  const onTournamentStatusChange = (value) => {
    const payload = { status: value };
    updateTournamentRecord({
      variables: {
        tournament_id: tournamentId,
        updateTournamentInput: {
          ...payload,
        },
      },
    })
      .then((res) => {
        setStatus(value);
        message.success({
          type: "success",
          content: "Tournament Updated",
        });
      })
      .catch((err) => {
        message.error({
          type: "error",
          content: err.message,
        });
      });
  };

  return (
    <>
      <div style={{ border: "2px" }}>
        {tournamentLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "20px",
            }}
          >
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          </div>
        ) : tournamentData ? (
          <Card className="tournament-details">
            <div className="header">
              <img
                className="logo"
                src={tournamentData?.tournament?.display_picture}
              />
              <Row className="title" style={{ width: "100%" }}>
                <Col span={12}>
                  <h1>{tournamentData?.tournament?.name}</h1>
                  <Select
                    value={selectedSeasonId}
                    style={{ margin: 5 }}
                    options={seasonsData?.findAllSeasonsByTournamentId?.map(
                      (seasonData) => {
                        return {
                          key: seasonData?.id,
                          value: seasonData?.id,
                          description: seasonData?.description,
                          label: seasonData?.name,
                        };
                      }
                    )}
                    onChange={handleSeasonSelect}
                  />
                </Col>
                {/* <Row > */}
                <Col
                  span={12}
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    marginTop: "20px",
                    alignItems: "center",
                    //marginRight: "5px"
                    //alignContent:"right"
                  }}
                >
                  {/* <Radio.Group
                    value={status}
                    onChange={(e) => onTournamentStatusChange(e.target.value)}
                  >
                    <Radio.Button value="DRAFT">Draft</Radio.Button>
                    <Radio.Button value="PUBLISHED">Publish</Radio.Button>
                  </Radio.Group> */}
                  <Segmented
                    value={status}
                    onChange={onTournamentStatusChange}
                    options={[
                      {
                        label: "Draft",
                        value: "DRAFT",
                      },
                      {
                        label: "Published",
                        value: "PUBLISHED",
                      },
                    ]}
                  />
                  <Button
                    style={{ marginLeft: "4px" }}
                    onClick={() => navigate(`/user-role/${tournamentId}`)}
                  >
                    Assign roles
                  </Button>
                </Col>
                {/* </Row> */}
              </Row>
            </div>

            <div className="tournament-detail-tabs">
              <Col span={24}>
                <Tabs activeKey={activeKey} onChange={handleTabChange}>
                  {tabs.map((item) => (
                    <Tabs.TabPane key={item.key} tab={item.label}>
                      {item.component}
                    </Tabs.TabPane>
                  ))}
                </Tabs>
              </Col>
            </div>
          </Card>
        ) : (
          "Not found tournament"
        )}
      </div>
    </>
  );
};

export default TournamentDetails;
