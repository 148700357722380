import { Card, Col, Image, Row } from "antd";
import React from "react";
import "./style.scss";
import { useParams } from "react-router";
import { useQuery } from "@apollo/client";
import GET_TOURNAMENT_BY_ID from "../../../api/get_tournament_by_id";
import GET_LISTED_TOURNAMENT_BY_ID from "../../../api/get_tournament_list_by_id";

const TournamentListDetails = () => {
  const { id: tournamentId } = useParams();
  const {
    data: tournamentListDataById,
    loading: tournamentListDataByIdLoading,
  } = useQuery(GET_LISTED_TOURNAMENT_BY_ID, {
    variables: { tournament_listed_id: tournamentId.trim() },
  });

  const data = tournamentListDataById?.findOneListedTournament;

  return (
    <div>
      <Row className="tournament-listing-wrapper">
        {/* <Col span={12}> */}
        <Col span={4} className="tournament-name-column">
          {" "}
          <img className="card-image" src={data?.display_picture}></img>
        </Col>
        <Col span={7} className="tournament-name-column">
          <h3 className="tournament-name">{data?.tournament_name} </h3>
          <Row>
            <Col>
              <h4 className="season-name">Season :{data?.season} </h4>
            </Col>
          </Row>
        </Col>
        {/* </Col> */}
      </Row>
      <Card>
        <Row>
          <Col span={6}>
            {" "}
            <Card title="Organizer">
              {" "}
              <Row>Organizer's Name:{data?.organizer_name}</Row>
              <Row>Organizer's Contact:{data?.organizer_contact}</Row>
              <Row>Location:{data?.location}</Row>
            </Card>
          </Col>
          <Col span={6}>
            {" "}
            <Card title="Date">
              <Row>Start date: {data?.start_date}</Row>
              <Row>End date: {data?.end_date}</Row>
              <Row>Timings: {data?.timings}</Row>
            </Card>
          </Col>
          <Col span={6}>
            {" "}
            <Card title="About Match">
              {" "}
              <Row>Match Duration: {data?.match_duration}</Row>
              <Row>Match Duration: {data?.match_duration}</Row>
              Match Format : {data?.format}
            </Card>
          </Col>
          <Col span={6}>
            {" "}
            <Card title="About Tournament">
              <Row>Number of Teams: {data?.number_of_teams}</Row>
              <Row>Age Limit: {data?.age_limit}</Row>
              <Row>Registration fee: {data?.registration_fees}</Row>
            </Card>
          </Col>
          <Col>
            <Card title="Other details ">
              <Row>Prize: {data?.prize}</Row>
              <Row>Status: {data?.status}</Row>
              <Row>Registration Last Date: {data?.registration_last_date}</Row>
            </Card>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default TournamentListDetails;
