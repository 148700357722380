import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery, useSubscription } from "@apollo/client";
// import GET_MATCHES from "../../api/get_all_matches";
import {
  Col,
  Row,
  Select,
  Form,
  InputNumber,
  Popconfirm,
  Table,
  Typography,
  Avatar,
  Modal,
  message,
  Image
} from "antd";
import { Spin } from 'antd';
import REMOVE_TEAM_PLAYER from "../../../api/remove_team_player";
import dayjs from "dayjs";
import EditTeamPlayer from "./edit_team_player";
import DELETE_TEAM_PLAYER from "../../../api/delete_team_player";
import { compareString } from "../../../utils/app.util";
import CreateUser from "../../users/blocks/new_user";

const PlayersTable = ({ playersData, refresh }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const utc = require('dayjs/plugin/utc');
  dayjs.extend(utc);
  const [tableData, setTableData] = useState(playersData);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false);
  const [selectedTeamPlayer, setSelectedTeaPlayer] = useState(null);

  const [deleteTeamPlayer, { data: removeTeamPlayerData, loading: removeTeamPlayerLoading, error: removeTeamPlayerError }] = useMutation(DELETE_TEAM_PLAYER)

  useEffect(() => {
    setTableData(formatPlayersData())
  }, [playersData])

  const handleEditModalCancel = () => {
    setIsEditModalOpen(false);
  };

  const handleEditUserModalCancel = () => {
    setIsEditUserModalOpen(false);
  };

  const formatPlayersData = () => {
    return playersData?.map(player => {
      return {
        key: player?.id,
        name: player?.user?.name,
        display_picture: player?.user?.display_picture,
        position: player?.user?.position,
        preferred_jersey_no: player?.user?.preferred_jersey_no,
        join_date: player?.join_date,
        leave_date: player?.leave_date,
        status: player?.status,
        user: { ...player?.user, key: player?.user?.id }
      }
    })
  }

  const onDelete = (record) => {
    deleteTeamPlayer({
      variables: {
        team_player_id: record?.key
      }
    }).then(res => {
      refresh();
    }).catch(err => {
      messageApi.open({
        type: 'error',
        content: err?.message || 'Something went wrong!',
      });
    })
  }

  const onEdit = (record) => {
    setSelectedTeaPlayer(record)
    setIsEditModalOpen(true)
  }

  const onEditUserProfile = (record) => {
    setSelectedTeaPlayer(record)
    setIsEditUserModalOpen(true)
  }

  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;
  const columns = [
    {
      title: '#',
      width: '2%',
      render: (text, record, index) => {
        return index + 1
      }
    },
    {
      title: "",
      dataIndex: "display_picture",
      width: "5%",
      editable: false,
      render: (display_picture) => {
        return display_picture && (
          <div
            style={{
              display: "inline-block",
              width: "32px",
              height: "32px",
              borderRadius: "50%",
              overflow: "hidden",
            }}
          >
            <Image
              src={display_picture}
              width="32px"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </div>
        )
      }
    },
    {
      title: "Name",
      dataIndex: "name",
      width: "15%",
      editable: false,
      // filters: [],
      // onFilter: (value, record) => record.name.indexOf(value) === 0,
      defaultSortOrder: 'ascend',
      sorter: (a, b) => compareString(a, b, 'name'),
    },
    {
      title: "Position",
      dataIndex: "position",
      width: "12%",
      editable: false,
      sorter: (a, b) => a?.position - b?.position,
    },
    {
      title: "Jersey",
      dataIndex: "preferred_jersey_no",
      width: "8%",
      editable: false,
      sorter: (a, b) => a?.preferred_jersey_no - b?.preferred_jersey_no,
    },
    {
      title: "Join Date",
      dataIndex: "join_date",
      width: "15%",
      editable: false,
      render: (join_date) => {
        return dayjs(join_date)?.format("YYYY-MM-DD HH:mm:ss")
      }
    },
    {
      title: "Leave Date",
      dataIndex: "leave_date",
      width: "15%",
      editable: false,
      render: (leave_date) => {
        if (leave_date) {
          return dayjs(leave_date)?.format("YYYY-MM-DD HH:mm:ss")
        }
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "10%",
      editable: false
    },
    {
      title: "Operation",
      dataIndex: "operation",
      width: '20%',
      render: (_, record) => {
        const editable = isEditing(record);
        return <>
          <Typography.Link>
            <a onClick={() => onEdit(record)}>Edit</a>
          </Typography.Link>

          <Typography.Link>
            <a style={{ marginLeft: '16px' }} onClick={() => onEditUserProfile(record)}>Edit Profile</a>
          </Typography.Link>

          <Typography.Link>
            <Popconfirm title="Delete" onConfirm={() => onDelete(record)}>
              <a style={{ marginLeft: '16px' }}>Delete</a>
            </Popconfirm>
          </Typography.Link>
        </>
      },
    },
  ];

  return (
    <>
      {contextHolder}
      <Row>
        <Col span={24}>
          <Form form={form} component={false}>
            <Table
              style={{marginBottom: 20}}
              bordered
              dataSource={tableData}
              columns={columns}
              rowClassName="editable-row"
              pagination={false}
              scroll={{ y: 300, x: "auto" }}
            />
          </Form>
        </Col>
      </Row>

      {isEditModalOpen && <Modal title="Edit Team Player" width={450} open={isEditModalOpen} footer={null} onCancel={handleEditModalCancel}>
        <EditTeamPlayer record={selectedTeamPlayer} refresh={refresh} close={handleEditModalCancel} />
      </Modal>}

      {isEditUserModalOpen && <Modal title="Edit Team Player" width={450} open={isEditUserModalOpen} footer={null} onCancel={handleEditUserModalCancel}>
        <CreateUser
          record={selectedTeamPlayer?.user}
          refresh={refresh}
          close={handleEditUserModalCancel}
        />
      </Modal>}
    </>
  );
};

export default PlayersTable;
