import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Form,
  Input,
  Select,
  Space,
  DatePicker,
  Button,
  message,
  Alert,
  Row,
  Col,
  InputNumber,
  Table,
  Checkbox,
  Avatar,
  Image,
} from "antd";
import { useCallback, useState } from "react";
import { useEffect } from "react";
import FIND_ALL_GROUPS_BY_SEASON_ID from "../../../api/get_all_groups_by_season";
import GET_MATCHES from "../../../api/get_all_matches";
import FIND_ALL_ROUNDS_BY_SEASON_ID from "../../../api/get_all_rounds_by_season";
import GET_EVENT_ENUM from "../../../api/get_event_enum";
import GET_TEAMS from "../../../api/get_teams";
import GET_TOURNAMENTS from "../../../api/get_tournaments";
import FIND_ALL_SEASONS_BY_TOURNAMENT_ID from "../../../api/get_tournament_seasons";
import GET_VENUES from "../../../api/get_venue_list";
import GET_MATCH_LINEUP_BY_ID from "../../../api/get_match_line_ups";
import CREATE_MATCH_EVENT from "../../../api/create_match_event";
import GET_TEAM_PLAYERS_BY_TEAM from "../../../api/get_team_players_by_team";
import CREATE_MATCH_LINE_UP from "../../../api/create_match_line_up";
import { compareString } from "../../../utils/app.util";
import GET_MATCHES_BY_TOURNAMENT_SEASON_ID from "../../../api/get_matches_by_tournament_season_id";
import { useParams } from "react-router";
import moment from "moment";
import dayjs from "dayjs";
import "./style.scss";
import GET_TOURNAMENT_SQUAD_BY_ID from "../../../api/get_tournament_squad_by_id";
import FIND_ALL_TOURNAMENT_SQUAD from "../../../api/get_all_tournament_squad";
import LineUpTable from "./lineup-table";

const NewLineUp = ({
  teamId,
  matchId,
  refresh,
  close,
  seasonId,
  existingPlayers,
  team1,
  team2,
}) => {
  const { id: selectedMatchId } = useParams();
  const { Option } = Select;
  const [errorMessage, setErrorMessage] = useState(null);
  const [statusMessage, setStatusMessage] = useState(null);
  const [teamPreviousMatches, setTeamPreviousMatches] = useState([]);
  const [previousMatches, setPreviousMatches] = useState([]);
  const [selectedPreviousMatch, setSelectedPreviousMatch] = useState(null);
  const [selectedPreviousMatchLineUps, setSelectedPreviousMatchLineUps] =
    useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [lineUpData, setLineUpData] = useState([]);

  const { data: playerPositions } = useQuery(GET_EVENT_ENUM, {
    variables: { enumName: "PlayerPosition" },
  });

  const { data: lineUpStatus } = useQuery(GET_EVENT_ENUM, {
    variables: { enumName: "LineupStatus" },
  });

  const [
    getTournamentMatchesData,
    { data: matchesData, loading: matchesLoading },
  ] = useLazyQuery(GET_MATCHES_BY_TOURNAMENT_SEASON_ID, {
    variables: { season_id: seasonId },
  });

  const [
    getMatchLineups,
    { data: matchLineUps, loading: matchLineUpsLoading, refetch },
  ] = useLazyQuery(GET_MATCH_LINEUP_BY_ID, {
    variables: { match_id: selectedPreviousMatch },
    fetchPolicy: "no-cache",
  });

  const [fetchPlayerList, { data: teamPlayersList }] = useLazyQuery(
    GET_TEAM_PLAYERS_BY_TEAM,
    {
      variables: { team_id: teamId },
      fetchPolicy: "no-cache",
    }
  );
  const [
    fetchTournamentSquad,
    { data: tournamentSquad, loading: tournamnetSquadLoading },
  ] = useLazyQuery(FIND_ALL_TOURNAMENT_SQUAD, {
    variables: { team_id: teamId, season_Id: seasonId },
    fetchPolicy: "no-cache",
  });

  const [
    createMatchLineUp,
    { data: createMatchLineUpData, loading: lineUploading, error: lienUpError },
  ] = useMutation(CREATE_MATCH_LINE_UP);

  const getFilteredPlayers = (teamPlayers, playerLists) => {
    return teamPlayers
      ? teamPlayers.filter(
          (player) =>
            player?.playerId && !playerLists?.includes(player?.playerId)
        )
      : [];
  };

  const resetLineUpData = (
    filteredPlayers,
    setLineUpData,
    setSelectedRowKeys
  ) => {
    const playerTable = [];
    filteredPlayers?.map((player) => {
      playerTable.push({
        key: player?.id,
        isSelected: false,
        name: player?.playerName,
        jerseyNumber: player?.jerseyNo,
        position: player?.position,
        teamPlayerId: player?.playerId,
        status: "CONFIRMED",
        display_picture: player?.displayPicture,
      });
    });
    setLineUpData(playerTable);
    setSelectedRowKeys([]);
  };

  useEffect(() => {
    if (seasonId && teamId) {
      fetchTournamentSquad({
        variables: {
          fetchTournamentSeasonSquadInput: {
            team_id: teamId,
            season_id: seasonId,
          },
        },
      });
    }
    if (seasonId) {
      getTournamentMatchesData();
    }
  }, [seasonId, teamId]);
  useEffect(() => {
    if (tournamentSquad) {
      const filteredPlayers = getFilteredPlayers(
        tournamentSquad?.findAllTournamentSquad?.fetchTournamentSeasonSquad,
        existingPlayers?.lineups?.map((lineup) => lineup?.teamPlayerId)
      );
      resetLineUpData(filteredPlayers, setLineUpData, setSelectedRowKeys);
    }
  }, [existingPlayers, tournamentSquad]);

  const reset = () => {
    const filteredPlayers = getFilteredPlayers(
      teamPlayersList?.teamPlayersByTeam,
      existingPlayers?.lineups?.map((lineup) => lineup.userId)
    );
    resetLineUpData(filteredPlayers, setLineUpData, setSelectedRowKeys);
  };

  useEffect(() => {
    if (selectedPreviousMatch) {
      getMatchLineups();
    }
  }, [selectedPreviousMatch]);

  useEffect(() => {
    if (matchLineUps) {
      let selectedRows = [];
      const teamData = matchLineUps?.matchLineupByMatchId?.find(
        (team) => team?.id == teamId
      );

      setSelectedPreviousMatchLineUps(teamData?.lineup);

      lineUpData?.map((player) => {
        let obj = teamData?.lineup?.find(
          (lineUpDataObj) => lineUpDataObj?.teamPlayerId == player?.teamPlayerId
        );

        if (obj) {
          player.jerseyNumber = obj?.jersey_no;
          player.position = obj?.position;
          player.isSelected = true;
          selectedRows?.push(player?.key);
        }
      });

      setSelectedRowKeys(selectedRows);
    }
  }, [matchLineUps]);
  useEffect(() => {
    let matches = [];
    if (matchesData) {
      matchesData.getMatchesByTournamentSeasonIdWithGroupByTournamentRound?.map(
        (item) =>
          item.matches?.filter((match) => {
            if (
              (match.team1?.id == teamId || match?.team2.id == teamId) &&
              match?.id != selectedMatchId
            ) {
              matches.push(match);
            }
          })
      );

      const sortedMatches = matches.sort((a, b) => {
        return (
          dayjs(
            b?.match_schedule?.start_date,
            "YYYY-MM-DD HH:mm:ss[Z]"
          ).unix() -
          dayjs(a?.match_schedule?.start_date, "YYYY-MM-DD HH:mm:ss[Z]").unix()
        );
      });

      setPreviousMatches(sortedMatches);
    }
  }, [matchesData]);

  useEffect(() => {
    reset();
  }, [teamPlayersList]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const columns = [
    {
      title: "#",
      width: 50,
      maxHeight: 200,

      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Picture",
      dataIndex: "display_picture",
      width: 120,
      height: 200,
      render: (display_picture) => {
        return (
          display_picture?.length && (
            <div
              style={{
                display: "inline-block",
                width: "32px",
                height: "32px",
                borderRadius: "50%",
                overflow: "hidden",
              }}
            >
              <Image
                src={display_picture}
                width="32px"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
          )
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      width: 250,
      height: "200px",
      editable: true,
      defaultSortOrder: "ascend",
      sorter: (a, b) => compareString(a, b, "name"),
      // filters: [],
      // onFilter: (value, record) => record.name.indexOf(value) === 0,
      // sorter: (a, b) => a?.name?.length - b?.name?.length,
    },
    {
      title: "Position",
      dataIndex: "position",
      width: 250,
      height: 200,
      editable: true,
      render: (text, record, index) => {
        return playerPositionDropDown(record);
      },
      // sorter: (a, b) => a?.position - b?.position,
    },
    {
      title: "Jersey No",
      dataIndex: "jerseyNo",
      width: 150,
      height: 200,
      editable: true,
      render: (text, record, index) => {
        return (
          <InputNumber
            step={1}
            type="number"
            pattern="[0-9]*"
            inputmode="numeric"
            defaultValue={record?.jerseyNumber}
            onChange={(value) => {
              record.jerseyNumber = value;
            }}
          />
        );
      },
      onCell: (record) => ({
        record,
        editable: true,
        inputType: "number",
        dataIndex: "jerseyNo",
        // title: col.title,
        // handleSave,
      }),
      // sorter: (a, b) => a?.jerseyNumber - b?.jerseyNumber,
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "200px",
      height: 200,
      editable: true,
      render: (text, record, index) => {
        return lineUpStatusDropDown(record);
      },
      // sorter: (a, b) => a?.status - b?.status,
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    width: 170,
    height: 100,
    onChange: onSelectChange,
  };

  const [form] = Form.useForm();

  const teamPlayerDropDown = (teamId) => {
    return (
      <Select placeholder="Select Player">
        {teamPlayersList?.teamPlayersByTeam?.map((player) => (
          <Option key={player?.id} value={player?.id}>
            {player?.user?.name}
          </Option>
        ))}
      </Select>
    );
  };

  const playerPositionDropDown = (record) => {
    return (
      <Select
        style={{ width: "100%" }}
        defaultValue={record.position}
        placeholder="Select Position"
        onChange={(value) => (record.position = value)}
        showSearch
        filterOption={(input, option) =>
          (option?.label?.toLowerCase() ?? "").includes(input.toLowerCase())
        }
        options={playerPositions?.enumValues?.map((enumValue) => {
          return { label: enumValue, value: enumValue };
        })}
      ></Select>
    );
  };

  const lineUpStatusDropDown = (record) => {
    return (
      <Select
        defaultValue={record.status}
        placeholder="Select Status"
        onChange={(value) => (record.status = value)}
      >
        {lineUpStatus?.enumValues?.map((enumValue) => (
          <Option key={enumValue} value={enumValue}>
            {enumValue}
          </Option>
        ))}
      </Select>
    );
  };

  const onClose = () => {
    setStatusMessage(null);
  };

  const onSave = async () => {
    const filteredData = structuredClone(
      lineUpData?.filter((record) => selectedRowKeys.includes(record?.key))
    );

    const data = filteredData?.map((player) => {
      return {
        team_player_id: player.teamPlayerId,
        status: player?.status,
        jersey_no: player?.jerseyNumber?.toString(),
        position: player?.position,
      };
    });
    if (filteredData?.length) {
      createMatchLineUp({
        variables: {
          addLineupInput: {
            matchId,
            teamId,
            lineup_players: filteredData?.map((player) => {
              return {
                team_player_id: player.teamPlayerId,
                status: player?.status,
                jersey_no: player?.jerseyNumber?.toString(),
                position: player?.position,
              };
            }),
          },
        },
      })
        .then((res) => {
          setStatusMessage({
            type: "success",
            content: "Saved",
          });

          reset();
          refresh();
          close();
        })
        .catch((err) => {
          setStatusMessage({
            type: "error",
            content: err.message,
          });
        });
    }
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    let inputNode;

    if (dataIndex == "name") {
      inputNode = teamPlayerDropDown();
    } else if (dataIndex == "position") {
      inputNode = playerPositionDropDown();
    } else if (dataIndex == "status") {
      inputNode = lineUpStatusDropDown();
    } else {
      inputNode =
        inputType === "number" ? (
          <InputNumber pattern="[0-9]*" inputmode="numeric" />
        ) : (
          <Input />
        );
    }

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  return (
    <>
      {/* {statusMessage && (
        <Alert
          message={statusMessage?.content}
          type={statusMessage?.type}
          showIcon
          closable
          onClose={onClose}
        />
      )} */}

      <Col span={24}>
        {seasonId ? (
          <>
            {/* <span>Select lineups from previous match</span> &nbsp; */}
            <Select
              style={{ margin: "5px 0px", width: "100%" }}
              allowClear={true}
              showSearch={true}
              placeholder="Select lineups  from previous match"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={previousMatches?.map((match) => {
                return {
                  label: `${match?.team1?.name} VS ${
                    match?.team2.name
                  } ${moment(match?.match_schedule?.start_date).format(
                    "DD-MMM-YYYY hh:mm A"
                  )}`,
                  value: match?.id,
                  key: match?.id,
                };
              })}
              onChange={(value) => {
                reset();
                setSelectedPreviousMatch(value);
              }}
              onClear={() => setSelectedRowKeys([])}
            ></Select>
          </>
        ) : null}
        <Row style={{ margin: "5px" }}>
          {errorMessage && (
            <Alert
              message={errorMessage}
              type="error"
              closable
              onClose={() => setErrorMessage(null)}
              style={{
                background: "#FC9F9F",
                display: "flex",
                width: "100%",
              }}
            />
          )}
        </Row>
        <Form form={form} component={false}>
          <Row>
            <Col>
              <Table
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                rowSelection={rowSelection}
                bordered
                dataSource={lineUpData}
                columns={columns}
                pagination={false}
                scroll={{ y: 300, x: "auto" }}
              />
              {/* <Table>
                <Col
                  rowSelection={rowSelection}
                  dataSource={lineUpData}
                  rowClassName="editable-row"
                  bordered
                  columns={columns}
                ></Col>
              </Table> */}
            </Col>
          </Row>
        </Form>
      </Col>

      <Col
        span={24}
        style={{
          display: "flex",
          justifyContent: "end",
          marginTop: 24,
          gap: 16,
        }}
      >
        <Button onClick={reset}>Reset</Button>
        <Button type="primary" onClick={onSave} loading={lineUploading}>
          Save
        </Button>
      </Col>
    </>
  );
};

export default NewLineUp;
