import { gql } from "@apollo/client";

const DELETE_TEAM_PLAYER = gql`
  mutation deleteTeamPlayer($team_player_id: String!) {
    deleteTeamPlayer(team_player_id: $team_player_id) {
    id
    join_date
    leave_date
    status
    user {
      id
      name
      email
      phone_number
      display_picture
      position
      preferred_jersey_no
      status
    }
    team {
      id
      name
      description
      display_picture
    }
    }
  }
`;

export default DELETE_TEAM_PLAYER;
