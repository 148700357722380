import { gql } from '@apollo/client';

const ADD_IMAGES_TO_TOURNAMENT_SEASON_GALLERY = gql`
  mutation AddImagesToTournamentSeasonGallery($tournament_season_id: String!, $galleryInput: GalleryInput!) {
    addImagesToTournamentSeasonGallery(tournament_season_id: $tournament_season_id, galleryInput: $galleryInput) {
      id
      display_picture
      is_published
      match_id
      created_at
      updated_at
    }
  }
`;

export default ADD_IMAGES_TO_TOURNAMENT_SEASON_GALLERY;