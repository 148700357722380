import gql from "graphql-tag";

const GET_TOURNAMENT_BY_ID = gql`
query tournament($id: String!) {
  tournament(id: $id) {
    id
    name
    display_picture
    description
    about_tournament
    status
    }
  }
`;
export default GET_TOURNAMENT_BY_ID;
