import { gql } from "@apollo/client";

const ASSIGN_INDIVIDUAL_AWARDS= gql`
mutation assignIndividualAwards($tournament_season_id: String!, $input: [IndividualInput!]!) {
  assignIndividualAwards(IndividualAwardsInput: { tournament_season_id: $tournament_season_id, input: $input }) {
    id
    awardType
    team {
      id
      name
      description
      display_picture
    }
    player {
      id
      name
      email
      phone_number
      display_picture
      position
      preferred_jersey_no
      status
    }
    tournament_season {
      id
      name
      start_date
      display_picture
      end_date
      about_tournament
      tournament_rules
    }
  }
}
`;

export default ASSIGN_INDIVIDUAL_AWARDS;
