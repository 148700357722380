import { gql } from "@apollo/client";

const DELETE_TEAM = gql`
  mutation deleteTeam($team_id: String!) {
    deleteTeam(team_id: $team_id) {
      id
      name
      description
      display_picture
     
    }
  }
`;

export default DELETE_TEAM;
