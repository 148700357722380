import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Col,
  Input,
  List,
  message,
  Modal,
  Popconfirm,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import React, { useState } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Spin } from "antd";
import dayjs from "dayjs";
import { useEffect } from "react";
import GET_MATCHES from "../../api/get_all_matches";
import CreateMatch from "./blocks/new_match";
import DELETE_MATCH_BY_ID from "../../api/delete_match_by_id";
import withAuthProtection from "../../utils/auth";

const Matches = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const { data: allMatches, loading, refetch } = useQuery(GET_MATCHES);
  const [
    deleteMatch,
    {
      data: deleteMatchData,
      loading: deleteMatchLoading,
      error: deleteMatchError,
    },
  ] = useMutation(DELETE_MATCH_BY_ID);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [selectedMatchRecord, setSelectedMatchRecord] = useState(null);
  const [matches, setMatches] = useState([]);
  const utc = require("dayjs/plugin/utc");
  dayjs.extend(utc);

  useEffect(() => {
    const matchList = [];

    allMatches?.matches?.map((record) => {
      matchList.push({
        ...record,
        key: record?.id,
        startTime: dayjs(record?.match_schedule?.start_date)?.format(
          "YYYY-MM-DD HH:mm"
        ),
        endTime: dayjs(record?.match_schedule?.end_date)?.format(
          "YYYY-MM-DD HH:mm"
        ),
      });
    });

    setMatches(matchList);
  }, [allMatches]);

  const columns = [
    {
      title: "Team 1",
      dataIndex: "team1",
      key: "team1",
      render: (team1) => team1?.name,
    },
    {
      title: "Team 2",
      dataIndex: "team2",
      key: "team2",
      render: (team2) => team2?.name,
    },
    {
      title: "Start Time",
      dataIndex: "startTime",
      key: "startTime",
      render: (startTime) => startTime,
      sorter: (a, b) =>
        dayjs(a?.startTime, "YYYY-MM-DD HH:mm[Z]").unix() -
        dayjs(b?.startTime, "YYYY-MM-DD HH:mm[Z]").unix(),
    },
    {
      title: "End Time",
      dataIndex: "endTime",
      key: "endTime",
      render: (endTime) => endTime,
      sorter: (a, b) =>
        dayjs(a?.endTime, "YYYY-MM-DD HH:mm[Z]").unix() -
        dayjs(b?.endTime, "YYYY-MM-DD HH:mm[Z]").unix(),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <a onClick={() => onEdit(record)}>
            <EditOutlined />
          </a>
          <Typography.Link>
            <Popconfirm title="Delete" onConfirm={() => onDelete(record)}>
              <a style={{ marginLeft: "24px" }}>
                <DeleteOutlined style={{ color: "#FF0000" }} />
              </a>
            </Popconfirm>
          </Typography.Link>
        </Space>
      ),
    },
  ];

  const showModal = () => {
    setIsCreateModalOpen(true);
  };

  const handleOk = () => {
    setIsCreateModalOpen(false);
  };

  const handleCancel = () => {
    setIsCreateModalOpen(false);

    if (selectedMatchRecord) {
      setSelectedMatchRecord(null);
    }
  };

  const onEdit = (record) => {
    setSelectedMatchRecord(record);
    showModal();
  };

  const onDelete = (record) => {
    deleteMatch({
      variables: {
        match_id: record?.key,
      },
    })
      .then((resp) => {
        messageApi.open({
          type: "success",
          content: "Deleted Successfully",
        });
        refetch();
      })
      .catch((err) => {
        messageApi.open({
          type: "error",
          content: err?.message || "Something went wrong!",
        });
      });
  };

  const onSearchTeams = (event) => {
    let data = [];

    if (event?.target?.value) {
      data = allMatches?.matches?.filter(
        (record) =>
          record?.team1?.name
            ?.toLowerCase()
            .includes(event?.target?.value?.toLowerCase()) ||
          record?.team2?.name
            ?.toLowerCase()
            .includes(event?.target?.value?.toLowerCase())
      );
    } else {
      data = allMatches?.matches;
    }

    let list = [];

    data?.map((record) => {
      list.push({
        ...record,
        key: record?.id,
        startTime: dayjs(record?.match_schedule?.start_date)?.format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        endTime: dayjs(record?.match_schedule?.end_date)?.format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      });
    });

    setMatches(list);
  };

  return (
    <>
      {contextHolder}
      <h1>Matches</h1>
      <Row>
        <Col span={5}>
          <Input placeholder="Search Match" onChange={onSearchTeams} />
        </Col>
        <Col span={19} style={{ textAlign: "right" }}>
          <Button type="primary" onClick={showModal}>
            Add New
          </Button>
        </Col>
      </Row>
      <Row style={{ padding: "5px" }}>
        <Col span={24}>
          {loading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            </div>
          ) : (
            <Table columns={columns} dataSource={matches} />
          )}
        </Col>
        <Col span={16}></Col>
      </Row>

      {isCreateModalOpen && (
        <Modal
          title={selectedMatchRecord ? "Update Match" : "Create Match"}
          width={1000}
          open={isCreateModalOpen}
          footer={null}
          onCancel={handleCancel}
        >
          <CreateMatch
            record={selectedMatchRecord}
            refresh={refetch}
            cancel={handleCancel}
            seasonId={selectedMatchRecord?.tournamentSeason?.id}
          />
        </Modal>
      )}
    </>
  );
};

export default withAuthProtection(Matches);
