import { gql } from "@apollo/client";

const UPDATE_TEAM_BY_ID = gql`
  mutation updateTeam($team_id: String!, $updateTeamInput: UpdateTeamInput!) {
    updateTeam(team_id: $team_id, updateTeamInput: $updateTeamInput) {
      id
      name
      description
      display_picture
    }
  }
`;

export default UPDATE_TEAM_BY_ID;
