import { gql } from "@apollo/client";

const REMOVE_TEAM_PLAYER = gql`
  mutation removeTeamPlayer($team_player_id: String!) {
    removeTeamPlayer(team_player_id: $team_player_id) {
    id
    join_date
    leave_date
    status
    user {
      id
      name
      email
      phone_number
      display_picture
      position
      preferred_jersey_no
      status
    }
    team {
      id
      name
      description
      display_picture
    }
    }
  }
`;

export default REMOVE_TEAM_PLAYER;
