import { gql } from "@apollo/client";

const FIND_MATCH_EVENTS_BY_MATCH_ID = gql`
  query FindMatchEventsByMatchId($match_id: String!) {
    findMatchEventsByMatchId(match_id: $match_id) {
      id
      time
      event
      player {
        id
        name
        email
        phone_number
        display_picture
        position
        preferred_jersey_no
        status
      }
      team {
        id
        name
        description
        display_picture
      }
      match {
        id
        format
        duration
        sub_count
        match_stat_duration
        created_at
        updated_at
      }
      time_elapsed
      extra_time_elapsed
      commentary
      match_stage
      event_meta {
        id
        area_of_play_start
        area_of_play_end
        body_part
        action_type
        association_type
        associated_player {
          name
          display_picture
          id
        }
        associated_team {
          name
          display_picture
          id
        }
        match_event {
          event
        }
      }
    }
  }
`;

export default FIND_MATCH_EVENTS_BY_MATCH_ID;
