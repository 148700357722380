import { EditFilled } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
} from "antd";
import Title from "antd/es/skeleton/Title";
import React, { useEffect, useState } from "react";
import GET_TEAMS_BY_TOURNAMENT_SEASON_ID from "../../../../api/get_teams_by_season_id";
import { useLazyQuery, useMutation } from "@apollo/client";
import ASSIGN_TEAM_AWARDS from "../../../../api/assign_team_awards";

const RunnersUpTab = ({ selectedSeasonId, handleClose }) => {
  const [form] = Form.useForm();
  const [selectedTeam, setSelectedTeam] = useState("null");
  const [
    getTournamentSeasonTeams,
    {
      data: tournamentSeasonsTeams,
      loading: tournamentSeasonTeamsLoading,
      refetch: tournamentSeasonTeamsRefetch,
    },
  ] = useLazyQuery(GET_TEAMS_BY_TOURNAMENT_SEASON_ID, {
    variables: {
      tournament_season_id: selectedSeasonId,
    },
  });

  const [
    assignTeamAwards,
    { data: assignedTeamAwardData, loading: lineUploading, error: lineUpError },
  ] = useMutation(ASSIGN_TEAM_AWARDS);

  const renderTeamOption = () => {
    if (tournamentSeasonsTeams) {
      return tournamentSeasonsTeams?.findSeasonTeamsBySeasonId?.map((teams) => {
        return { label: teams?.team?.name, value: teams?.team?.id };
      });
    }
  };

  useEffect(() => {
    getTournamentSeasonTeams({
      variables: {
        tournament_season_id: selectedSeasonId,
      },
    });
  }, [selectedSeasonId]);

  const handleTeamSelect = (value) => {
    setSelectedTeam(value);
  };

  const onFinish = async (formValue) => {
    assignTeamAwards({
      variables: {
        tournament_season_id: selectedSeasonId,
        input: {
          teamId: selectedTeam,
          awardType: "RUNNERS_UP",
        },
      },
    });
    // refresh();
    handleClose();
  };

  return (
    <>
      <h3>Tournament Runnerup</h3>
      <Form
        form={form}
        onFinish={onFinish}
        name="basic"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        style={{
          maxWidth: 600,
        }}
        initialValues={{
          remember: true,
        }}
        autoComplete="off"
      >
        <Form.Item
          name="tournamentRunnerup"
          rules={[
            {
              required: true,
              message: "Please input a name!",
            },
          ]}
        >
          <Select
            placeholder="Select Runnerup"
            showSearch
            filterOption={(input, option) =>
              (option?.label?.toLowerCase() ?? "").includes(input.toLowerCase())
            }
            options={renderTeamOption()}
            onSelect={handleTeamSelect}
          >
            {/* {} */}
          </Select>
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form>
    </>
  );
};

export default RunnersUpTab;
