import { gql } from "@apollo/client";

const REMOVE_EVENT_BY_ID = gql`
  mutation deleteMatchEventById($match_event_id: String!) {
    deleteMatchEventById(match_event_id: $match_event_id) {
      id
    time
    event
    player {
      id
      name
      email
      phone_number
      display_picture
      position
      preferred_jersey_no
      status
    }
    team {
      id
      name
      description
      display_picture
    }
    match {
      id
      format
      duration
      sub_count
      match_stat_duration
      streaming_url
      created_at
      updated_at
    }
    time_elapsed
    extra_time_elapsed
    commentary
    match_stage
    event_meta {
      id
      area_of_play_start
      area_of_play_end
      body_part
      action_type
    }
    }
  }
`;

export default REMOVE_EVENT_BY_ID;
