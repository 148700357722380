import { gql } from "@apollo/client";

const UPDATE_TOURNAMENT_SEASON= gql`
mutation updateTournamentSeasonById($tournament_season_id: String!, $updateTournamentSeasonInput: UpdateTournamentSeasonInput!) {
  updateTournamentSeasonById(tournament_season_id: $tournament_season_id, updateTournamentSeasonInput: $updateTournamentSeasonInput) {
    id
    name
    start_date
    display_picture
    end_date
    advanced_stats
    tournament {
      id
      name
      display_picture
      description
      about_tournament
    }
    about_tournament
    tournament_sponsors {
      id
      name
      display_picture
      description
      link
    }
    tournament_gallery {
      id
      display_picture
      is_published
      match_id
      created_at
      updated_at
    }
  }
}

`;

export default UPDATE_TOURNAMENT_SEASON;
