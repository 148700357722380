import { EditFilled } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
} from "antd";
import Title from "antd/es/skeleton/Title";
import React, { useEffect, useState } from "react";
import GET_TEAMS_BY_TOURNAMENT_SEASON_ID from "../../../../api/get_teams_by_season_id";
import { useLazyQuery, useMutation } from "@apollo/client";
import ASSIGN_TEAM_AWARDS from "../../../../api/assign_team_awards";
import ASSIGN_INDIVIDUAL_AWARDS from "../../../../api/assign_individual_awards";
import GET_TEAM_PLAYERS_BY_TEAM from "../../../../api/get_team_players_by_team";

const BestPlayer = ({ selectedSeasonId, handleClose, refresh }) => {
  const [form] = Form.useForm();
  const [selectedTeam, setSelectedTeam] = useState("null");
  const [selectedPlayer, setSelectedPlayer] = useState("null");

  const [
    getTournamentSeasonTeams,
    {
      data: tournamentSeasonsTeams,
      loading: tournamentSeasonTeamsLoading,
      refetch: tournamentSeasonTeamsRefetch,
    },
  ] = useLazyQuery(GET_TEAMS_BY_TOURNAMENT_SEASON_ID, {
    variables: {
      tournament_season_id: selectedSeasonId,
    },
  });
  const [fetchPlayerList, { data: teamPlayersList, refetch: playerRefetch }] =
    useLazyQuery(GET_TEAM_PLAYERS_BY_TEAM, {
      variables: { team_id: selectedTeam },
      fetchPolicy: "no-cache",
    });

  const [
    assignIndividualAwards,
    {
      data: assignedIndividualAwardData,
      loading: individualAwardsLoading,
      error: individualAwardsError,
    },
  ] = useMutation(ASSIGN_INDIVIDUAL_AWARDS);

  useEffect(() => {
    getTournamentSeasonTeams({
      variables: {
        tournament_season_id: selectedSeasonId,
      },
    });
  }, [selectedSeasonId]);

  useEffect(() => {
    if (selectedTeam) {
      fetchPlayerList();
    }
  }, []);

  const handleTeamSelect = (value) => {
    setSelectedTeam(value);
  };

  const renderTeamOption = () => {
    if (tournamentSeasonsTeams) {
      return tournamentSeasonsTeams?.findSeasonTeamsBySeasonId?.map((teams) => {
        return { label: teams?.team?.name, value: teams?.team?.id };
      });
    }
  };

  const renderPlayerOption = () => {
    if (teamPlayersList) {
      return teamPlayersList?.teamPlayersByTeam?.map((players) => {
        return { label: players?.user?.name, value: players?.user?.id };
      });
    }
  };
  const onFinish = async (formValue) => {
    assignIndividualAwards({
      variables: {
        tournament_season_id: selectedSeasonId,
        input: {
          playerId: selectedPlayer,
          awardType: "GOLDEN_BALL",
        },
      },
    });
    refresh();
    handleClose();
  };

  const handlePlayerSelect = (value) => {
    setSelectedPlayer(value);
  };

  return (
    <>
      <h3>Best Player</h3>
      <Form
        form={form}
        onFinish={onFinish}
        name="basic"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        style={{
          maxWidth: 600,
        }}
        initialValues={{
          remember: true,
        }}
        autoComplete="off"
      >
        <Form.Item
          name="tournamentTeam"
          rules={[
            {
              required: true,
              message: "Please input a name!",
            },
          ]}
        >
          <Select
            placeholder="Select a team"
            showSearch
            filterOption={(input, option) =>
              (option?.label?.toLowerCase() ?? "").includes(input.toLowerCase())
            }
            options={renderTeamOption()}
            onSelect={handleTeamSelect}
          >
            {/* {} */}
          </Select>
        </Form.Item>
        <Form.Item
          name="bestPlayer"
          rules={[
            {
              required: true,
              message: "Please input a name!",
            },
          ]}
        >
          <Select
            placeholder="Select a player"
            showSearch
            filterOption={(input, option) =>
              (option?.label?.toLowerCase() ?? "").includes(input.toLowerCase())
            }
            options={renderPlayerOption()}
            onSelect={handlePlayerSelect}
          >
            {/* {} */}
          </Select>
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form>
    </>
  );
};

export default BestPlayer;
