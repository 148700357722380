import { gql } from "@apollo/client";

const CREATE_USERS= gql`
mutation createUsers($createUserInputs: [CreateUserInput!]!) {
  createUsers(createUserInputs: $createUserInputs) {
    id
    name
    email
    phone_number
    display_picture
    position
    preferred_jersey_no
    status
    teamPlayer {
      id
      join_date
      leave_date
      status
    }
  }
}

`;

export default CREATE_USERS;
