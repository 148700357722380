import { gql } from "@apollo/client";

export const CREATE_TOURNAMENT_SEASON_SQUAD = gql`
  mutation CreateTournamentSeasonSquad($createTournamentSeasonSquadInput: CreateTournamentSeasonSquadInput!) {
    createTournamentSeasonSquad(createTournamentSeasonSquadInput: $createTournamentSeasonSquadInput) {
      TournamentSeasonSquad {
        season_id
        team_id
        playerId
        position
        jerseyNo
        status
      }
    }
  }
`;

export default CREATE_TOURNAMENT_SEASON_SQUAD        ;
