import {
  DeleteFilled,
  EditFilled,
  PictureOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  VideoCameraAddOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Row,
  Table,
  Typography,
  message,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import "./style.scss";
import MediaInput from "./mediaInput/mediaInput";
import Title from "antd/es/skeleton/Title";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import GET_LIST_GOALRUSH_SCHOOLS from "../../api/list_goalrush_school";
import REMOVE_GOALRUSH_SCHOOL from "../../api/remove_goalrush_school";

const GoalrushSchool = () => {
  const [selectionType, setSelectionType] = useState("checkbox");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedContent, setSelectedContent] = useState(null);

  const [createModalOpen, setCreateModalOpen] = useState(false);

  const {
    data: goalrushSchooldata,
    loading: goalrushSchoolLoading,
    refetch: goalrushSchoolDataRefetch,
  } = useQuery(GET_LIST_GOALRUSH_SCHOOLS, {
    variables: {
      type: "GLOSSARY",
    },
  });

  const [
    removeGoalrushSchool,
    { data: deletedGoalrushSchool, loading: deletedLoadingGoalrushSchool },
  ] = useMutation(REMOVE_GOALRUSH_SCHOOL);

  console.log("imag3s", selectedContent?.images?.image);

  const columns = [
    {
      title: "#",
      width: 50,
      maxHeight: 200,

      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 100,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 200,
    },

    {
      title: "Images",
      dataIndex: "images",
      key: "images",
      width: 120,
      height: 200,
      render: (images) => {
        return (
          images?.length && (
            <div>
              {images.map((image, index) => (
                <div
                  key={index}
                  style={{
                    display: "inline-block",
                    width: "50px",
                    height: "40px",
                    overflow: "hidden",
                  }}
                >
                  <Image
                    src={
                      process.env.REACT_APP_API_URL + "/media/download/" + image
                    }
                    width="45px"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
              ))}
            </div>
          )
        );
      },
    },

    {
      title: "Edit",
      dataIndex: "edit",
      key: "edit",
      width: 120,
      render: (_, record) => {
        return (
          <>
            <Button type="link">
              <EditFilled
                onClick={(e) => onEdit(record)}
                style={{ color: "#60BAF9" }}
              />
            </Button>
            <Popconfirm
              key="delete"
              title="Delete the content"
              icon={<DeleteFilled />}
              description="Are you sure to delete this content?"
              onConfirm={(event) => {
                event.stopPropagation();
                onDelete(record);
              }}
              onCancel={(event) => event.stopPropagation()}
              okText="Yes"
              cancelText="No"
            >
              <DeleteFilled
                style={{
                  cursor: "pointer",
                  color: "#4f5453",
                  fontSize: "15px",
                }}
                onClick={(event) => event.stopPropagation()}
              />
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleCancel = () => {
    setCreateModalOpen(false);

    if (selectedContent) {
      setSelectedContent(null);
    }
  };

  const HandleCreateButton = () => {
    setCreateModalOpen(true);
    console.log("createModalOpen", createModalOpen);
  };
  const closeModal = () => {
    setCreateModalOpen(false);
    goalrushSchoolDataRefetch();
    if (selectedContent) {
      setSelectedContent(null);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    width: 170,
    height: 100,
    onChange: onSelectChange,
  };

  const data = goalrushSchooldata?.listGoalrushSchools;
  const onEdit = (record) => {
    console.log("recordOfOcntent", record);
    setSelectedContent(record);
    console.log("selectedContent", selectedContent);

    setCreateModalOpen(true);
  };
  const onDelete = (record) => {
    removeGoalrushSchool({
      variables: {
        goalrush_school_id: record?.id,
      },
    })
      .then((res) => {
        message.open({
          type: "success",
          content: "Content Removed",
        });
        goalrushSchoolDataRefetch();
      })
      .catch((err) => {
        message.open({
          type: "error",
          content: err?.message || "Something went wrong!",
        });
      });
  };
  console.log("data", data);

  return (
    <>
      <div>
        <Row className="heading-row">
          <Col span={23}>
            <h2>GoalRush School</h2>
          </Col>
          <Col span={1} className="heading-column">
            <Button className="add-button" onClick={HandleCreateButton}>
              Add New +
            </Button>
          </Col>
        </Row>
      </div>
      <div>
        <Table
          rowSelection={rowSelection}
          dataSource={data}
          columns={columns}
        />
      </div>
      {createModalOpen && (
        <Modal
          title={selectedContent ? "Update Content" : "Create Content"}
          footer={null}
          open={createModalOpen}
          onCancel={closeModal}
        >
          <MediaInput
            record={selectedContent}
            refetch={goalrushSchoolDataRefetch}
            close={handleCancel}
          />
        </Modal>
      )}
    </>
  );
};

export default GoalrushSchool;
