import {
  PaperClipOutlined,
  PictureOutlined,
  PlusOutlined,
  UploadOutlined,
  VideoCameraAddOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Upload,
  message,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import "./style.scss";
import CREATE_WHATS_NEW from "../../../api/create_goalrush_schools";
import { useMutation } from "@apollo/client";
import UPDATE_GOALRUSH_SCHOOL from "../../../api/update_goalrush_school";
import CREATE_GOALRUSH_SCHOOL from "../../../api/create_goalrush_schools";

const MediaInput = ({ record, refetch, close }) => {
  const [statusMessage, setStatusMessage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = Form.useForm();
  const dateFormat = "DD/MM/YYYY";

  const [
    createGoalrushSchool,
    { data: GoalrushSchool, loading: loadingGoalrushSchool },
  ] = useMutation(CREATE_GOALRUSH_SCHOOL);

  const [
    updateGoalrushSchoolById,
    {
      data: updateGoalrushSchool,
      loading: loadingupdateGoalrushSchool,
      refetch: refetchupdateGoalrushSchool,
    },
  ] = useMutation(UPDATE_GOALRUSH_SCHOOL);

  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        name: record?.name,
        description: record?.description,
        // image: record?.body,
        links: record?.links,
      });
    } else {
      form.resetFields();
    }
  }, [record]);

  const onFinish = async (formValue) => {
    if (record) {
      updateContent(formValue);
    } else {
      saveContent(formValue);
    }
  };
  const saveContent = async (formValue) => {
    var imagedata = new FormData();
    if (formValue?.images?.fileList) {
      formValue.images.fileList.forEach((file) => {
        imagedata.append("files", file.originFileObj);
      });
    }
    const response = await fetch(
      process.env.REACT_APP_API_URL + "/media/upload_multiple_files",
      {
        method: "POST",
        body: imagedata,
      }
    );

    const fileNames = await response.json();

    createGoalrushSchool({
      variables: {
        createGoalrushSchoolInput: {
          name: formValue?.name,
          description: formValue?.description,
          images: fileNames,
          links: formValue?.links,
          type: "GLOSSARY",
        },
      },
    })
      .then((resp) => {
        message.open({
          type: "success",
          content: "Content Added",
        });
        close();
        refetch();
      })
      .catch((err) => {
        message.open({
          type: "error",
          content: err?.message || "Something went wrong!",
        });
      });
  };
  const updateContent = async (formValue) => {
    let payload = formValue;

    updateGoalrushSchoolById({
      variables: {
        goalrush_school_id: record?.id,
        updateGoalrushSchoolInput: {
          ...payload,
        },
      },
    })
      .then((res) => {
        setStatusMessage({
          type: "success",
          content: "Content Updated",
        });

        // onReset();
        // refetch( );
        close();
      })
      .catch((err) => {
        setStatusMessage({
          type: "error",
          content: err.message,
        });
      });
  };

  const onClose = () => {
    setStatusMessage(null);
  };

  const handleSubmit = async () => {
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);
  };

  const props = {
    multiple: true,
  };
  return (
    <>
      <div>
        {statusMessage && (
          <Alert
            message={statusMessage?.content}
            type={statusMessage?.type}
            showIcon
            closable
            onClose={onClose}
          />
        )}
        <Form
          form={form}
          name="complex-form"
          labelCol={{ span: 5 }}
          onFinish={onFinish}
          wrapperCol={{ span: 18 }}
          layout="horizontal"
          style={{ maxWidth: 800 }}
        >
          <Form.Item
            name="name"
            rules={[{ required: true, message: "Heading is required" }]}
            label="Name"
          >
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Description">
            <TextArea rows={5} />
          </Form.Item>

          <Form.Item name="images" label="Image">
            <Upload
              beforeUpload={(file) => {
                return false;
              }}
              {...props}
            >
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
          </Form.Item>

          {/* <Form.Item name="image" label="Images">
            <Upload {...props}>
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
          </Form.Item> */}
          {/* <Form.Item name="video" label="Video">
            <Upload accept="video/*">
              <Button icon={<UploadOutlined />}>Upload Video only</Button>
            </Upload>
          </Form.Item> */}
          <Form.Item name="links" label="Link">
            <Input />
          </Form.Item>

          <Row>
            <Col
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "right",
              }}
              span={19}
            >
              <Button style={{ marginRight: "4px" }} htmlType="button">
                Reset
              </Button>
            </Col>{" "}
            <Col span={5}>
              <Button type="primary" htmlType="submit" onClick={handleSubmit}>
                {record ? "Update" : "Submit"}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default MediaInput;
