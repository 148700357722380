import { useMutation, useQuery } from "@apollo/client";
import {
  Avatar,
  Button,
  Card,
  Col,
  Image,
  Input,
  List,
  Modal,
  Popconfirm,
  Row,
  Space,
  message,
} from "antd";
import React, { useState } from "react";
import { PlusSquareOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import dayjs from "dayjs";
import { useEffect } from "react";
import CreateMatch from "./blocks/new_team";
import GET_TEAMS from "../../api/get_teams";
import DELETE_TEAM from "../../api/delete_team";
import { useNavigate } from "react-router";
import withAuthProtection from "../../utils/auth";
import Meta from "antd/es/card/Meta";
import TeamCard from "./blocks/teamCard";

const Teams = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const { data: allTeams, loading, refetch } = useQuery(GET_TEAMS);
  const [deleteTeam, { data: deletedTeamData, loading: deleteTeamLoading }] =
    useMutation(DELETE_TEAM);
  const navigate = useNavigate();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [selectedMatchRecord, setSelectedMatchRecord] = useState(null);
  const [teams, setTeams] = useState([]);
  const utc = require("dayjs/plugin/utc");
  dayjs.extend(utc);

  const onTeamClick = (teamId) => {
    navigate(`/team-details/${teamId}`);
  };

  useEffect(() => {
    const TeamList = [];

    allTeams?.teams?.map((team) => {
      TeamList.push(team);
    });

    setTeams(TeamList);
  }, [allTeams]);

  const showModal = () => {
    setIsCreateModalOpen(true);
  };

  const handleOk = () => {
    setIsCreateModalOpen(false);
  };

  const handleCancel = () => {
    setIsCreateModalOpen(false);

    if (selectedMatchRecord) {
      setSelectedMatchRecord(null);
    }
  };

  const onEdit = (record) => {
    setSelectedMatchRecord(record);
    showModal();
  };

  const onSearchTeams = (event) => {
    let data;

    if (event?.target?.value) {
      data = allTeams?.teams?.filter((record) =>
        record?.name
          ?.toLowerCase()
          .includes(event?.target?.value?.toLowerCase())
      );
    } else {
      data = allTeams?.teams;
    }

    const list = [];

    data.map((record) => {
      list.push({
        ...record,
        key: record?.id,
      });
    });

    setTeams(list);
  };

  const onDelete = (record) => {
    deleteTeam({
      variables: {
        team_id: record?.key,
      },
    })
      .then((res) => {
        messageApi.open({
          type: "success",
          content: "Team deleted",
        });
        refetch();
      })
      .catch((err) => {
        messageApi.open({
          type: "error",
          content: err?.message || "Something went wrong!",
        });
      });
  };

  return (
    <>
      {contextHolder}

      <Row>
        <Col span={23}>
          <h2>
            Teams{" "}
            <PlusSquareOutlined
              onClick={showModal}
              style={{ color: "blue", fontSize: "24PX" }}
            />
          </h2>
        </Col>
        <Col span={1}></Col>
      </Row>

      <Row style={{ marginTop: "24px" }}>
        <Col span={5}>
          <Input placeholder="Search Team" onChange={onSearchTeams} />
        </Col>
      </Row>

      <Row>
        {teams?.map((team) => (
          <TeamCard
            data={team}
            onClick={(e) => {
              onTeamClick(team?.id);
            }}
            onEditClick={(teamData) => onEdit(team)}
            onDeleteClick={(teamData) => onDelete(team?.id)}
            refetch={refetch}
          />
        ))}
      </Row>

      {isCreateModalOpen && (
        <Modal
          title={selectedMatchRecord ? "Update Team" : "Create Team"}
          width={500}
          open={isCreateModalOpen}
          footer={null}
          onCancel={handleCancel}
        >
          <CreateMatch
            record={selectedMatchRecord}
            refresh={refetch}
            close={handleCancel}
          />
        </Modal>
      )}
    </>
  );
};

export default withAuthProtection(Teams);
