import { ApolloClient, InMemoryCache, HttpLink, split, ApolloLink } from "@apollo/client";
import { Observable, getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { onError } from '@apollo/client/link/error';
import { setContext } from "apollo-link-context";
import Cookies from "js-cookie";
import { app } from "../firebase";
import {
  getAuth,
} from "firebase/auth";

const auth = getAuth(app);
const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_GRAPHQL_SUBSCRIPTION_URL,
    fetchOptions: {
      fetchOptions: {
        mode: "no-cors",
      },
    },
  })
);

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_API_URL,
});

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      if (
        err.extensions.code == 'FORBIDDEN'
      ) {
        const observable = new Observable((observer) => {
          auth.currentUser?.getIdToken(true).then((idToken) => {
            Cookies.set("jwt-token", idToken, {
              path: "/",
            });

            const oldHeaders = operation.getContext().headers;
            operation.setContext({
              headers: {
                ...oldHeaders,
                authorization: `Bearer ${idToken}`,
              },
            });

            // Retry the failed request
            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            };

            forward(operation).subscribe(subscriber);
          }).catch((err) => {
            auth.signOut();
            Cookies.remove('jwt-token');
            window.location.href = window.location.origin + '/login'
          });
        })

        return observable;
      }
    }
  } else {
    console.log('graphql log: graphql error');
  }
});

const token = localStorage.getItem("token");
console.log(">>>123");
const corsLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      'Authorization': `Bearer ${Cookies.get('jwt-token')}`,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "GET,OPTIONS,PATCH,DELETE,POST,PUT",
      "Access-Control-Allow-Headers":
        "X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version",
    },
  };
});

const link = corsLink.concat(httpLink);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  link
);
const client = new ApolloClient({
  link: ApolloLink.from([errorLink, splitLink, httpLink, corsLink]),
  cache: new InMemoryCache(),
});

export default client;
