import gql from "graphql-tag";

const GET_TEAMS_BY_TOURNAMENT_SEASON_ID = gql`
query findSeasonTeamsBySeasonId($tournament_season_id: String!) {
  findSeasonTeamsBySeasonId(tournament_season_id: $tournament_season_id) {
    id
    tournament_enrollment_status
    tournament_season {
      id
      name
      start_date
      display_picture
      end_date
      about_tournament
    }
    tournament {
      id
      name
      display_picture
      description
      about_tournament
    }
    team {
      id
      name
      description
      display_picture
    }
    tournament_season_group {
      id
      group_name
    }
    }
  }
`;
export default GET_TEAMS_BY_TOURNAMENT_SEASON_ID;
