import { gql } from "@apollo/client";

const WALKOVER= gql`
mutation walkover($matchId: String!, $winnerTeamId: String!) {
  walkover(match_id: $matchId, winner_team_id: $winnerTeamId) {
    id
    winner
    team1Goal
    team2Goal
    team1PenaltyGoal
    team2PenaltyGoal
  }
}

`;

export default WALKOVER;
