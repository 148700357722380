import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Form, Input, Select, Space, DatePicker, Button, message, Alert, Row, Col, Upload, InputNumber, Divider } from "antd"
import { useState } from "react";
import { useEffect } from "react";
import GET_TEAMS from "../../../api/get_teams";
import { PlusOutlined } from "@ant-design/icons";
import { uploadS3Image } from "../../../api/rest-api/s3-api";
import CREATE_USER from "../../../api/create_user";
import UPDATE_USER_BY_ID from "../../../api/update_user";
import GET_EVENT_ENUM from "../../../api/get_event_enum";
import ADD_TEAM_PLAYERS from "../../../api/add_team_player";


const CreateUser = ({ record, refresh, close }) => {
    const [createUser, { data: createdUserData, loading: createUserLoading, error: createdUserError }] = useMutation(CREATE_USER);
    const { data: playerPositions } = useQuery(GET_EVENT_ENUM, { variables: { enumName: 'PlayerPosition' } });
    const { data: teams, loading: teamsLoading, error: teamsLoadingError } = useQuery(GET_TEAMS);
    const [updateUserById, { data: updateUserData, loading: updateUserLoading, error: updateUserError }] = useMutation(UPDATE_USER_BY_ID);
    const [addTeamPlayers, { data: addTeamPlayersData, loading: addTeamPlayersLoading, error: addTeamPlayersError }] = useMutation(ADD_TEAM_PLAYERS);
    const { Option } = Select;
    const [statusMessage, setStatusMessage] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedTeam, setSelectedTeam] = useState(null);

    const [form] = Form.useForm();

    useEffect(() => {
        if (record) {
            form.setFieldsValue({
                name: record?.name,
                phone_number: record?.phone_number,
                preferred_jersey_no: record?.preferred_jersey_no,
                email: record?.email,
                position: record?.position,
                // display_picture: record?.display_picture
            })

            setSelectedImage({ src: record?.display_picture })
        } else {
            form.resetFields()
        }
    }, [record])

    const onFinish = async (formValue) => {
        if (record) {
            updateUser(formValue)
        } else {
            saveUser(formValue)
        }
    }

    const saveUser = async (formValue) => {
        let payload = formValue

        try {
            if (selectedImage?.file) {
                await uploadS3Image(selectedImage?.file).then((resp) => {
                    payload = { ...payload, display_picture: `${process.env.REACT_APP_API_URL}/media/download/${resp?.data}` }
                }).catch(err => {
                    console.log(err)
                })
            }

            const response = await createUser({
                variables: {
                    createUserInput: payload
                },
            })

            if (response?.data) {
                if (selectedTeam) {
                    addPlayerToTeam(response?.data?.createUser?.id)
                } else {
                    setStatusMessage({
                        type: 'success',
                        content: 'User created',
                    });

                    onReset();
                    refresh();
                    close();
                }
            }

            if (response?.errors) {
                setStatusMessage({
                    type: 'error',
                    content: response?.error?.message,
                });
            }
        } catch (err) {
            setStatusMessage({
                type: 'error',
                content: err.message,
            });
        }
    }


    const addPlayerToTeam = (userId) => {
        addTeamPlayers({
            variables: {
                addTeamPlayersInput: {
                    user_ids: [userId],
                    team_id: selectedTeam,
                },
            },
        }).then((res) => {
            setStatusMessage({
                type: 'success',
                content: 'Saved and Added to team',
            });

            refresh();
            setSelectedTeam(null)
            close();
        }).catch((err) => {
            setStatusMessage({
                type: 'error',
                content: err.message,
            });
        })
    }
    const updateUser = async (formValue) => {
        let payload = formValue

        if (selectedImage?.file) {
            await uploadS3Image(selectedImage?.file).then((resp) => {
                payload = { ...payload, display_picture: `${process.env.REACT_APP_API_URL}/media/download/${resp?.data}` }
            }).catch(err => {
                console.log(err)
            })
        }


        else if (!selectedImage?.src) {
            payload = { ...payload, display_picture: '' }
        }

        updateUserById({
            variables: {
                user_id: record?.key,
                updateUserInput: {
                    ...payload
                }
            },
        }).then((res) => {
            setStatusMessage({
                type: 'success',
                content: 'User Updated',
            });

            onReset();
            refresh();
            close();
        }).catch((err) => {
            setStatusMessage({
                type: 'error',
                content: err.message,
            });
        })
    }

    const onReset = () => {
        form?.resetFields();
        setSelectedImage(null)
    };

    const onClose = () => {
        setStatusMessage(null)
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Select</div>
        </div>
    );

    const readURL = (input) => {
        const file = input?.target?.files[0];

        if (file) {
            var reader = new FileReader();

            reader.onload = function (e) {
                setSelectedImage({ file, src: e.target.result })

                setTimeout(() => {
                    document.getElementById('profile_image').setAttribute('src', e.target.result);
                }, 0)
            }

            reader.readAsDataURL(file);
        }
    }

    return (
        <>
            {statusMessage && <Alert message={statusMessage?.content} type={statusMessage?.type} showIcon closable onClose={onClose} />}
            <Form
                form={form}
                name="complex-form"
                onFinish={onFinish}
                style={{ marginTop: '24px' }}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 15 }}
            >

                <Row gutter={4}>
                    <Col span={24}>
                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[{ required: true, message: 'Name is required' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Phone"
                            name="phone_number"
                            rules={[{ required: true, message: 'Phone is required' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[{ type: "email", message: 'Email should be valid' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Position"
                            name="position"
                        >
                            <Select
                                showSearch
                                filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())}
                                options={playerPositions?.enumValues?.map((enumValue) => {
                                    return { label: enumValue, value: enumValue }
                                })}>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Jersey Number"
                            name="preferred_jersey_no"
                        >
                            <InputNumber pattern="[0-9]*" inputmode="numeric"/>
                        </Form.Item>

                        <Form.Item
                            label="Image"
                            name="display_picture"
                        >
                            {/* <Upload
                            accept="image/png, image/jpeg"
                            beforeUpload={(file) => {
                                debugger
                                setFileList([file]);

                                return false;
                            }}
                            listType="picture-card"
                            fileList={fileList}
                        // onPreview={handlePreview}
                        // onChange={handleChange}
                        >
                            {fileList.length == 1 ? null : uploadButton}
                        </Upload> */}

                            <input type="file" id="select_file" onChange={(event) => readURL(event)} hidden />
                            {selectedImage && selectedImage?.src != '' ? <><img id="profile_image" src={selectedImage?.src} style={{ cursor: 'pointer', borderRadius: '50%' }} alt="your image" width={100} height={100} onClick={() => {
                                document.getElementById('select_file').click();
                            }} /> <Button onClick={() => setSelectedImage(null)}>Remove</Button> </> :
                                <div id="profile_image" src={selectedImage?.src} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%', border: '1px solid gray', height: '100px', width: '100px' }} onClick={() => {
                                    document.getElementById('select_file').click();
                                }}>Select image</div>}
                        </Form.Item>
                    </Col>

                    {!record && <Col span={24}>
                        <Divider>Select Team</Divider>
                        <Select onChange={(value) => setSelectedTeam(value)}
                            style={{width: '50%'}}
                            showSearch
                            filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())}
                            placeholder="Select team to add"
                            options={teams?.teams?.map((team) => {
                                return { label: team?.name, value: team?.id }
                            })}
                        >
                        </Select>
                    </Col>}
                    <Col span={24}>
                        <Space style={{ justifyContent: 'right', width: '100%' }}>
                            <Button htmlType="button" onClick={onReset}>
                                Reset
                            </Button>
                            <Button type="primary" htmlType="submit" loading={createUserLoading || updateUserLoading || addTeamPlayersLoading}>
                                {record ? 'Update' : 'Submit'}
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default CreateUser