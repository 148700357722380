import { gql } from "@apollo/client";

const CREATE_VENUE = gql`
  mutation CreateVenue(, $createVenueInput: CreateVenueInput!) {
    createVenue(createVenueInput:$createVenueInput) {
      id
      name
      address
      map_location
      contact_info
    }
  }
`;

export default CREATE_VENUE;
 