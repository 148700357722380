import { gql } from "@apollo/client";

const REMOVE_GOALRUSH_SCHOOL = gql`
  mutation RemoveGoalrushSchool($goalrush_school_id: String!) {
    removeGoalrushSchool(goalrush_school_id: $goalrush_school_id) {
      id
      name
      description
      links
      images
      type
      createdAt
      createdBy
    }
  }
`;

export default REMOVE_GOALRUSH_SCHOOL;
