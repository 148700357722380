import { useQuery } from "@apollo/client";
import {
  Button,
  Col,
  Modal,
  Row,
  Space,
  Table,
  Spin,
  Input,
  Card,
  Popconfirm,
} from "antd";
import Icon, { LoadingOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import CreateVenue from "./blocks/addNewVenue";
import GET_VENUES from "../../api/get_venue_list";
import Select from "rc-select";
import { List } from "immutable";
import Title from "antd/es/skeleton/Title";
import Meta from "antd/es/card/Meta";
import withAuthProtection from "../../utils/auth";

const Venue = () => {
  const [venues, setVenues] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const { data: allVenues, loading, refetch } = useQuery(GET_VENUES);

  useEffect(() => {
    const venueList = [];

    allVenues?.venues?.map((record) => {
      venueList.push({
        ...record,
        key: record?.id,
      });
    });

    setVenues(venueList);
  }, [allVenues]);
  const columns = [
    {
      title: "Venue name",
      dataIndex: "name",
      render: (name) => name,
      defaultSortOrder: "ascend",
      key: "name",
    },
    {
      title: "address",
      dataIndex: "address",
      render: (address) => address,
      defaultSortOrder: "ascend",
      key: "address",
    },
    {
      title: "Contact",
      dataIndex: "contact_info",
      render: (contact_info) => contact_info,
      defaultSortOrder: "ascend",
      key: "contact_info",
    },
  ];

  const showModal = () => {
    setIsCreateModalOpen(true);
  };

  const handleOk = () => {
    setIsCreateModalOpen(false);
  };

  const handleCancel = () => {
    setIsCreateModalOpen(false);
  };

  const onSearchVenue = (event) => {
    let data;

    if (event?.target?.value) {
      data = allVenues?.venues?.filter((record) =>
        record?.name
          ?.toLowerCase()
          .includes(event?.target?.value?.toLowerCase())
      );
    } else {
      data = allVenues?.venues;
    }
    const list = [];

    data.map((record) => {
      list.push({
        ...record,
        key: record?.id,
      });
    });

    setVenues(list);
  };

  return (
    <>
      <Row style={{ margin: "5px" }}>
        <Col span={5}>
          <Input placeholder="Search Venue" onChange={onSearchVenue} />
        </Col>
        <Col span={19} style={{ textAlign: "right" }}>
          <Space>
            <Button
              type="primary"
              onClick={showModal}
              icon={<PlusCircleOutlined />}
            >
              Add New Venue
            </Button>
          </Space>
        </Col>
      </Row>

      <Row grid={{ display: "flex", padding: "5px" }}>
        {venues?.map((record) => (
          <Col span={6} key={record.id}>
            <Card
              style={{
                minHeight: "150px",
                cursor: "pointer",
                background: "#eefaff",
                padding: "5px",
                margin: "5px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Row>
                <Meta title={record?.name} />
              </Row>
              <Row style={{ marginTop: "12px" }}>{record?.description}</Row>
              {record?.address}
            </Card>
          </Col>
        ))}
      </Row>

      {isCreateModalOpen && (
        <Modal open={isCreateModalOpen} footer={null} onCancel={handleCancel}>
          <CreateVenue refresh={refetch} close={handleCancel} />
        </Modal>
      )}
    </>
  );
};

export default withAuthProtection(Venue);
