import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Button, Card, Col, Popconfirm, Row, message } from "antd";
import { Modal } from "antd";
import DropdownButton from "antd/es/dropdown/dropdown-button";
import Icon, { DeleteOutlined } from "@ant-design/icons";
import ADD_SPONSORS_TO_TOURNAMENT_SEASON_MUTATION from "../../../../api/add_sponsors_to_tournament_seasons";
import GET_TOURNAMENT_SEASON_SPONSORS_QUERY from "../../../../api/get_tournament_season_sponsors_query";
import DELETE_SPONSOR_FROM_TOURNAMENT_SEASON_MUTATION from "../../../../api/delete_sponsor_from_tournament_season";
import AddNewSponsor from "./addNewSponsor";

const SponsorsTab = ({ selectedSeasonId }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [sponsorData, setSponsorData] = useState(null);
  const [addSponsorData, { data, error }] = useMutation(
    ADD_SPONSORS_TO_TOURNAMENT_SEASON_MUTATION
  );
  const [sponsorId, setSponsorId] = useState([]);

  const [
    getSponsorsData,
    {
      data: tournamentSponsors,
      loading: tournamentSeasonSponsorLoading,
      refetch,
    },
  ] = useLazyQuery(GET_TOURNAMENT_SEASON_SPONSORS_QUERY, {
    variables: { tournament_season_id: selectedSeasonId },
  });

  useEffect(() => {
    getSponsorsData();
  }, [selectedSeasonId]);

  const handleAddNewSponsorClick = () => {
    setIsModalVisible(true);
  };
  const [
    deleteSponsorData,
    { data: deleteSponsorDataResult, error: deleteSponsorDataError },
  ] = useMutation(DELETE_SPONSOR_FROM_TOURNAMENT_SEASON_MUTATION);

  const DeleteSponsorButtonClick = (sponsorId) => {
    deleteSponsorData({
      variables: { sponsor_id: sponsorId },
    })
      .then((response) => {
        refetch();
      })
      .catch((error) => {
        console.log("error");
      });
  };
  const cancel = (e) => {
    message.error("Cancelled");
  };
  return (
    <div className="ant-row">
      <Row style={{ width: 500, margin: 10, display: "flex" }}>
        <Button onClick={handleAddNewSponsorClick} type="primary">
          Add New Sponsor
        </Button>
      </Row>
      <Modal
        title="Add New Sponsor"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={() => {
          setIsModalVisible(false);
        }}
        okButtonProps={{}}
        footer={null}
      >
        <AddNewSponsor
          selectedSeasonId={selectedSeasonId}
          onCancel={() => setIsModalVisible(false)}
          onSubmit={() => refetch()}
        />
      </Modal>
      <Row gutter={[16, 16]}>
        {tournamentSponsors?.getTournamentSeasonSponsors?.map(
          (tournamentSponsors) => (
            <Col span={7} key={tournamentSponsors.id}>
              <Card>
                <img
                  width="auto"
                  height={120}
                  src={tournamentSponsors.display_picture}
                  alt={tournamentSponsors.name}
                />
                <Popconfirm
                  title="Delete the sponsor"
                  description="Are you sure to delete this sponsor?"
                  onConfirm={() =>
                    DeleteSponsorButtonClick(tournamentSponsors?.id)
                  }
                  onCancel={cancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    type="default"
                    style={{
                      position: "absolute",
                      right: 0,
                      top: 10,
                      left: 10,
                    }}
                    icon={<DeleteOutlined />}
                    danger
                  />
                </Popconfirm>
              </Card>
            </Col>
          )
        )}
      </Row>
    </div>
  );
};

export default SponsorsTab;
