import { gql } from "@apollo/client";

const ASSIGN_ROLES_FOR_SEASON_MUTATION = gql`
  mutation AssignRolesToMultipleUsersForSeasons(
    $input: AssignRolesToUsersInput!
  ) {
    assignRolesToMultipleUsersForSeasons(input: $input)
  }
`;

export default ASSIGN_ROLES_FOR_SEASON_MUTATION;
