import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Typography,
  Popconfirm,
  Input,
  Select,
  Space,
  Table,
  Row,
  InputNumber,
} from "antd";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  LoadingOutlined,
  EditOutlined,
  DeleteOutlined,
  SaveOutlined,
  CloseOutlined,
  FormOutlined,
  EditFilled,
  DeleteFilled,
} from "@ant-design/icons";
import { useLocation, useParams } from "react-router";
import moment from "moment";
import ASSIGN_ROLES_FOR_TOURNAMENT_MUTATION from "../../../api/assign_user_roles_for_tournaments";
import FIND_USERS_WITH_ROLE_BY_ENTITY from "../../../api/find_users_with_role_by_entity";
import REMOVE_USER_ROLE_FROM_TOURNAMENT from "../../../api/remove_user_role_from_tournament";
import GET_TOURNAMENT_BY_ID from "../../../api/get_tournament_by_id";
import GET_USERS from "../../../api/get_all_users";
import { Roles } from "../constants/const";

const TournamentTable = () => {
  const location = useLocation();
  const { id: tournamentId } = useParams();
  const [form] = Form.useForm();
  const [selectedSeasonId, setSelectedSeasonId] = useState();
  const [
    assignUserRoleTournaments,
    {
      data: assignUserRoleTournamentData,
      loading: assignUserRoleTournamentLoading,
      error: assignUserRoleTournamentError,
    },
  ] = useMutation(ASSIGN_ROLES_FOR_TOURNAMENT_MUTATION);
  const { data: tournamentData, loading: tournamentLoading } = useQuery(
    GET_TOURNAMENT_BY_ID,
    {
      variables: { id: tournamentId },
    }
  );
  const {
    data: usersWithTournamentRoleData,
    loading: usersWithTournamentRoleLoading,
    refetch: refetchTournamentRole,
    error,
  } = useQuery(FIND_USERS_WITH_ROLE_BY_ENTITY, {
    variables: {
      args: {
        entityId: tournamentId,
        entityType: "TOURNAMENT",
      },
    },
  });
  const [
    deleteUserRole,
    { loading: deleteUserRoleLoading, error: deleteUserRoleError },
  ] = useMutation(REMOVE_USER_ROLE_FROM_TOURNAMENT);
  const [selectedMatch, setSelectedMatch] = useState();
  const [selectedMatchTeam, setSelectedMatchTeam] = useState(null);
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;
  const { data: allUsers, loading } = useQuery(GET_USERS);
  const [tournamentDataSource, setTournamentDataSource] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [seasonDataSource, setSeasonDataSource] = useState([]);
  const [matchDataSource, setMatchDataSource] = useState([]);
  const [count, setCount] = useState(0);
  const { Option } = Select;

  useEffect(() => {
    if (usersWithTournamentRoleData?.findUsersWithRoleByEntity) {
      const newDataSource =
        usersWithTournamentRoleData.findUsersWithRoleByEntity.map(
          (user, index) => ({
            key: index + 1,
            userId: user.id,
            user: user.name,
            role: user.role,
          })
        );
      setTournamentDataSource(newDataSource);
    }
  }, [usersWithTournamentRoleData]);

  const TournamentColumn = [
    {
      title: "#",
      width: "3%",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "User",
      width: "40%",
      dataIndex: "user",
      key: "user",
      editable: true,
      onCell: (record) => ({
        record,
        dataIndex: "user",
        title: "User",
        editing: isEditing(record),
      }),
    },
    {
      title: "Role",
      width: "40%",
      dataIndex: "role",
      key: "role",
      editable: true,
      onCell: (record) => ({
        record,
        dataIndex: "role",
        title: "Role",
        editing: isEditing(record),
      }),
    },
    {
      title: "Operation",
      dataIndex: "operation",
      width: 120,
      render: (_, record) => {
        if (record.role === "CREATOR") {
          return null;
        }
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => handleSubmitTournament(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              <SaveOutlined />
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <CloseOutlined />
            </Popconfirm>
          </span>
        ) : (
          <Space>
            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              <EditOutlined />
            </Typography.Link>
            <Typography.Link>
              <Popconfirm
                title="Delete"
                onConfirm={() => handleDelete(record.key)}
              >
                <DeleteOutlined style={{ marginLeft: "8px" }} />
              </Popconfirm>
            </Typography.Link>
          </Space>
        );
      },
    },
  ];

  const handleSubmitTournament = async (key) => {
    const formValue = form.getFieldsValue();
    try {
      const input = {
        userIds: [formValue.user],
        role: formValue.role,
        entityId: tournamentId,
      };
      const response = await assignUserRoleTournaments({
        variables: { input },
      });
      const row = await form.validateFields();
      const selectedUser = allUsers.users.find((u) => u.id === formValue.user);
      const userName = selectedUser ? selectedUser.name : null;

      const updatedData = { ...formValue, user: userName };
      const newData = [...tournamentDataSource];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        newData[index] = { ...newData[index], ...updatedData };
        setTournamentDataSource(newData);
        setEditingKey("");
        refetchTournamentRole();
      } else {
        newData.push(row);
        setTournamentDataSource(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const handleAdd = () => {
    const newKey = tournamentDataSource.length + 1;
    form.resetFields();
    const newRow = {
      key: newKey,
      user: "",
      role: "",
      isNew: true,
    };

    setTournamentDataSource((prevDataSource) => [...prevDataSource, newRow]);
    setEditingKey(newKey);
  };

  const handleDeleteUserRole = async (userId, entityId) => {
    try {
      await deleteUserRole({
        variables: {
          input: { userId, entityId },
        },
      });
      refetchTournamentRole();
    } catch (error) {
      console.error("Error deleting user role:", error);
    }
  };

  const handleDelete = (key) => {
    const record = tournamentDataSource.find((item) => item.key === key);
    if (record) {
      handleDeleteUserRole(record.userId, tournamentId);
    }
  };

  const cancel = () => {
    const newData = tournamentDataSource
      .map((item) => {
        if (item.isNew && editingKey === item.key) {
          return null;
        }
        return item;
      })
      .filter((item) => item != null);

    setTournamentDataSource(newData);
    setEditingKey("");
    form.resetFields();
  };

  const edit = (record) => {
    form.setFieldsValue({
      user: "",
      role: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  const onSearch = (value) => {
    setSelectedMatch(value);
  };

  const handleSeasonSelect = (value) => {
    setSelectedSeasonId(value);
  };

  const getUserValues = (value) => {
    const usersArray = allUsers.users;
    if (!Array.isArray(usersArray)) {
      console.error("allUsers is not an array");
      return;
    }
    const user = usersArray.find((user) => user.id === value);
    if (user) {
      form.setFieldValue({ user: user.name });
    } else {
      console.error("User not found");
    }
  };

  const UserDropdown = () => (
    <Form.Item
      name="user"
      rules={[{ required: true, message: "Please input user!" }]}
    >
      <Select
        showSearch
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onChange={(value) => getUserValues(value)}
      >
        {allUsers?.users?.map((user) => (
          <Option key={user.id} value={user.id}>
            {user.name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );

  const RoleDropdown = () => (
    <Form.Item
      name="role"
      rules={[{ required: true, message: "Please input role!" }]}
    >
      <Select
        showSearch
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onChange={(value) => form.setFieldsValue({ role: value })}
      >
        {Object.entries(Roles).map(([key, value]) => (
          <Option key={key} value={value}>
            {value}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    let inputNode;

    if (dataIndex == "user") {
      inputNode = UserDropdown();
    } else if (dataIndex == "role") {
      inputNode = RoleDropdown();
    } else {
      inputNode =
        inputType === "number" ? (
          <InputNumber pattern="[0-9]*" inputmode="numeric" />
        ) : (
          <Input />
        );
    }

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  return (
    <>
      <Row style={{ marginTop: "50px" }}>
        <Col span={16}>
          <h2>{tournamentData?.tournament?.name}</h2>
        </Col>
        <Col span={8} style={{ textAlign: "right", marginBottom: "8px" }}>
          <Space>
            <Button onClick={() => handleAdd()}>Add New</Button>
          </Space>
        </Col>
        <Col span={24}>
          <Form form={form} onFinish={handleSubmitTournament} component={false}>
            <Table
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              bordered
              dataSource={tournamentDataSource}
              columns={TournamentColumn}
              pagination={false}
              scroll={{ y: 300, x: "auto" }}
            />
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default TournamentTable;
